
































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Contact, ProjectContact } from '@/models/contacts/interfaces';
export default defineComponent({
  props: {
    contact: {
      type: Object as PropType<Contact | ProjectContact>,
      required: true,
    },
    allowCrud: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    small: {
      type: Boolean,
    },
  },
  setup(props, { slots }) {
    const hasActions = computed(() => {
      return slots.actions && slots.actions().length;
    });

    const fullName = computed(() => {
      return `${props.contact.FirstName} ${props.contact.LastName}`;
    });

    return {
      hasActions,
      fullName,
    };
  },
});
