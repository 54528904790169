var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},on:{"click:outside":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock-reset")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('user.change-pw-title')))])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{attrs:{"id":"password-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"password-content",attrs:{"tile":"","flat":""}},[_c('dialog-title',{attrs:{"headerText":_vm.$t('user.change-pw-title')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}])}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-3",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('user.change-pw-label1'),"type":"password","rules":[
                _vm.rules.required,
                _vm.rules.min,
                _vm.rules.containsLower,
                _vm.rules.containsUpper ],"required":"","outlined":"","clearable":"","autofocus":""},model:{value:(_vm.payload.Password),callback:function ($$v) {_vm.$set(_vm.payload, "Password", $$v)},expression:"payload.Password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","type":"password","clearable":"","outlined":"","label":_vm.$t('user.change-pw-label2'),"rules":[
                _vm.rules.required,
                _vm.rules.passwordMatch,
                _vm.rules.containsLower,
                _vm.rules.containsUpper ],"required":""},model:{value:(_vm.payload.ConfirmPassword),callback:function ($$v) {_vm.$set(_vm.payload, "ConfirmPassword", $$v)},expression:"payload.ConfirmPassword"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.passwordValidator,"color":"secondary","type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }