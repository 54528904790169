var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.calculatedCertificates.length === 0)?_c('div',[_vm._v(" - ")]):(_vm.calculatedCertificates.length > 2)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":"info"}},[_vm._v("mdi-certificate")]),_c('span',{staticClass:"overflow-ellipsis"},[_vm._v(_vm._s(_vm.calculatedCertificates[0].propertyName))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.calculatedCertificates[0].propertyDescription))])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":"info"}},[_vm._v("mdi-certificate")]),_c('span',{staticClass:"overflow-ellipsis"},[_vm._v(_vm._s(_vm.calculatedCertificates[1].propertyName))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.calculatedCertificates[1].propertyDescription))])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":"primary"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"certificate-length"},[_vm._v(_vm._s(_vm.calculatedCertificates.length - 2))])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.calculatedCertificates),function(certificate,i){return [(i > 1)?_c('v-list-item',{key:i},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":"info"}},[_vm._v("mdi-certificate")]),_c('span',{staticClass:"overflow-ellipsis"},[_vm._v(" "+_vm._s(certificate.propertyName))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(certificate.propertyDescription))])])],1):_vm._e()]})],2)],1)],1):_c('div',_vm._l((_vm.calculatedCertificates),function(certificate){return _c('v-tooltip',{key:certificate.propertyGuid,attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":"","color":"info"}},[_vm._v("mdi-certificate")]),_vm._v(" "+_vm._s(certificate.propertyName)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(certificate.propertyDescription))])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }