var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-bar',{attrs:{"title":_vm.$t('titles.project-overview'),"breadcrumb":_vm.activeProjectOverview && _vm.activeProjectOverview.Name},scopedSlots:_vm._u([{key:"content-right",fn:function(){return [(
          _vm.activeProjectOverview &&
            _vm.activeProjectOverview.Children &&
            _vm.activeProjectOverview.Children.length > 0 &&
            !_vm.loading
        )?_c('new-calculation',{attrs:{"show":_vm.props.createNewCalculation,"name":_vm.props.calculationName,"parent":_vm.activeProjectOverview}}):_vm._e(),(
          _vm.activeProjectOverview &&
            _vm.activeProjectOverview.Children &&
            _vm.activeProjectOverview.Children.length > 0
        )?_c('reports',{attrs:{"isProject":true}}):_vm._e(),(
          _vm.activeProjectOverview &&
            _vm.activeProjectOverview.Children &&
            _vm.activeProjectOverview.Children.length > 0 &&
            !_vm.loading
        )?_c('import-calculation',{attrs:{"basic":true,"parent":_vm.activeProjectOverview}}):_vm._e(),(
          _vm.activeProjectOverview &&
            _vm.activeProjectOverview.Children &&
            _vm.activeProjectOverview.Children.length > 0
        )?_c('export-project',{attrs:{"project":_vm.activeProjectOverview}}):_vm._e(),(_vm.activeProjectOverview)?_c('delete-project',{attrs:{"project":_vm.activeProjectOverview}}):_vm._e()]},proxy:true}])}),(_vm.activeProjectOverview)?_c('v-container',{staticClass:"grey lighten-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(
            _vm.activeProjectOverview &&
              _vm.activeProjectOverview.Children &&
              _vm.activeProjectOverview.Children.length > 0
          )?_c('calculations',{attrs:{"parent":_vm.activeProjectOverview,"calculations":_vm.activeProjectOverview.Children
              ? _vm.activeProjectOverview.Children
              : [],"showHeaders":true,"small":true}}):_c('kalk-helper',{attrs:{"small":true,"title":_vm.$t('titles.add-first-calculation')}},[_c('template',{slot:"message"},[_vm._v(" "+_vm._s(_vm.$t('info.start-project1'))+" "),_c('a',{staticClass:"documentation",attrs:{"href":_vm.documentationUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('info.start-project2')))]),_vm._v(" "+_vm._s(_vm.$t('info.start-project3'))+" ")]),_c('template',{slot:"actions"},[_c('new-calculation',{attrs:{"parent":_vm.activeProjectOverview}}),_vm._v(" / "),_c('import-calculation',{attrs:{"basic":true,"parent":_vm.activeProjectOverview}})],1)],2)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('project-details')],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }