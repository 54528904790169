



























import {
  BuildingItemCalculation,
  ItemStatus,
} from '@/models/building-element/interfaces';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    buildingItemCalc: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
  },
  setup() {
    return {
      ItemStatus,
    };
  },
});
