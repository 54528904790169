


































import { defineComponent } from '@vue/composition-api';
import { DocumentationStatus } from '@/models/building-element/interfaces';

export default defineComponent({
  props: {
    nobbNr: {
      type: Number,
    },
    status: {
      type: Number,
    },
  },
  setup() {
    return {
      DocumentationStatus,
    };
  },
});
