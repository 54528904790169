































































































import Factor from '@/components/factors/factor/index.vue';
import FactorSummary from '@/components/factors/factor-summary/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import { useFactors } from '@/modules/factors';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { CompanyFactor } from '@/models/company/interfaces';
import { useProject } from '@/modules/project';
import {
  getIconByProfessionCode,
  getNameByProfessionCode,
} from '@/helpers/common';
import ContentBar from '@/components/common/content-bar/index.vue';
import Loader from '@/components/loader/index.vue';

export default defineComponent({
  components: {
    Factor,
    FactorSummary,
    Confirm,
    InfoTooltip,
    ContentBar,
    Loader,
  },
  setup() {
    const { loading, resetFactors, filteredFactors, getFactors } = useFactors();
    const { getProfessions, professions } = useProject();
    const dialog = ref<boolean>(false);
    const activeProf = ref<string>('03');

    const factors = ref<Array<CompanyFactor> | undefined>();

    const initFactors = async () => {
      factors.value = await getFactors();
      await getProfessions();
    };

    onMounted(async () => {
      await initFactors();
    });

    const reset = async () => {
      await resetFactors();
    };

    return {
      getIconByProfessionCode,
      getNameByProfessionCode,
      filteredFactors,
      reset,
      initFactors,
      loading,
      factors,
      professions,
      dialog,
      activeProf,
    };
  },
});
