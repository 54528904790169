








































































































































import {
  BlobPriceListCategoryOverview,
  PriceListItem,
} from '@/models/pricebook/interfaces';
import {
  defineComponent,
  ref,
  PropType,
  computed,
  onMounted,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';
import { usePriceBook } from '@/modules/pricebook';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    category: {
      type: Object as PropType<BlobPriceListCategoryOverview>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { insertPriceListItem } = usePriceBook();
    const dialog = ref(false);
    const loading = ref(false);
    const item = ref<PriceListItem>();

    const close = () => {
      dialog.value = false;
    };

    const valid = computed(() => {
      return true;
    });

    onMounted(() => {
      if (props.category) {
        item.value = {
          Id: uuidv4(),
          Name: null,
          ItemNumber: null,
          NOBBNumber: null,
          BasePrice: null,
          DiscountPercentage: null,
          Unit: null,
          ItemsInPackage: null,
          PackageUnitDesc: null,
          Description: null,
          Price: null,
        };
      }
    });

    const submit = async () => {
      loading.value = true;
      if (item.value) {
        //Without NOBBnumber it cannot be found in pricesearch, 9 is the number associated with "Egendefinert", adding in shadow if not set
        item.value.NOBBNumber = item.value.NOBBNumber ?? 9;
        await insertPriceListItem(props.category, item.value);
        emit('update', true);
      }
      loading.value = false;
      close();
    };

    return {
      close,
      submit,
      dialog,
      valid,
      loading,
      item,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
    };
  },
});
