export const HOME_PATH = '/';
export const VERSIONS_PATH = '/versions';
export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';

export const PROJECT_START_PATH = '/project/start';
export const PROJECT_PRICEBOOK_PATH = '/pricebook';
export const PROJECT_ORGANIZATION_PATH = '/organization';
export const PROJECT_CALCULATION_PATH = '/project/calculation';
export const PROJECT_CALCULATION_OVERVIEW_PATH =
  '/project/calculation/overview';
export const PROJECT_CALCULATION_BUILDING_ELEMENT_PATH =
  '/project/calculation/element';
export const PROJECT_CALCULATION_PRICE_UPDATE_PATH =
  '/project/calculation/price-update';
export const PROJECT_CALCULATION_ENVIRONMENT = '/project/environment';
export const PROJECT_ADMIN_PATH = '/admin';
export const PROJECT_OVERVIEW_PATH = '/project/overview';
export const PROJECT_LIBRARY_PATH = '/library';

export const PUBLIC_PATHS = [HOME_PATH, LOGIN_PATH, VERSIONS_PATH];
