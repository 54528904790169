var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.buildingItemUnitIsEqualToPriceUnit &&
      _vm.props.priceUnit &&
      _vm.props.buildingItemUnit
  )?_c('div',[_c('span',{staticClass:"pr-1"},[_vm._v(" "+_vm._s(_vm.calculatedByAmount !== undefined ? _vm.calculatedByAmount.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 10, }) : '-')+" ")]),_c('span',[_vm._v(_vm._s(_vm.unit))])]):(_vm.props.priceUnit && _vm.props.buildingItemUnit)?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('environment.reference-vs-building-item-unit', { reference: _vm.unitDescriptionConverter(_vm.props.priceUnit), used: _vm.props.buildingItemUnit, })))])])],1):_c('div',[_vm._v("-")])}
var staticRenderFns = []

export { render, staticRenderFns }