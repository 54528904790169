








































import { ref, PropType, defineComponent } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { useBuildingItem } from '@/modules/building-item';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    item: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
    icon: {
      type: Boolean,
    },
    isMenuitem: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const { deleteItemFromCalculation } = useBuildingItem();

    const deleteEvent = () => {
      loading.value = true;
      deleteItemFromCalculation(props.item);
      loading.value = false;
      emit('close', true);
      dialog.value = false;
    };

    return {
      deleteEvent,
      dialog,
      loading,
    };
  },
});
