








































































import {
  ref,
  defineComponent,
  PropType,
  onMounted,
  computed,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';
import { useCalculation } from '@/modules/calculation';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import { useLibrary } from '@/modules/library';
import { BuildingTypeTemplate } from '@/models/library/interfaces';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
    primary: {
      type: Boolean,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const loading = ref(false);
    const selected = ref<BuildingTypeTemplate>();
    const newName = ref<Nullable<string>>(props.element.Name ?? null);

    const { projectCalculation } = useCalculation();
    const {
      addElementToLibrary,
      getBuildingTemplatesByProfession,
      buildingTemplatesByProfession,
    } = useLibrary();

    const valid = computed(() => {
      return selected.value != null && newName.value;
    });

    onMounted(async () => {
      if (
        !buildingTemplatesByProfession.value &&
        projectCalculation.value?.Profession?.Code
      ) {
        await getBuildingTemplatesByProfession(
          projectCalculation.value.Profession.Code,
          true
        );
      }
    });

    const close = () => {
      dialog.value = false;
      selected.value = undefined;
      newName.value = null;
    };

    const submit = async () => {
      if (selected.value && valid.value && newName.value) {
        loading.value = true;
        await addElementToLibrary(selected.value, props.element, newName.value);
        loading.value = false;
        dialog.value = false;
      }
    };

    return {
      close,
      submit,
      buildingTemplatesByProfession,
      dialog,
      loading,
      valid,
      newName,
      selected,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
    };
  },
});
