import { reactive, toRefs } from '@vue/composition-api';

interface State {
  amountTransition: boolean;
}

const state = reactive<State>({
  amountTransition: true,
});

export const useNudge = () => {
  const nudgeAmount = () => {
    state.amountTransition = false;
    state.amountTransition = true;
  };
  return {
    ...toRefs(state),
    nudgeAmount,
  };
};
