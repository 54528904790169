







import { defineComponent } from '@vue/composition-api';
import BuildingElement from '@/components/calculation/building-elements/index.vue';
import LibraryDrawer from '@/components/library/drawer/index.vue';

export default defineComponent({
  components: {
    BuildingElement,
    LibraryDrawer,
  },
});
