var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{class:{
    'no-nobb':
      _vm.buildingItem.Status !== null &&
      _vm.buildingItem.Status.StatusType === _vm.ItemStatus.MissingNobb,
  }},[_c('tr',{staticClass:"remove-height white-background"},[_c('td',[_c('div',{staticClass:"product-with-check"},[_c('v-simple-checkbox',{attrs:{"value":_vm.isSelected,"ripple":false,"disabled":!_vm.isEnabled},on:{"click":function($event){_vm.isEnabled && _vm.emit('toggle-select', _vm.buildingItem)}}}),_c('div',[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.buildingItem.Name)+" ")]),_c('div',[_vm._v(_vm._s(_vm.buildingItem.PriceListItemName))])])],1)]),_c('td',[_c('BuildingItemStatus',{attrs:{"buildingItemCalc":_vm.buildingItem}})],1),_c('td',[(
          _vm.buildingItem.PriceMatch &&
            _vm.buildingItem.PriceMatch.BuildingItemPriceListItemName
        )?_c('div',{staticClass:"emphasize"},[_vm._v(" "+_vm._s(_vm.buildingItem.PriceMatch.BuildingItemPriceListItemName)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm.buildingItem.Price ? _vm.buildingItem.Price.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }) : '')+" ")]),_c('td',[(
          _vm.buildingItem.PriceMatch &&
            _vm.buildingItem.PriceMatch.DiscountPercentage
        )?_c('div',[_vm._v(" "+_vm._s(_vm.buildingItem.PriceMatch.DiscountPercentage)+" ")]):_vm._e()]),_c('td',[(_vm.buildingItem.PriceMatch && _vm.buildingItem.PriceMatch.BasePrice)?_c('div',[_vm._v(" "+_vm._s(_vm.buildingItem.PriceMatch.BasePrice)+" ")]):_vm._e()]),_c('td',[_c('div',{staticClass:"pricediff-grid"},[(
            _vm.buildingItem.PriceMatch !== null &&
              (_vm.buildingItem.PriceMatch.PriceDifference.value <
                _vm.negativeThreshold ||
                _vm.buildingItem.PriceMatch.PriceDifference.value >
                  _vm.positiveThreshold)
          )?_c('div',[_vm._v(" "+_vm._s(_vm.buildingItem.PriceMatch.PriceDifference.value.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 2, } ))+" ")]):(_vm.buildingItem.PriceMatch !== null)?_c('div',[_vm._v("0")]):_vm._e(),(
            _vm.buildingItem.PriceMatch !== null &&
              _vm.buildingItem.PriceMatch.PriceDifference.value <
                _vm.negativeThreshold
          )?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-trending-down")]):(
            _vm.buildingItem.PriceMatch !== null &&
              _vm.buildingItem.PriceMatch.PriceDifference.value >
                _vm.positiveThreshold
          )?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-trending-up")]):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"emphasize"},[_vm._v(" "+_vm._s(_vm.buildingItem.PriceMatch && _vm.buildingItem.PriceMatch.PriceListPrice ? _vm.buildingItem.PriceMatch.PriceListPrice.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 2, } ) : '')+" ")])]),_c('td',[(_vm.actionsAvailable)?_c('v-text-field',{staticClass:"amountprunit",attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.emit('amount-change', _vm.buildingItem)},"focus":function($event){return $event.target.select()}},model:{value:(_vm.buildingItem.AmountPrUnit),callback:function ($$v) {_vm.$set(_vm.buildingItem, "AmountPrUnit", _vm._n($$v))},expression:"buildingItem.AmountPrUnit"}}):_c('div',[_vm._v(" "+_vm._s(_vm.buildingItem.AmountPrUnit ? _vm.buildingItem.AmountPrUnit.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }) : '')+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.buildingItem.UnitForOrder))]),_c('td',[(
          _vm.buildingItem.PriceMatch && _vm.buildingItem.PriceMatch.PriceListUnit
        )?_c('div',{class:_vm.buildingItem.PriceMatch.HasDifferentUnit.value
            ? 'emphasize-red'
            : 'emphasize'},[_vm._v(" "+_vm._s(_vm.buildingItem.PriceMatch.PriceListUnit)+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.buildingItem.PriceListName))]),_c('td',[_vm._v(_vm._s(_vm.buildingItem.NOBBNumber))]),_c('td',[_c('div',{staticClass:"action-grid"},[(_vm.actionsAvailable)?_c('get-product',{attrs:{"itemCalc":_vm.buildingItem,"iconButton":true},on:{"product":function (product) { return _vm.emit('update-product', product); }}}):_vm._e(),(_vm.actionsAvailable && !_vm.buildingItem.Precut)?_c('edit-time-estimate',{attrs:{"item":_vm.buildingItem,"iconButton":true},on:{"updated":function($event){return _vm.emit('time-updated', _vm.buildingItem)}}}):_vm._e(),(
            _vm.actionsAvailable &&
              _vm.buildingItem.IsTimeEstimateAdded.value === true &&
              !_vm.buildingItem.Precut
          )?_c('remove-time-estimate',{attrs:{"item":_vm.buildingItem,"iconButton":true},on:{"updated":function($event){return _vm.emit('time-updated', _vm.buildingItem)}}}):_vm._e(),(_vm.buildingItem.NOBBNumber)?_c('nobb-info',{attrs:{"nobbNr":_vm.buildingItem.NOBBNumber,"iconButton":true}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }