


















































































import CalcFactorEditModal from '@/components/factors/calculation-factors/edit-modal/index.vue';
import { calculateFactor, calculateValue } from '@/helpers/factor';
import { useCalculation } from '@/modules/calculation';
import { useFactors } from '@/modules/factors';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    CalcFactorEditModal,
  },
  setup() {
    const { getFactorNameFromId } = useFactors();
    const { projectCalculation } = useCalculation();

    return {
      calculateFactor,
      getFactorNameFromId,
      calculateValue,
      projectCalculation,
    };
  },
});
