import {
  PriceListItem,
  SearchablePriceListItem,
} from '@/models/pricebook/interfaces';

export const calculatedPrice = (
  item: PriceListItem | SearchablePriceListItem
): Nullable<number> => {
  let price: Nullable<number> = null;
  if (!item.BasePrice) return price;

  if (item.DiscountPercentage == null) {
    price = item.BasePrice;
  } else {
    price = Number(item.BasePrice * (100 - item.DiscountPercentage)) / 100;
  }

  return price;
};
