var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000"},on:{"click:outside":_vm.close},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('buttons.edit'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('dialog-title',{attrs:{"headerText":_vm.$t('titles.calculation-factors'),"secondaryText":_vm.name},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),_c('add-modal',{attrs:{"unit":_vm.unit,"items":_vm._factorItems,"name":_vm.name},on:{"added":_vm.added}}),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]},proxy:true}])}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm._factorItems,"loading-text":_vm.$t('info.loading'),"no-data-text":_vm.$t('info.no-data'),"no-results-text":_vm.$t('info.no-result'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"font-bold",attrs:{"hide-details":"","outlined":"","label":_vm.$t('labels.name'),"dense":""},on:{"focus":function($event){return $event.target.select()},"input":function($event){_vm.change = true}},model:{value:(item.Name),callback:function ($$v) {_vm.$set(item, "Name", $$v)},expression:"item.Name"}})]}},{key:"item.Value",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"font-bold",attrs:{"hide-details":"","outlined":"","label":_vm.$t('labels.value'),"dense":"","type":"number"},on:{"focus":function($event){return $event.target.select()},"input":function($event){item.Value = Number.isNaN($event) ? 0 : Number($event)},"change":function($event){_vm.change = true}},model:{value:(item.Value),callback:function ($$v) {_vm.$set(item, "Value", _vm._n($$v))},expression:"item.Value"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","dark":"","outlined":"","color":"#c10909b0"},on:{"click":function($event){_vm.deleteEvent(_vm._factorItems.indexOf(item))}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.remove'))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.unit",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.unit)+" ")]}}],null,true)}),_c('v-row',{staticClass:"summary"},[(_vm.unit === '%')?_c('v-col',{staticClass:"factor-highlight"},[_vm._v(" "+_vm._s(_vm.$t('labels.factor'))+": "),_c('span',{staticClass:"factor"},[_vm._v(_vm._s(_vm.calculateFactor(_vm._factorItems)))])]):_vm._e(),_c('v-col',{staticClass:"factor-highlight"},[_vm._v(" "+_vm._s(_vm.$t('labels.sum'))+": "),_c('span',{staticClass:"sum"},[_vm._v(_vm._s(_vm.calculateValue(_vm._factorItems))+" "+_vm._s(_vm.unit))])])],1)],1),_c('v-card-actions',{staticClass:"factor-actions"},[_c('info-tooltip',{attrs:{"information":_vm.$t('info.factors-edit', { name: _vm.name })}}),_c('v-spacer'),_c('v-form',{attrs:{"id":"factor-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-btn',{attrs:{"color":"secondary","type":"submit","loading":_vm.loading,"disabled":!(_vm.add || _vm.del || _vm.change)}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"close-action",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }