export enum ProffesionType {
  ConcreteWork = '05',
  Masonry = '09',
  GroundWork = '03',
  Carpentry = '12',
  Rigs = '01',
  PainterWork = '21',
  SubContractors = '99',
}

export enum VersionNoteType {
  New,
  Change,
  Remove,
  Fixed,
}

export enum ProjectStartFilter {
  Recent,
  MyProjects,
  All,
}

export enum RowColors {
  Default,
  Red,
  Orange,
  Green,
}

export enum RowColorsElement {
  Default = 0,
  Orange = 3,
  Green = 4,
  Red = 5,
}

export enum CalculationColumnView {
  Calculation,
  HourlyConsumption,
  Economy,
  Materials,
  Delivery,
}

export enum ExportType {
  JSON,
  XML,
}

export enum ApplicationSource {
  External,
  Kalk2010,
  KalkOnline,
}

export enum AddItemMethod {
  CreateNew = 1,
  FromTimeBook = 2,
  FromPriceBook = 3,
  FromCalculation = 4,
  FromLibrary = 5,
  FromNobb = 6,
}

export enum CalculationState {
  Idle,
  UnsavedChanges,
  Updated,
  Problem,
}

export enum BatchType {
  Save,
  Insert,
  Delete,
}

export enum PublicationType {
  Hmf,
  Fdv,
  Pre,
  Bro,
  Ser,
  Man,
  Mlj,
  Hms,
  Sds,
  Ifc,
  Epd,
  Yte,
  Breeam,
  Mtg,
  Esds,
  Vb,
  Vid,
  Clp,
  Ecop,
  TekG,
  Prods,
  Cpr,
  Eta,
}
