






















































































































































import { Contact, ProjectContact } from '@/models/contacts/interfaces';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import ContactCard from '@/components/contacts/contact/index.vue';
import { useContacts } from '@/modules/contacts';
import Confirm from '@/components/common/confirm/index.vue';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { useCalculation } from '@/modules/calculation';

export default defineComponent({
  components: {
    ContactCard,
    Confirm,
    DialogTitle,
  },
  setup() {
    const {
      getContacts,
      addContactToCalculation,
      removeContactFromCalculation,
      setAsPrimaryContact,
      existsAsCalculationContact,
      existsAsPrimary,
    } = useContacts();
    const { getCalculationContacts, projectCalculation } = useCalculation();

    const calculationContacts = ref<Array<ProjectContact>>();
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(true);
    const contacts = ref<Array<Contact> | undefined>(undefined);

    onMounted(async () => {
      if (projectCalculation.value?.Id) {
        contacts.value = await getContacts();
        calculationContacts.value = await getCalculationContacts(
          projectCalculation.value.Id
        );
      }

      loading.value = false;
    });

    const close = () => {
      dialog.value = false;
    };

    const handleSetAsPrimary = async (contact: Contact) => {
      if (!contact.Id) return;
      loading.value = true;
      await setAsPrimaryContact(contact.Id);
      if (projectCalculation.value?.Id) {
        calculationContacts.value = await getCalculationContacts(
          projectCalculation.value.Id,
          true
        );
      }
      loading.value = false;
    };

    const handleAddContact = async (contact: Contact) => {
      loading.value = true;
      await addContactToCalculation(contact);
      if (projectCalculation.value?.Id) {
        calculationContacts.value = await getCalculationContacts(
          projectCalculation.value.Id,
          true
        );
      }
      loading.value = false;
    };

    const handleRemoveContact = async (contact: Contact) => {
      loading.value = true;
      await removeContactFromCalculation(contact);
      if (projectCalculation.value?.Id) {
        calculationContacts.value = await getCalculationContacts(
          projectCalculation.value.Id,
          true
        );
      }
      loading.value = false;
    };

    return {
      dialog,
      loading,
      contacts,
      calculationContacts,
      close,
      existsAsCalculationContact,
      existsAsPrimary,
      handleSetAsPrimary,
      handleAddContact,
      handleRemoveContact,
    };
  },
});
