


































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useLibrary } from '@/modules/library';
import { useCalculation } from '@/modules/calculation';
import {
  BuildingTypeTemplate,
  BuildingElementTemplate,
  BuildingItemTemplate,
} from '@/models/library/interfaces';
import {
  BUILDING_ELEMENT_HEADERS_SIMPLE_ALT2,
  BUILDING_ITEM_HEADERS_SIMPLE_ALT1,
} from '@/constants/table-headers';
import { cleanSource, getIconByProfessionCode } from '@/helpers/common';
import Loader from '@/components/loader/index.vue';
import { sortBuildingElementTemplatesByNS3451 } from '@/helpers/library';
import { useStandards } from '@/modules/standards';
import { Ns3451 } from '@/models/standards/ns3451/interfaces';

interface BuildingElementTemplateNodes extends BuildingElementTemplate {
  Items: Array<BuildingItemTemplate>;
}

interface NS3451BuildingTemplates {
  Name: string;
  Items: Array<
    | BuildingElementTemplateNodes
    | BuildingItemTemplate
    | NS3451BuildingTemplates
  >;
}

export default defineComponent({
  props: {
    selectItems: {
      type: Boolean,
    },
  },
  components: {
    Loader,
  },
  setup(props, { emit }) {
    const {
      getBuildingTemplatesByProfession,
      getBuildingTemplate,
    } = useLibrary();
    const { activeCalculation } = useCalculation();
    const { getStandards } = useStandards();

    const templates = ref<Array<BuildingTypeTemplate>>();
    const selectedTemplate = ref<BuildingTypeTemplate>();
    const elements = ref<Array<BuildingElementTemplate>>();
    const filteredElements = ref<Array<BuildingElementTemplate>>([]);
    const selected = ref<BuildingElementTemplate>();
    const standards = ref<Array<Ns3451>>();
    const categorizedTemplates = ref<Array<NS3451BuildingTemplates>>([]);

    const search = ref<string>('');
    const loading = ref<boolean>(false);

    const groupByToMap = <T, Q>(
      array: T[],
      predicate: (value: T, index: number, array: T[]) => Q
    ) =>
      array.reduce((map, value, index, array) => {
        const key = predicate(value, index, array);
        map.get(key)?.push(value) ?? map.set(key, [value]);
        return map;
      }, new Map<Q, T[]>());

    const getNS3451NameFromStandards = (key: Nullable<string>): string => {
      if (!standards.value || (!key && key?.length !== 3)) return 'Ukjendt';

      const topLevel = Number(key.charAt(0));
      const topLevelNS3451 = standards.value.find(s => s.Number === topLevel);
      if (topLevelNS3451) {
        const secondLevel = Number(`${topLevel}${key.charAt(1)}`);
        const secondLevelNS3451 = topLevelNS3451.Children.find(
          s => s.Number === secondLevel
        );
        if (secondLevelNS3451) {
          const thirdLevel = Number(
            `${topLevel}${key.charAt(1)}${key.charAt(2)}`
          );
          const thirdLevelNS3451 = secondLevelNS3451.Children.find(
            s => s.Number === thirdLevel
          );
          if (thirdLevelNS3451) {
            return thirdLevelNS3451.CodeName;
          }
        }
      }

      return 'Ukjendt';
    };

    const buildNS3451Categories = (
      templates: Array<BuildingElementTemplate>
    ) => {
      const newCategories: Array<NS3451BuildingTemplates> = [];

      const groupedTemplates = groupByToMap(templates, e => e.NS3451);

      groupedTemplates.forEach((value, key) => {
        const elementTemplates: Array<BuildingElementTemplateNodes> = [];
        value.forEach(et => {
          const elementNode: BuildingElementTemplateNodes = {
            ...et,
            Name: props.selectItems
              ? `${et.Name} (${et.BuildingItemTemplates.length})`
              : et.Name,
            Items: props.selectItems ? et.BuildingItemTemplates : [],
          };
          elementTemplates.push(elementNode);
        });

        const category: NS3451BuildingTemplates = {
          Name: `${getNS3451NameFromStandards(key)} (${value.length})`,
          Items: elementTemplates,
        };

        newCategories.push(category);
      });

      categorizedTemplates.value = newCategories;
    };

    const updateElements = async (template: BuildingElementTemplate) => {
      loading.value = true;
      elements.value = [];
      if (template.Id) {
        const _bt = await getBuildingTemplate(template.Id);
        if (_bt?.BuildingElementTemplates) {
          if (!elements.value) elements.value = [];
          elements.value = _bt.BuildingElementTemplates;
          filteredElements.value = cleanSource(elements.value ?? []);
          filteredElements.value = sortBuildingElementTemplatesByNS3451(
            filteredElements.value
          );
          buildNS3451Categories(filteredElements.value);
        }
      }
      loading.value = false;
    };

    watch([search], () => {
      if (elements.value && search.value != null) {
        filteredElements.value = elements.value.filter(
          ele =>
            (ele.Name && ele.Name.includes(search.value.toUpperCase())) ||
            ele.BuildingItemTemplates.find(
              i =>
                i.Name &&
                i.Name.toUpperCase().includes(search.value.toUpperCase())
            ) ||
            ele.NS3451?.toUpperCase().includes(search.value.toUpperCase())
        );
      } else {
        filteredElements.value = cleanSource(elements.value ?? []);
      }
      buildNS3451Categories(filteredElements.value);
    });

    const addElement = (elementTemplate: BuildingElementTemplate) => {
      if (elementTemplate) {
        emit('addFromTemplate', elementTemplate);
      }
    };

    const addItem = (elementItemTemplate: BuildingItemTemplate) => {
      if (elementItemTemplate) {
        emit('addFromTemplate', elementItemTemplate);
      }
    };

    onMounted(async () => {
      if (activeCalculation?.value) {
        templates.value = await getBuildingTemplatesByProfession(
          activeCalculation?.value.ProfessionCode
        );
        standards.value = await getStandards();
      }
    });

    return {
      addElement,
      addItem,
      updateElements,
      getIconByProfessionCode,
      search,
      templates,
      loading,
      selectedTemplate,
      filteredElements,
      elements,
      selected,
      BUILDING_ELEMENT_HEADERS_SIMPLE_ALT2,
      BUILDING_ITEM_HEADERS_SIMPLE_ALT1,
      categorizedTemplates,
    };
  },
});
