





































































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useLibrary } from '@/modules/library';
import { useCalculation } from '@/modules/calculation';
import {
  BuildingTypeTemplate,
  BuildingElementTemplate,
  BuildingItemTemplate,
} from '@/models/library/interfaces';
import NewTemplate from '@/components/library/new-template/index.vue';
import DeleteTemplate from '@/components/library/delete-template/index.vue';
import DeleteElementTemplate from '@/components/library/delete-element-template/index.vue';
import DeleteItemElementTemplate from '@/components/library/delete-item-template/index.vue';
import AddItemToCalculation from '@/components/library/add-item-to-calculation/index.vue';
import UpdateTemplate from '@/components/library/update-template/index.vue';
import Loading from '@/components/loader/index.vue';
import KalkHelper from '@/components/common/kalk-helper/index.vue';
import { useBuildingElement } from '@/modules/building-element';
import { useBatch } from '@/modules/batch';
import { useBuildingItem } from '@/modules/building-item';
import { BatchType } from '@/constants/enum';
import { useStandards } from '@/modules/standards';
import { Ns3451 } from '@/models/standards/ns3451/interfaces';

interface BuildingElementTemplateNodes extends BuildingElementTemplate {
  Items: Array<BuildingItemTemplate>;
}

interface NS3451BuildingTemplates {
  Name: string;
  Items: Array<
    | BuildingElementTemplateNodes
    | BuildingItemTemplate
    | NS3451BuildingTemplates
  >;
}

export default defineComponent({
  components: {
    NewTemplate,
    DeleteTemplate,
    DeleteElementTemplate,
    DeleteItemElementTemplate,
    UpdateTemplate,
    Loading,
    KalkHelper,
    AddItemToCalculation,
  },
  props: {
    elementId: {
      type: String,
    },
  },
  setup(props) {
    const {
      setActiveTemplate,
      activeTemplate,
      loading,
      buildingTemplatesByProfession,
    } = useLibrary();
    const { projectCalculation } = useCalculation();
    const { createFromBuildingElementTemplate } = useBuildingElement();
    const { registerBuildingItemBatchOperation } = useBatch();
    const { createBICFromBuildingItemTemplate } = useBuildingItem();
    const { getStandards } = useStandards();

    const elements = ref<Array<BuildingElementTemplate>>();
    const templates = ref<Array<BuildingTypeTemplate>>();
    const search = ref<Nullable<string>>(null);
    const standards = ref<Array<Ns3451>>();
    const categorizedTemplates = ref<Array<NS3451BuildingTemplates>>([]);

    const groupByToMap = <T, Q>(
      array: T[],
      predicate: (value: T, index: number, array: T[]) => Q
    ) =>
      array.reduce((map, value, index, array) => {
        const key = predicate(value, index, array);
        map.get(key)?.push(value) ?? map.set(key, [value]);
        return map;
      }, new Map<Q, T[]>());

    const getNS3451NameFromStandards = (key: Nullable<string>): string => {
      if (!standards.value || (!key && key?.length !== 3)) return 'Ukjendt';

      const topLevel = Number(key.charAt(0));
      const topLevelNS3451 = standards.value.find(s => s.Number === topLevel);
      if (topLevelNS3451) {
        const secondLevel = Number(`${topLevel}${key.charAt(1)}`);
        const secondLevelNS3451 = topLevelNS3451.Children.find(
          s => s.Number === secondLevel
        );
        if (secondLevelNS3451) {
          const thirdLevel = Number(
            `${topLevel}${key.charAt(1)}${key.charAt(2)}`
          );
          const thirdLevelNS3451 = secondLevelNS3451.Children.find(
            s => s.Number === thirdLevel
          );
          if (thirdLevelNS3451) {
            return thirdLevelNS3451.CodeName;
          }
        }
      }

      return 'Ukjendt';
    };

    const buildNS3451Categories = (
      templates: Array<BuildingElementTemplate>
    ) => {
      const newCategories: Array<NS3451BuildingTemplates> = [];

      const groupedTemplates = groupByToMap(templates, e => e.NS3451);

      groupedTemplates.forEach((value, key) => {
        const elementTemplates: Array<BuildingElementTemplateNodes> = [];
        value.forEach(et => {
          const elementNode: BuildingElementTemplateNodes = {
            ...et,
            Items: et.BuildingItemTemplates,
          };
          elementTemplates.push(elementNode);
        });

        const category: NS3451BuildingTemplates = {
          Name: `${getNS3451NameFromStandards(key)} (${value.length})`,
          Items: elementTemplates,
        };

        newCategories.push(category);
      });

      categorizedTemplates.value = newCategories;
    };

    watch(
      () => [activeTemplate.value?.BuildingElementTemplates, search.value],
      ([newTemplates, search]) => {
        if (newTemplates && newTemplates instanceof Array) {
          buildNS3451Categories(newTemplates);
        }
        if (
          search &&
          activeTemplate.value?.BuildingElementTemplates &&
          typeof search === 'string'
        ) {
          const filteredElements = activeTemplate.value.BuildingElementTemplates.filter(
            ele =>
              (ele.Name && ele.Name.includes(search.toUpperCase())) ||
              ele.BuildingItemTemplates.find(
                i =>
                  i.Name && i.Name.toUpperCase().includes(search.toUpperCase())
              ) ||
              ele.NS3451?.toUpperCase().includes(search.toUpperCase())
          );
          buildNS3451Categories(filteredElements);
        }
      }
    );

    const createFromBuildingItemTemplate = (template: BuildingItemTemplate) => {
      if (projectCalculation.value && props.elementId) {
        const element = projectCalculation.value.BuildingElements.find(
          ele => ele.Id === props.elementId
        );

        if (element?.Id) {
          const newItemCalc = createBICFromBuildingItemTemplate(
            template,
            element,
            element.Id
          );
          element.BuildingItems.push(newItemCalc);
          registerBuildingItemBatchOperation(newItemCalc, BatchType.Insert);
        }
      }
    };

    onMounted(async () => {
      standards.value = await getStandards();
      if (activeTemplate.value) {
        buildNS3451Categories(activeTemplate.value.BuildingElementTemplates);
      }
    });

    return {
      setActiveTemplate,
      createFromBuildingElementTemplate,
      createFromBuildingItemTemplate,
      search,
      templates,
      loading,
      elements,
      projectCalculation,
      activeTemplate,
      buildingTemplatesByProfession,
      categorizedTemplates,
    };
  },
});
