




import { useAuth } from '@/modules/auth';
import { HOME_PATH } from '@/constants/routes';
import { defineComponent, onMounted } from '@vue/composition-api';
import router from '@/router/index';

export default defineComponent({
  name: 'Logout',
  setup() {
    const { logout } = useAuth();
    onMounted(async () => {
      await logout();
      await router.push(HOME_PATH);
    });
  },
});
