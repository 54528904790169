import {
  ProjectFactor,
  ProjectFactorItem,
} from '@/models/calculation/interfaces';
import { CompanyFactor, CompanyFactorItem } from '@/models/company/interfaces';

export const calculateValue = (
  factorItems: Array<CompanyFactorItem>
): number => {
  let value = 0;
  factorItems &&
    factorItems.length > 0 &&
    factorItems.map(item => {
      value += item.Value;
    });
  return value;
};

export const calculateFactor = (
  factorItems: Array<CompanyFactorItem>
): number => {
  const value = calculateValue(factorItems);
  return Math.round(value + Number.EPSILON + 100) / 100;
};

export const calculateValueProject = (
  factorItems: Array<ProjectFactorItem> | undefined
): number => {
  let value = 0;
  factorItems &&
    factorItems.length > 0 &&
    factorItems.map(item => {
      if (item.Value) value += item.Value;
    });
  return value;
};

export const calculateFactorProject = (
  factorItems: Array<ProjectFactorItem> | undefined
): number => {
  const value = calculateValueProject(factorItems);
  return Math.round(value + Number.EPSILON + 100) / 100;
};

export const customerHourPrice = (factors: Array<CompanyFactor>): string => {
  let factor2 = 0;
  let factor3 = 0;
  let factor4 = 0;
  let factor6 = 0;

  factors &&
    factors.length > 0 &&
    factors.map(factor => {
      if (factor.FactorNumber == 2)
        factor2 = calculateValue(factor.CompanyFactorItems);
      if (factor.FactorNumber == 3)
        factor3 = calculateFactor(factor.CompanyFactorItems);
      if (factor.FactorNumber == 4)
        factor4 = calculateFactor(factor.CompanyFactorItems);
      if (factor.FactorNumber == 6)
        factor6 = calculateFactor(factor.CompanyFactorItems);
    });

  const value = factor2 * ((factor3 + factor4 - 1) * factor6);
  return value.toFixed(2);
};

export const customerHourPriceCalculation = (
  factors: Array<ProjectFactor>
): string => {
  let factor2 = 0;
  let factor3 = 0;
  let factor4 = 0;
  let factor6 = 0;

  factors &&
    factors.length > 0 &&
    factors.map(factor => {
      if (factor.FactorNumber == 2 && factor.FactorItems)
        factor2 = calculateValueProject(factor.FactorItems);
      if (factor.FactorNumber == 3)
        factor3 = calculateFactorProject(factor.FactorItems);
      if (factor.FactorNumber == 4)
        factor4 = calculateFactorProject(factor.FactorItems);
      if (factor.FactorNumber == 6)
        factor6 = calculateFactorProject(factor.FactorItems);
    });

  const value = factor2 * ((factor3 + factor4 - 1) * factor6);
  return value.toFixed(2);
};
