





































































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    breadcrumb: {
      type: String,
    },
    small: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },
});
