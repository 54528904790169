





























































































































































































































































import {
  defineComponent,
  ref,
  ComputedRef,
  computed,
} from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import router from '@/router/index';
import {
  BuildingElementCalculation,
  ItemStatus,
} from '@/models/building-element/interfaces';
import { useCalculation } from '@/modules/calculation';
import ElementFooter from '@/components/element-footer-status/index.vue';
import BuildingItem from '@/components/calculation/building-item/index.vue';
import ElementSwitcher from '@/components/calculation/building-elements/element-switcher/index.vue';
import AdditionItem from '@/components/calculation/addition-item/index.vue';
import NewItem from '@/components/calculation/new-building-item/index.vue';
import EditElement from '@/components/calculation/edit-element/index.vue';
import DeleteElement from '@/components/calculation/delete-element/index.vue';
import AddToLibrary from '@/components/calculation/building-elements/add-to-library/index.vue';
import Documentation from '@/components/documentation/index.vue';
import ElementWarnings from '@/components/calculation/building-elements/calc-element/element-warnings/index.vue';
import LibraryDrawer from '@/components/library/drawer/index.vue';
import { DataTableHeader } from '@/models/common/interfaces';
import { BatchType, CalculationColumnView } from '@/constants/enum';
import {
  BUILDING_ELEMENT_HEADERS_CALCULATION,
  BUILDING_ELEMENT_HEADERS_DELIVERY,
  BUILDING_ELEMENT_HEADERS_ECONOMY,
  BUILDING_ELEMENT_HEADERS_HOURLY_CONSUMPTION,
  BUILDING_ELEMENT_HEADERS_MATERIALS,
} from '@/constants/table-headers';
import { PROJECT_CALCULATION_PATH } from '@/constants/routes';
import { useBatch } from '@/modules/batch';
import { translate } from '@/localization';
import PriceUpdate from '@/components/calculation/building-elements/calc-element/price-update/index.vue';

export default defineComponent({
  components: {
    ContentBar,
    ElementFooter,
    BuildingItem,
    ElementSwitcher,
    AdditionItem,
    NewItem,
    EditElement,
    DeleteElement,
    AddToLibrary,
    Documentation,
    ElementWarnings,
    LibraryDrawer,
    PriceUpdate,
  },
  setup() {
    const {
      projectCalculation,
      NS3420Units,
      activateLibrary,
    } = useCalculation();
    const {
      registerElementSaveChangesWithChildren,
      registerElementBatchOperation,
    } = useBatch();
    const columnView = ref<number>(0);
    const smallVariant = ref<boolean>(false);
    const updateProducts = ref(false);

    const columns = computed(() => {
      let headers: Array<DataTableHeader> = [];
      switch (columnView.value) {
        case CalculationColumnView.Calculation:
          headers = BUILDING_ELEMENT_HEADERS_CALCULATION;
          break;
        case CalculationColumnView.HourlyConsumption:
          headers = BUILDING_ELEMENT_HEADERS_HOURLY_CONSUMPTION;
          break;
        case CalculationColumnView.Economy:
          headers = BUILDING_ELEMENT_HEADERS_ECONOMY;
          break;
        case CalculationColumnView.Materials:
          headers = BUILDING_ELEMENT_HEADERS_MATERIALS;
          break;
        case CalculationColumnView.Delivery:
          headers = BUILDING_ELEMENT_HEADERS_DELIVERY;
          break;
      }
      return headers;
    });

    const buildingElement: ComputedRef<
      BuildingElementCalculation | undefined
    > = computed(() => {
      return projectCalculation?.value?.BuildingElements.find(
        ele => ele.Id === router?.currentRoute?.query.elementId
      );
    });

    const amountEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Calculation,
        CalculationColumnView.HourlyConsumption,
        CalculationColumnView.Materials,
        CalculationColumnView.Economy,
      ];
      return enabledWhen.includes(columnView.value);
    });

    const commentWide = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Calculation,
        CalculationColumnView.Materials,
        CalculationColumnView.Economy,
      ];
      return enabledWhen.includes(columnView.value);
    });

    const milestoneEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(columnView.value);
    });

    const factor1Enabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.HourlyConsumption,
      ];
      return enabledWhen.includes(columnView.value);
    });

    const productionLineEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(columnView.value);
    });

    const FDVEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Materials,
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(columnView.value);
    });

    const deliveryOrderEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Calculation,
        CalculationColumnView.Materials,
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(columnView.value);
    });
    const cancelPriceUpdate = () => {
      updateProducts.value = false;
    };

    const initPriceUpdate = async (element: BuildingElementCalculation) => {
      if (element.BuildingItems) {
        element.BuildingItems.forEach(item => {
          if (!item.NOBBNumber) {
            item.Status = {
              StatusColor: 'primary',
              StatusText: `${translate('info.no-nobbno')}`,
              StatusType: ItemStatus.MissingNobb,
            };
          }
        });
      }

      updateProducts.value = true;
    };

    return {
      registerElementSaveChangesWithChildren,
      registerElementBatchOperation,
      initPriceUpdate,
      cancelPriceUpdate,
      columns,
      updateProducts,
      columnView,
      smallVariant,
      CalculationColumnView,
      PROJECT_CALCULATION_PATH,
      amountEnabled,
      milestoneEnabled,
      factor1Enabled,
      productionLineEnabled,
      FDVEnabled,
      deliveryOrderEnabled,
      commentWide,
      BatchType,
      NS3420Units,
      buildingElement,
      activateLibrary,
    };
  },
});
