






















































































































































import {
  BUILDING_ELEMENT_HEADERS_SIMPLE_ALT1,
  BUILDING_ITEM_HEADERS_SIMPLE_ALT,
} from '@/constants/table-headers';
import { cleanSource } from '@/helpers/common';
import {
  BuildingElement,
  BuildingItem,
} from '@/models/building-element/interfaces';
import { Calculation } from '@/models/calculation/interfaces';
import Loader from '@/components/loader/index.vue';
import { ProjectOverview } from '@/models/projects/interfaces';
import { useCalculation } from '@/modules/calculation';
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { sortBuildingElements } from '@/helpers/building-element';

export default defineComponent({
  props: {
    selectItems: {
      type: Boolean,
    },
  },
  components: {
    Loader,
  },
  setup(props, { emit }) {
    const {
      activeCalculation,
      getCalculationByProfession,
      getCalculation,
    } = useCalculation();
    const calculation = ref<Calculation>();
    const calculationsByProf = ref<Array<ProjectOverview>>([]);
    const loading = ref(false);
    const filteredElements = ref<Array<BuildingElement>>([]);
    const search = ref<string>('');
    const expanded = ref<Array<number>>([]);
    const excludeItems = ref<boolean>(props.selectItems);

    const handleChange = async (id: string) => {
      loading.value = true;
      calculation.value = await getCalculation(id);
      filteredElements.value = cleanSource(
        calculation.value?.BuildingElements ?? []
      );
      filteredElements.value = sortBuildingElements(filteredElements.value);
      loading.value = false;
    };

    watch([search], () => {
      if (calculation.value?.BuildingElements && search.value != null) {
        filteredElements.value = calculation.value.BuildingElements.filter(
          ele =>
            (ele.Name && ele.Name.includes(search.value.toUpperCase())) ||
            ele.Items.find(
              i =>
                i.Name &&
                i.Name.toUpperCase().includes(search.value.toUpperCase())
            )
        );
      } else {
        filteredElements.value = cleanSource(
          calculation.value?.BuildingElements ?? []
        );
      }

      if (filteredElements.value.length === 1) {
        expanded.value = [0];
      } else expanded.value = [];
    });

    const addElement = (element: BuildingElement) => {
      emit('addElement', element);
    };

    const addItem = (item: BuildingItem) => {
      emit('addItem', item);
    };

    onMounted(async () => {
      if (activeCalculation?.value?.ProfessionCode) {
        calculationsByProf.value = await getCalculationByProfession(
          activeCalculation?.value?.ProfessionCode
        );
      }
    });

    return {
      addElement,
      addItem,
      handleChange,
      calculationsByProf,
      calculation,
      filteredElements,
      expanded,
      excludeItems,
      search,
      loading,
      BUILDING_ELEMENT_HEADERS_SIMPLE_ALT1,
      BUILDING_ITEM_HEADERS_SIMPLE_ALT,
    };
  },
});
