






































































































import { ExportType } from '@/constants/enum';
import { ProjectOverview } from '@/models/projects/interfaces';
import { useExport } from '@/modules/export';
import { useImport } from '@/modules/import';
import { useProject } from '@/modules/project';
import {
  ref,
  defineComponent,
  PropType,
  onMounted,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';
import { useCalculation } from '@/modules/calculation';
import router from '@/router';
import { PROJECT_CALCULATION_PATH } from '@/constants/routes';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    calculation: {
      type: Object as PropType<ProjectOverview>,
      required: true,
    },
    isMenuitem: {
      type: Boolean,
    },
  },
  setup({ calculation }) {
    const { getProjects } = useProject();
    const dialog = ref(false);
    const loading = ref(false);
    const selectedProject = ref<Nullable<string>>(null);
    const availableProjects = ref<Array<ProjectOverview>>([]);
    const newName = ref<string>(calculation.Name);
    const includeAmount = ref(true);
    const navigateToCalc = ref(true);

    onMounted(async () => {
      const existingProjects = await getProjects();
      if (existingProjects) {
        availableProjects.value = existingProjects.filter(
          p => p.Id !== calculation.ParentId
        );
      }
    });

    const close = () => {
      dialog.value = false;
      selectedProject.value = null;
      includeAmount.value = true;
      newName.value = calculation.Name;
    };

    const copyEvent = async () => {
      const { exportProject } = useExport();
      const { createImportProject, importProject } = useImport();
      const {
        refreshActiveProject,
        setActiveProject,
        projects,
        activeProjectOverview,
      } = useProject();
      const { setActiveCalculation } = useCalculation();

      if (!selectedProject.value || !projects?.value || !calculation.Id) return;
      loading.value = true;

      const nsProject = await exportProject(
        calculation.Id,
        false,
        ExportType.JSON
      );

      if (nsProject) {
        const parent = projects.value.find(p => p.Id === selectedProject.value);
        if (parent && nsProject) {
          const newImport = await createImportProject(
            nsProject,
            parent,
            newName.value,
            includeAmount.value
          );

          if (newImport) {
            const success = await importProject(newImport);
            if (success) {
              await getProjects(true);
              if (navigateToCalc.value && projects.value) {
                const newActiveProj = projects.value.find(proj =>
                  proj.Children.some(calc => calc.Id === newImport.Project.Id)
                );

                if (
                  newActiveProj &&
                  activeProjectOverview.value &&
                  newActiveProj.Id === activeProjectOverview.value.Id
                ) {
                  close();
                  await refreshActiveProject();
                } else if (newActiveProj?.Id) {
                  await setActiveProject(newActiveProj.Id, false);
                }

                if (newActiveProj) {
                  const calc = newActiveProj.Children.find(
                    calc => calc.Id === newImport.Project.Id
                  );
                  if (calc) {
                    await setActiveCalculation(calc);
                    await router.push(PROJECT_CALCULATION_PATH);
                  }
                }
              } else {
                await refreshActiveProject();
              }
            }
          }
        }
      }

      loading.value = false;
    };

    return {
      copyEvent,
      close,
      dialog,
      loading,
      selectedProject,
      availableProjects,
      includeAmount,
      newName,
      navigateToCalc,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
    };
  },
});
