
















import { PropType, computed, defineComponent } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import { Epd } from '@/models/nobb/interfaces';
import { useEnvironment } from '@/modules/environment';

export default defineComponent({
  components: {
    Loader,
  },
  props: {
    epd: {
      type: Array as PropType<Array<Epd>>,
      required: true,
    },
  },
  setup(props) {
    const {
      tryGetEpdA1A3GWPIOBCNumber,
      tryGetEpdA1A3GWPIOBCUnit,
    } = useEnvironment();

    const numberA1A3 = computed(() => tryGetEpdA1A3GWPIOBCNumber(props.epd));
    const unit = computed(() => tryGetEpdA1A3GWPIOBCUnit(props.epd));
    return {
      props,
      numberA1A3,
      unit,
    };
  },
});
