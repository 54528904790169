import { BatchType } from '@/constants/enum';
import { mapAdditionCalcToAdditionItem } from '@/helpers/addition-item';
import {
  mapElementCalcToBaseElement,
  sortBuildingElementsCalculation,
} from '@/helpers/building-element';
import { mapBuildingItemCalcToBuildingItem } from '@/helpers/building-item';
import {
  AdditionItemCalculation,
  BuildingElement,
  BuildingElementCalculation,
  BuildingItem,
  BuildingItemCalculation,
} from '@/models/building-element/interfaces';
import {
  CalculationBatch,
  CalculationBatchOperation,
  FactorBatchOperation,
  ProjectFactorItem,
} from '@/models/calculation/interfaces';
import { useCalculation } from './calculation';

export const useBatch = () => {
  const {
    projectCalculation,
    saveCalculation,
    initActiveCalculation,
  } = useCalculation();

  const registerAdditionItemBatchOperation = (
    aic: AdditionItemCalculation,
    type: BatchType
  ) => {
    if (!projectCalculation.value) return;

    const additionItem = mapAdditionCalcToAdditionItem(aic);

    const newOperation: CalculationBatchOperation = {
      AdditionItem: additionItem,
    };

    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    switch (type) {
      case BatchType.Save: {
        calcBatch.SaveOperations.push(newOperation);
        break;
      }
      case BatchType.Delete:
        calcBatch.DeleteOperations.push(newOperation);
        break;
      case BatchType.Insert:
        calcBatch.InsertOperations.push(newOperation);
        break;
    }

    saveCalculation(calcBatch);
  };

  const registerNewElementWithChildren = (element: BuildingElement) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    const newElementOperation: CalculationBatchOperation = {
      BuildingElement: element,
    };

    calcBatch.InsertOperations.push(newElementOperation);
    element.Items.forEach(item => {
      const newBuildingItemOperation: CalculationBatchOperation = {
        BuildingItem: item,
      };
      calcBatch.InsertOperations.push(newBuildingItemOperation);
    });

    element.AdditionItems.forEach(item => {
      const newAdditionItemOperation: CalculationBatchOperation = {
        AdditionItem: item,
      };
      calcBatch.InsertOperations.push(newAdditionItemOperation);
    });

    saveCalculation(calcBatch);
  };

  const registerNewElementsWithChildren = (
    elements: Array<BuildingElement>
  ) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    elements.forEach(element => {
      const newElementOperation: CalculationBatchOperation = {
        BuildingElement: element,
      };

      calcBatch.InsertOperations.push(newElementOperation);
      element.Items.forEach(item => {
        const newBuildingItemOperation: CalculationBatchOperation = {
          BuildingItem: item,
        };
        calcBatch.InsertOperations.push(newBuildingItemOperation);
      });

      element.AdditionItems.forEach(item => {
        const newAdditionItemOperation: CalculationBatchOperation = {
          AdditionItem: item,
        };
        calcBatch.InsertOperations.push(newAdditionItemOperation);
      });
    });

    saveCalculation(calcBatch);
  };

  const registerNewBuildingItems = (items: Array<BuildingItem>) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    items.forEach(bi => {
      const newItemOperation: CalculationBatchOperation = {
        BuildingItem: bi,
      };
      calcBatch.InsertOperations.push(newItemOperation);
    });

    saveCalculation(calcBatch);
  };

  const registerBuildingItemBatchOperation = (
    bic: BuildingItemCalculation,
    type: BatchType
  ) => {
    if (!projectCalculation.value) return;

    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    const buildingItem = mapBuildingItemCalcToBuildingItem(bic);

    const newOperation: CalculationBatchOperation = {
      BuildingItem: buildingItem,
    };

    switch (type) {
      case BatchType.Save: {
        calcBatch.SaveOperations.push(newOperation);
        break;
      }
      case BatchType.Delete:
        calcBatch.DeleteOperations.push(newOperation);
        break;
      case BatchType.Insert:
        calcBatch.InsertOperations.push(newOperation);
        break;
    }
    saveCalculation(calcBatch);
  };

  const registerBuildingItemsBatchOperation = (
    bics: Array<BuildingItemCalculation>,
    type: BatchType
  ) => {
    if (!projectCalculation.value) return;

    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    bics.forEach(bic => {
      const buildingItem = mapBuildingItemCalcToBuildingItem(bic);

      const newOperation: CalculationBatchOperation = {
        BuildingItem: buildingItem,
      };

      switch (type) {
        case BatchType.Save: {
          calcBatch.SaveOperations.push(newOperation);
          break;
        }
        case BatchType.Delete:
          calcBatch.DeleteOperations.push(newOperation);
          break;
        case BatchType.Insert:
          calcBatch.InsertOperations.push(newOperation);
          break;
      }
    });

    saveCalculation(calcBatch);
  };

  const registerElementsBatchOperation = (
    elements: Array<BuildingElementCalculation>,
    type: BatchType
  ) => {
    if (!projectCalculation.value) return;

    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    elements.forEach(element => {
      const baseElement = mapElementCalcToBaseElement(element);

      const newOperation: CalculationBatchOperation = {
        BuildingElement: baseElement,
      };

      switch (type) {
        case BatchType.Save: {
          calcBatch.SaveOperations.push(newOperation);
          break;
        }
        case BatchType.Delete:
          calcBatch.DeleteOperations.push(newOperation);
          break;
        case BatchType.Insert:
          calcBatch.InsertOperations.push(newOperation);
          break;
      }
    });

    saveCalculation(calcBatch);
  };

  const registerElementBatchOperation = (
    element: BuildingElementCalculation,
    type: BatchType
  ) => {
    if (!projectCalculation.value) return;

    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    const baseElement = mapElementCalcToBaseElement(element);

    const newOperation: CalculationBatchOperation = {
      BuildingElement: baseElement,
    };

    switch (type) {
      case BatchType.Save: {
        calcBatch.SaveOperations.push(newOperation);
        break;
      }
      case BatchType.Delete:
        calcBatch.DeleteOperations.push(newOperation);
        break;
      case BatchType.Insert:
        calcBatch.InsertOperations.push(newOperation);
        break;
    }
    saveCalculation(calcBatch);
  };

  const registerChangedFactorsBatchOperation = async (
    id: string,
    factors: Array<ProjectFactorItem>
  ) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    const batch: FactorBatchOperation = {
      ProjectFactorId: id,
      ProjectFactorProjectFactorItems: factors,
    };

    const factorBatch: Array<FactorBatchOperation> = [];
    factorBatch.push(batch);

    calcBatch.ChangedFactors = factorBatch;
    await saveCalculation(calcBatch);
  };

  const registerItemSaveChangesWithAdditionAndParent = (
    item: BuildingItemCalculation
  ) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    item.IsModifiedRecently = true;

    if (item.BuildingElement) {
      const buildingItem = mapBuildingItemCalcToBuildingItem(item);
      const newItemOperation: CalculationBatchOperation = {
        BuildingItem: buildingItem,
      };
      calcBatch.SaveOperations.push(newItemOperation);

      const baseElement = mapElementCalcToBaseElement(item.BuildingElement);
      const newOperation: CalculationBatchOperation = {
        BuildingElement: baseElement,
      };
      calcBatch.SaveOperations.push(newOperation);

      item.BuildingElement.AdditionItems.forEach(aic => {
        const additionItem = mapAdditionCalcToAdditionItem(aic);
        const newOperation: CalculationBatchOperation = {
          AdditionItem: additionItem,
        };
        calcBatch.SaveOperations.push(newOperation);
      });
    }

    saveCalculation(calcBatch);
  };

  const registerElementSaveChangesWithChildren = (
    element: BuildingElementCalculation
  ) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    const baseElement = mapElementCalcToBaseElement(element);

    const newOperation: CalculationBatchOperation = {
      BuildingElement: baseElement,
    };

    calcBatch.SaveOperations.push(newOperation);

    if (element.BuildingItems && element.BuildingItems.length > 0) {
      element.BuildingItems.forEach(item => {
        item.IsModifiedRecently = true;
        const buildingItem = mapBuildingItemCalcToBuildingItem(item);

        const newOperation: CalculationBatchOperation = {
          BuildingItem: buildingItem,
        };

        calcBatch.SaveOperations.push(newOperation);
      });
    }
    if (element.AdditionItems && element.AdditionItems.length > 0) {
      element.AdditionItems.forEach(addition => {
        const additionItem = mapAdditionCalcToAdditionItem(addition);

        const newOperation: CalculationBatchOperation = {
          AdditionItem: additionItem,
        };
        calcBatch.SaveOperations.push(newOperation);
      });
    }

    saveCalculation(calcBatch);
  };

  const registerElementInsertChangesWithChildren = (
    element: BuildingElementCalculation
  ) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    const baseElement = mapElementCalcToBaseElement(element);

    const newOperation: CalculationBatchOperation = {
      BuildingElement: baseElement,
    };

    calcBatch.InsertOperations.push(newOperation);

    if (element.BuildingItems && element.BuildingItems.length > 0) {
      element.BuildingItems.forEach(item => {
        item.IsModifiedRecently = true;
        const buildingItem = mapBuildingItemCalcToBuildingItem(item);

        const newOperation: CalculationBatchOperation = {
          BuildingItem: buildingItem,
        };

        calcBatch.InsertOperations.push(newOperation);
      });
    }
    if (element.AdditionItems && element.AdditionItems.length > 0) {
      element.AdditionItems.forEach(addition => {
        const additionItem = mapAdditionCalcToAdditionItem(addition);

        const newOperation: CalculationBatchOperation = {
          AdditionItem: additionItem,
        };
        calcBatch.InsertOperations.push(newOperation);
      });
    }

    saveCalculation(calcBatch);
  };

  const registerAllBuildingItemSaveChanges = (
    element: BuildingElementCalculation
  ) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };

    if (element.BuildingItems && element.BuildingItems.length > 0) {
      element.BuildingItems.forEach(item => {
        item.IsModifiedRecently = true;
        const buildingItem = mapBuildingItemCalcToBuildingItem(item);

        const newOperation: CalculationBatchOperation = {
          BuildingItem: buildingItem,
        };

        calcBatch.SaveOperations.push(newOperation);
      });
    }

    saveCalculation(calcBatch);
  };

  const registerElementUnitChanges = (
    element: BuildingElementCalculation,
    add: boolean
  ) => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };
    if (add) {
      element.AdditionItems.forEach(aic => {
        const additionItem = mapAdditionCalcToAdditionItem(aic);

        const newOperation: CalculationBatchOperation = {
          AdditionItem: additionItem,
        };
        calcBatch.InsertOperations.push(newOperation);
      });
    } else {
      element.AdditionItems.forEach(aic => {
        const additionItem = mapAdditionCalcToAdditionItem(aic);

        const newOperation: CalculationBatchOperation = {
          AdditionItem: additionItem,
        };
        calcBatch.DeleteOperations.push(newOperation);
      });
    }

    const baseElement = mapElementCalcToBaseElement(element);

    const newOperation: CalculationBatchOperation = {
      BuildingElement: baseElement,
    };
    calcBatch.SaveOperations.push(newOperation);

    saveCalculation(calcBatch);
  };

  const registerElementAndAdditionsChanges = async (
    element: BuildingElementCalculation
  ): Promise<void> => {
    const calcBatch: CalculationBatch = {
      SaveOperations: [],
      InsertOperations: [],
      DeleteOperations: [],
      ChangedFactors: [],
      ProjectTotals: {},
    };
    const baseElement = mapElementCalcToBaseElement(element);

    const newOperation: CalculationBatchOperation = {
      BuildingElement: baseElement,
    };
    calcBatch.SaveOperations.push(newOperation);
    element.AdditionItems.forEach(aic => {
      const additionItem = mapAdditionCalcToAdditionItem(aic);

      const newOperation: CalculationBatchOperation = {
        AdditionItem: additionItem,
      };
      calcBatch.SaveOperations.push(newOperation);
    });

    saveCalculation(calcBatch);
  };

  const resetChanges = async () => {
    if (
      projectCalculation.value?.Id &&
      projectCalculation.value.BuildingElements
    ) {
      await initActiveCalculation(projectCalculation.value.Id);
      sortBuildingElementsCalculation(
        projectCalculation.value.BuildingElements
      );
    }
  };

  return {
    registerNewElementWithChildren,
    registerNewElementsWithChildren,
    registerNewBuildingItems,
    registerAdditionItemBatchOperation,
    registerBuildingItemBatchOperation,
    registerBuildingItemsBatchOperation,
    registerElementBatchOperation,
    registerElementsBatchOperation,
    registerChangedFactorsBatchOperation,
    registerItemSaveChangesWithAdditionAndParent,
    registerElementSaveChangesWithChildren,
    registerAllBuildingItemSaveChanges,
    registerElementUnitChanges,
    registerElementAndAdditionsChanges,
    registerElementInsertChangesWithChildren,
    resetChanges,
  };
};
