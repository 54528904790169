



























































































































































































































import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import { NobbItem } from '@/models/nobb/interfaces';
import { useProductInfo } from '@/modules/product-info';
import { useCalculation } from '@/modules/calculation';
import BuildingItemEnvironmentRow from '@/components/environment/BuildingItemEnvironmentRow.vue';
import { useEnvironment } from '@/modules/environment';

export default defineComponent({
  components: {
    Loader,
    BuildingItemEnvironmentRow,
  },
  props: {
    buildingElement: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
  },
  setup(props) {
    const nobbItems = ref<Array<NobbItem>>([]);
    const loading = ref(false);

    const { projectCalculation } = useCalculation();
    const { getNobbItems } = useProductInfo();
    const {
      getBuildingElementSumGWP,
      getBuildingElementCalculatedSumGWP,
      extractNobbNosFromBuildingElement,
    } = useEnvironment();

    const getNobbItemFromNobbNumber = (nobbNumber?: Nullable<number>) => {
      if (nobbNumber && nobbItems) {
        return nobbItems.value.find(item => item.nobbNumber === nobbNumber);
      }
    };

    const computedSumGWP = computed(() =>
      getBuildingElementSumGWP(props.buildingElement, nobbItems.value)
    );

    const computedCalculatedSumGWP = computed(() =>
      getBuildingElementCalculatedSumGWP(props.buildingElement, nobbItems.value)
    );

    const computedItemsMissingNobb = computed(() => {
      return props.buildingElement.BuildingItems.some(
        item => item.NOBBNumber === null || item.NOBBNumber === undefined
      );
    });

    const computedItemsMissingEPD = computed(() => {
      return nobbItems.value.some(item => item.properties.epd.length === 0);
    });

    onMounted(async () => {
      try {
        loading.value = true;
        nobbItems.value = await getNobbItems(
          extractNobbNosFromBuildingElement(props.buildingElement)
        );
      } finally {
        loading.value = false;
      }
    });

    return {
      getNobbItemFromNobbNumber,
      nobbItems,
      projectCalculation,
      props,
      loading,
      computedSumGWP,
      computedCalculatedSumGWP,
      computedItemsMissingNobb,
      computedItemsMissingEPD,
    };
  },
});
