













































































































import { PropType, defineComponent, ref } from '@vue/composition-api';
import BuildingItemEnvironmentRow from '@/components/environment/BuildingItemEnvironmentRow.vue';
import { ProjectCalculation } from '@/models/calculation/interfaces';
import BuildingElementSumRow from '@/components/environment/BuildingElementSumRow.vue';

export default defineComponent({
  components: {
    BuildingItemEnvironmentRow,
    BuildingElementSumRow,
  },
  props: {
    calculation: {
      type: Object as PropType<ProjectCalculation>,
      required: true,
    },
  },
  setup(props) {
    const calculationSumTotal = ref(0);
    const calculationCalculatedSumTotal = ref(0);

    return {
      props,
      calculationSumTotal,
      calculationCalculatedSumTotal,
    };
  },
});
