var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.activeTemplate)?_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-title',[_c('v-row',{staticClass:"ma-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-combobox',{staticClass:"select-elements",attrs:{"items":_vm.buildingTemplatesByProfession,"item-text":"Name","item-value":"Id","dense":"","color":"primary","label":_vm.$t('labels.libraries-occupation'),"width":"100%","hide-details":"","loading":_vm.loading},on:{"change":_vm.setActiveTemplate},model:{value:(_vm.activeTemplate),callback:function ($$v) {_vm.activeTemplate=$$v},expression:"activeTemplate"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.activeTemplate)?_c('v-row',{staticClass:"ma-1",attrs:{"dense":""}},[_c('v-spacer'),_c('delete-template',{attrs:{"template":_vm.activeTemplate}}),_c('update-template',{attrs:{"template":_vm.activeTemplate}})],1):_vm._e()],1),_c('new-template',{attrs:{"profession":_vm.projectCalculation.Profession}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"element-search ma-1",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('common.search'),"outlined":"","dense":"","single-line":"","hide-details":"","clearable":"","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('loading',{attrs:{"margins":true}}):_c('perfect-scrollbar',{staticClass:"library-treeview",attrs:{"options":{
        suppressScrollX: true,
      }}},[_c('v-treeview',{attrs:{"color":"secondary","item-text":"Name","item-children":"Items","item-key":"Id","open-on-click":"","items":_vm.categorizedTemplates,"return-object":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(item.Name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Name))])])]}},{key:"append",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"append-container"},[(
                item.Id &&
                  item.BuildingItemTemplates &&
                  item.BuildingItemTemplates.length > 0
              )?_c('delete-element-template',{attrs:{"template":item}}):(item.Id && !item.BuildingItemTemplates)?_c('delete-item-element-template',{attrs:{"template":item}}):_vm._e(),(
                item.BuildingItemTemplates &&
                  item.BuildingItemTemplates.length > 0 &&
                  !_vm.elementId
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(item.Id)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.createFromBuildingElementTemplate(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.add-element-template')))])]):(item.Id && !_vm.elementId && !item.BuildingItemTemplates)?_c('add-item-to-calculation',{attrs:{"template":item,"icon":true}}):_vm._e()],1)]}}],null,false,905881337)})],1)],1):_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-alert',{attrs:{"dense":"","type":"info","tile":"","color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t('info.no-templates'))+" ")]),_c('new-template',{attrs:{"profession":_vm.projectCalculation.Profession,"first":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }