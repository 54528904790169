import {
  AdditionItem,
  AdditionItemCalculation,
  BuildingElementCalculation,
} from '@/models/building-element/interfaces';
import { useCalculation } from '@/modules/calculation';
import { computed } from '@vue/composition-api';
import { roundToTwoDecimals } from './common';

const mapBuildingAdditionItemCalcProperties = (
  additionItem: AdditionItem,
  element: BuildingElementCalculation
): AdditionItemCalculation => {
  const { getCalculationFactorValue } = useCalculation();
  const _additionItem: AdditionItemCalculation = {
    PriceListItemName: null,
    UnitForOrder: null,
    Comments: null,
    BuildingElement: element,
    BuildingElementId: element.Id,
    Items: [],
    AmountAdjusted: null,
    AmountAdjustment: additionItem.AmountAdjustment,
    AmountPrUnit: additionItem.AmountPrUnit ?? null,
    Id: additionItem.Id,
    Factor1: additionItem.Factor1 ?? null,
    Name: additionItem.Name ?? null,
    Amount: additionItem.Amount ?? 0,
    Unit: additionItem.Unit ?? null,
    SortOrder: additionItem.SortOrder ?? null,
    Created: additionItem.Created,
    LastModified: additionItem.LastModified,
    Price: additionItem.Price,
    MileStone: '',
    NS3420: '',
    Factor1Calculated: computed({
      get() {
        if (_additionItem.Factor1 != null) {
          return _additionItem.Factor1;
        } else if (
          _additionItem.BuildingElement?.Factor1Calculated?.value != null
        ) {
          return _additionItem.BuildingElement.Factor1Calculated.value;
        }

        return null;
      },
      set(val) {
        _additionItem.Factor1 = val;
      },
    }),
    BaseTime: computed(() => {
      let time = 0;
      if (_additionItem.BuildingElement != null) {
        if (
          _additionItem.Name === 'Åpninger' &&
          _additionItem.BuildingElement.OpeningAddition?.value != null
        ) {
          time = _additionItem.BuildingElement.OpeningAddition.value;
        } else if (
          _additionItem.BuildingElement.SurfaceAddition?.value != null
        ) {
          time = _additionItem.BuildingElement.SurfaceAddition.value;
        }
      }
      return time;
    }),
    TimePrUnit: computed(() => {
      let time = 0;

      if (
        _additionItem.BaseTime?.value != null &&
        _additionItem.Factor1Calculated?.value != null
      ) {
        time =
          _additionItem.BaseTime.value * _additionItem.Factor1Calculated.value;
      }
      return time;
    }),
    AmountCalculated: computed({
      get() {
        if (_additionItem.Amount != null) {
          return _additionItem.Amount;
        }

        return 0;
      },
      set(val) {
        if (!val) {
          _additionItem.Amount = 0;
        } else {
          _additionItem.Amount = val;
        }

        if (val != null) {
          _additionItem.AmountAdjustment = null;
        }
      },
    }),
    SumHours: computed(() => {
      let sum = 0;

      if (
        _additionItem.TimePrUnit?.value &&
        _additionItem.AmountCalculated?.value
      ) {
        sum =
          _additionItem.TimePrUnit.value * _additionItem.AmountCalculated.value;
      }

      return sum;
    }),
    Factor2: computed(() => {
      let factor2: Nullable<number> = null;

      if (_additionItem.BuildingElement?.Project) {
        factor2 = getCalculationFactorValue(
          2,
          _additionItem.BuildingElement?.Project
        );
      }

      let time = 0;
      if (_additionItem.TimePrUnit?.value != null && factor2 != null) {
        time = _additionItem.TimePrUnit.value * factor2;
      }
      return time;
    }),
    SumProductionSalary: computed(() => {
      let prodSalary = 0;

      if (
        _additionItem.Factor2?.value != null &&
        _additionItem.AmountCalculated?.value != null
      ) {
        prodSalary =
          _additionItem.Factor2.value * _additionItem.AmountCalculated.value;
      }
      return prodSalary;
    }),
    Factor3: computed(() => {
      let factor3: Nullable<number> = null;

      if (_additionItem.BuildingElement?.Project) {
        factor3 = getCalculationFactorValue(
          3,
          _additionItem.BuildingElement?.Project
        );
      }

      let prodSalary = 0;
      if (factor3 != null && _additionItem.SumProductionSalary?.value != null) {
        prodSalary = _additionItem.SumProductionSalary.value * (factor3 - 1);
      }
      return prodSalary;
    }),
    Factor4: computed(() => {
      let factor4: Nullable<number> = null;

      if (_additionItem.BuildingElement?.Project) {
        factor4 = getCalculationFactorValue(
          4,
          _additionItem.BuildingElement?.Project
        );
      }

      let prodSalary = 0;
      if (factor4 != null && _additionItem.SumProductionSalary?.value != null) {
        prodSalary = _additionItem.SumProductionSalary.value * (factor4 - 1);
      }
      return prodSalary;
    }),
    SumSalaryAndSocialCost: computed(() => {
      let salary = 0;

      if (
        _additionItem.SumProductionSalary?.value != null &&
        _additionItem.Factor3?.value != null
      ) {
        salary =
          _additionItem.SumProductionSalary.value + _additionItem.Factor3.value;
      }
      return salary;
    }),
    ProjectCost: computed(() => {
      const cost = 0;

      if (
        _additionItem.SumSelfCostMaterialsSubContractors?.value != null &&
        _additionItem.SumProductionSalary?.value != null &&
        _additionItem.Factor3?.value != null
      ) {
        return (
          _additionItem.SumSelfCostMaterialsSubContractors.value +
          _additionItem.SumProductionSalary.value +
          _additionItem.Factor3.value
        );
      }
      return cost;
    }),
    SumSelfCostSalaryAndExpenses: computed(() => {
      let cost = 0;
      if (
        _additionItem.SumProductionSalary?.value != null &&
        _additionItem.Factor3?.value != null &&
        _additionItem.Factor4?.value != null
      ) {
        cost =
          _additionItem.SumProductionSalary.value +
          _additionItem.Factor3.value +
          _additionItem.Factor4.value;
      }
      return cost;
    }),
    Factor6: computed(() => {
      let factor6: Nullable<number> = null;

      if (_additionItem.BuildingElement?.Project) {
        factor6 = getCalculationFactorValue(
          6,
          _additionItem.BuildingElement?.Project
        );
      }
      let selfCost = 0;
      if (
        factor6 != null &&
        _additionItem.SumSelfCostSalaryAndExpenses?.value != null
      ) {
        selfCost =
          _additionItem.SumSelfCostSalaryAndExpenses.value * (factor6 - 1);
      }
      return selfCost;
    }),
    SumSalaryAndExpenses: computed(() => {
      let salary = 0;
      if (
        _additionItem.SumSelfCostSalaryAndExpenses?.value != null &&
        _additionItem.Factor6?.value != null
      ) {
        salary =
          _additionItem.SumSelfCostSalaryAndExpenses.value +
          _additionItem.Factor6.value;
      }

      return salary;
    }),
    SumSelfCost: computed(() => {
      let sumSelfCost = 0;

      if (_additionItem.SumSelfCostSalaryAndExpenses?.value != null) {
        sumSelfCost = _additionItem.SumSelfCostSalaryAndExpenses.value;
      }
      return sumSelfCost;
    }),
    SumProfit: computed(() => {
      let profit = 0;

      if (_additionItem.Factor6?.value != null) {
        profit = _additionItem.Factor6.value;
      }
      return profit;
    }),
    SumTotal: computed(() => {
      let total = 0;

      if (_additionItem.SumSalaryAndExpenses?.value != null) {
        total = _additionItem.SumSalaryAndExpenses.value;
      }

      return total;
    }),
    UnitPrice: computed(() => {
      let price = 0;

      if (
        _additionItem.AmountCalculated?.value != null &&
        _additionItem.AmountCalculated.value > 0 &&
        _additionItem.SumTotal?.value != null &&
        _additionItem.SumTotal.value > 0
      ) {
        price =
          roundToTwoDecimals(_additionItem.SumTotal.value) /
          roundToTwoDecimals(_additionItem.AmountCalculated.value);
      }
      return price;
    }),
    SumTotalWithAdditions: computed(() => {
      let total = 0;

      if (_additionItem.SumTotal?.value != null) {
        total = _additionItem.SumTotal.value;
      }
      return total;
    }),
    UnitPriceWithAdditions: computed(() => {
      let additions = 0;

      if (_additionItem.UnitPrice?.value != null) {
        additions = _additionItem.UnitPrice.value;
      }
      return additions;
    }),
    SumSelfCostMaterialsSubContractors: computed(() => {
      return 0;
    }),
    UnitPriceMaterials: computed(() => {
      return 0;
    }),
    SumMaterialsUE: computed(() => {
      return 0;
    }),
    SumTime: computed(() => {
      return 0;
    }),
  };

  return _additionItem;
};

export const buildAdditionItemCalculations = (
  additions: Array<AdditionItem>,
  element: BuildingElementCalculation
): Array<AdditionItemCalculation> => {
  const _additionCalc: Array<AdditionItemCalculation> = [];
  additions.forEach(adi => {
    const addition = mapBuildingAdditionItemCalcProperties(adi, element);
    _additionCalc.push(addition);
  });

  return _additionCalc;
};

export const mapAdditionCalcToAdditionItem = (
  additionCalc: AdditionItemCalculation
): AdditionItem => {
  return {
    Amount: additionCalc.Amount ?? 0,
    AmountAdjustment: additionCalc.AmountAdjustment,
    AmountPrUnit: additionCalc.AmountPrUnit,
    BuildingElementId: additionCalc.BuildingElementId,
    Comments: additionCalc.Comments,
    Created: additionCalc.Created,
    Factor1: additionCalc.Factor1,
    Id: additionCalc.Id,
    LastModified: additionCalc.LastModified,
    Name: additionCalc.Name,
    Price: additionCalc.Price,
    SortOrder: additionCalc.SortOrder,
    Unit: additionCalc.Unit,
  };
};
