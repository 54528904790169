




























































































































import { defineComponent, PropType, computed } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import { useCalculation } from '@/modules/calculation';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { NS3420Units } = useCalculation();
    const valid = computed(() => {
      return true;
    });

    const submit = async () => {
      emit('close', true);
    };

    const close = () => {
      emit('close', true);
    };

    return {
      submit,
      close,
      NS3420Units,
      valid,
      rules: {
        required: (v: string) => !!v || 'Field is required.',
      },
    };
  },
});
