


































































































































































































import { defineComponent, PropType } from '@vue/composition-api';
import {
  BuildingItemCalculation,
  ItemStatus,
} from '@/models/building-element/interfaces';
import BuildingItemStatus from '@/components/calculation/building-item/building-item-status/index.vue';
import EditTimeEstimate from '@/components/calculation/building-item/edit-time-estimate/index.vue';
import RemoveTimeEstimate from '@/components/calculation/building-item/remove-time-estimate/index.vue';
import GetProduct from '@/components/calculation/get-product/index.vue';
import NobbInfo from '@/components/nobb-info/index.vue';

export default defineComponent({
  components: {
    BuildingItemStatus,
    EditTimeEstimate,
    RemoveTimeEstimate,
    GetProduct,
    NobbInfo,
  },
  props: {
    buildingItem: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
    isEnabled: {
      type: Boolean,
    },
    isSelected: {
      type: Boolean,
    },
    actionsAvailable: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const positiveThreshold = 0.01;
    const negativeThreshold = -0.01;
    return { ItemStatus, emit, positiveThreshold, negativeThreshold };
  },
});
