import {
  createReportName,
  downloadExcel,
  getPriceRequestMaterials,
} from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { ReportFileType, ReportType } from './enums';
import { ReportOptions } from '@/models/report/interfaces';
import { useCalculation } from '@/modules/calculation';
import XLSX from 'xlsx';
import { numberToAbsStringOrEmpty } from '@/helpers/common';
import {
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_XSMALL,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_XSMALL,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

const generatePriceRequestReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  console.log(options);

  if (!projectCalculation.value) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);

  const pdfName = createReportName(type, ReportFileType.PDF);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;

  const head = [
    [
      translate('labels.zip'),
      translate('labels.delivery-order'),
      translate('labels.production-line'),
      translate('labels.nobb-number'),
      translate('labels.description'),
      translate('labels.precut'),
      translate('labels.unit'),
      translate('labels.quantity'),
      translate('labels.length'),
      translate('labels.amount'),
      translate('labels.comments'),
      translate('labels.unit-price'),
      translate('labels.sum'),
    ],
  ];
  const prData: (string | number)[][] = [];

  let postnr = 1;
  let matItems = getPriceRequestMaterials(projectCalculation.value, false);

  const matItemsWithoutDeliveryOrder = matItems.filter(
    mi => mi.DeliveryOrder === null
  );
  matItems = matItems.filter(
    mi =>
      mi.DeliveryOrder !== null &&
      options.DeliveryOrders.includes(mi.DeliveryOrder)
  );

  if (options.IncludeWithoutDeliveryOrder) {
    matItems = matItems.concat(matItemsWithoutDeliveryOrder);
  }

  matItems = matItems.sort((a, b) =>
    (a.NOBBNumber ?? -1) > (b.NOBBNumber ?? -1)
      ? 1
      : (b.NOBBNumber ?? -1) > (a.NOBBNumber ?? -1)
      ? -1
      : 0
  );

  matItems = matItems.sort((a, b) =>
    (a.DeliveryOrder ?? -1) > (b.DeliveryOrder ?? -1)
      ? 1
      : (b.DeliveryOrder ?? -1) > (a.DeliveryOrder ?? -1)
      ? -1
      : 0
  );

  matItems.forEach(item => {
    const row = [
      postnr++,
      item.DeliveryOrder ?? '',
      '',
      item.NOBBNumber ?? '',
      item.ItemName ?? '',
      item.Precut ? (item.PrecutText != null ? item.PrecutText : '') : '',
      item.Unit ?? '',
      item.TotalAmount != null && item.TotalAmount > 0 ? item.TotalAmount : '',
      item.AmountPrUnit != null && item.AmountPrUnit > 0
        ? `${item.AmountPrUnit} ${item.PrecutUnit}`
        : '',
      numberToAbsStringOrEmpty(item.AmountOfMaterials, 2),
      item.Comments ?? '',
      '',
      '',
    ];

    prData.push(row);
  });

  const reportName = `${translate('reports.price-request-report')}`;

  doc.text(reportName, 14, first.finalY + 9);

  const sumExclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-exclude-mva')}`,
    '',
  ];
  const mvaRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-mva-only')}`,
    '',
  ];
  const sumInclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-include-mva')}`,
    '',
  ];

  prData.push(sumExclMvaRow);
  prData.push(mvaRow);
  prData.push(sumInclMvaRow);

  autoTable(doc, {
    startY: first.finalY + 14,
    head: head,
    body: prData,
    headStyles: { fillColor: '#58a333' },
    styles: {
      fontSize:
        options.FontSize === 'default'
          ? DEFAULT_TEXT_XSMALL
          : LARGE_TEXT_XSMALL,
    },
    didParseCell(data) {
      if (
        (data.section === 'body' && data.row.index === prData.length - 1) ||
        data.row.index === prData.length - 2 ||
        data.row.index === prData.length - 3
      ) {
        data.cell.styles.fillColor = 'white';
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.cellPadding = 0.7;
      }
    },
    showHead: 'firstPage',
    columnStyles: {
      0: {
        valign: 'middle',
      },
      1: {
        valign: 'middle',
      },
      2: {
        valign: 'middle',
      },
      3: {
        valign: 'middle',
      },
      4: {
        valign: 'middle',
      },
      5: {
        valign: 'middle',
        textColor: '#b71c1c',
      },
      6: {
        valign: 'middle',
      },
      7: {
        valign: 'middle',
      },
      8: {
        valign: 'middle',
      },
      9: {
        valign: 'middle',
      },
      10: {
        valign: 'middle',
      },
      11: {
        valign: 'middle',
        cellWidth: 20,
      },
      12: {
        valign: 'middle',
        cellWidth: 20,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

const generatePriceRequestReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();
  console.log(options);

  if (!projectCalculation.value) return;

  const wb = XLSX.utils.book_new();

  const xlsxName = createReportName(type, ReportFileType.XLSX);

  const sheetName = checkAndbuildSheetName(
    projectCalculation.value.Name,
    wb.SheetNames
  );

  wb.Props = {
    Title: `${translate('reports.price-request-report')}`,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  const data: (string | number)[][] = [];

  data.push([sheetName]);
  data.push(['']);

  await createHeaderXlsx(data);
  await createProjectInfoXlsx(data);

  data.push([
    `${translate('labels.zip')}`,
    `${translate('labels.delivery-order')}`,
    `${translate('labels.production-line')}`,
    `${translate('labels.nobb-number')}`,
    `${translate('labels.description')}`,
    `${translate('labels.precut')}`,
    `${translate('labels.unit')}`,
    `${translate('labels.quantity')}`,
    `${translate('labels.length')}`,
    `${translate('labels.amount')}`,
    `${translate('labels.comments')}`,
    `${translate('labels.unit-price')}`,
    `${translate('labels.sum')}`,
  ]);

  let postnr = 1;

  let matItems = getPriceRequestMaterials(projectCalculation.value, false);

  const matItemsWithoutDeliveryOrder = matItems.filter(
    mi => mi.DeliveryOrder === null
  );
  matItems = matItems.filter(
    mi =>
      mi.DeliveryOrder !== null &&
      options.DeliveryOrders.includes(mi.DeliveryOrder)
  );

  if (options.IncludeWithoutDeliveryOrder) {
    matItems = matItems.concat(matItemsWithoutDeliveryOrder);
  }

  matItems = matItems.sort((a, b) =>
    (a.NOBBNumber ?? -1) > (b.NOBBNumber ?? -1)
      ? 1
      : (b.NOBBNumber ?? -1) > (a.NOBBNumber ?? -1)
      ? -1
      : 0
  );

  matItems = matItems.sort((a, b) =>
    (a.DeliveryOrder ?? -1) > (b.DeliveryOrder ?? -1)
      ? 1
      : (b.DeliveryOrder ?? -1) > (a.DeliveryOrder ?? -1)
      ? -1
      : 0
  );

  matItems.forEach(item => {
    const row = [
      postnr++,
      item.DeliveryOrder ?? '',
      '',
      item.NOBBNumber ?? '',
      item.ItemName ?? '',
      item.Precut ? (item.PrecutText != null ? item.PrecutText : '') : '',
      item.Unit ?? '',
      item.TotalAmount != null && item.TotalAmount > 0 ? item.TotalAmount : '',
      item.AmountPrUnit != null && item.AmountPrUnit > 0
        ? `${item.AmountPrUnit} ${item.PrecutUnit}`
        : '',
      numberToAbsStringOrEmpty(item.AmountOfMaterials, 2),
      item.Comments ?? '',
      '',
      '',
    ];

    data.push(row);
  });

  const sumExclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-exclude-mva')}`,
    '',
  ];
  const mvaRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-mva-only')}`,
    '',
  ];
  const sumInclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-include-mva')}`,
    '',
  ];

  data.push(sumExclMvaRow);
  data.push(mvaRow);
  data.push(sumInclMvaRow);

  const ws = XLSX.utils.aoa_to_sheet(data);
  wb.Sheets[sheetName] = ws;

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  downloadExcel(xlsxName, wbout);
};

export const generatePriceRequestReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generatePriceRequestReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generatePriceRequestReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
