














































import { ref, PropType, defineComponent } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import { useBuildingElement } from '@/modules/building-element';
import router from '@/router/index';
import { PROJECT_CALCULATION_PATH } from '@/constants/routes';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
    icon: {
      type: Boolean,
    },
    isMenuitem: {
      type: Boolean,
    },
    primary: {
      type: Boolean,
    },
    routeToCalculation: {
      type: Boolean,
    },
  },
  setup(props) {
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const { deleteElementFromCalculation } = useBuildingElement();

    const deleteEvent = async () => {
      loading.value = true;
      deleteElementFromCalculation(props.element);
      if (props.routeToCalculation) {
        await router.push(PROJECT_CALCULATION_PATH);
      }
      loading.value = false;
    };

    return {
      deleteEvent,
      dialog,
      loading,
    };
  },
});
