import {
  BuildingElementCalculation,
  BuildingItem,
  BuildingItemCalculation,
  DocumentationStatus,
} from '@/models/building-element/interfaces';
import { useCalculation } from '@/modules/calculation';
import { computed } from '@vue/composition-api';
import { roundToTwoDecimals } from './common';
import { v4 as uuidv4 } from 'uuid';
import { useBatch } from '@/modules/batch';

const mapBuildingItemCalcProperties = (
  item: BuildingItem
): BuildingItemCalculation => {
  const { getCalculationFactorValue } = useCalculation();
  const _itemCalc: BuildingItemCalculation = {
    Id: item.Id,
    BuildingElementId: item.BuildingElementId,
    Name: item.Name ?? null,
    Unit: item.Unit ?? null,
    DocumentationDownloaded: DocumentationStatus.Default,
    UnitForOrder: item.UnitForOrder ?? null,
    SortOrder: item.SortOrder ?? null,
    Amount: item.Amount ?? null,
    AmountPrUnit: item.AmountPrUnit ?? null,
    Price: item.Price,
    Factor1: item.Factor1,
    PriceListName: item.PriceListName,
    PriceListItemName: item.PriceListItemName ?? null,
    Comments: item.Comments,
    MileStone: item.MileStone,
    AmountAdjustment: item.AmountAdjustment,
    AccountNumber: item.AccountNumber,
    NOBBNumber: item.NOBBNumber,
    NS3420: item.NS3420,
    BaseTime: item.BaseTime,
    Precut: item.Precut,
    SurfaceAddition: item.SurfaceAddition,
    Transport: item.Transport,
    AmountControl: item.AmountControl,
    OpeningAddition: item.OpeningAddition,
    IsFDVExportSelected: item.IsFDVExportSelected ?? false,
    DeliveryOrder: item.DeliveryOrder,
    TransportationAddition: item.TransportationAddition,
    TimeEstimateName: item.TimeEstimateName,
    ItemWarnings: [],
    AmountCalculated: computed({
      get() {
        if (_itemCalc.Amount != null) {
          return _itemCalc.Amount;
        }

        if (_itemCalc.BuildingElement?.Amount != null) {
          if (_itemCalc.AmountAdjustment != null) {
            return (
              _itemCalc.BuildingElement.Amount * _itemCalc.AmountAdjustment
            );
          }
          return _itemCalc.BuildingElement.Amount;
        }

        return 0;
      },
      set(val) {
        _itemCalc.Amount = val;
        if (val != null) {
          _itemCalc.AmountAdjustment = null;
        }
      },
    }),
    IsAmountSet: computed(() => {
      let isSet = false;
      if (
        _itemCalc.AmountCalculated.value !== null &&
        _itemCalc.AmountCalculated.value !== 0
      ) {
        isSet = true;
      }
      return isSet;
    }),
    PreCutText: computed(() => {
      return _itemCalc.Precut ? 'P' : '';
    }),
    Factor2: computed(() => {
      let factor2: Nullable<number> = null;
      let f2 = 0;

      if (_itemCalc.BuildingElement?.Project) {
        factor2 = getCalculationFactorValue(
          2,
          _itemCalc.BuildingElement.Project
        );
      }

      if (_itemCalc.IsAmountSet.value) {
        if (_itemCalc.TimePrUnit.value !== null && factor2 !== null) {
          f2 = _itemCalc.TimePrUnit.value * factor2;
        }
      }

      return f2;
    }),
    Factor3: computed(() => {
      let factor3: Nullable<number> = null;
      let prodSalary = 0;

      if (_itemCalc.BuildingElement?.Project) {
        factor3 = getCalculationFactorValue(
          3,
          _itemCalc.BuildingElement.Project
        );
      }

      if (factor3 != null && _itemCalc.SumProductionSalary.value != null) {
        prodSalary = _itemCalc.SumProductionSalary.value * (factor3 - 1);
      }

      return prodSalary;
    }),
    Factor4: computed(() => {
      let factor4: Nullable<number> = null;
      let prodSalary = 0;

      if (_itemCalc.BuildingElement?.Project) {
        factor4 = getCalculationFactorValue(
          4,
          _itemCalc.BuildingElement.Project
        );
      }

      if (factor4 != null && _itemCalc.SumProductionSalary.value != null) {
        prodSalary = _itemCalc.SumProductionSalary.value * (factor4 - 1);
      }
      return prodSalary;
    }),
    Factor6: computed(() => {
      let factor6: Nullable<number> = null;
      let selfCost = 0;

      if (_itemCalc.BuildingElement?.Project) {
        factor6 = getCalculationFactorValue(
          6,
          _itemCalc.BuildingElement.Project
        );
      }

      if (
        factor6 !== null &&
        _itemCalc.SumSelfCostSalaryAndExpenses.value !== null
      ) {
        selfCost = _itemCalc.SumSelfCostSalaryAndExpenses.value * (factor6 - 1);
      }
      return selfCost;
    }),
    Factor7: computed(() => {
      let factor7: Nullable<number> = null;
      let selfCost = 0;

      if (_itemCalc.BuildingElement?.Project) {
        factor7 = getCalculationFactorValue(
          7,
          _itemCalc.BuildingElement.Project
        );
      }

      if (
        factor7 !== null &&
        _itemCalc.SumSelfCostMaterialsSubContractors.value !== null
      ) {
        selfCost =
          _itemCalc.SumSelfCostMaterialsSubContractors.value * (factor7 - 1);
      }
      return selfCost;
    }),
    Factor5Calculated: computed({
      get() {
        let factor = 1;

        if (_itemCalc.IsAmountSet.value && _itemCalc.Factor5 != null) {
          factor = _itemCalc.Factor5;
        } else if (_itemCalc.BuildingElement) {
          factor = _itemCalc.BuildingElement.Factor5Calculated?.value ?? 1;
        }

        return factor;
      },
      set(val) {
        _itemCalc.Factor5 = val;
      },
    }),
    SumSelfCostMaterialsSubContractors: computed(() => {
      let sum = 0;

      if (
        _itemCalc.IsAmountSet?.value &&
        _itemCalc.Price != null &&
        _itemCalc.AmountOfMaterials?.value != null &&
        _itemCalc.Factor5Calculated?.value != null
      ) {
        sum =
          _itemCalc.AmountOfMaterials.value *
          _itemCalc.Price *
          _itemCalc.Factor5Calculated.value;
      }

      return sum;
    }),
    Factor1Calculated: computed({
      get() {
        if (_itemCalc.Factor1 != null) {
          return _itemCalc.Factor1;
        } else if (
          _itemCalc.BuildingElement?.Factor1Calculated?.value != null
        ) {
          return _itemCalc.BuildingElement.Factor1Calculated.value;
        }

        return null;
      },
      set(val) {
        _itemCalc.Factor1 = val;
      },
    }),
    TimePrUnit: computed(() => {
      let baseTimeCalc = 0;
      if (
        _itemCalc.BaseTime != null &&
        _itemCalc.Factor1Calculated.value !== null
      ) {
        baseTimeCalc = _itemCalc.BaseTime * _itemCalc.Factor1Calculated.value;
      }

      return baseTimeCalc;
    }),
    SumHours: computed(() => {
      let hours = 0;
      if (
        _itemCalc.TimePrUnit.value !== null &&
        _itemCalc.AmountCalculated.value !== null
      ) {
        hours = _itemCalc.TimePrUnit.value * _itemCalc.AmountCalculated.value;
      }
      return hours;
    }),
    SumProductionSalary: computed(() => {
      let salary = 0;

      if (
        _itemCalc.Factor2.value !== null &&
        _itemCalc.AmountCalculated.value !== null
      ) {
        salary = _itemCalc.Factor2.value * _itemCalc.AmountCalculated.value;
      }
      return salary;
    }),
    SumSelfCostSalaryAndExpenses: computed(() => {
      if (
        _itemCalc.SumProductionSalary.value !== null &&
        _itemCalc.Factor3.value !== null &&
        _itemCalc.Factor4.value !== null
      ) {
        return (
          _itemCalc.SumProductionSalary.value +
          _itemCalc.Factor3.value +
          _itemCalc.Factor4.value
        );
      }
      return null;
    }),
    SumSalaryAndExpenses: computed(() => {
      let expenses = 0;

      if (
        _itemCalc.SumSelfCostSalaryAndExpenses.value !== null &&
        _itemCalc.Factor6.value !== null
      ) {
        expenses =
          _itemCalc.SumSelfCostSalaryAndExpenses.value +
          _itemCalc.Factor6.value;
      }

      return expenses;
    }),
    SumMaterialsUE: computed(() => {
      let sum = 0;

      if (_itemCalc.IsAmountSet.value) {
        if (
          _itemCalc.SumSelfCostMaterialsSubContractors.value !== null &&
          _itemCalc.Factor7.value !== null
        ) {
          sum =
            _itemCalc.SumSelfCostMaterialsSubContractors.value +
            _itemCalc.Factor7.value;
        }
      }

      return sum;
    }),
    SumTotal: computed(() => {
      const sumSAndE = _itemCalc.SumSalaryAndExpenses.value ?? 0;

      const sumMAndUE = _itemCalc.SumMaterialsUE.value ?? 0;

      return sumSAndE + sumMAndUE;
    }),
    UnitPrice: computed(() => {
      if (
        _itemCalc.AmountCalculated.value !== null &&
        _itemCalc.AmountCalculated.value !== 0 &&
        _itemCalc.SumTotal.value !== null &&
        _itemCalc.SumTotal.value !== 0
      ) {
        return (
          roundToTwoDecimals(_itemCalc.SumTotal.value) /
          roundToTwoDecimals(_itemCalc.AmountCalculated.value)
        );
      }
      return null;
    }),
    UnitPriceMaterials: computed(() => {
      if (_itemCalc.IsAmountSet.value) {
        if (
          _itemCalc.SumSelfCostMaterialsSubContractors.value !== null &&
          _itemCalc.SumSelfCostMaterialsSubContractors.value !== 0 &&
          _itemCalc.AmountCalculated.value !== null &&
          _itemCalc.AmountCalculated.value !== 0
        ) {
          return Number(
            _itemCalc.SumSelfCostMaterialsSubContractors.value /
              _itemCalc.AmountCalculated.value
          );
        }
      }
      return 0;
    }),
    AmountOfMaterials: computed(() => {
      if (
        _itemCalc.AmountPrUnit != null &&
        _itemCalc.AmountCalculated.value !== null
      ) {
        return _itemCalc.AmountCalculated.value * _itemCalc.AmountPrUnit;
      }
      return null;
    }),
    IsTimeEstimateAdded: computed(() => {
      return _itemCalc.NS3420 != null && _itemCalc.TimeEstimateName != null;
    }),
    IsTimeFactorAdded: computed(() => {
      return _itemCalc.TimeFactors ? _itemCalc.TimeFactors.length !== 0 : false;
    }),
    ReportPriceListName: computed(() => {
      return _itemCalc.PriceListItemName ?? '';
    }),
    SurfaceAdditionAmount: computed(() => {
      if (_itemCalc.BuildingElement?.AdditionItems) {
        const addition = _itemCalc.BuildingElement.AdditionItems.find(
          p => p.Name === 'Flatetillegg'
        );
        if (addition) {
          if (addition.Amount) {
            return addition.Amount;
          }
        }
      }
      return 0;
    }),
    OpeningAdditionAmount: computed(() => {
      if (_itemCalc.BuildingElement?.AdditionItems) {
        const addition = _itemCalc.BuildingElement.AdditionItems.find(
          p => p.Name === 'Åpninger'
        );
        if (addition) {
          if (addition.Amount != null) {
            return addition.Amount;
          }
        }
      }
      return 0;
    }),
    SumAdditions: computed(() => {
      let surface = 0;
      let opening = 0;

      if (
        _itemCalc.SurfaceAddition != null &&
        _itemCalc.SurfaceAdditionAmount.value != null &&
        _itemCalc.Factor1Calculated.value != null
      ) {
        surface =
          _itemCalc.SurfaceAdditionAmount.value *
          _itemCalc.SurfaceAddition *
          _itemCalc.Factor1Calculated.value;
      }

      if (
        _itemCalc.OpeningAddition != null &&
        _itemCalc.Factor1Calculated.value != null &&
        _itemCalc.OpeningAdditionAmount.value != null
      ) {
        opening =
          _itemCalc.OpeningAdditionAmount.value *
          _itemCalc.OpeningAddition *
          _itemCalc.Factor1Calculated.value;
      }
      return surface + opening;
    }),
    SumTime: computed(() => {
      let time = 0;

      if (
        _itemCalc.SumAdditions.value !== null &&
        _itemCalc.SumHours.value !== null
      ) {
        time = _itemCalc.SumAdditions.value + _itemCalc.SumHours.value;
      }

      return time;
    }),
    SumTotalWithAdditions: computed(() => {
      let hourCost = 0;
      let sumMAndUE = 0;

      let f2: Nullable<number> = null;
      let f3: Nullable<number> = null;
      let f4: Nullable<number> = null;
      let f6: Nullable<number> = null;

      let cost: Nullable<number> = null;

      if (_itemCalc.BuildingElement?.Project) {
        f2 = getCalculationFactorValue(2, _itemCalc.BuildingElement.Project);
        f3 = getCalculationFactorValue(3, _itemCalc.BuildingElement.Project);
        f4 = getCalculationFactorValue(4, _itemCalc.BuildingElement.Project);
        f6 = getCalculationFactorValue(6, _itemCalc.BuildingElement.Project);
      }

      if (f2 !== null && f3 !== null && f4 !== null && f6 !== null) {
        cost = f2 * (f3 + f4 - 1) * f6;
      }

      if (_itemCalc.SumTime.value != null && cost != null) {
        hourCost = _itemCalc.SumTime.value * cost;
      }

      if (_itemCalc.SumMaterialsUE.value != null) {
        sumMAndUE = _itemCalc.SumMaterialsUE.value;
      }

      return hourCost + sumMAndUE;
    }),
    UnitPriceWithAdditions: computed(() => {
      let sumTotal = 0;
      let amountCalc = 0;

      if (_itemCalc.SumTotalWithAdditions.value != null) {
        sumTotal = roundToTwoDecimals(_itemCalc.SumTotalWithAdditions.value);
      }

      if (_itemCalc.AmountCalculated.value != null) {
        amountCalc = roundToTwoDecimals(_itemCalc.AmountCalculated.value);
      }

      if (sumTotal !== 0 && amountCalc !== 0) {
        return sumTotal / amountCalc;
      }

      return 0;
    }),
    ProjectCost: computed(() => {
      let factor2: Nullable<number> = null;
      let factor3: Nullable<number> = null;

      if (_itemCalc.BuildingElement?.Project) {
        factor2 = getCalculationFactorValue(
          2,
          _itemCalc.BuildingElement.Project
        );
        factor3 = getCalculationFactorValue(
          3,
          _itemCalc.BuildingElement.Project
        );
      }

      let time = 0;
      let selfcost = 0;

      if (
        factor2 !== null &&
        factor3 !== null &&
        _itemCalc.SumTime.value !== null
      ) {
        time = _itemCalc.SumTime.value * factor2 * factor3;
      }

      if (_itemCalc.SumSelfCostMaterialsSubContractors.value !== null) {
        selfcost = _itemCalc.SumSelfCostMaterialsSubContractors.value;
      }

      return time + selfcost;
    }),
    SumProfit: computed(() => {
      let profit = 0;

      if (
        _itemCalc.Factor6.value !== null &&
        _itemCalc.Factor7.value !== null
      ) {
        profit = _itemCalc.Factor6.value + _itemCalc.Factor7.value;
      }

      return profit;
    }),
    SumSelfCost: computed(() => {
      let cost = 0;
      if (
        _itemCalc.SumSelfCostMaterialsSubContractors.value !== null &&
        _itemCalc.SumSelfCostSalaryAndExpenses.value !== null
      ) {
        cost =
          _itemCalc.SumSelfCostSalaryAndExpenses.value +
          _itemCalc.SumSelfCostMaterialsSubContractors.value;
      }
      return cost;
    }),
    SumSalaryAndSocialCost: computed(() => {
      let cost = 0;

      if (
        _itemCalc.Factor3.value !== null &&
        _itemCalc.SumProductionSalary.value !== null
      ) {
        cost = _itemCalc.SumProductionSalary.value + _itemCalc.Factor3.value;
      }
      return cost;
    }),
    DeviationAmount: computed(() => {
      if (_itemCalc.AmountControl != null) {
        return (
          _itemCalc.AmountControl - (_itemCalc.AmountCalculated.value ?? 0)
        );
      }
      return 0;
    }),
    DeviationPrice: computed(() => {
      if (_itemCalc.DeviationAmount.value !== null) {
        return (
          roundToTwoDecimals(_itemCalc.DeviationAmount.value) *
          (_itemCalc.UnitPrice.value ?? 0)
        );
      }
      return 0;
    }),
    SumMargin: computed(() => {
      let margin = 0;

      if (
        _itemCalc.Factor4.value != null &&
        _itemCalc.SumProfit.value != null
      ) {
        margin = _itemCalc.Factor4.value + _itemCalc.SumProfit.value;
      }
      return margin;
    }),
    Items: [],
    TimeFactors: [],
    IsHighLighted: false,
    IsRecentlyAdded: false,
    IsSelected: false,
    IsModifiedRecently: false,
    PriceMatch: null,
    Status: null,
  };

  return _itemCalc;
};

export const removeTimeEstimate = (item: BuildingItemCalculation) => {
  item.NS3420 = null;
  item.BaseTime = 0;
  item.SurfaceAddition = 0;
  item.TransportationAddition = 0;
  item.OpeningAddition = 0;
  item.TimeEstimateName = null;
  item.IsModifiedRecently = true;
};

export const createNewDefaultFromElement = (
  elementId: string
): BuildingItem => {
  const item: BuildingItem = {
    Id: uuidv4(),
    AmountPrUnit: 1,
    BaseTime: 0,
    OpeningAddition: 0,
    SurfaceAddition: 0,
    TransportationAddition: 0,
    Precut: false,
    Price: 0,
    Name: '',
    BuildingElementId: elementId,
    AccountNumber: null,
    Amount: null,
    AmountAdjustment: null,
    AmountControl: null,
    Comments: null,
    Created: null,
    DeliveryOrder: null,
    Factor1: null,
    Factor5: null,
    IsFDVExportSelected: false,
    LastModified: null,
    MileStone: null,
    NOBBNumber: null,
    NS3420: null,
    PriceListItemName: null,
    PriceListName: null,
    SortOrder: null,
    TimeEstimateName: null,
    Transport: null,
    Unit: null,
    UnitForOrder: null,
  };

  return item;
};

export const createNewDefaultBIC = (
  element: BuildingElementCalculation,
  elementId: string
): BuildingItemCalculation => {
  const item: BuildingItem = createNewDefaultFromElement(elementId);
  const newCalcItem = mapBuildingItemCalcProperties(item);
  newCalcItem.BuildingElement = element;
  newCalcItem.BuildingElementId = element.Id;
  newCalcItem.TimeFactors = [];

  return newCalcItem;
};

export const onBuildingItemAdjustmentChange = (
  item: BuildingItemCalculation
) => {
  const { registerItemSaveChangesWithAdditionAndParent } = useBatch();
  item.Amount = null;
  registerItemSaveChangesWithAdditionAndParent(item);
};

export const buildItemCalculations = (
  items: Array<BuildingItem>,
  element: BuildingElementCalculation
): Array<BuildingItemCalculation> => {
  const _itemsCalc: Array<BuildingItemCalculation> = [];
  items.forEach(item => {
    const itemCalc = mapBuildingItemCalcProperties(item);
    itemCalc.BuildingElement = element;
    itemCalc.BuildingElementId = element.Id;
    _itemsCalc.push(itemCalc);
  });

  return _itemsCalc;
};

export const calculateItemWarnings = (element: BuildingElementCalculation) => {
  if (element.BuildingItems && element.BuildingItems.length > 0) {
    element.BuildingItems.forEach(item => {
      item.ItemWarnings = [];
      if (item.AmountAdjustment) {
        item.ItemWarnings.push('info.amount-adjusted');
      }

      if (
        !item.AmountPrUnit ||
        item.AmountPrUnit === 0 ||
        item.AmountPrUnit === 1
      ) {
        item.ItemWarnings.push('info.amount-waste');
      }

      if (!item.NOBBNumber) {
        item.ItemWarnings.push('info.no-nobbno');
      }
    });
  }
};

export const instanceOfBuildingItemCalculation = (
  object: BuildingItem | BuildingItemCalculation
): object is BuildingItemCalculation => {
  return 'IsModifiedRecently' in object;
};

export const mapBuildingItemCalcToBuildingItem = (
  itemCalc: BuildingItemCalculation
): BuildingItem => {
  return {
    Amount: itemCalc.Amount,
    AmountAdjustment: itemCalc.AmountAdjustment,
    AmountPrUnit: itemCalc.AmountPrUnit,
    BuildingElementId: itemCalc.BuildingElementId ?? null,
    Comments: itemCalc.Comments,
    Created: itemCalc.Created,
    Factor1: itemCalc.Factor1,
    Id: itemCalc.Id,
    LastModified: itemCalc.LastModified,
    Name: itemCalc.Name,
    Price: itemCalc.Price,
    SortOrder: itemCalc.SortOrder,
    Unit: itemCalc.Unit,
    AccountNumber: itemCalc.AccountNumber,
    AmountControl: itemCalc.AmountControl,
    BaseTime: itemCalc.BaseTime,
    DeliveryOrder: itemCalc.DeliveryOrder,
    Factor5: itemCalc.Factor5,
    IsFDVExportSelected: itemCalc.IsFDVExportSelected,
    MileStone: itemCalc.MileStone,
    NOBBNumber: itemCalc.NOBBNumber,
    NS3420: itemCalc.NS3420,
    OpeningAddition: itemCalc.OpeningAddition,
    Precut: itemCalc.Precut,
    PriceListItemName: itemCalc.PriceListItemName,
    PriceListName: itemCalc.PriceListName,
    SurfaceAddition: itemCalc.SurfaceAddition,
    TimeEstimateName: itemCalc.TimeEstimateName,
    Transport: itemCalc.Transport,
    TransportationAddition: itemCalc.TransportationAddition,
    UnitForOrder: itemCalc.UnitForOrder,
  };
};
