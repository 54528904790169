



















































































































































































































































































import {
  LICENSE_PRICE_ADDITION_MEMBERSHIP,
  LICENSE_PRICE_ADDITION_NO_MEMBERSHIP,
  LICENSE_PRICE_FULL_MEMBERSHIP,
  LICENSE_PRICE_FULL_NO_MEMBERSHIP,
} from '@/constants/common';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    return {
      registerUrl: process.env.VUE_APP_REGISTER_URL,
      LICENSE_PRICE_ADDITION_MEMBERSHIP,
      LICENSE_PRICE_ADDITION_NO_MEMBERSHIP,
      LICENSE_PRICE_FULL_NO_MEMBERSHIP,
      LICENSE_PRICE_FULL_MEMBERSHIP,
    };
  },
});
