














































import { ESTIMATE_HEADERS } from '@/constants/table-headers';
import { defineComponent, ref } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import TimeEstimates from '@/components/time-estimate/index.vue';
import { TimeEstimate } from '@/models/time-estimate/interface';

export default defineComponent({
  components: {
    Loader,
    DialogTitle,
    TimeEstimates,
  },
  props: {},
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);

    const close = async () => {
      dialog.value = false;
    };

    const addFromEstimate = (estimate: TimeEstimate) => {
      emit('addFromEstimate', estimate);
      close();
    };

    return {
      close,
      addFromEstimate,
      ESTIMATE_HEADERS,
      dialog,
    };
  },
});
