import { reactive, toRefs } from '@vue/composition-api';
import { CustomError } from '@/models/common/interfaces';
import { useSnackbar } from './snackbar';
import { useErrorModal } from './error';
import { useApi } from './api';
import { isSuccess } from '@/helpers/common';
import { NobbItem, NobbModule } from '@/models/nobb/interfaces';

interface State {
  error?: CustomError;
}

const state = reactive<State>({
  error: undefined,
});

export const useProductInfo = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const getNobbItem = async (nobbNr: number): Promise<NobbItem | undefined> => {
    let items: Array<NobbItem> = [];
    let item: NobbItem | undefined = undefined;

    const { get } = useApi('/nobb');

    const queries: Record<string, string> = {
      nobbParams: `items?nobbnos=${nobbNr}`,
    };

    try {
      const response = await get(queries);
      if (isSuccess(response.status)) {
        items = JSON.parse(response.data);
        item = items.find(item => item.nobbNumber === nobbNr);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return item;
  };

  const convertToNobbNos = (numbers: number[]): string => {
    if (numbers.length === 1) {
      return String(numbers[0]);
    }
    return numbers.join(',');
  };

  const validateCommaSeparatedList = (input: string): boolean => {
    const regex = /^\d+(,\d+)*$/;
    return regex.test(input);
  };

  const getNobbItems = async (
    numbers: Array<number>
  ): Promise<Array<NobbItem>> => {
    let items: Array<NobbItem> = [];

    const { get } = useApi('/nobb');

    try {
      const nobbNos = convertToNobbNos(numbers);
      if (validateCommaSeparatedList(nobbNos)) {
        const queries: Record<string, string> = {
          nobbParams: `items?nobbnos=${convertToNobbNos(numbers)}`,
        };

        try {
          const response = await get(queries);
          if (isSuccess(response.status)) {
            items = JSON.parse(response.data);
          }
        } catch (e) {
          state.error = e.response;
          if (state.error) {
            errorModal(state.error);
          } else {
            snack('snack.sorry', false);
          }
        }
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
      return [];
    }
    return items;
  };

  const getDocumentsByItem = async (
    nobbNr: number,
    publicationtype: string,
    filename: string
  ): Promise<boolean> => {
    let success = false;
    const { get } = useApi('/nobb/pdf');

    const queries: Record<string, string> = {
      nobbParams: `media/documents/items/${nobbNr}/${publicationtype}`,
    };

    try {
      const response = await get(queries, undefined, undefined, 'arraybuffer');
      if (isSuccess(response.status)) {
        // create the blob object with content-type "application/pdf"
        const arr = new Uint8Array(response.data);
        const blob = new Blob([arr], { type: 'application/pdf' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = filename;

        document.body.appendChild(link);

        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        // Remove link from body
        document.body.removeChild(link);

        success = true;
      }
    } catch (e) {
      success = false;
    }

    return success;
  };

  const convertMedia = async (mediaUrl: string): Promise<string> => {
    let url = '';

    const { get } = useApi('/nobb');

    const queries: Record<string, string> = {
      nobbParams: `media/converter?url=${mediaUrl}`,
    };

    try {
      const response = await get(queries);
      if (isSuccess(response.status)) {
        url = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return url;
  };

  const getNobbModule = async (
    moduleNr: number
  ): Promise<NobbModule | undefined> => {
    let items: Array<NobbModule> = [];
    let item: NobbModule | undefined = undefined;

    const { get } = useApi('/nobb');

    const queries: Record<string, string> = {
      nobbParams: `modules?modules=${moduleNr}`,
    };

    try {
      const response = await get(queries);
      if (isSuccess(response.status)) {
        items = JSON.parse(response.data);
        item = items.find(item => item.number === moduleNr);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return item;
  };

  const downloadMedia = async (
    mediaUrl: string,
    name: string
  ): Promise<void> => {
    const { get } = useApi('/nobb/');

    const queries: Record<string, string> = {
      nobbParams: `media/${mediaUrl}?download=false`,
    };

    try {
      const response = await get(queries);
      if (isSuccess(response.status)) {
        const blob = response.data.on('finish', function() {
          return response.data.toBlob('application/pdf');
        });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = name;

        document.body.appendChild(link);

        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        // Remove link from body
        document.body.removeChild(link);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
  };

  return {
    ...toRefs(state),
    getNobbItem,
    getNobbItems,
    getNobbModule,
    downloadMedia,
    convertMedia,
    getDocumentsByItem,
  };
};
