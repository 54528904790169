














































































































import { translate } from '@/localization';
import { useImport } from '@/modules/import';
import { useSnackbar } from '@/modules/snackbar';
import { ref, defineComponent } from '@vue/composition-api';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import { useLibrary } from '@/modules/library';
import { finalizeBuildingTypeTemplate } from '@/helpers/library';
import Profession from '@/components/profession/index.vue';

export default defineComponent({
  components: {
    vueDropzone: vue2Dropzone,
    DialogTitle,
    InfoTooltip,
    Profession,
  },
  setup(props, { emit }) {
    const { importLibraryFromFile } = useImport();
    const { importTemplate } = useLibrary();
    const dialog = ref<boolean>(false);
    const templateFile = ref<File | undefined>(undefined);
    const importZone = ref();
    const library = ref<BuildingTypeTemplate | undefined>();
    const validating = ref(false);
    const loading = ref(false);

    const validateImport = async (file: File) => {
      validating.value = true;
      templateFile.value = file;
      importZone.value && importZone.value.removeAllFiles();
      if (file.type === 'application/json' || file.type === 'text/xml') {
        try {
          library.value = await importLibraryFromFile(file);
        } catch (e) {
          console.log(e);
          library.value = undefined;
        }

        if (library.value) {
          importZone.value.disable();
        } else {
          templateFile.value = undefined;
        }
        importZone.value && importZone.value.removeAllFiles();
      } else {
        templateFile.value = undefined;
        importZone.value && importZone.value.removeAllFiles();
      }
      validating.value = false;
    };

    const handleError = (file: File, message: string) => {
      const { snack } = useSnackbar();
      importZone.value.removeFile(file);
      if (!message.includes('Upload')) {
        snack(message, false);
      }
    };

    const close = () => {
      templateFile.value = undefined;
      dialog.value = false;
      library.value = undefined;
    };

    const submit = async () => {
      loading.value = true;
      if (library.value) {
        try {
          const payload = finalizeBuildingTypeTemplate(library.value);
          const success = await importTemplate(payload);
          if (success) {
            emit('update', library.value.ProfessionCode?.toString());
            close();
          }
        } catch (e) {
          console.log(e);
          loading.value = false;
        }
      }
      loading.value = false;
    };

    const cancel = () => {
      templateFile.value = undefined;
      library.value = undefined;
      if (importZone.value) importZone.value.enable();
    };

    return {
      submit,
      validateImport,
      handleError,
      cancel,
      close,
      dialog,
      templateFile,
      importZone,
      library,
      validating,
      loading,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'http://localhost',
        thumbnailWidth: 150,
        maxFilesize: 15,
        maxFiles: 1,
        acceptedFiles: '.xml,.json',
        dictDefaultMessage: `${translate(
          'project.import-project-default-message'
        )}`,
      },
    };
  },
});
