import './util/installCompositionApi.ts';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import AppLayout from '@/layout/applayout/index.vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import { i18n } from '@/localization/index';
import CountryFlag from 'vue-country-flag';

Vue.use(PerfectScrollbar);
Vue.component('AppLayout', AppLayout);
Vue.component('country-flag', CountryFlag);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App),
  i18n,
}).$mount('#app');
