















































































































































import { useCompany } from '@/modules/company';
import { computed, ref } from '@vue/composition-api';
import OrganizationForm from '@/components/forms/organization-form/index.vue';
import { defineComponent } from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import Loader from '@/components/loader/index.vue';
import { createAddressString } from '@/helpers/common';

export default defineComponent({
  components: {
    OrganizationForm,
    ContentBar,
    Loader,
  },
  setup() {
    const { loading, company } = useCompany();
    const dialog = ref<boolean>(false);

    const close = () => {
      dialog.value = false;
    };

    const address = computed(() => {
      createAddressString(company?.value);
    });

    return {
      company,
      address,
      close,
      dialog,
      loading,
    };
  },
});
