














































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { useAuth } from '@/modules/auth';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    attemptedUsername: {
      type: String,
    },
  },
  setup(props) {
    const { resetPassword } = useAuth();
    const username = ref<string>();
    const valid = ref(false);
    const dialog = ref(false);
    const success = ref(false);
    const loading = ref(false);

    watch(
      () => props.attemptedUsername,
      first => {
        username.value = first;
      }
    );

    onMounted(() => {
      username.value = props.attemptedUsername;
    });

    const close = () => {
      dialog.value = false;
    };

    const submit = async () => {
      if (username.value) {
        loading.value = true;
        success.value = await resetPassword(username.value);
        loading.value = false;
      }
    };

    return {
      close,
      submit,
      dialog,
      valid,
      username,
      success,
      loading,
      usernameRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
});
