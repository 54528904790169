



























































































import {
  ref,
  defineComponent,
  PropType,
  onMounted,
  computed,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';
import { useCalculation } from '@/modules/calculation';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { useLibrary } from '@/modules/library';
import {
  BuildingElementTemplate,
  BuildingTypeTemplate,
} from '@/models/library/interfaces';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    item: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const loading = ref(false);
    const selectedLibrary = ref<BuildingTypeTemplate>();
    const selectedElementTemplate = ref<BuildingElementTemplate>();
    const newName = ref<Nullable<string>>(props.item.Name ?? null);

    const { projectCalculation } = useCalculation();
    const {
      addItemToLibrary,
      getBuildingTemplatesByProfession,
      buildingTemplatesByProfession,
      getBuildingTemplate,
    } = useLibrary();

    const valid = computed(() => {
      return (
        selectedLibrary.value != null &&
        newName.value &&
        selectedElementTemplate.value != null
      );
    });

    onMounted(async () => {
      if (
        !buildingTemplatesByProfession.value &&
        projectCalculation.value?.Profession?.Code
      ) {
        await getBuildingTemplatesByProfession(
          projectCalculation.value.Profession.Code,
          true
        );
      }
    });

    const onLibraryChange = async () => {
      if (selectedLibrary.value?.Id) {
        selectedLibrary.value = await getBuildingTemplate(
          selectedLibrary.value.Id
        );
        selectedElementTemplate.value = undefined;
      }
    };

    const close = () => {
      dialog.value = false;
      selectedLibrary.value = undefined;
      selectedElementTemplate.value = undefined;
      newName.value = null;
      emit('close', true);
    };

    const submit = async () => {
      if (
        selectedLibrary.value &&
        valid.value &&
        newName.value &&
        selectedElementTemplate.value
      ) {
        loading.value = true;
        await addItemToLibrary(
          selectedLibrary.value,
          selectedElementTemplate.value,
          props.item,
          newName.value
        );
        loading.value = false;
        dialog.value = false;
      }
    };

    return {
      close,
      submit,
      onLibraryChange,
      buildingTemplatesByProfession,
      selectedElementTemplate,
      dialog,
      loading,
      valid,
      newName,
      selectedLibrary,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
    };
  },
});
