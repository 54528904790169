




















































































































































import { ref, defineComponent, computed } from '@vue/composition-api';
import { useImport } from '@/modules/import';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { useProject } from '@/modules/project';
import { useSnackbar } from '@/modules/snackbar';
import { ImportedProject } from '@/models/projects/interfaces';
import { translate } from '@/localization/index';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import ImportInfo from '@/components/projects/import-project/import-info/index.vue';
import { NstGenerelt } from '@/models/standards/ns3459/interfaces';

export default defineComponent({
  props: {
    isMenuitem: {
      type: Boolean,
    },
    first: {
      type: Boolean,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    DialogTitle,
    InfoTooltip,
    ImportInfo,
  },
  setup() {
    const dialog = ref(false);
    const loading = ref(false);
    const projectFile = ref<File | undefined>(undefined);
    const importZone = ref();
    const validating = ref(false);
    const newProject = ref<ImportedProject | undefined>(undefined);
    const generalInfo = ref<NstGenerelt | undefined>(undefined);

    const {
      parseProjectFile,
      importProject,
      createImportProject,
    } = useImport();
    const { getProjects, refreshActiveProject } = useProject();

    const valid = computed(() => {
      return (
        newProject.value &&
        generalInfo.value &&
        projectFile.value &&
        newProject.value.Project.Name &&
        newProject.value.Project.ProjectNumber
      );
    });

    const fileOk = computed(() => {
      return newProject.value && generalInfo.value && projectFile.value;
    });

    const validateImport = async (file: File) => {
      validating.value = true;
      projectFile.value = file;
      importZone.value && importZone.value.removeAllFiles();
      if (file.type === 'application/json' || file.type === 'text/xml') {
        try {
          const ns = await parseProjectFile(file);
          if (ns) {
            generalInfo.value = ns.genereltField;
            newProject.value = await createImportProject(ns);
          }
        } catch (e) {
          console.log(e);
        }

        if (valid.value) {
          importZone.value.disable();
        } else {
          projectFile.value = undefined;
        }
        importZone.value && importZone.value.removeAllFiles();
      } else {
        projectFile.value = undefined;
        importZone.value && importZone.value.removeAllFiles();
      }
      validating.value = false;
    };

    const removeCalc = (calcId: string) => {
      if (
        newProject.value &&
        newProject.value.Children &&
        newProject.value.Children.length > 0
      ) {
        const calcIndex = newProject.value.Children.findIndex(
          calc => calc.Project && calc.Project.Id === calcId
        );

        if (calcIndex >= 0) {
          newProject.value.Children.splice(calcIndex, 1);
        }
      }
    };

    const cancel = () => {
      projectFile.value = undefined;
      newProject.value = undefined;
      generalInfo.value = undefined;
      if (importZone.value) importZone.value.enable();
    };

    const close = () => {
      projectFile.value = undefined;
      newProject.value = undefined;
      generalInfo.value = undefined;
      dialog.value = false;
    };

    const handleError = (file: File, message: string) => {
      const { snack } = useSnackbar();
      importZone.value.removeFile(file);
      if (!message.includes('Upload')) {
        snack(message, false);
      }
    };

    const submit = async () => {
      loading.value = true;
      if (valid.value && newProject.value) {
        let imported = false;
        imported = await importProject(newProject.value);
        if (imported) {
          await getProjects(true);
          await refreshActiveProject();
          close();
        }
      }

      loading.value = false;
    };

    return {
      cancel,
      close,
      validateImport,
      handleError,
      submit,
      removeCalc,
      loading,
      importZone,
      projectFile,
      dialog,
      valid,
      fileOk,
      validating,
      newProject,
      generalInfo,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'http://localhost',
        thumbnailWidth: 150,
        maxFilesize: 3,
        maxFiles: 1,
        acceptedFiles: '.xml,.json',
        dictDefaultMessage: `${translate(
          'project.import-project-default-message'
        )}`,
      },
    };
  },
});
