import { ExportType } from '@/constants/enum';
import { isSuccess } from '@/helpers/common';
import {
  createAdditionItemTemplateType,
  createBuildingElementTemplateType,
  createBuildingItemTemplateType,
  createBuildingTypeTemplateType,
} from '@/helpers/library';
import { CustomError } from '@/models/common/interfaces';
import {
  AdditionItemTemplateType,
  BuildingElementTemplateType,
  BuildingItemTemplateType,
  BuildingTypeTemplate,
  BuildingTypeTemplateType,
} from '@/models/library/interfaces';
import { NstNS3459 } from '@/models/standards/ns3459/interfaces';
import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { useErrorModal } from './error';
import { useSnackbar } from './snackbar';

interface State {
  loading: boolean;
  error?: CustomError;
}

const state = reactive<State>({
  loading: false,
  error: undefined,
});

export const useExport = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const exportProject = async (
    projectId: string,
    includeCalculations: boolean,
    type: ExportType
  ): Promise<NstNS3459 | undefined> => {
    const { get } = useApi(
      `/project/${projectId}/export/${includeCalculations}`
    );
    let nstNS3459: NstNS3459 | undefined = undefined;
    state.loading = true;
    try {
      if (type === ExportType.XML) {
        const response = await get(undefined, 'application/xml');
        if (isSuccess(response.status)) {
          nstNS3459 = response.data;
        }
      } else if (type === ExportType.JSON) {
        const response = await get();
        if (isSuccess(response.status)) {
          nstNS3459 = response.data;
        }
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;

    return nstNS3459;
  };

  const exportTemplate = async (
    template: BuildingTypeTemplate
  ): Promise<BuildingTypeTemplateType | undefined> => {
    const templateType: BuildingTypeTemplateType = createBuildingTypeTemplateType(
      template
    );

    const elementTypes: Array<BuildingElementTemplateType> = [];

    template.BuildingElementTemplates.forEach(eleTemplate => {
      const elementTemplate = createBuildingElementTemplateType(eleTemplate);
      const itemTypes: Array<BuildingItemTemplateType> = [];
      const additionTypes: Array<AdditionItemTemplateType> = [];
      eleTemplate.BuildingItemTemplates.forEach(itemTemplate => {
        const itemTemplateType = createBuildingItemTemplateType(itemTemplate);
        itemTypes.push(itemTemplateType);
      });
      eleTemplate.AdditionItemTemplates.forEach(additionTemplate => {
        const additionTemplateType = createAdditionItemTemplateType(
          additionTemplate
        );
        additionTypes.push(additionTemplateType);
      });
      elementTemplate.AdditionItemTemplates = additionTypes;
      elementTemplate.BuildingItemTemplates = itemTypes;

      elementTypes.push(elementTemplate);
    });

    templateType.BuildingElementTemplates = elementTypes;

    return templateType;
  };

  return {
    exportProject,
    exportTemplate,
    ...toRefs(state),
  };
};
