import { reactive, toRefs } from '@vue/composition-api';

interface State {
  active: boolean;
  text: string;
  success: boolean;
}

const state = reactive<State>({
  active: false,
  text: '',
  success: true,
});

export const useSnackbar = () => {
  const snack = (message: string, success: boolean) => {
    state.active = true;
    state.text = message;
    state.success = success;
  };

  return {
    snack,
    ...toRefs(state),
  };
};
