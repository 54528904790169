import { ApplicationSource } from '@/constants/enum';
import {
  AdditionItem,
  AdditionType,
  BuildingElement,
  BuildingItem,
} from '@/models/building-element/interfaces';
import { ProjectContact } from '@/models/contacts/interfaces';
import {
  NstEgenskap,
  NstInnholdNSProsjektdata,
  NstPostNSProsjektdata,
  NstProsjektdeltaker,
  NstProsjektNS,
} from '@/models/standards/ns3459/interfaces';
import { ImportedProject, Project } from '@/models/projects/interfaces';
import { useFactors } from './factors';
import { v4 as uuidv4 } from 'uuid';
import {
  ProjectFactor,
  ProjectFactorItem,
} from '@/models/calculation/interfaces';
import { convertUnit } from '@/helpers/common';

export const useProjectBuilder = () => {
  let _projectContacts: Array<ProjectContact> = [];
  let _applicationSource: ApplicationSource | undefined = undefined;
  const _supportedProfessionCodes: Array<string> = [];
  const _buildingElements: Array<BuildingElement> = [];
  const _IdElementsDictionary = new Map<string, BuildingElement>();

  const convertNumberFromNstEgenskap = (
    properties: Array<NstEgenskap>,
    nameField: string
  ): Nullable<number> => {
    let converted: Nullable<number> = null;

    const property = properties.find(e => e.navnField === nameField);

    if (property?.verdiField != null) {
      converted = parseFloat(property.verdiField.toString().replace(/,/g, '.'));
    }

    return converted;
  };

  const convertStringFromNstEgenskap = (
    properties: Array<NstEgenskap>,
    nameField: string
  ): Nullable<string> => {
    let converted: Nullable<string> = null;

    const property = properties.find(e => e.navnField === nameField);

    if (property?.verdiField != null) {
      converted = property.verdiField;
    }

    return converted;
  };

  const convertBooleanFromNstEgenskap = (
    properties: Array<NstEgenskap>,
    nameField: string
  ): boolean => {
    let converted = false;

    const property = properties.find(e => e.navnField === nameField);

    if (
      property?.verdiField != null &&
      property.verdiField.toLowerCase() === 'true'
    ) {
      converted = true;
    }

    return converted;
  };

  const getApplicationSource = async (
    nsProject: NstProsjektNS
  ): Promise<ApplicationSource> => {
    const kalkIndicator = nsProject.egenskaperField.find(
      e => e.navnField === 'KalkExport'
    );
    if (kalkIndicator) return ApplicationSource.Kalk2010;

    const onlineIndicator = nsProject.egenskaperField.find(
      e => e.navnField === 'KalkOnlineExport'
    );
    if (onlineIndicator) return ApplicationSource.KalkOnline;

    return ApplicationSource.External;
  };

  const getName = (post: NstPostNSProsjektdata): string => {
    let result = '';

    if (post.kodeField) result = post.kodeField.tekstField.uformatertField;
    if (post.tekstField) result += post.tekstField.uformatertField;

    result.trim();

    if (result.length > 1000) result = result.substring(0, 999);

    return result;
  };

  const getCode = (post: NstPostNSProsjektdata): string => {
    let result = '';

    if (post.kodeField) {
      result = post.kodeField.idField;
    }
    return result;
  };

  const createFactors = async (
    nsProject: NstProsjektNS,
    project: ImportedProject
  ): Promise<void> => {
    const { getFactorUnitFromId } = useFactors();
    if (!nsProject.egenskaperField || nsProject.egenskaperField.length === 0)
      return;
    if (_applicationSource === ApplicationSource.External) return;

    let i: number;
    for (i = 1; i <= 7; i++) {
      const factor: ProjectFactor = {
        Id: uuidv4(),
        FactorItems: [],
      };

      factor.FactorNumber = i;
      factor.Unit = getFactorUnitFromId(i);
      factor.ProjectId = project.Project.Id;

      project.Factors.push(factor);

      const factorItems = nsProject.egenskaperField.filter(e => {
        return e.navnField.substring(0, 7) === `Faktor${i}`;
      });

      factorItems.forEach(fi => {
        const item: ProjectFactorItem = {
          Id: uuidv4(),
        };

        item.Name = fi.navnField.substring(7, fi.navnField.length);
        item.Value = parseFloat(fi.verdiField.toString().replace(/,/g, '.'));
        item.ProjectFactorId = factor.Id;
        if (factor.FactorItems) {
          factor.FactorItems.push(item);
        }
      });

      if (factor.FactorItems) {
        factor.Value = factor.FactorItems?.reduce((prev, cur) => {
          return prev + (cur.Value ?? 0);
        }, 0);
      }
    }

    project.Project.ProjectNumber =
      convertStringFromNstEgenskap(
        nsProject.egenskaperField,
        'ProjectNumber'
      ) ?? undefined;
    project.Project.AddressLine1 =
      convertStringFromNstEgenskap(nsProject.egenskaperField, 'AddressLine1') ??
      undefined;
    project.Project.AddressLine2 =
      convertStringFromNstEgenskap(nsProject.egenskaperField, 'AddressLine2') ??
      undefined;
    project.Project.PostNumber =
      convertStringFromNstEgenskap(nsProject.egenskaperField, 'PostNumber') ??
      undefined;
    project.Project.PostOffice =
      convertStringFromNstEgenskap(nsProject.egenskaperField, 'PostOffice') ??
      undefined;
    project.Project.StreetNumber =
      convertStringFromNstEgenskap(nsProject.egenskaperField, 'StreetNumber') ??
      undefined;
    project.Project.UsageNumber =
      convertStringFromNstEgenskap(nsProject.egenskaperField, 'UsageNumber') ??
      undefined;
    project.Project.LocationNumber =
      convertStringFromNstEgenskap(
        nsProject.egenskaperField,
        'LocationNumber'
      ) ?? undefined;
    project.Project.HousingNumber =
      convertStringFromNstEgenskap(
        nsProject.egenskaperField,
        'HousingNumber'
      ) ?? undefined;
    project.Project.SectionNumber =
      convertStringFromNstEgenskap(
        nsProject.egenskaperField,
        'SectionNumber'
      ) ?? undefined;
    project.Project.PriceInfo =
      convertStringFromNstEgenskap(nsProject.egenskaperField, 'PriceInfo') ??
      undefined;
    project.Project.AdditionalInformation =
      convertStringFromNstEgenskap(
        nsProject.egenskaperField,
        'AdditionalInformation'
      ) ?? undefined;
  };

  const setPrimaryContact = async (nsProject: NstProsjektNS): Promise<void> => {
    if (!nsProject.egenskaperField || nsProject.egenskaperField.length === 0)
      return;
    if (!nsProject.egenskaperField.some(c => c.navnField === 'PrimaryContact'))
      return;

    const primaryContact = nsProject.egenskaperField.find(
      e => e.navnField === 'PrimaryContact'
    )?.verdiField;

    const contact = _projectContacts.find(pc => {
      primaryContact === `${pc.FirstName} ${pc.LastName}`;
    });

    if (contact) {
      contact.IsPrimaryContact = true;
    }
  };

  const getFirstName = (nameArray: Array<string>): string => {
    let firstName: string | undefined = '';
    const lArray = nameArray;

    if (lArray.length <= 2) {
      firstName = lArray.shift();
      if (firstName) return firstName;
    }

    lArray.forEach((name, i) => {
      if (i !== lArray.length - 1) {
        firstName += `${name} `;
      }
    });

    const ret = firstName?.trim();

    return ret ?? '';
  };

  const getLastName = (nameArray: Array<string>): string => {
    if (nameArray.length < 2) return '';
    return nameArray[nameArray.length - 1];
  };

  const getContactsFromDeltakere = async (
    deltakere: Array<NstProsjektdeltaker>
  ): Promise<Array<ProjectContact>> => {
    const contacts: Array<ProjectContact> = [];

    try {
      deltakere.forEach(deltaker => {
        const projectContact: ProjectContact = {
          Id: uuidv4(),
          Email: deltaker.personField.epostField,
          Phone: deltaker.personField.telefonField,
          Mobile: deltaker.personField.telefonField,
          AddressLine1: deltaker.personField.gateadresseField,
          AddressLine2: deltaker.personField.postadresseField,
          PostNumber: deltaker.personField.postnrField,
          PostOffice: deltaker.personField.poststedField,
        };

        const nameArray = deltaker.personField.navnField.split(' ');
        projectContact.FirstName = getFirstName(nameArray);
        projectContact.LastName = getLastName(nameArray);
        projectContact.CompanyName = deltaker.firmaField.navnField;

        contacts.push(projectContact);
      });
    } catch (e) {
      console.log(e);
    }

    return contacts;
  };

  const buildAdditionItem = (
    element: BuildingElement,
    post: NstPostNSProsjektdata
  ): AdditionItem => {
    const item: AdditionItem = {
      Id: uuidv4(),
    };

    item.BuildingElementId = element.Id;
    item.Name = getName(post);
    item.SortOrder =
      item.Name === AdditionType[AdditionType.Flatetillegg] ? 1 : 0;

    if (post.prisinfoField) {
      item.Unit = convertUnit(post.prisinfoField.enhetField);
      item.Price = post.prisinfoField.enhetsprisField;
      item.Amount = Number(post.prisinfoField.sumField);
    }
    return item;
  };

  const buildBuildingItem = async (
    element: BuildingElement,
    post: NstPostNSProsjektdata
  ): Promise<BuildingItem> => {
    const buildingItem: BuildingItem = {
      Id: uuidv4(),
      BuildingElementId: element.Id,
      Name: getName(post),
      NS3420: getCode(post),
    };

    if (post.prisinfoField) {
      buildingItem.Unit = convertUnit(post.prisinfoField.enhetField);
      buildingItem.UnitForOrder = convertUnit(post.prisinfoField.enhetField);
      buildingItem.Price = post.prisinfoField.enhetsprisField;
      buildingItem.Amount = post.prisinfoField.mengdeField;
    }

    if (
      !post.egenskaperField ||
      post.egenskaperField.length === 0 ||
      _applicationSource === ApplicationSource.External
    )
      return buildingItem;

    buildingItem.AmountAdjustment = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'AmountAdjustment'
    );
    buildingItem.AccountNumber = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'AccountNumber'
    );
    buildingItem.Amount = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Amount'
    );
    buildingItem.AmountPrUnit = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'AmountPrUnit'
    );
    buildingItem.BaseTime = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'BaseTime'
    );
    buildingItem.Comments = convertStringFromNstEgenskap(
      post.egenskaperField,
      'Comments'
    );
    buildingItem.DeliveryOrder = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'DeliveryOrder'
    );
    buildingItem.Factor1 = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Factor1'
    );
    buildingItem.Factor5 = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Factor5'
    );
    buildingItem.NOBBNumber = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'NOBBNumber'
    );
    buildingItem.NS3420 = convertStringFromNstEgenskap(
      post.egenskaperField,
      'NS3420'
    );
    buildingItem.OpeningAddition = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'OpeningAddition'
    );
    buildingItem.SurfaceAddition = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'SurfaceAddition'
    );
    buildingItem.TransportationAddition = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'TransportationAddition'
    );
    buildingItem.Transport = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Transport'
    );
    buildingItem.SortOrder = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'SortOrder'
    );
    buildingItem.UnitForOrder = convertStringFromNstEgenskap(
      post.egenskaperField,
      'UnitForOrder'
    );
    buildingItem.Price = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Price'
    );
    buildingItem.Precut = convertBooleanFromNstEgenskap(
      post.egenskaperField,
      'Precut'
    );
    buildingItem.PriceListName = convertStringFromNstEgenskap(
      post.egenskaperField,
      'PriceListName'
    );
    buildingItem.PriceListItemName = convertStringFromNstEgenskap(
      post.egenskaperField,
      'PriceListItemName'
    );
    buildingItem.TimeEstimateName = convertStringFromNstEgenskap(
      post.egenskaperField,
      'TimeEstimateName'
    );
    buildingItem.IsFDVExportSelected = convertBooleanFromNstEgenskap(
      post.egenskaperField,
      'IsFDVExportSelected'
    );
    buildingItem.MileStone = convertStringFromNstEgenskap(
      post.egenskaperField,
      'MileStone'
    );

    return buildingItem;
  };

  const buildElement = async (
    post: NstPostNSProsjektdata,
    project: Project,
    ns3450: Nullable<string>,
    ns3451: Nullable<string>
  ): Promise<BuildingElement> => {
    const element: BuildingElement = {
      Id: uuidv4(),
      Items: [],
      AdditionItems: [],
      Name: post.tekstField.uformatertField ?? null,
      ProjectId: project.Id ?? null,
      ProductionLine: null,
      SortOrder: 0,
      NS3450: ns3450,
      NS3451: ns3451,
      Amount: null,
      Transport: 0,
      Comments: null,
    };

    if (!post.egenskaperField || post.egenskaperField.length === 0) {
      return element;
    }

    element.Amount = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Amount'
    );
    element.Factor1 = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Factor1'
    );
    element.Factor5 = convertNumberFromNstEgenskap(
      post.egenskaperField,
      'Factor5'
    );
    element.ProductionLine = convertStringFromNstEgenskap(
      post.egenskaperField,
      'ProductionLine'
    );
    element.SortOrder =
      convertNumberFromNstEgenskap(post.egenskaperField, 'SortOrder') ?? 0;

    element.Transport =
      convertNumberFromNstEgenskap(post.egenskaperField, 'Transport') ?? 0;
    element.Unit = convertStringFromNstEgenskap(post.egenskaperField, 'Unit');

    return element;
  };

  const buildKalkProjectData = async (
    projectdata: NstInnholdNSProsjektdata,
    nsProject: NstProsjektNS,
    project: ImportedProject
  ): Promise<void> => {
    if (
      !projectdata ||
      !projectdata.postField ||
      projectdata.postField.length === 0
    )
      return;

    // This implementation is based on hierarchic structure of Postnrplan

    for (const post of projectdata.postField) {
      const fagId = post.postnrdelerField[0];

      const fag = nsProject.postnrplanField.postnrdelKoderField.find(
        plan =>
          plan.kodeField === fagId.kodeField &&
          plan.typeField === fagId.typeField
      );

      const ns3451Kode = post.postnrdelerField[1];

      if (post.postnrdelerField.length === 2) {
        // Dette er en Kalk2010 import og inneholder postnrdelen kun 2 elementer, er denne posten bygningsdelsdata.

        const ns3450 = fag?.kodeField ? fag.kodeField : null;
        const ns3451 = ns3451Kode?.kodeField ? ns3451Kode.kodeField : null;

        const buildingElement = await buildElement(
          post,
          project,
          ns3450,
          ns3451
        );

        _IdElementsDictionary.set(post.idField, buildingElement);
        project.BuildingElements.push(buildingElement);
      } else {
        // Ellers er det et delprodukt eller tillegg.
        const buildingElement = _IdElementsDictionary.get(post.postnrField);

        if (
          post.internKommentarField &&
          post.internKommentarField.uformatertField === 'Tillegg' &&
          buildingElement
        ) {
          const item = buildAdditionItem(buildingElement, post);
          if (!buildingElement.AdditionItems)
            buildingElement.AdditionItems = [];
          buildingElement.AdditionItems.push(item);
        } else if (buildingElement) {
          const item = await buildBuildingItem(buildingElement, post);
          if (!buildingElement.Items) buildingElement.Items = [];
          buildingElement.Items.push(item);
        }
      }
    }
  };

  const removeEmptyBuildingElements = () => {
    const elementsWithoutItems = _buildingElements.filter(
      e => !e.Items || e.Items.length === 0
    );
    elementsWithoutItems.forEach(ele => (ele.ProjectId = null));
  };

  const buildProjectData = async (projectData: NstInnholdNSProsjektdata) => {
    const filteredPost = projectData.postField.filter(post =>
      _supportedProfessionCodes.includes(post.postnrdelerField[0].kodeField)
    );

    for (const post of filteredPost) {
      const ns3451Kode = post.postnrdelerField[1];
      const buildingElement:
        | BuildingElement
        | undefined = _buildingElements.find(
        b => b.NS3451 === ns3451Kode.kodeField
      );
      if (buildingElement) {
        const item = await buildBuildingItem(buildingElement, post);
        if (!buildingElement.Items) buildingElement.Items = [];
        buildingElement.Items.push(item);
      }
    }

    removeEmptyBuildingElements();
  };

  const createElementsFromPostnrPlan = (
    nsProject: NstProsjektNS,
    project: ImportedProject
  ) => {
    const fagKoder = nsProject.postnrplanField.postnrdelKoderField.filter(p =>
      _supportedProfessionCodes.includes(p.kodeField)
    );

    fagKoder.forEach(fag => {
      if (fag.postnrdelKoderField) {
        fag.postnrdelKoderField.forEach(delkode => {
          const element: BuildingElement = {
            Id: uuidv4(),
            Name: delkode.navnField,
            NS3450: fag.kodeField,
            NS3451: delkode.kodeField,
            ProjectId: project.Project.Id ? project.Project.Id : null,
            Items: [],
            AdditionItems: [],
            ProductionLine: null,
            SortOrder: 0,
            Amount: null,
            Transport: 0,
            Comments: null,
          };

          _buildingElements.push(element);
        });
      }
    });
  };

  const createBuildingElements = async (
    nsProject: NstProsjektNS,
    project: ImportedProject
  ): Promise<void> => {
    const projectItem = nsProject.itemField as NstInnholdNSProsjektdata;

    if (
      _applicationSource === ApplicationSource.Kalk2010 ||
      _applicationSource === ApplicationSource.KalkOnline
    ) {
      await buildKalkProjectData(projectItem, nsProject, project);
      return;
    }

    createElementsFromPostnrPlan(nsProject, project);
    buildProjectData(projectItem);
  };

  const buildProjectFromNST = async (
    project: ImportedProject,
    nsProject: NstProsjektNS
  ): Promise<boolean> => {
    let success = true;
    try {
      if (project.Project.Profession?.Code) {
        _supportedProfessionCodes.push(project.Project.Profession.Code);
      }
      _applicationSource = await getApplicationSource(nsProject);
      if (nsProject.prosjektdeltakereField) {
        _projectContacts = await getContactsFromDeltakere(
          nsProject.prosjektdeltakereField
        );
      }

      await setPrimaryContact(nsProject);
      await createFactors(nsProject, project);
      await createBuildingElements(nsProject, project);

      project.ProjectContacts = _projectContacts;
    } catch (e) {
      console.log(e);
      success = false;
    }

    return success;
  };

  return {
    buildProjectFromNST,
  };
};
