var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-bar',{attrs:{"small":true,"title":_vm.$t('titles.pricelists')}}),_c('v-card',{staticClass:"pa-2",attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('titles.company-pricelists')))]),(_vm.loadingPricelists)?_c('loader',{attrs:{"margins":true}}):[_c('perfect-scrollbar',{attrs:{"options":{
          suppressScrollX: true,
        }}},[_c('v-treeview',{ref:"treeRef",attrs:{"active":_vm.active,"items":_vm.pricelists,"item-children":"categories","load-children":_vm.getCategories,"open":_vm.open,"item-text":"Name","item-key":"Id","search":_vm.search,"selection-type":"independent","color":"primary","open-on-click":"","activatable":"","transition":"","dense":""},on:{"update:active":[function($event){_vm.active=$event},_vm.onActiveChangeEvent],"update:open":[function($event){_vm.open=$event},_vm.onOpenEvent]},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
        var item = ref.item;
return [(!item.categories)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-group ")]):_vm._e()]}},{key:"append",fn:function(ref){
        var item = ref.item;
return [(item.categories && item.categories.length)?_c('span',[_vm._v(" "+_vm._s(("(" + (item.categories.length) + ")"))+" ")]):(item.categories && item.categories.length === 0)?_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v(" mdi-cloud-download-outline ")]):_vm._e(),(
                (item.categories && item.categories.length) ||
                  (item.categories && item.categories.length === 0)
              )?_c('v-menu',{attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var menu = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(!item.IsSubscription)?_c('add-category',{attrs:{"pricelist":item},on:{"update":_vm.updatePriceLists}}):_vm._e(),(!item.CompanyPriceList)?_c('delete-pricelist',{attrs:{"pricelist":item},on:{"update":_vm.updatePriceLists}}):_vm._e()],1)],1)],1):_vm._e(),(!item.categories)?_c('v-menu',{attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var menu = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(!item.IsSubscription)?_c('add-item',{attrs:{"category":item},on:{"update":_vm.updatePriceLists}}):_vm._e(),(!item.IsSubscription)?_c('delete-category',{attrs:{"category":item},on:{"update":_vm.updatePriceLists}}):_vm._e()],1)],1)],1):_vm._e()]}}])})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }