































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
