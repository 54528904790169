















































































import { PropType, computed, defineComponent } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import { Environment } from '@/models/nobb/interfaces';
import { useEnvironment } from '@/modules/environment';

export default defineComponent({
  components: {
    Loader,
  },
  props: {
    environmentCertificates: {
      type: Array as PropType<Array<Environment>>,
      required: true,
    },
  },
  setup(props) {
    const { tryGetProductCertificates } = useEnvironment();
    const calculatedCertificates = computed(() =>
      tryGetProductCertificates(props.environmentCertificates)
    );
    return {
      props,
      calculatedCertificates,
    };
  },
});
