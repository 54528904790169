









































































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useUsers } from '@/modules/users';
import UserModal from '@/components/user-adm/user-modal/index.vue';
import UserPwModal from '@/components/user-adm/user-pwreset-modal/index.vue';
import UserDeleteModal from '@/components/user-adm/user-delete-modal/index.vue';
import { useAuth } from '@/modules/auth';
import { useLicense } from '@/modules/license';
import Loader from '@/components/loader/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';

export default defineComponent({
  components: {
    UserModal,
    UserPwModal,
    UserDeleteModal,
    Loader,
    ContentBar,
  },
  setup() {
    const { getUsers, users } = useUsers();
    const { getLicenses, licenses } = useLicense();
    const { user } = useAuth();
    const loading = ref<boolean>(true);

    onMounted(async () => {
      await getUsers();
      await getLicenses();
      loading.value = false;
    });

    return {
      users,
      loading,
      auth: user,
      licenses,
    };
  },
});
