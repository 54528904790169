


























































































































































































































































































































































































































































import { ref, defineComponent, computed, watch } from '@vue/composition-api';
import Report from '@/components/reports/report/index.vue';
import { useReport } from '@/modules/report';
import { useCalculation } from '@/modules/calculation';
import { ReportFileType, ReportType } from '@/models/report/enums';
import { ReportOptions } from '@/models/report/interfaces';
import { getBuildingMaterials } from '@/helpers/reports';
import { useProject } from '@/modules/project';
import { translate } from '@/localization';

export default defineComponent({
  props: {
    isProject: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Report,
  },
  setup({ isProject }) {
    const { generateReport } = useReport();
    const { updating, projectCalculation } = useCalculation();
    const { activeProjectOverview } = useProject();
    const dialog = ref<boolean>(false);
    const selectedItem = ref<ReportType>();
    const loading = ref<boolean>();
    const options = ref<ReportOptions>({
      DeliveryOrders: [],
      Calculations: [],
      FileType: ReportFileType.PDF,
      IncludeComments: true,
      IncludeAmounts: true,
      IncludeItemSums: false,
      IncludeElementSums: false,
      IncludePrecut: true,
      IncludeWithoutDeliveryOrder: true,
      IncludeBuildingItems: true,
      IncludeBuildingElements: true,
      IncludeBuildingItemsWithMissingEPD: true,
      IncludeBuildingItemsWithMissingNOBB: true,
      ShowProductName: true,
      FontSize: 'default',
    });

    const availableDeliveryOrders = ref<Array<number>>([]);

    const includeCommentsEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.MaterialOrder,
        ReportType.AttachmentPriceOffer,
        ReportType.PriceRequest,
        ReportType.ProjectAttachmentPriceOffer,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeAmountsEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.AttachmentPriceOffer,
        ReportType.ProjectAttachmentPriceOffer,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingElementSumsEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.AttachmentPriceOffer,
        ReportType.ProjectAttachmentPriceOffer,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingItemSumsEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.AttachmentPriceOffer,
        ReportType.ProjectAttachmentPriceOffer,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const includePrecutEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [ReportType.MaterialOrder];
      return enabledWhen.includes(selectedItem.value);
    });

    const showProductNameEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.AttachmentPriceOffer,
        ReportType.ProjectAttachmentPriceOffer,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingItemsEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.BuildingElements,
        ReportType.MainSums,
        ReportType.TimeView,
        ReportType.EconomyView,
        ReportType.DeliveryView,
        ReportType.MaterialsView,
        ReportType.CalculationView,
        ReportType.ProjectBuildingElements,
        ReportType.ProjectMainSums,
        ReportType.AttachmentPriceOffer,
        ReportType.ProjectAttachmentPriceOffer,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingElementsEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [ReportType.Environment];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingItemsWithMissingNOBBEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [ReportType.Environment];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingItemsWithMissingEPDEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [ReportType.Environment];
      return enabledWhen.includes(selectedItem.value);
    });

    const deliveryOrdersEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.MaterialOrder,
        ReportType.PriceRequest,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const calculationsEnabled = computed(() => {
      if (!isProject || selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.ProjectMileStone,
        ReportType.ProjectAttachmentPriceOffer,
        ReportType.ProjectBuildingElements,
        ReportType.ProjectMainSums,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeWithoutDeliveryOrder = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [
        ReportType.MaterialOrder,
        ReportType.PriceRequest,
      ];
      return enabledWhen.includes(selectedItem.value);
    });

    const valid = computed(() => {
      let ok = true;

      if (selectedItem.value === undefined) ok = false;
      if (isProject && options.value.Calculations.length === 0) ok = false;

      return ok;
    });

    const filterChange = (rt: ReportType) => {
      availableDeliveryOrders.value = [];
      options.value = {
        DeliveryOrders: [],
        Calculations: [],
        FileType: ReportFileType.PDF,
        IncludeComments: true,
        IncludeAmounts: true,
        IncludeItemSums: false,
        IncludeElementSums: false,
        IncludePrecut: true,
        IncludeWithoutDeliveryOrder: true,
        IncludeBuildingItems: true,
        IncludeBuildingElements: true,
        IncludeBuildingItemsWithMissingEPD: true,
        IncludeBuildingItemsWithMissingNOBB: true,
        ShowProductName: true,
        FontSize: 'default',
      };
      switch (rt) {
        case ReportType.MaterialOrder:
        case ReportType.PriceRequest:
          if (projectCalculation.value) {
            const matItems = getBuildingMaterials(
              projectCalculation.value,
              false
            );
            const orders = matItems
              .map(item => item.DeliveryOrder)
              .filter((value, index, self) => self.indexOf(value) === index)
              .filter(o => o !== null);

            if (orders) {
              orders.forEach(o => {
                if (o !== null) availableDeliveryOrders.value.push(o);
              });

              options.value.DeliveryOrders = availableDeliveryOrders.value;
            }
          }
          break;
        case ReportType.ProjectMileStone:
        case ReportType.ProjectAttachmentPriceOffer:
        case ReportType.ProjectBuildingElements:
        case ReportType.ProjectMainSums:
          if (activeProjectOverview.value) {
            options.value.Calculations = activeProjectOverview.value.Children;
          }
          break;
        case ReportType.Environment:
          if (!options.value.IncludeBuildingElements) {
            options.value.IncludeBuildingItemsWithMissingEPD = false;
            options.value.IncludeBuildingItemsWithMissingNOBB = false;
          }
          break;
        default:
          break;
      }
    };

    const submit = async () => {
      loading.value = true;
      if (selectedItem.value !== undefined) {
        await generateReport(selectedItem.value, options.value, isProject);
      }
      loading.value = false;
    };

    watch(
      [options],
      () => {
        if (!options.value.IncludeBuildingElements) {
          options.value.IncludeBuildingItemsWithMissingEPD = false;
          options.value.IncludeBuildingItemsWithMissingNOBB = false;
        }
      },
      { deep: true }
    );

    return {
      submit,
      filterChange,
      dialog,
      selectedItem,
      options,
      ReportFileType,
      loading,
      updating,
      ReportType,
      valid,
      availableDeliveryOrders,
      includeCommentsEnabled,
      includeAmountsEnabled,
      includeBuildingElementSumsEnabled,
      includeBuildingElementsEnabled,
      includeBuildingItemSumsEnabled,
      includeWithoutDeliveryOrder,
      includeBuildingItemsEnabled,
      includeBuildingItemsWithMissingNOBBEnabled,
      includeBuildingItemsWithMissingEPDEnabled,
      showProductNameEnabled,
      deliveryOrdersEnabled,
      includePrecutEnabled,
      calculationsEnabled,
      activeProjectOverview,
      items: [
        { name: translate('common.default'), value: 'default' },
        { name: translate('common.large'), value: 'large' },
      ],
    };
  },
});
