

































import { ref, PropType, defineComponent } from '@vue/composition-api';
import { useLicense } from '@/modules/license';
import { License } from '@/models/admin/interfaces';
import Confirm from '@/components/common/confirm/index.vue';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    license: {
      type: Object as PropType<License>,
      required: true,
    },
  },
  setup({ license }) {
    const { loading, deleteLicense } = useLicense();
    const valid = ref(false);
    const dialog = ref(false);

    valid.value = license && license.Id !== null && license.Id !== '';

    const deleteEvent = async () => {
      await deleteLicense(license);
      dialog.value = false;
    };

    return {
      loading,
      valid,
      dialog,
      deleteEvent,
    };
  },
});
