import { Profession } from '@/models/common/interfaces';
import {
  AdditionItemTemplate,
  AdditionItemTemplateType,
  AdditionItemTemplateTypeXML,
  BuildingElementTemplate,
  BuildingElementTemplateType,
  BuildingElementTemplateTypeXML,
  BuildingItemTemplate,
  BuildingItemTemplateType,
  BuildingItemTemplateTypeXML,
  BuildingTypeTemplate,
  BuildingTypeTemplateType,
  XMLExport,
} from '@/models/library/interfaces';
import { v4 as uuidv4 } from 'uuid';

export const createDefaultBuildingTypeTemplate = (
  profession: Profession
): BuildingTypeTemplate | undefined => {
  return {
    Id: uuidv4(),
    BuildingElementTemplates: [],
    Created: new Date().toLocaleString(),
    Description: null,
    GlobalKey: uuidv4(),
    LastModified: new Date().toLocaleString(),
    ImportTime: null,
    Name: null,
    OrganizationId: null,
    Profession: profession,
    ProfessionCode: profession.Code,
    ProfessionId: profession.Id ?? null,
  };
};

export const sortBuildingItemTemplates = (
  items: Array<BuildingItemTemplate>
): Array<BuildingItemTemplate> => {
  const sortedItems = items.sort((a, b) =>
    (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
      ? 1
      : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
      ? -1
      : 0
  );
  return sortedItems;
};

export const sortBuildingElementTemplatesByNS3451 = (
  elements: Array<BuildingElementTemplate>
): Array<BuildingElementTemplate> => {
  const sortedElements = elements.sort((a, b) =>
    ('' + a.NS3451 ?? '').localeCompare(b.NS3451 ?? '' + '')
  );
  return sortedElements;
};

export const createBuildingItemTemplateType = (
  itemTemplate: BuildingItemTemplate
): BuildingItemTemplateType => {
  const itemTemplateType: BuildingItemTemplateType = {
    AccountNumber: itemTemplate.AccountNumber,
    AmountAdjustment: itemTemplate.AmountAdjustment,
    Amount: itemTemplate.Amount,
    AmountPrUnit: itemTemplate.AmountPrUnit,
    BaseTime: itemTemplate.BaseTime,
    DeliveryOrder: itemTemplate.DeliveryOrder,
    IsFDVExportSelected: itemTemplate.IsFDVExportSelected ?? false,
    MileStone: itemTemplate.MileStone,
    NOBBNumber: itemTemplate.NOBBNumber,
    NS3420: itemTemplate.NS3420,
    Name: itemTemplate.Name,
    OpeningAddition: itemTemplate.OpeningAddition ?? 0,
    PreCut: itemTemplate.Precut ?? false,
    Price: itemTemplate.Price ?? 0,
    PriceListName: itemTemplate.PriceListName,
    SortOrder: itemTemplate.SortOrder,
    SurfaceAddition: itemTemplate.SurfaceAddition ?? 0,
    TimeEstimateName: itemTemplate.TimeEstimateName,
    TimeFactorTemplates: [],
    Transport: itemTemplate.Transport ?? 0,
    TransportationAddition: itemTemplate.TransportationAddition ?? 0,
    Unit: itemTemplate.Unit,
    UnitForOrder: itemTemplate.UnitForOrder,
  };

  return itemTemplateType;
};

export const createAdditionItemTemplateType = (
  additionTemplate: AdditionItemTemplate
): AdditionItemTemplateType => {
  const additionTemplateType: AdditionItemTemplateType = {
    AmountAdjustment: additionTemplate.AmountAdjustment,
    Name: additionTemplate.Name,
  };
  return additionTemplateType;
};

export const createBuildingElementTemplateType = (
  elementTemplate: BuildingElementTemplate
): BuildingElementTemplateType => {
  const eleTemplateType: BuildingElementTemplateType = {
    Amount: null,
    Code: elementTemplate.Code,
    Name: elementTemplate.Name,
    NS3450: elementTemplate.NS3450,
    NS3451: elementTemplate.NS3451,
    ProductionLine: elementTemplate.ProductionLine,
    SortOrder: elementTemplate.SortOrder,
    Transport: elementTemplate.Transport,
    Unit: elementTemplate.Unit,
    AdditionItemTemplates: [],
    BuildingItemTemplates: [],
  };
  return eleTemplateType;
};

export const createBuildingTypeTemplateType = (
  template: BuildingTypeTemplate
): BuildingTypeTemplateType => {
  const templateType: BuildingTypeTemplateType = {
    BuildingElementTemplates: [],
    Name: template.Name,
    Description: template.Description,
    GlobalKey: template.GlobalKey,
    ImportDate: new Date().toISOString(),
    ProfessionCode: template.ProfessionCode,
  };
  return templateType;
};

export const createXMLObject = (
  buildingTypeTemplate: BuildingTypeTemplateType
): XMLExport => {
  const buildingTypeTemplateTypeXML: XMLExport = {
    BuildingTypeTemplate: {
      BuildingElementTemplates: [],
      Description: buildingTypeTemplate.Description,
      GlobalKey: buildingTypeTemplate.GlobalKey,
      ImportDate: buildingTypeTemplate.ImportDate,
      Name: buildingTypeTemplate.Name,
      ProfessionCode: buildingTypeTemplate.ProfessionCode,
      $: {
        'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
        'xmlns:xsd': 'http://www.w3.org/2001/XMLSchema',
        xmlns: 'http://tempuri.org/BuildingLibraryFile.xsd',
      },
    },
  };

  const elementXMLTypes: Array<BuildingElementTemplateTypeXML> = [];

  buildingTypeTemplate.BuildingElementTemplates.forEach(eleTemplate => {
    const buildingElementTemplateTypeXML: BuildingElementTemplateTypeXML = {
      AdditionItemTemplates: [],
      BuildingItemTemplates: [],
      Amount:
        eleTemplate.Amount === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.Amount,
            },
      Code:
        eleTemplate.Code === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.Code,
            },
      NS3450:
        eleTemplate.NS3450 === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.NS3450,
            },
      NS3451:
        eleTemplate.NS3451 === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.NS3451,
            },
      Name:
        eleTemplate.Name === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.Name,
            },
      ProductionLine:
        eleTemplate.ProductionLine === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.ProductionLine,
            },
      SortOrder:
        eleTemplate.SortOrder === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.SortOrder,
            },
      Transport:
        eleTemplate.Transport === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.Transport,
            },
      Unit:
        eleTemplate.Unit === null
          ? {
              $: {
                'xsi:nil': 'true',
              },
            }
          : {
              _: eleTemplate.Unit,
            },
    };

    const itemXMLTypes: Array<BuildingItemTemplateTypeXML> = [];
    const additionXMLTypes: Array<AdditionItemTemplateTypeXML> = [];
    eleTemplate.BuildingItemTemplates.forEach(itemTemplate => {
      const itemXMLTemplateType: BuildingItemTemplateTypeXML = {
        AccountNumber:
          itemTemplate.AccountNumber === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.AccountNumber,
              },
        Amount:
          itemTemplate.Amount === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.Amount,
              },
        AmountAdjustment:
          itemTemplate.AmountAdjustment === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.AmountAdjustment,
              },
        Unit:
          itemTemplate.Unit === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.Unit,
              },
        Transport:
          itemTemplate.Transport === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.Transport,
              },
        SortOrder:
          itemTemplate.SortOrder === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.SortOrder,
              },
        AmountPrUnit:
          itemTemplate.AmountPrUnit === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.AmountPrUnit,
              },
        Name:
          itemTemplate.Name === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.Name,
              },
        BaseTime:
          itemTemplate.BaseTime === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.BaseTime,
              },
        DeliveryOrder:
          itemTemplate.DeliveryOrder === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.DeliveryOrder,
              },
        IsFDVExportSelected:
          itemTemplate.IsFDVExportSelected === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.IsFDVExportSelected,
              },
        MileStone:
          itemTemplate.MileStone === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.MileStone,
              },
        NOBBNumber:
          itemTemplate.NOBBNumber === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.NOBBNumber,
              },
        NS3420:
          itemTemplate.NS3420 === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.NS3420,
              },
        OpeningAddition:
          itemTemplate.OpeningAddition === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.OpeningAddition,
              },
        PreCut:
          itemTemplate.PreCut === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.PreCut,
              },
        Price:
          itemTemplate.Price === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.Price,
              },
        PriceListName:
          itemTemplate.PriceListName === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.PriceListName,
              },
        SurfaceAddition:
          itemTemplate.SurfaceAddition === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.SurfaceAddition,
              },
        TimeEstimateName:
          itemTemplate.TimeEstimateName === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.TimeEstimateName,
              },
        TimeFactorTemplates: [],
        TransportationAddition:
          itemTemplate.TransportationAddition === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.TransportationAddition,
              },
        UnitForOrder:
          itemTemplate.UnitForOrder === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: itemTemplate.UnitForOrder,
              },
      };
      itemXMLTypes.push(itemXMLTemplateType);
    });
    eleTemplate.AdditionItemTemplates.forEach(additionTemplate => {
      const additionXMLTemplateType: AdditionItemTemplateTypeXML = {
        AmountAdjustment:
          additionTemplate.AmountAdjustment === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: additionTemplate.AmountAdjustment,
              },
        Name:
          additionTemplate.Name === null
            ? {
                $: {
                  'xsi:nil': 'true',
                },
              }
            : {
                _: additionTemplate.Name,
              },
      };
      additionXMLTypes.push(additionXMLTemplateType);
    });
    buildingElementTemplateTypeXML.AdditionItemTemplates = additionXMLTypes;
    buildingElementTemplateTypeXML.BuildingItemTemplates = itemXMLTypes;

    elementXMLTypes.push(buildingElementTemplateTypeXML);
  });

  buildingTypeTemplateTypeXML.BuildingTypeTemplate.BuildingElementTemplates = elementXMLTypes;

  return buildingTypeTemplateTypeXML;
};

export const finalizeBuildingTypeTemplate = (
  template: BuildingTypeTemplate
): BuildingTypeTemplate => {
  template.Id = uuidv4();
  template.Created = new Date().toLocaleString();
  template.LastModified = new Date().toLocaleString();

  if (template.BuildingElementTemplates instanceof Array === false) {
    try {
      const elements: Array<BuildingElementTemplate> = [];
      const singleElement: BuildingElementTemplate = (template.BuildingElementTemplates as unknown) as BuildingElementTemplate;
      elements.push(singleElement);
      template.BuildingElementTemplates = elements;
    } catch (e) {
      console.log(e);
    }
  }

  template.BuildingElementTemplates.forEach(eleTemplate => {
    eleTemplate.Id = uuidv4();
    if (template.Id) {
      eleTemplate.BuildingTypeTemplateId = template.Id;
    }
    eleTemplate.Created = new Date().toISOString();
    eleTemplate.LastModified = new Date().toISOString();
    if (
      eleTemplate.BuildingItemTemplates != null &&
      eleTemplate.BuildingItemTemplates instanceof Array
    ) {
      eleTemplate.BuildingItemTemplates.forEach(itemTemplate => {
        itemTemplate.Id = uuidv4();
        itemTemplate.LastModified = new Date().toISOString();
        itemTemplate.Created = new Date().toISOString();
        if (eleTemplate.Id) {
          itemTemplate.BuildingElementTemplateId = eleTemplate.Id;
        }
      });
    } else {
      try {
        const items: Array<BuildingItemTemplate> = [];
        const singleItem: BuildingItemTemplate =
          eleTemplate.BuildingItemTemplates;
        singleItem.Id = uuidv4();
        singleItem.LastModified = new Date().toISOString();
        singleItem.Created = new Date().toISOString();
        if (eleTemplate.Id) {
          singleItem.BuildingElementTemplateId = eleTemplate.Id;
        }
        items.push(singleItem);
        eleTemplate.BuildingItemTemplates = items;
      } catch (e) {
        console.log(e);
      }
    }

    if (eleTemplate.AdditionItemTemplates) {
      eleTemplate.AdditionItemTemplates.forEach(additionTemplate => {
        additionTemplate.Id = uuidv4();
        if (eleTemplate.Id) {
          additionTemplate.BuildingElementTemplateId = eleTemplate.Id;
        }
        additionTemplate.Created = new Date().toISOString();
        additionTemplate.LastModified = new Date().toISOString();
      });
    }
  });

  return template;
};
