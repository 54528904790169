import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { CompanyFactor, CompanyFactorItem } from '@/models/company/interfaces';
import { useSnackbar } from '@/modules/snackbar';
import { useErrorModal } from './error';
import { CustomError } from '@/models/common/interfaces';
import { isSuccess } from '@/helpers/common';
import {
  CalculationBatch,
  ProjectFactorItem,
} from '@/models/calculation/interfaces';
import { useCalculation } from './calculation';
import { useBatch } from './batch';
import { useProject } from './project';

interface FactorState {
  factors?: Array<CompanyFactor>;
  loading: boolean;
  error?: CustomError;

  carpentry?: Array<CompanyFactor>;
  painterWork?: Array<CompanyFactor>;
  subContractors?: Array<CompanyFactor>;
  rigs?: Array<CompanyFactor>;
  groundWork?: Array<CompanyFactor>;
  concreteWork?: Array<CompanyFactor>;
  masonry?: Array<CompanyFactor>;
}

const state = reactive<FactorState>({
  factors: undefined,
  loading: false,
  error: undefined,
});

export const useFactors = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const filteredFactors = (
    profession: string,
    factors: Array<CompanyFactor>
  ): Array<CompanyFactor> => {
    let filteredItems: Array<CompanyFactor> = [];
    if (factors) {
      filteredItems = factors.filter(fi => {
        return fi.Profession.Code === profession;
      });
    }
    return filteredItems;
  };

  const getFactors = async (
    force?: boolean
  ): Promise<Array<CompanyFactor> | undefined> => {
    const { get } = useApi('/company/factor');

    if (state.factors && !force) {
      return state.factors;
    }
    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.factors = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return state.factors;
  };

  const getProjectFactorItemsFromCompanyFactorByCode = async (
    code: string
  ): Promise<Array<ProjectFactorItem>> => {
    const cFactors = await getFactors();
    const projectItems: Array<ProjectFactorItem> = [];

    if (cFactors) {
      const cFactor = cFactors.find(f => f.Profession.Code === code);
      if (cFactor) {
        cFactor.CompanyFactorItems.forEach(cf => {
          const pItem: ProjectFactorItem = {
            Name: cf.Name,
            Value: cf.Value,
          };
          projectItems.push(pItem);
        });
      }
    }

    return projectItems;
  };

  const updateFactors = async (
    id: string,
    newFactors: Array<CompanyFactorItem>
  ): Promise<boolean> => {
    const { put } = useApi(`/company/factor/${id}`);
    state.loading = true;
    let success = false;

    try {
      const response = await put(newFactors);
      if (isSuccess(response.status)) {
        if (state.factors) {
          state.factors.forEach(factor => {
            if (factor.Id === id) {
              factor.CompanyFactorItems = newFactors;
            }
          });
        }
        snack('snack.factors-updated', true);
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return success;
  };

  const updateCalculationFactors = async (
    id: string,
    newFactors: Array<ProjectFactorItem>
  ): Promise<boolean> => {
    const {
      projectCalculation,
      calculation,
      setActiveCalculation,
      updateProjectCalculationFactors,
      updateActiveCalculationWithBatch,
      activeCalculation,
    } = useCalculation();
    const { buildProjectTotalsForCalculations } = useProject();

    const { registerChangedFactorsBatchOperation } = useBatch();
    let updated = false;

    if (
      id &&
      projectCalculation.value &&
      projectCalculation.value.Factors &&
      projectCalculation.value.Id &&
      calculation?.value
    ) {
      if (activeCalculation?.value) {
        updateProjectCalculationFactors(id, newFactors);
        await registerChangedFactorsBatchOperation(id, newFactors);
        await setActiveCalculation(activeCalculation.value);
        await buildProjectTotalsForCalculations();

        // Updating project totals through updateActiveCalculationWithBatch
        const calcBatch: CalculationBatch = {
          SaveOperations: [],
          InsertOperations: [],
          DeleteOperations: [],
          ChangedFactors: [],
          ProjectTotals: {},
        };

        await updateActiveCalculationWithBatch(calcBatch);
      }
      updated = true;
    }
    return updated;
  };

  const resetFactors = async (): Promise<boolean> => {
    const { post } = useApi('/company/factor/reset');
    state.loading = true;
    let success = false;

    try {
      const response = await post();
      if (isSuccess(response.status)) {
        await getFactors(true);
        snack('snack.factors-reset', true);
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return success;
  };

  const getFactorNameFromId = (factorNr: number) => {
    switch (factorNr) {
      case 1:
        return 'Justering av grunntiden';
      case 2:
        return 'Justering av produksjonslønn';
      case 3:
        return 'Sosiale kostnader';
      case 4:
        return 'Faste kostnader';
      case 5:
        return 'Justering av materialkostnadane';
      case 6:
        return 'Fortjeneste, lønn, sosiale og faste kostnader';
      case 7:
        return 'Fortjeneste, innkjøp (materialer / UE)';
      default:
        return '';
    }
  };

  const getFactorUnitFromId = (factorNr: number) => {
    switch (factorNr) {
      case 1:
        return '%';
      case 2:
        return 'kr';
      case 3:
        return '%';
      case 4:
        return '%';
      case 5:
        return '%';
      case 6:
        return '%';
      case 7:
        return '%';
      default:
        return '';
    }
  };

  return {
    getFactors,
    filteredFactors,
    resetFactors,
    getFactorNameFromId,
    getFactorUnitFromId,
    updateFactors,
    updateCalculationFactors,
    getProjectFactorItemsFromCompanyFactorByCode,
    ...toRefs(state),
  };
};
