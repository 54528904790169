


























import { defineComponent } from '@vue/composition-api';
import LicenseAdm from '@/components/license-adm/index.vue';
import UserAdm from '@/components/user-adm/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';

export default defineComponent({
  name: 'Admin',
  components: {
    LicenseAdm,
    UserAdm,
    ContentBar,
  },
});
