


























































































































































































































































































import { getIconByProfessionCode } from '@/helpers/common';
import { ImportedProject } from '@/models/projects/interfaces';
import { NstGenerelt } from '@/models/standards/ns3459/interfaces';
import { defineComponent, PropType } from '@vue/composition-api';
import moment from 'moment';
import Profession from '@/components/profession/index.vue';
import { translate } from '@/localization';

export default defineComponent({
  components: {
    Profession,
  },
  props: {
    project: {
      type: Object as PropType<ImportedProject>,
      required: true,
    },
    generalInfo: {
      type: Object as PropType<NstGenerelt>,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      getIconByProfessionCode,
      emit,
      moment,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
        min: (v: string) =>
          (v && v.length >= 8) ||
          `${translate('validation.minimum-characters', [8])}`,
      },
    };
  },
});
