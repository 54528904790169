




















































































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useCalculation } from '@/modules/calculation';
import { useTimeEstimate } from '@/modules/time-estimate';
import {
  TimeEstimate,
  TimeEstimateCategory,
} from '@/models/time-estimate/interface';
import { cleanSource, getIconByProfessionCode } from '@/helpers/common';
import { ESTIMATE_HEADERS } from '@/constants/table-headers';
import Profession from '@/components/profession/index.vue';

export default defineComponent({
  props: {
    searchKey: {
      type: String,
    },
  },
  components: {
    Profession,
  },
  setup(props, { emit }) {
    const { projectCalculation } = useCalculation();
    const { getTimeEstimateCategories, buildEstimates } = useTimeEstimate();

    const loading = ref<boolean>(false);
    const categories = ref<Array<TimeEstimateCategory>>();
    const filteredCategories = ref<Array<TimeEstimateCategory>>();
    const search = ref<string>('');
    const expanded = ref<number>();
    const excludeItems = ref<boolean>(false);

    onMounted(async () => {
      if (projectCalculation.value?.Profession?.Code) {
        categories.value = await getTimeEstimateCategories(
          projectCalculation.value.Profession.Code
        );
        if (categories.value) {
          buildEstimates(categories.value);
          filteredCategories.value = cleanSource(categories.value ?? []);
        }

        search.value = props.searchKey ?? '';
      }
    });

    watch([search], () => {
      if (categories.value) {
        filteredCategories.value = categories.value.filter(
          cat =>
            (cat.NS3420 && cat.NS3420.includes(search.value.toUpperCase())) ||
            (cat.Name && cat.Name.includes(search.value.toUpperCase())) ||
            cat.Estimates.find(e =>
              e.Name.toUpperCase().includes(search.value.toUpperCase())
            )
        );

        if (filteredCategories.value.length > 0) {
          expanded.value = 0;
        }
      }
    });

    const itemSelected = (estimate: TimeEstimate) => {
      emit('selectedEstimate', estimate);
    };

    return {
      close,
      getIconByProfessionCode,
      itemSelected,
      loading,
      filteredCategories,
      expanded,
      excludeItems,
      search,
      ESTIMATE_HEADERS,
      projectCalculation,
    };
  },
});
