import { ProjectOverview } from '@/models/projects/interfaces';

export const projectSumTotal = (project: ProjectOverview) => {
  let total = 0;

  if (project.Children) {
    project.Children.forEach(calc => {
      total += calc.Total != null ? calc.Total : 0;
    });
  }

  return total;
};
