

































































































































































































































































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
} from '@vue/composition-api';

import { useCalculation } from '@/modules/calculation';
import {
  BUILDING_ELEMENT_HEADERS_CALCULATION,
  BUILDING_ELEMENT_HEADERS_DELIVERY,
  BUILDING_ELEMENT_HEADERS_MATERIALS,
  BUILDING_ELEMENT_HEADERS_ECONOMY,
  BUILDING_ELEMENT_HEADERS_HOURLY_CONSUMPTION,
} from '@/constants/table-headers';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import { BatchType, CalculationColumnView } from '@/constants/enum';
import { DataTableHeader } from '@/models/common/interfaces';
import { PROJECT_CALCULATION_OVERVIEW_PATH } from '@/constants/routes';
import {
  getCalculationTotalByProp,
  getCalculationTotalTypography,
} from '@/helpers/calculation';
import { useBatch } from '@/modules/batch';
import { useLibrary } from '@/modules/library';
import CalculationSwitcher from '@/components/calculation/calculation-switcher/index.vue';
import KalkHelper from '@/components/common/kalk-helper/index.vue';
import PriceList from '@/components/calculation/building-elements/pricelist/index.vue';
import BuildingCalcElement from '@/components/calculation/building-elements/calc-element/index.vue';
import { v4 as uuidv4 } from 'uuid';
import draggable from 'vuedraggable';

export default defineComponent({
  components: {
    CalculationSwitcher,
    KalkHelper,
    PriceList,
    BuildingCalcElement,
    draggable,
  },
  props: {
    selectable: {
      type: Boolean,
    },
    customElements: {
      type: Array as PropType<Array<BuildingElementCalculation>>,
    },
    customLoader: {
      type: Boolean,
    },
  },
  setup({ customElements }) {
    const {
      NS3420Units,
      projectCalculation,
      updating,
      loading,
    } = useCalculation();
    const {
      registerElementsBatchOperation,
      registerElementSaveChangesWithChildren,
      registerAllBuildingItemSaveChanges,
    } = useBatch();
    const { activeTemplate } = useLibrary();
    const search = ref<string>('');
    const selected = ref<Array<BuildingElementCalculation>>([]);
    const columnView = ref<number>(0);
    const smallVariant = ref<boolean>(false);
    const key = ref<string>(uuidv4());

    const elements: ComputedRef<Array<BuildingElementCalculation>> = computed({
      get() {
        let listed: Array<BuildingElementCalculation> = [];
        if (customElements) {
          listed = customElements;
        } else if (projectCalculation.value?.BuildingElements) {
          listed = projectCalculation.value.BuildingElements;
        }
        return listed;
      },
      set(val) {
        console.log(val);
      },
    });

    const expendCalc = computed(() => {
      const exp: Array<number> = [];
      if (projectCalculation?.value?.BuildingElements) {
        projectCalculation?.value?.BuildingElements.forEach((e, i) => {
          if (e.IsExpanded) {
            exp.push(i);
          }
        });
      }

      return exp;
    });

    const collapseAll = () => {
      if (projectCalculation?.value?.BuildingElements) {
        projectCalculation?.value?.BuildingElements.forEach(e => {
          e.IsExpanded = false;
        });
      }
    };

    const columns = computed(() => {
      let headers: Array<DataTableHeader> = [];
      switch (columnView.value) {
        case CalculationColumnView.Calculation:
          smallVariant.value = false;
          headers = BUILDING_ELEMENT_HEADERS_CALCULATION;
          break;
        case CalculationColumnView.HourlyConsumption:
          smallVariant.value = true;
          headers = BUILDING_ELEMENT_HEADERS_HOURLY_CONSUMPTION;
          break;
        case CalculationColumnView.Economy:
          smallVariant.value = false;
          headers = BUILDING_ELEMENT_HEADERS_ECONOMY;
          break;
        case CalculationColumnView.Materials:
          smallVariant.value = false;
          headers = BUILDING_ELEMENT_HEADERS_MATERIALS;
          break;
        case CalculationColumnView.Delivery:
          smallVariant.value = false;
          headers = BUILDING_ELEMENT_HEADERS_DELIVERY;
          break;
      }
      return headers;
    });

    const customFilter = (
      _value: string,
      search: string | null,
      item: BuildingElementCalculation
    ) => {
      const searchable = Object.assign({}, item);
      searchable.Project = undefined;
      searchable.BuildingItems.forEach(bi => {
        bi.BuildingElement = undefined;
      });
      searchable.AdditionItems.forEach(ai => {
        ai.BuildingElement = undefined;
      });
      const filterText = JSON.stringify(searchable).toLowerCase();
      if (search) return filterText.includes(search.toLowerCase());
    };

    const onDropCallback = () => {
      const panels = document.querySelector('#element-container');
      if (panels) {
        const newPanels = panels.querySelectorAll('.v-expansion-panel');
        newPanels.forEach((panel, i) => {
          const id = panel.querySelector('.element-id') as HTMLInputElement;
          if (id && elements.value) {
            const bEle = elements.value.find(ele => ele.Id === id.value);
            if (bEle) {
              bEle.SortOrder = i;
            }
          }
        });
        if (elements.value) {
          registerElementsBatchOperation(elements.value, BatchType.Save);
        }
      }
    };

    return {
      customFilter,
      collapseAll,
      getCalculationTotalByProp,
      getCalculationTotalTypography,
      registerElementSaveChangesWithChildren,
      registerAllBuildingItemSaveChanges,
      onDropCallback,
      NS3420Units,
      projectCalculation,
      CalculationColumnView,
      search,
      selected,
      columnView,
      columns,
      loading,
      PROJECT_CALCULATION_OVERVIEW_PATH,
      updating,
      activeTemplate,
      BatchType,
      smallVariant,
      expendCalc,
      key,
      elements,
      allowDrag: true,
    };
  },
});
