import { reactive, toRefs } from '@vue/composition-api';
import { CustomError } from '@/models/common/interfaces';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { BlobPriceListsSignedUris } from '@/models/pricebook/interfaces';
import { isSuccess } from '@/helpers/common';
import { useApi } from './api';
import { useErrorModal } from './error';
import { useSnackbar } from './snackbar';

interface State {
  error?: CustomError;
  signedURIs: BlobPriceListsSignedUris | undefined;
}

const state = reactive<State>({
  error: undefined,
  signedURIs: undefined,
});

export const useAzure = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const getSignedUris = async (
    force?: boolean
  ): Promise<BlobPriceListsSignedUris | undefined> => {
    const { get } = useApi('/blobpricelists/signeduris');

    if (state.signedURIs && !force) {
      return state.signedURIs;
    }

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.signedURIs = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.signedURIs;
  };

  const getCompanyContainer = async (): Promise<
    ContainerClient | undefined
  > => {
    let container: ContainerClient | undefined = undefined;

    if (!state.signedURIs) {
      await getSignedUris();
    }

    if (state.signedURIs?.CompanyContainerSignedUri) {
      try {
        const companyBlobServiceClient = new BlobServiceClient(
          state.signedURIs.CompanyContainerSignedUri
        );
        container = companyBlobServiceClient.getContainerClient('');
      } catch (e) {
        console.log(e);
        container = undefined;
      }
    }

    return container;
  };

  const getSharedContainer = async (): Promise<ContainerClient | undefined> => {
    let container: ContainerClient | undefined = undefined;

    if (!state.signedURIs) {
      await getSignedUris();
    }

    if (state.signedURIs?.SharedContainerSignedUri) {
      try {
        const sharedBlobServiceClient = new BlobServiceClient(
          state.signedURIs.SharedContainerSignedUri
        );
        container = sharedBlobServiceClient.getContainerClient('');
      } catch (e) {
        console.log(e);
        container = undefined;
      }
    }

    return container;
  };

  return {
    getCompanyContainer,
    getSharedContainer,
    ...toRefs(state),
  };
};
