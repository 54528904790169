
































































import { PropType, defineComponent, ref } from '@vue/composition-api';
import { ProjectOverview } from '@/models/projects/interfaces';
import { useCalculation } from '@/modules/calculation';
import Confirm from '@/components/common/confirm/index.vue';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    calculation: {
      type: Object as PropType<ProjectOverview>,
      required: true,
    },
    isMenuitem: {
      type: Boolean,
    },
  },
  setup({ calculation }) {
    const { loading, lockCalculation, unlockCalculation } = useCalculation();
    const dialog = ref(false);

    const lockEvent = async () => {
      if (calculation.CalculationLockedTime && calculation.Id) {
        await unlockCalculation(calculation.Id);
        dialog.value = false;
      } else if (calculation.Id) {
        await lockCalculation(calculation.Id);
        dialog.value = false;
      }
    };

    return {
      loading,
      dialog,
      lockEvent,
    };
  },
});
