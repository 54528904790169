































































































































































import { computed, ref } from '@vue/composition-api';
import EditDetails from '@/components/projects/details-edit-modal/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import { defineComponent } from '@vue/composition-api';
import { useProject } from '@/modules/project';
import Loader from '@/components/loader/index.vue';

export default defineComponent({
  components: {
    EditDetails,
    ContentBar,
    Loader,
  },
  setup() {
    const {
      activeProjectOverview,
      activeProjectDetails,
      getActiveProjectDetails,
    } = useProject();

    const loading = ref(false);

    const updateInfo = async () => {
      loading.value = true;
      if (activeProjectOverview?.value?.Id)
        await getActiveProjectDetails(activeProjectOverview.value.Id);
      loading.value = false;
    };

    const address = computed(() => {
      let text = '';

      if (!activeProjectDetails?.value) return text;
      if (activeProjectDetails.value.AddressLine1)
        text += activeProjectDetails.value.AddressLine1;
      if (
        activeProjectDetails.value.PostOffice ||
        activeProjectDetails.value.PostNumber
      ) {
        text += ', ';
        if (
          activeProjectDetails.value.PostOffice &&
          !activeProjectDetails.value.PostNumber
        ) {
          text += activeProjectDetails.value.PostOffice;
        } else if (
          activeProjectDetails.value.PostNumber &&
          !activeProjectDetails.value.PostOffice
        ) {
          text += activeProjectDetails.value.PostNumber;
        } else if (
          activeProjectDetails.value.PostNumber &&
          activeProjectDetails.value.PostOffice
        ) {
          text += `${activeProjectDetails.value.PostNumber} - ${activeProjectDetails.value.PostOffice}`;
        }
      }

      return text;
    });

    return {
      activeProjectDetails,
      address,
      loading,
      updateInfo,
    };
  },
});
