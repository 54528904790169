
























import { ref, defineComponent, PropType } from '@vue/composition-api';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import ElementForm from '@/components/forms/element-form/index.vue';

export default defineComponent({
  components: {
    ElementForm,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
    },
    isMenuitem: {
      type: Boolean,
    },
  },
  setup() {
    const dialog = ref<boolean>(false);

    const close = () => {
      dialog.value = false;
    };

    return {
      dialog,
      close,
    };
  },
});
