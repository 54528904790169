import { isSuccess } from '@/helpers/common';
import {
  BuildingElementCalculation,
  BuildingItemCalculation,
} from '@/models/building-element/interfaces';
import {
  CustomError,
  ProfessionTemplateSetting,
} from '@/models/common/interfaces';
import {
  BuildingElementTemplate,
  BuildingItemTemplate,
  BuildingTypeTemplate,
  TemplateFile,
} from '@/models/library/interfaces';
import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { useCalculation } from './calculation';
import { useErrorModal } from './error';
import { useGlobal } from './global';
import { useSnackbar } from './snackbar';
import { v4 as uuidv4 } from 'uuid';

interface State {
  error?: CustomError;
  buildingTemplatesByProfession: Array<BuildingTypeTemplate> | undefined;
  buildingTemplates: Array<BuildingTypeTemplate> | undefined;
  lastUsedTemplate: BuildingTypeTemplate | undefined;
  activeTemplate: BuildingTypeTemplate | undefined;
  downloadableFiles: Array<TemplateFile>;
  loading: boolean;
}

const state = reactive<State>({
  error: undefined,
  buildingTemplatesByProfession: [],
  buildingTemplates: undefined,
  lastUsedTemplate: undefined,
  activeTemplate: undefined,
  downloadableFiles: [],
  loading: false,
});

export const useLibrary = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const getBuildingTemplate = async (
    templateId: string
  ): Promise<BuildingTypeTemplate | undefined> => {
    let template: BuildingTypeTemplate | undefined = undefined;

    const { get } = useApi(`/buildingtypetemplate/${templateId}/full`);

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        template = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return template;
  };

  const getLastUsedTemplate = async (
    force?: boolean
  ): Promise<BuildingTypeTemplate | undefined> => {
    const { userSettings } = useGlobal();
    const { activeCalculation } = useCalculation();

    if (state.lastUsedTemplate && !force) {
      return state.lastUsedTemplate;
    }

    if (!activeCalculation?.value) return;

    let lastUsed: ProfessionTemplateSetting | undefined = undefined;
    let selectedTemplate: BuildingTypeTemplate | undefined = undefined;

    if (userSettings?.value) {
      lastUsed = userSettings.value.LastUsedTemplates?.find(
        t => t.ProfessionCode === activeCalculation.value?.ProfessionCode
      );
    }

    if (lastUsed) {
      selectedTemplate = state.buildingTemplatesByProfession?.find(
        t => t.Id === lastUsed?.Id
      );
      if (selectedTemplate) {
        state.lastUsedTemplate = selectedTemplate;
        return selectedTemplate;
      }
    }

    return state.buildingTemplatesByProfession &&
      state.buildingTemplatesByProfession.length > 0
      ? state.buildingTemplatesByProfession[0]
      : undefined;
  };

  const getBuildingTemplatesByProfession = async (
    professionCode: string,
    force?: boolean
  ): Promise<Array<BuildingTypeTemplate> | undefined> => {
    if (state.buildingTemplatesByProfession && !force) {
      return state.buildingTemplatesByProfession;
    }
    const { get } = useApi(`/buildingtypetemplate/${professionCode}`);

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.buildingTemplatesByProfession = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.buildingTemplatesByProfession;
  };

  const getBuildingTemplatesByCode = async (
    professionCode: string
  ): Promise<Array<BuildingTypeTemplate> | undefined> => {
    let templates: Array<BuildingTypeTemplate> = [];
    const { get } = useApi(`/buildingtypetemplate/${professionCode}`);

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        templates = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return templates;
  };

  const updateLastUsedTemplate = async (
    professionCode: string,
    id: string,
    templateName: string
  ): Promise<void> => {
    const { userSettings, saveSettings } = useGlobal();
    if (userSettings?.value?.LastUsedTemplates) {
      const exists:
        | ProfessionTemplateSetting
        | undefined = userSettings.value.LastUsedTemplates.find(
        t => t.ProfessionCode === professionCode
      );

      if (exists?.Id && exists.TemplateName) {
        exists.Id = id;
        exists.TemplateName = templateName;
        await saveSettings();
      } else {
        const pts: ProfessionTemplateSetting = {
          Id: id,
          ProfessionCode: professionCode,
          TemplateName: templateName,
        };
        userSettings.value.LastUsedTemplates.push(pts);
        await saveSettings();
      }
    }
    await getLastUsedTemplate(true);
  };

  const getAllBuildingTemplates = async (
    force?: boolean
  ): Promise<Array<BuildingTypeTemplate> | undefined> => {
    if (state.buildingTemplates && !force) {
      return state.buildingTemplates;
    }

    const { get } = useApi('/buildingtypetemplate');

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.buildingTemplates = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.buildingTemplates;
  };

  const downloadTemplateFile = async (
    templateFile: TemplateFile
  ): Promise<boolean> => {
    let success = false;
    const { post } = useApi('/buildingtypetemplate');

    try {
      const response = await post(templateFile);
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const getTemplateFiles = async (
    force?: boolean
  ): Promise<Array<TemplateFile>> => {
    if (state.downloadableFiles && !force) {
      return state.downloadableFiles;
    }
    const { get } = useApi('/templatefile');
    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.downloadableFiles = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    return state.downloadableFiles;
  };

  const createBuildingTypeTemplate = async (
    buildingTypeTemplate: BuildingTypeTemplate
  ): Promise<boolean> => {
    let success = false;
    const { post } = useApi('/buildingtypetemplate/create');

    try {
      const response = await post(buildingTypeTemplate);
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.template-created', true);
        if (
          buildingTypeTemplate.ProfessionCode &&
          buildingTypeTemplate.Id &&
          buildingTypeTemplate.Name
        ) {
          await updateLastUsedTemplate(
            buildingTypeTemplate.ProfessionCode,
            buildingTypeTemplate.Id,
            buildingTypeTemplate.Name
          );
        }
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const createBuildingElementTemplate = async (
    buildingElementTemplate: BuildingElementTemplate,
    buildingTypeTemplateId: string
  ): Promise<boolean> => {
    let success = false;
    const { post } = useApi(
      `/buildingtypetemplate/${buildingTypeTemplateId}/addbuildingelementtemplate`
    );

    try {
      const response = await post(buildingElementTemplate);
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.building-element-template-created', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const createBuildingItemTemplate = async (
    buildingItemTemplate: BuildingItemTemplate,
    buildingElementTemplateId: string
  ): Promise<boolean> => {
    let success = false;
    const { post } = useApi(
      `/buildingtypetemplate/${buildingElementTemplateId}/addbuildingitemtemplate`
    );

    try {
      const response = await post(buildingItemTemplate);
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.building-item-template-created', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const updateBuildingTypeTemplate = async (
    buildingTypeTemplate: BuildingTypeTemplate
  ): Promise<boolean> => {
    let success = false;
    const { put } = useApi('/buildingtypetemplate/update');

    try {
      const response = await put(buildingTypeTemplate);
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.template-updated', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const deleteBuildingTypeTemplate = async (
    buildingTypeTemplateId: string
  ): Promise<boolean> => {
    let success = false;
    const { deleteReq } = useApi(
      `/buildingtypetemplate/${buildingTypeTemplateId}/deletebuildingtypetemplate`
    );

    try {
      const response = await deleteReq();
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.template-deleted', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const deleteBuildingElementTemplate = async (
    buildingElementTemplateId: string
  ): Promise<boolean> => {
    let success = false;
    const { deleteReq } = useApi(
      `/buildingtypetemplate/${buildingElementTemplateId}/deletebuildingelementtemplate`
    );

    try {
      const response = await deleteReq();
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.building-element-template-deleted', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const deleteBuildingItemTemplate = async (
    buildingItemTemplateId: string
  ): Promise<boolean> => {
    let success = false;
    const { deleteReq } = useApi(
      `/buildingtypetemplate/${buildingItemTemplateId}/deletebuildingitemtemplate`
    );

    try {
      const response = await deleteReq();
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.building-item-template-deleted', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const importTemplate = async (
    template: BuildingTypeTemplate
  ): Promise<boolean> => {
    let success = false;
    const { post } = useApi('/buildingtypetemplate/import');

    try {
      const response = await post(template);
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.import-success', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const setActiveTemplate = async (template: BuildingTypeTemplate) => {
    if (template.ProfessionCode && template.Name && template.Id) {
      state.loading = true;
      if (state.lastUsedTemplate?.Id !== template.Id) {
        await updateLastUsedTemplate(
          template.ProfessionCode,
          template.Id,
          template.Name
        );
      }
      state.activeTemplate = await getBuildingTemplate(template.Id);

      state.loading = false;
    }
  };

  const destructCurrentLibrary = async () => {
    state.buildingTemplatesByProfession = [];
    state.activeTemplate = undefined;
    state.buildingTemplates = [];
    state.lastUsedTemplate = undefined;
  };

  const initCalculationLibrary = async () => {
    const { projectCalculation } = useCalculation();

    if (!projectCalculation?.value?.Profession?.Code) return;
    state.loading = true;
    await getBuildingTemplatesByProfession(
      projectCalculation.value.Profession.Code,
      true
    );
    const lastUsed = await getLastUsedTemplate(true);
    if (lastUsed) {
      await setActiveTemplate(lastUsed);
    } else {
      destructCurrentLibrary();
    }

    state.loading = false;
  };

  const createBuildingItemTemplateFromBIC = (item: BuildingItemCalculation) => {
    const template: BuildingItemTemplate = {
      Id: uuidv4(),
      Name: item.Name,
      TimeEstimateName: item.TimeEstimateName ?? null,
      DeliveryOrder: item.DeliveryOrder ?? null,
      AccountNumber: item.AccountNumber?.toString() ?? null,
      NOBBNumber: item.NOBBNumber?.toString() ?? null,
      NS3420: item.NS3420 ?? null,
      Unit: item.Unit,
      UnitForOrder: item.UnitForOrder,
      Amount: item.Amount ?? null,
      AmountAdjustment: item.AmountAdjustment ?? null,
      AmountPrUnit: item.AmountPrUnit ?? null,
      Price: item.Price ?? null,
      PriceListName: item.PriceListName ?? null,
      PriceListItemName: item.PriceListItemName ?? null,
      BaseTime: item.BaseTime ?? null,
      OpeningAddition: item.OpeningAddition ?? null,
      SurfaceAddition: item.SurfaceAddition ?? null,
      TransportationAddition: item.TransportationAddition ?? null,
      IsFDVExportSelected: item.IsFDVExportSelected,
      MileStone: item.MileStone ?? null,
      Comments: item.Comments ?? null,
      Transport: item.Transport ?? null,
      Precut: item.Precut ?? null,
      SortOrder: item.SortOrder,
      Created: new Date().toLocaleString(),
      LastModified: new Date().toLocaleString(),
      BuildingElementTemplateId: null,
    };

    return template;
  };

  const createBuildingElementTemplateFromBEC = (
    element: BuildingElementCalculation,
    buildingTypeTemplateId: string
  ) => {
    const template: BuildingElementTemplate = {
      Id: uuidv4(),
      Name: element.Name,
      BuildingTypeTemplateId: buildingTypeTemplateId,
      Created: new Date().toLocaleString(),
      LastModified: new Date().toLocaleString(),
      Amount: null,
      NS3450: element.NS3450,
      NS3451: element.NS3451,
      Unit: element.Unit?.value ?? null,
      Comments: element.Comments,
      AdditionItemTemplates: [],
      BuildingItemTemplates: [],
      Code: null,
      ProductionLine: null,
      SortOrder: null,
      Transport: null,
    };

    if (element.BuildingItems.length > 0) {
      element.BuildingItems.forEach(item => {
        const itemTemplate = createBuildingItemTemplateFromBIC(item);
        template.BuildingItemTemplates.push(itemTemplate);
      });
    }

    return template;
  };

  const addElementToActiveLibrary = async (
    element: BuildingElementCalculation
  ) => {
    const { activateLibrary } = useCalculation();

    activateLibrary.value = true;
    if (state.activeTemplate?.Id) {
      const template = createBuildingElementTemplateFromBEC(
        element,
        state.activeTemplate.Id
      );
      state.loading = true;
      const success = await createBuildingElementTemplate(
        template,
        state.activeTemplate.Id
      );
      if (success) {
        state.activeTemplate = await getBuildingTemplate(
          state.activeTemplate.Id
        );
      }
      state.loading = false;
    }
  };

  const addElementToLibrary = async (
    template: BuildingTypeTemplate,
    element: BuildingElementCalculation,
    newName: string
  ) => {
    const { activateLibrary } = useCalculation();
    if (template.Id) {
      const temp = createBuildingElementTemplateFromBEC(element, template.Id);
      temp.Name = newName;
      state.loading = true;
      const success = await createBuildingElementTemplate(temp, template.Id);
      if (success && state.activeTemplate?.Id === template.Id) {
        activateLibrary.value = false;
        state.activeTemplate = await getBuildingTemplate(template.Id);
      }
      state.loading = false;
    }
  };

  const addItemToLibrary = async (
    template: BuildingTypeTemplate,
    elementTemplate: BuildingElementTemplate,
    item: BuildingItemCalculation,
    newName: string
  ) => {
    const { activateLibrary } = useCalculation();
    if (elementTemplate.Id && template.Id) {
      state.loading = true;
      const itemTemplate = createBuildingItemTemplateFromBIC(item);
      itemTemplate.Name = newName;
      const success = await createBuildingItemTemplate(
        itemTemplate,
        elementTemplate.Id
      );
      if (success && state.activeTemplate?.Id === template.Id) {
        activateLibrary.value = false;
        state.activeTemplate = await getBuildingTemplate(template.Id);
      }
      state.loading = false;
    }
  };

  return {
    ...toRefs(state),
    getBuildingTemplate,
    getBuildingTemplatesByProfession,
    getBuildingTemplatesByCode,
    getAllBuildingTemplates,
    downloadTemplateFile,
    getTemplateFiles,
    createBuildingTypeTemplate,
    createBuildingElementTemplate,
    createBuildingItemTemplate,
    updateBuildingTypeTemplate,
    deleteBuildingTypeTemplate,
    deleteBuildingElementTemplate,
    deleteBuildingItemTemplate,
    getLastUsedTemplate,
    importTemplate,
    updateLastUsedTemplate,
    initCalculationLibrary,
    setActiveTemplate,
    destructCurrentLibrary,
    addElementToLibrary,
    addElementToActiveLibrary,
    addItemToLibrary,
    createBuildingElementTemplateFromBEC,
  };
};
