





























































































































































import { ExportType } from '@/constants/enum';
import { ref, defineComponent, PropType } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import { useExport } from '@/modules/export';
import xml2js from 'xml2js';
import { createXMLObject } from '@/helpers/library';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    template: {
      type: Object as PropType<BuildingTypeTemplate>,
      required: true,
    },
  },

  setup(props) {
    const { exportTemplate } = useExport();

    const dialog = ref<boolean>(false);
    const loading = ref(false);
    const type = ref<ExportType>(ExportType.XML);

    const submit = async () => {
      loading.value = true;
      if (props.template.Id && type.value != null) {
        const response = await exportTemplate(props.template);
        if (response) {
          try {
            const xmlPayload = {
              BuildingTypeTemplate: response,
            };

            let blobParts = JSON.stringify(xmlPayload);
            let blobType = '';

            if (type.value === ExportType.XML) {
              const builderOptions: xml2js.BuilderOptions = {
                xmldec: {
                  version: '1.0',
                  encoding: 'UTF-8',
                },
              };

              const xmlObject = createXMLObject(response);
              const builder = new xml2js.Builder(builderOptions);
              blobParts = builder.buildObject(xmlObject);

              blobType = 'application/xml';
            } else {
              blobType = 'application/json';
            }
            const blob = new Blob([blobParts], {
              type: blobType,
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${props.template.Name}.${
              type.value === ExportType.XML ? 'xml' : 'json'
            }`;
            link.click();
            link.remove();
          } catch (e) {
            console.log(e);
          }
        }
      }
      loading.value = false;
      dialog.value = false;
    };

    return {
      submit,
      dialog,
      loading,
      type,
      ExportType,
    };
  },
});
