







import { DEFAULT_LAYOUT } from '@/constants/common';
import { defineComponent } from '@vue/composition-api';
import AppLayoutLoading from '@/layout/applayout-loading/index.vue';
import { useLoader } from '@/modules/loader';

export default defineComponent({
  name: 'AppLayout',
  computed: {
    layout() {
      const layout = this?.$route?.meta?.layout || DEFAULT_LAYOUT;
      return () => import(`@/layout/${layout}/index.vue`);
    },
  },
  components: {
    AppLayoutLoading,
  },
  setup() {
    const { loading } = useLoader();
    return { loading };
  },
});
