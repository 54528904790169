

















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    information: {
      type: String,
    },
  },
});
