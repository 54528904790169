
























































































































































































import { ref, defineComponent } from '@vue/composition-api';
import { useCompany } from '@/modules/company';
import { Company } from '@/models/company/interfaces';
import { cleanSource } from '@/helpers/common';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';

const valid = ref<boolean>(false);

export default defineComponent({
  components: {
    DialogTitle,
  },
  setup(props, { emit }) {
    const { loading, company, error, updateCompany } = useCompany();
    let payload: Company = {};

    if (company?.value) {
      payload = cleanSource(company.value);
    }

    const submit = async () => {
      await updateCompany(payload);
      emit('close', true);
    };

    const close = () => {
      emit('close', true);
    };

    return {
      submit,
      close,
      loading,
      valid,
      payload,
      error,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
      emailRules: [
        (v: string) => !!v || `${translate('validation.required')}`,
        (v: string) =>
          /.+@.+\..+/.test(v) || `${translate('validation.invalid')}`,
      ],
    };
  },
});
