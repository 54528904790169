import {
  CheckUserLicenseResult,
  License,
  LicensePrice,
  LicenseTermination,
} from '@/models/admin/interfaces';
import { User } from '@/models/auth/interfaces';
import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { useSnackbar } from './snackbar';
import moment from 'moment';
import { cleanSource, isSuccess } from '@/helpers/common';
import { CustomError, UsernameModel } from '@/models/common/interfaces';
import { useUsers } from './users';
import { useErrorModal } from './error';
import { useAuth } from './auth';
import { translate } from '@/localization/index';

interface AdminState {
  licenses?: Array<License>;
  licensePrice?: LicensePrice;
  loading: boolean;
  error?: CustomError;
  availableUsers: Array<string>;
  calculatingChoices: boolean;
  canChoose: boolean;
}

const state = reactive<AdminState>({
  error: undefined,
  licensePrice: undefined,
  licenses: undefined,
  loading: false,
  availableUsers: [],
  calculatingChoices: false,
  canChoose: false,
});

export const useLicense = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const checkUserLicense = async (): Promise<
    CheckUserLicenseResult | undefined
  > => {
    const { get } = useApi('/licensing/checkuserlicense');
    let check: CheckUserLicenseResult | undefined = undefined;
    try {
      const response = await get();
      if (isSuccess(response.status)) {
        check = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    return check;
  };

  const upgradeLicense = async (user: UsernameModel): Promise<boolean> => {
    const { post } = useApi('/licensing/management/upgradefromtriallicense');
    const { updateAuthAndLicense } = useAuth();
    let success = false;

    if (!user.UserName) return false;

    try {
      const response = await post(user);
      if (isSuccess(response.status)) {
        snack('snack.license-updated', true);
        success = true;
        await updateAuthAndLicense();
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const shouldListLicense = (license: License): boolean => {
    //If license has an expire date, make sure it's before the current date and that it is not a trial license.
    if (license.ExpiryDate) {
      return !moment(license.ExpiryDate).isBefore() && !license.Trial;
    } else {
      return !license.Trial;
    }
  };

  const getLicenses = async (
    force?: boolean
  ): Promise<Array<License> | undefined> => {
    const { get } = useApi('/licensing/licenses');

    if (state.licenses && !force) {
      return state.licenses;
    }
    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.licenses = response.data;

        if (state.licenses) {
          state.licenses = state.licenses.filter(l => shouldListLicense(l));
        }
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return state.licenses;
  };

  const canAssignToExistingUser = async (): Promise<void> => {
    const { getUsers } = useUsers();
    state.calculatingChoices = true;
    const users = await getUsers();
    const licenses = await getLicenses();

    state.availableUsers = [];
    users?.forEach(user => {
      if (!licenses?.some(l => l.GrantedToUsername === user.Email)) {
        state.availableUsers.push(user.Email);
      }
    });
    state.calculatingChoices = false;
    state.canChoose = state.availableUsers.length > 0;
  };

  const purchaseLicense = async (newUser: UsernameModel): Promise<boolean> => {
    const { post } = useApi('/licensing/management/purchaselicenseforuser');
    const { updateAuthAndLicense, user } = useAuth();
    let success = false;

    if (!newUser) return false;

    try {
      const response = await post(newUser);
      if (isSuccess(response.status)) {
        snack('snack.license-created', true);
        success = true;
        if (user?.value?.Email === newUser.UserName)
          await updateAuthAndLicense();
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return success;
  };

  const unassignLicense = async (user: User): Promise<boolean> => {
    await getLicenses();

    state.licenses?.forEach((license, i) => {
      if (license.GrantedToUsername === user.Email && state.licenses) {
        const updatedLicense = cleanSource(license);
        updatedLicense.GrantedToUsername = '';
        state.licenses.splice(i, 1);
        state.licenses.unshift(updatedLicense);
        return true;
      }
    });

    return false;
  };

  const deleteLicense = async (
    license: License
  ): Promise<LicenseTermination | undefined> => {
    const { deleteReq } = useApi(`/licensing/management/license/${license.Id}`);

    if (!license.Id) return undefined;

    state.loading = true;

    try {
      const response = await deleteReq();
      if (isSuccess(response.status)) {
        const termination: LicenseTermination = {
          ExpiryDate: response.data.ExpiryDate,
        };
        state.licenses?.forEach((l, i) => {
          if (l.Id === license.Id && state.licenses) {
            license.ExpiryDate = response.data.ExpiryDate;
            state.licenses.splice(i, 1);
            state.licenses.unshift(license);
            snack(
              `${translate('snack.license-expires')} ${moment(
                license.ExpiryDate
              ).format('Do MMMM YYYY')}`,
              true
            );
          }
        });
        state.loading = false;
        return termination;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return undefined;
  };

  const canAssignLicense = (license: License): boolean => {
    let canAssign = false;

    if (!license.GrantedToUsername) {
      if (license.ExpiryDate) {
        return moment(new Date()).isBefore(license.ExpiryDate);
      } else {
        canAssign = true;
      }
    }

    return canAssign;
  };

  const canDeleteLicense = (license: License): boolean => {
    let canDelete = false;

    if (!license.ExpiryDate) {
      canDelete = true;
    }

    return canDelete;
  };

  const showLicenseActions = (license: License): boolean => {
    return canAssignLicense(license) || canDeleteLicense(license);
  };

  const grantLicenseToUser = async (
    licenseId: string,
    userName: UsernameModel
  ): Promise<boolean> => {
    const { put } = useApi(`/licensing/management/license/${licenseId}/grant`);
    let success = false;

    if (!licenseId) return false;

    state.loading = true;

    try {
      const response = await put(userName);
      if (isSuccess(response.status)) {
        snack(
          `${translate('snack.license-granted-to')} ${userName.UserName}.`,
          true
        );
        state.licenses?.forEach((l, i) => {
          if (l.Id === licenseId && state.licenses) {
            l.GrantedToUsername = userName.UserName;
            state.licenses.splice(i, 1);
            state.licenses.unshift(l);
          }
        });
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return success;
  };

  const getLicensePrice = async (): Promise<LicensePrice | undefined> => {
    const { get } = useApi('/licensing/management/pricing');
    if (state.licensePrice) {
      return state.licensePrice;
    }

    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.licensePrice = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return state.licensePrice;
  };

  return {
    ...toRefs(state),
    getLicenses,
    getLicensePrice,
    unassignLicense,
    deleteLicense,
    canAssignLicense,
    canDeleteLicense,
    showLicenseActions,
    purchaseLicense,
    grantLicenseToUser,
    checkUserLicense,
    upgradeLicense,
    canAssignToExistingUser,
  };
};
