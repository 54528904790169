















































import {
  customerHourPrice,
  customerHourPriceCalculation,
} from '@/helpers/factor';
import { ProjectFactor } from '@/models/calculation/interfaces';
import { CompanyFactor } from '@/models/company/interfaces';
import { defineComponent, PropType } from '@vue/composition-api';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';

export default defineComponent({
  components: {
    InfoTooltip,
  },
  props: {
    factors: {
      type: Array as PropType<Array<CompanyFactor> | Array<ProjectFactor>>,
    },
    IsCalculation: {
      type: Boolean,
    },
  },
  setup() {
    return {
      customerHourPrice,
      customerHourPriceCalculation,
    };
  },
});
