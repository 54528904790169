
































































import { Epd, NobbItem } from '@/models/nobb/interfaces';
import { ref, defineComponent, PropType } from '@vue/composition-api';
import { translate } from '@/localization';

export default defineComponent({
  props: {
    nobbItem: {
      type: Object as PropType<NobbItem | undefined>,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const close = () => {
      dialog.value = false;
    };

    const valueWithUnit = (epd: Epd) => {
      switch (epd.unit) {
        case 'tekst':
          return epd.value;
        case 'kg CO2 eq.':
          return `${epd.value} ${translate(
            'environment.kg-carbondioxide-equivalent'
          )}`;
        case 'år':
          return `${epd.value} ${translate('common.year')}`;
        case 'Ja/Nei':
          return epd.value === 'True'
            ? translate('common.yes')
            : translate('common.no');
        default:
          return `${epd.value} ${epd.unit}`;
      }
    };

    return {
      props,
      dialog,
      valueWithUnit,
      close,
    };
  },
});
