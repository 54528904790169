






















































































import { ref, defineComponent, computed } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import DownloadableLibrary from '@/components/pricebook/download-pricelist/downloadable-pricelist/index.vue';
import Profession from '@/components/profession/index.vue';
import Loader from '@/components/loader/index.vue';
import { TemplateFile } from '@/models/library/interfaces';
import { useLibrary } from '@/modules/library';

export default defineComponent({
  components: {
    DialogTitle,
    DownloadableLibrary,
    Profession,
    Loader,
  },
  props: {
    professionCode: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const {
      downloadTemplateFile,
      getAllBuildingTemplates,
      getTemplateFiles,
    } = useLibrary();
    const dialog = ref(false);
    const loadingTemplates = ref(false);
    const loading = ref(false);
    const selectedFile = ref<TemplateFile>();
    const availableFiles = ref<Array<TemplateFile>>([]);

    const valid = computed(() => {
      return selectedFile.value != null;
    });

    const getDownloadables = async (professionCode: string) => {
      loadingTemplates.value = true;
      const templates = await getAllBuildingTemplates(true);
      const files = await getTemplateFiles(true);

      availableFiles.value = files.filter(
        file => professionCode === file.Profession.Code
      );

      availableFiles.value.forEach((file, i) => {
        if (templates) {
          const found = templates.findIndex(
            temp => temp.GlobalKey === file.GlobalKey
          );
          if (found >= 0) {
            availableFiles.value.splice(i, 1);
          }
        }
      });
      loadingTemplates.value = false;
    };

    const close = () => {
      selectedFile.value = undefined;
      dialog.value = false;
    };

    const submit = async () => {
      if (selectedFile.value && valid.value) {
        loading.value = true;
        const success = await downloadTemplateFile(selectedFile.value);
        if (success) {
          emit('update', selectedFile.value.Profession.Code);
          selectedFile.value = undefined;
          close();
        }
      }
      loading.value = false;
    };

    return {
      close,
      submit,
      getDownloadables,
      availableFiles,
      dialog,
      loading,
      valid,
      selectedFile,
      loadingTemplates,
    };
  },
});
