import { translate } from '@/localization';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { ProjectCalculation } from '@/models/calculation/interfaces';
import {
  NO_MILESTONE,
  ReportFileType,
  ReportType,
} from '@/models/report/enums';
import { MaterialItemTotal } from '@/models/report/interfaces';
import { useCalculation } from '@/modules/calculation';
import { computed } from '@vue/composition-api';
import { activeBuildingItems, allBuildingItems } from './calculation';
import { saveAs } from 'file-saver';
import { useProject } from '@/modules/project';

export const onlyIfPrecut = (
  isPrecut: Nullable<boolean> | undefined,
  amount: Nullable<number>
): Nullable<number> => {
  if (isPrecut == null || !isPrecut) return null;

  return amount;
};

export const onlyIfPrecutUnit = (
  isPrecut: Nullable<boolean>,
  precutUnit: Nullable<string>
): Nullable<string> => {
  if (isPrecut === null || !isPrecut) return '';
  return precutUnit;
};

export const getPrecutText = (isPrecut: Nullable<boolean>): string => {
  if (!isPrecut) return '';
  return 'P';
};

export const getPriceListItemName = (
  groupName: string,
  item: BuildingItemCalculation
) => {
  if (!item.PriceListItemName || !item.NOBBNumber) return groupName;

  return item.PriceListItemName;
};

export const getMileStoneData = (
  project: ProjectCalculation,
  includeAllBuildingItems: boolean
): Map<string, Array<MaterialItemTotal>> => {
  const materialItems: Array<MaterialItemTotal> = [];

  const milestones = new Map<string, Array<MaterialItemTotal>>();

  let items: Array<BuildingItemCalculation> = activeBuildingItems(project)
    ? activeBuildingItems(project)
    : [];

  if (includeAllBuildingItems) {
    items = allBuildingItems(project) ? allBuildingItems(project) : [];
  }

  if (items) {
    items.forEach(item => {
      const matItem: MaterialItemTotal = {
        Id: item.Id ?? '',
        AccountName: null,
        AccountNumber: null,
        Amount: item.AmountCalculated.value,
        AmountOfMaterials: item.AmountCalculated.value ?? 0,
        ItemName: `${item.Name} ${
          item.PriceListItemName ? `(${item.PriceListItemName})` : ''
        }`,
        ProductionLine: null,
        Unit: item.Unit,
        TimePrUnit: item.TimePrUnit.value,
        ProjectCost: item.ProjectCost.value,
        SumHours: item.SumTime.value,
        MileStone: item.MileStone ?? null,
        SortOrder: item.SortOrder,
        Sum: item.SumTotal.value,
        SumSelfCost: item.SumSelfCost.value,
        OpeningAddition: item.OpeningAddition ?? 0,
        SurfaceAddition: item.SurfaceAddition ?? 0,
        Factor1Calculated: item.Factor1Calculated.value ?? 0,
        SumSelfCostMaterialsSubContractors:
          item.SumSelfCostMaterialsSubContractors.value ?? 0,
        AmountPrUnit: 0,
        BuildingElement: item.BuildingElement,
        BuildingElementId: item.BuildingElementId ?? '',
        Comments: '',
        DeliveryOrder: null,
        NOBBNumber: null,
        OpeningAdditionAmount: computed(() => {
          return 0;
        }),
        Precut: null,
        PrecutText: null,
        PrecutUnit: null,
        Price: null,
        PriceListItemName: null,
        SumAdditions: computed(() => {
          return 0;
        }),
        SumProfit: null,
        SumTime: computed(() => {
          return 0;
        }),
        SurfaceAdditionAmount: computed(() => {
          return 0;
        }),
        TotalAmount: null,
        UnitForOrder: null,
      };

      materialItems.push(matItem);
    });
  }

  if (materialItems) {
    materialItems.forEach(matItem => {
      if (matItem.MileStone !== null && matItem.MileStone !== '') {
        if (milestones.has(matItem.MileStone)) {
          const values = milestones.get(matItem.MileStone);
          if (values) {
            values.push(matItem);
          }
        } else {
          milestones.set(matItem.MileStone, [matItem]);
        }
      } else {
        if (milestones.has(NO_MILESTONE)) {
          const values = milestones.get(NO_MILESTONE);
          if (values) {
            values.push(matItem);
          }
        } else {
          milestones.set(NO_MILESTONE, [matItem]);
        }
      }
    });
  }

  return milestones;
};

export const getMaterialsForElement = (
  project: ProjectCalculation,
  includeAllBuildingItems: boolean
): Array<MaterialItemTotal> => {
  const materialItems: Array<MaterialItemTotal> = [];
  let items: Array<BuildingItemCalculation> = project.ActiveBuildingItems?.value
    ? project.ActiveBuildingItems.value
    : [];

  if (includeAllBuildingItems) {
    items = project.AllBuildingItems?.value
      ? project.AllBuildingItems.value
      : [];
  }

  if (items) {
    items.forEach(item => {
      const matItem: MaterialItemTotal = {
        BuildingElement: item.BuildingElement,
        BuildingElementId: item.BuildingElementId ?? '',
        NOBBNumber: item.NOBBNumber ?? null,
        ItemName: getPriceListItemName(item.Name ?? '', item),
        PriceListItemName: item.Name,
        Unit: item.Unit,
        Price: item.Price ?? null,
        AmountOfMaterials: item.AmountCalculated.value ?? 0,
        AmountPrUnit: item.AmountPrUnit,
        Precut: item.Precut ?? null,
        PrecutUnit: onlyIfPrecutUnit(item.Precut ?? null, 'm'),
        PrecutText: getPrecutText(item.Precut ?? null),
        DeliveryOrder: item.DeliveryOrder ?? null,
        AccountNumber: item.AccountNumber ? item.AccountNumber.toString() : '',
        TotalAmount: onlyIfPrecut(
          item.Precut ?? null,
          item.AmountCalculated.value
        ),
        SumSelfCostMaterialsSubContractors:
          item.SumSelfCostMaterialsSubContractors.value ?? 0,
        SumSelfCost: item.SumSelfCost.value,
        SumProfit: item.SumProfit.value,
        UnitForOrder: item.UnitForOrder,
        MileStone: item.MileStone ?? null,
        Id: item.Id ?? '',
        AccountName: null,
        Amount: item.Amount,
        ProductionLine: null,
        TimePrUnit: item.TimePrUnit.value,
        ProjectCost: item.ProjectCost.value,
        SumHours: item.SumTime.value,
        SortOrder: item.SortOrder,
        Sum: item.SumTotal.value,

        OpeningAddition: item.OpeningAddition ?? 0,
        SurfaceAddition: item.SurfaceAddition ?? 0,
        Factor1Calculated: item.Factor1Calculated.value ?? 0,
        Comments: '',
        OpeningAdditionAmount: computed(() => {
          return 0;
        }),
        SumAdditions: computed(() => {
          return 0;
        }),
        SumTime: computed(() => {
          return 0;
        }),
        SurfaceAdditionAmount: computed(() => {
          return 0;
        }),
      };

      materialItems.push(matItem);
    });
  }

  return materialItems;
};

export const createReportName = (
  type: ReportType,
  fileType: ReportFileType,
  isProject?: boolean
) => {
  let reportName = '';
  const { projectCalculation } = useCalculation();
  const { activeProjectOverview } = useProject();

  switch (type) {
    case ReportType.MaterialOrder:
      reportName = `${translate('reports.material-order-report')}`;
      break;
    case ReportType.MileStone:
      reportName = `${translate('reports.milestone-report')}`;
      break;
    case ReportType.MaterialListPrecut:
      reportName = `${translate('reports.material-list-precut-report')}`;
      break;
    case ReportType.AttachmentPriceOffer:
      reportName = `${translate('reports.attachment-price-offer-report')}`;
      break;
    case ReportType.BuildingElements:
      reportName = `${translate('reports.building-elements-report')}`;
      break;
    case ReportType.MainSums:
      reportName = `${translate('reports.main-sums-report')}`;
      break;
    case ReportType.PriceRequest:
      reportName = `${translate('reports.price-request-report')}`;
      break;
    case ReportType.CalculationView:
      reportName = `${translate('reports.calculation-view-report')}`;
      break;
    case ReportType.TimeView:
      reportName = `${translate('reports.time-view-report')}`;
      break;
    case ReportType.DeliveryView:
      reportName = `${translate('reports.delivery-view-report')}`;
      break;
    case ReportType.EconomyView:
      reportName = `${translate('reports.economy-view-report')}`;
      break;
    case ReportType.MaterialsView:
      reportName = `${translate('reports.material-view-report')}`;
      break;
    case ReportType.ProjectMileStone:
      reportName = `${translate('reports.project-milestone-report')}`;
      break;
    case ReportType.ProjectAttachmentPriceOffer:
      reportName = `${translate('reports.project-attachment-price-report')}`;
      break;
    case ReportType.ProjectBuildingElements:
      reportName = `${translate('reports.project-building-element-report')}`;
      break;
    case ReportType.ProjectMainSums:
      reportName = `${translate('reports.project-main-sums-report')}`;
      break;
    case ReportType.Environment:
      reportName = `${translate('reports.environment-report')}`;
      break;
    default:
      reportName = `${translate('common.unknown')}`;
      break;
  }

  let name = `${reportName}.${ReportFileType[fileType].toLowerCase()}`;

  if (isProject && activeProjectOverview.value) {
    name = `${activeProjectOverview.value.Name.replace(
      ' ',
      ''
    )}_${reportName}.${ReportFileType[fileType].toLowerCase()}`;
  } else if (projectCalculation.value?.Name) {
    name = `${projectCalculation.value.Name.replace(
      ' ',
      ''
    )}_${reportName}.${ReportFileType[fileType].toLowerCase()}`;
  }

  name = name.replace(/[&#,+()$~%'":*?<>{}]/g, '');

  return name;
};

export const getBuildingMaterials = (
  project: ProjectCalculation,
  includeAllBuildingItems: boolean
): Array<MaterialItemTotal> => {
  const materialItems: Array<MaterialItemTotal> = [];
  let items: Array<BuildingItemCalculation> = activeBuildingItems(project)
    ? activeBuildingItems(project)
    : [];

  if (includeAllBuildingItems) {
    items = allBuildingItems(project) ? allBuildingItems(project) : [];
  }

  if (items) {
    items = items.filter(
      item => item.SumMaterialsUE.value && item.SumMaterialsUE.value != 0
    );

    items.forEach(item => {
      const matItem: MaterialItemTotal = {
        NOBBNumber: item.NOBBNumber ?? null,
        ItemName: getPriceListItemName(item.Name ?? '', item),
        Unit: item.UnitForOrder,
        Price: item.Price ?? null,
        AmountOfMaterials: item.AmountOfMaterials.value ?? 0,
        AmountPrUnit: onlyIfPrecut(item.Precut, item.AmountPrUnit),
        Precut: item.Precut ?? null,
        PrecutUnit: onlyIfPrecutUnit(item.Precut ?? null, 'm'),
        PrecutText: getPrecutText(item.Precut ?? null),
        DeliveryOrder: item.DeliveryOrder ?? null,
        AccountNumber: item.NS3420 ?? '',
        TotalAmount: onlyIfPrecut(item.Precut, item.AmountCalculated.value),
        Comments: item.Comments ?? '',
        AccountName: null,
        Amount: null,
        BuildingElementId: null,
        Factor1Calculated: 0,
        Id: '',
        MileStone: null,
        OpeningAddition: 0,
        PriceListItemName: null,
        ProductionLine: null,
        ProjectCost: null,
        SortOrder: null,
        Sum: null,
        SumHours: null,
        SumProfit: null,
        SumSelfCost: null,
        SumSelfCostMaterialsSubContractors: null,
        SurfaceAddition: 0,
        TimePrUnit: null,
        UnitForOrder: null,
        BuildingElement: item.BuildingElement,
        OpeningAdditionAmount: computed(() => {
          return 0;
        }),
        SumAdditions: computed(() => {
          return 0;
        }),
        SumTime: computed(() => {
          return 0;
        }),
        SurfaceAdditionAmount: computed(() => {
          return 0;
        }),
      };

      const matExist = materialItems.findIndex(
        mi =>
          mi.NOBBNumber === matItem.NOBBNumber &&
          mi.UnitForOrder === matItem.UnitForOrder &&
          mi.BuildingElement?.ProductionLine ===
            matItem.BuildingElement?.ProductionLine &&
          mi.DeliveryOrder === matItem.DeliveryOrder &&
          mi.Precut === matItem.Precut &&
          mi.AmountPrUnit === matItem.AmountPrUnit
      );

      if (matExist >= 0) {
        const newMat = materialItems[matExist];
        newMat.AmountOfMaterials += matItem.AmountOfMaterials;
        if (newMat.TotalAmount !== null && newMat.TotalAmount > 0) {
          newMat.TotalAmount += matItem.TotalAmount ?? 0;
        } else {
          newMat.TotalAmount = matItem.TotalAmount ?? 0;
        }

        materialItems.splice(matExist, 1, newMat);
      } else {
        materialItems.push(matItem);
      }
    });
  }

  return materialItems;
};

export const getPriceRequestMaterials = (
  project: ProjectCalculation,
  includeAllBuildingItems: boolean
): Array<MaterialItemTotal> => {
  const materialItems: Array<MaterialItemTotal> = [];
  let items: Array<BuildingItemCalculation> = activeBuildingItems(project)
    ? activeBuildingItems(project)
    : [];

  if (includeAllBuildingItems) {
    items = allBuildingItems(project) ? allBuildingItems(project) : [];
  }

  if (items) {
    items = items.filter(
      item => item.SumMaterialsUE.value && item.SumMaterialsUE.value != 0
    );

    items.forEach(item => {
      const matItem: MaterialItemTotal = {
        NOBBNumber: item.NOBBNumber ?? null,
        ItemName: getPriceListItemName(item.Name ?? '', item),
        Unit: item.UnitForOrder,
        Price: item.Price ?? null,
        AmountOfMaterials: item.AmountOfMaterials.value ?? 0,
        AmountPrUnit: onlyIfPrecut(item.Precut, item.AmountPrUnit),
        Precut: item.Precut ?? null,
        PrecutUnit: onlyIfPrecutUnit(item.Precut ?? null, 'm'),
        PrecutText: getPrecutText(item.Precut ?? null),
        DeliveryOrder: item.DeliveryOrder ?? null,
        AccountNumber: item.NS3420 ?? '',
        TotalAmount: onlyIfPrecut(item.Precut, item.AmountCalculated.value),
        Comments: item.Comments ?? '',
        AccountName: null,
        Amount: null,
        BuildingElementId: null,
        Factor1Calculated: 0,
        Id: '',
        MileStone: null,
        OpeningAddition: 0,
        PriceListItemName: null,
        ProductionLine: null,
        ProjectCost: null,
        SortOrder: null,
        Sum: null,
        SumHours: null,
        SumProfit: null,
        SumSelfCost: null,
        SumSelfCostMaterialsSubContractors: null,
        SurfaceAddition: 0,
        TimePrUnit: null,
        UnitForOrder: null,
        BuildingElement: item.BuildingElement,
        OpeningAdditionAmount: computed(() => {
          return 0;
        }),
        SumAdditions: computed(() => {
          return 0;
        }),
        SumTime: computed(() => {
          return 0;
        }),
        SurfaceAdditionAmount: computed(() => {
          return 0;
        }),
      };

      const matExist = materialItems.findIndex(
        mi =>
          mi.NOBBNumber === matItem.NOBBNumber &&
          mi.UnitForOrder === matItem.UnitForOrder &&
          mi.BuildingElement?.ProductionLine ===
            matItem.BuildingElement?.ProductionLine &&
          mi.DeliveryOrder === matItem.DeliveryOrder &&
          mi.Precut === matItem.Precut &&
          mi.AmountPrUnit === matItem.AmountPrUnit &&
          mi.Price === matItem.Price
      );

      if (matExist >= 0) {
        const newMat = materialItems[matExist];
        newMat.AmountOfMaterials += matItem.AmountOfMaterials;
        if (newMat.TotalAmount !== null && newMat.TotalAmount > 0) {
          newMat.TotalAmount += matItem.TotalAmount ?? 0;
        } else {
          newMat.TotalAmount = matItem.TotalAmount ?? 0;
        }

        materialItems.splice(matExist, 1, newMat);
      } else {
        materialItems.push(matItem);
      }
    });
  }

  return materialItems;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadExcel = (fileName: string, out: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf); //create uint8array as viewer
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  };

  saveAs(new Blob([s2ab(out)], { type: 'application/octet-stream' }), fileName);
};
