






















































































































































































































































































































































import { Contact } from '@/models/contacts/interfaces';
import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
} from '@vue/composition-api';
import ContactCard from '@/components/contacts/contact/index.vue';
import { useContacts } from '@/modules/contacts';
import { cleanSource } from '@/helpers/common';
import Confirm from '@/components/common/confirm/index.vue';
import DialogTitle from '@/components/common/dialog-title/index.vue';

interface State {
  dialog?: boolean;
  newContact?: boolean;
  editContact?: boolean;
  payload?: Contact;
  deleteContact?: Contact;
  valid?: boolean;
  deleteDialog: boolean;
}

const initialState: State = {
  dialog: false,
  newContact: false,
  editContact: false,
  payload: {},
  deleteContact: undefined,
  valid: false,
  deleteDialog: false,
};

const state = reactive<State>(initialState);

export default defineComponent({
  components: {
    ContactCard,
    Confirm,
    DialogTitle,
  },
  props: {
    contacts: {
      type: Array as PropType<Array<Contact>>,
      required: true,
    },
  },
  setup() {
    const {
      createContact,
      updateContract,
      deleteContact,
      loading,
    } = useContacts();

    const close = () => {
      state.dialog = false;
      state.newContact = false;
      state.payload = {};
      state.deleteContact = undefined;
      state.editContact = false;
    };

    const cancel = () => {
      state.newContact = false;
      state.payload = {};
      state.deleteContact = undefined;
      state.editContact = false;
    };

    const cancelDelete = () => {
      state.deleteContact = undefined;
    };

    const setEditMode = (contact: Contact) => {
      if (state.deleteContact?.Id === contact.Id) {
        cancel();
      } else {
        state.newContact = false;
        state.editContact = true;
        state.deleteContact = contact;
        state.payload = cleanSource(contact);
      }
    };

    const setNewMode = () => {
      state.editContact = false;
      state.newContact = true;
      state.payload = {};
    };

    const deleteContactById = async (id: string) => {
      await deleteContact(id);
      state.deleteContact = undefined;
      state.editContact = false;
      state.payload = undefined;
    };

    const submit = async () => {
      if (state.newContact && state.payload) {
        const contact: Contact = state.payload;
        await createContact(contact);
      } else if (state.editContact && state.payload) {
        await updateContract(state.payload);
      }
      cancel();
    };

    return {
      ...toRefs(state),
      close,
      cancel,
      setEditMode,
      setNewMode,
      cancelDelete,
      deleteContactById,
      submit,
      loading,
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      fieldRules: [(v: string) => !!v || 'Field is required'],
    };
  },
});
