var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.props.nobbItem &&
      _vm.props.buildingItem.NOBBNumber &&
      _vm.props.buildingItem.NOBBNumber === _vm.props.nobbItem.nobbNumber
  )?_c('tr',[_c('td',[_c('div',[_c('div',[_c('span',[_vm._v(_vm._s(_vm.props.buildingItem.Name))])]),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.props.nobbItem.primaryText)+" ")])])])]),_c('td',[_c('NobbItemCertificates',{attrs:{"environment-certificates":_vm.props.nobbItem.properties.environment}})],1),_c('td',[_c('ValidationDate',{attrs:{"epd":_vm.props.nobbItem.properties.epd}})],1),_c('td',[_c('EnvironmentImpact',{attrs:{"epd":_vm.props.nobbItem.properties.epd}})],1),_c('td',[_c('PriceUnit',{attrs:{"nobb-item":_vm.props.nobbItem,"building-item-unit":_vm.props.buildingItem.UnitForOrder}})],1),_c('td',[(_vm.props.buildingItem.AmountCalculated.value)?_c('div',[_vm._v(" "+_vm._s(_vm.props.buildingItem.AmountCalculated.value.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 2, } ))+" "),(_vm.props.buildingItem.Unit)?_c('span',[_vm._v(" "+_vm._s(_vm.props.buildingItem.Unit)+" ")]):_vm._e()]):_vm._e()]),_c('td',[(_vm.props.buildingItem.AmountOfMaterials.value)?_c('div',[_vm._v(" "+_vm._s(_vm.props.buildingItem.AmountOfMaterials.value.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 2, } ))+" "),(_vm.props.buildingItem.UnitForOrder)?_c('span',[_vm._v(" "+_vm._s(_vm.props.buildingItem.UnitForOrder)+" ")]):_vm._e()]):_vm._e()]),_c('td',[_c('CalculatedEnvironmentImpact',{attrs:{"epd":_vm.props.nobbItem.properties.epd,"building-item-amount-calculated":_vm.props.buildingItem.AmountOfMaterials.value,"price-unit":_vm.props.nobbItem.priceUnit,"building-item-unit":_vm.props.buildingItem.UnitForOrder}})],1),_c('td',[_c('v-menu',{staticClass:"action-menu",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var menu = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-menu",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2366626882)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.props.buildingItem.NOBBNumber)?_c('nobb-info',{attrs:{"nobbNr":_vm.props.buildingItem.NOBBNumber}}):_vm._e(),(_vm.props.nobbItem && _vm.props.nobbItem.properties.epd.length > 0)?_c('NobbItemEPDDialog',{attrs:{"nobb-item":_vm.props.nobbItem}}):_vm._e()],1)],1)],1)],1)]):_c('tr',{staticClass:"unimportant"},[_c('td',[_c('div',[_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('info.no-nobbno')))])]),_vm._v(" "+_vm._s(_vm.props.buildingItem.Name)+" ")],1)])]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])}
var staticRenderFns = []

export { render, staticRenderFns }