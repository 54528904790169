import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import {
  Contact,
  ContactMapping,
  ProjectContact,
} from '@/models/contacts/interfaces';
import { useSnackbar } from './snackbar';
import { CustomError } from '@/models/common/interfaces';
import { useErrorModal } from './error';
import { isSuccess } from '@/helpers/common';
import { useCalculation } from './calculation';

interface ContactsState {
  contacts?: Array<Contact>;
  projectContacts?: Array<Contact>;
  loading: boolean;
  error?: CustomError;
}

const state = reactive<ContactsState>({
  contacts: undefined,
  projectContacts: undefined,
  loading: false,
  error: undefined,
});

export const useContacts = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const getContacts = async (
    force?: boolean
  ): Promise<Array<Contact> | undefined> => {
    const { get } = useApi('/contact');
    if (state.contacts && !force) {
      return state.contacts;
    }
    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.contacts = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return state.contacts;
  };

  const existsAsCalculationContact = (
    contact: Contact,
    calcContacts: Array<ProjectContact>
  ): boolean => {
    const { projectCalculation } = useCalculation();
    let exists = false;
    if (!projectCalculation?.value?.Id) {
      return exists;
    }

    if (calcContacts) {
      const projContact = calcContacts.find(pc => pc.ContactId === contact.Id);

      if (projContact) {
        exists = true;
      }
    }

    return exists;
  };

  const existsAsPrimary = (
    contact: Contact,
    calcContacts: Array<ProjectContact>
  ): boolean => {
    const { projectCalculation } = useCalculation();

    let exists = false;
    if (!projectCalculation?.value?.Id) {
      return exists;
    }

    if (calcContacts) {
      const projContact = calcContacts.find(
        pc => pc.ContactId === contact.Id && pc.IsPrimaryContact
      );

      if (projContact) {
        exists = true;
      }
    }

    return exists;
  };

  const setAsPrimaryContact = async (contactId: string): Promise<void> => {
    const { projectCalculation } = useCalculation();

    if (!projectCalculation?.value?.Id) {
      return;
    }
    const { post } = useApi(
      `/project/${projectCalculation.value.Id}/SetAsPrimaryContact/${contactId}`
    );

    try {
      const response = await post();
      if (isSuccess(response.status)) {
        snack('snack.contact-primary-contact-changed', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
  };

  const removeContactFromCalculation = async (
    contact: Contact
  ): Promise<void> => {
    const { projectCalculation, getCalculationContacts } = useCalculation();

    if (!projectCalculation?.value?.Id) {
      return;
    }

    const { put } = useApi(`/project/${projectCalculation.value.Id}/contacts`);

    const contactMappings: Array<ContactMapping> = [];
    const calcContacts = await getCalculationContacts(
      projectCalculation.value.Id
    );

    if (calcContacts && calcContacts.length > 0) {
      calcContacts.forEach(pc => {
        if (pc.ContactId !== contact.Id) {
          const mapping: ContactMapping = {
            ProjectRole: pc.ProjectRole ?? '',
            ContactId: pc.ContactId ?? '',
            IsPrimaryContact: pc.IsPrimaryContact ?? false,
          };

          contactMappings.push(mapping);
        }
      });
    }

    try {
      const response = await put(contactMappings);
      if (isSuccess(response.status)) {
        snack('snack.contact-removed-calculation', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
  };

  const addContactToCalculation = async (contact: Contact): Promise<void> => {
    const { projectCalculation, getCalculationContacts } = useCalculation();

    if (!projectCalculation?.value?.Id) {
      return;
    }

    const { put } = useApi(`/project/${projectCalculation.value.Id}/contacts`);

    const contactMappings: Array<ContactMapping> = [];
    const calcContacts = await getCalculationContacts(
      projectCalculation.value.Id
    );

    if (calcContacts && calcContacts.length > 0) {
      calcContacts.forEach(pc => {
        const mapping: ContactMapping = {
          ProjectRole: pc.ProjectRole ?? '',
          ContactId: pc.ContactId ?? '',
          IsPrimaryContact: pc.IsPrimaryContact ?? false,
        };

        contactMappings.push(mapping);
      });
    }

    if (contact.Id) {
      const newContact: ContactMapping = {
        ProjectRole: 'Participant',
        ContactId: contact.Id,
        IsPrimaryContact: false,
      };

      contactMappings.push(newContact);
    }

    try {
      const response = await put(contactMappings);
      if (isSuccess(response.status)) {
        snack('snack.contact-added-calculation', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
  };

  const updateContract = async (updatedContact: Contact): Promise<void> => {
    const { put } = useApi('/contact/');

    state.loading = true;

    try {
      const response = await put(updatedContact);
      if (isSuccess(response.status) && state.contacts) {
        state.contacts.forEach(contact => {
          if (contact.Id === updatedContact.Id && state.contacts) {
            const index = state.contacts.indexOf(contact);
            state.contacts.splice(index, 1);
            state.contacts.unshift(updatedContact);
          }
        });
        snack('snack.contact-updated', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
  };

  const createContact = async (newContact: Contact): Promise<void> => {
    const { post } = useApi('/contact/');
    state.loading = true;

    try {
      const response = await post(newContact);
      if (isSuccess(response.status) && state.contacts) {
        state.contacts.unshift(response.data);
        snack('snack.contact-created', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
  };

  const deleteContact = async (contactId: string): Promise<void> => {
    const { deleteReq } = useApi(`/contact/${contactId}`);
    state.loading = true;

    try {
      const response = await deleteReq();
      if (isSuccess(response.status)) {
        state.contacts?.forEach(contact => {
          if (contact.Id === contactId && state.contacts) {
            const index = state.contacts.indexOf(contact);
            state.contacts?.splice(index, 1);
            snack('snack.contact-deleted', true);
          }
        });
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
  };

  return {
    createContact,
    updateContract,
    deleteContact,
    getContacts,
    setAsPrimaryContact,
    addContactToCalculation,
    existsAsCalculationContact,
    existsAsPrimary,
    removeContactFromCalculation,
    ...toRefs(state),
  };
};
