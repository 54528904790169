import { UNITS } from '@/constants/common';
import { defaultLocaleString, translate } from '@/localization';
import { Company } from '@/models/company/interfaces';
import { ProjectOverview } from '@/models/projects/interfaces';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const cleanSource = (source: Record<string, any>) => {
  return JSON.parse(JSON.stringify(source));
};

export const isStorageSupported = (): boolean => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const boolToYesNoLocaleKey = (yesno: boolean) => {
  if (yesno) {
    return 'common.yes';
  } else {
    return 'common.no';
  }
};

export const numberWithCommas = (number: string): string => {
  return number.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const getIconByProfessionCode = (code: string) => {
  switch (code) {
    case '05':
      return 'mdi-filter';
    case '09':
      return 'mdi-pickaxe';
    case '03':
      return 'mdi-tools';
    case '12':
      return 'mdi-hand-saw';
    case '01':
      return 'mdi-dump-truck';
    case '21':
      return 'mdi-format-paint';
    case '99':
      return 'mdi-account-group';
    default:
      return 'mdi-test';
  }
};

export const getNameByProfessionCode = (code: string) => {
  switch (code) {
    case '05':
      return `${translate('factors.name-concrete-work')}`;
    case '09':
      return `${translate('factors.name-masonry')}`;
    case '03':
      return `${translate('factors.name-groundwork')}`;
    case '12':
      return `${translate('factors.name-carpentry')}`;
    case '01':
      return `${translate('factors.name-rigs')}`;
    case '21':
      return `${translate('factors.name-painter-work')}`;
    case '99':
      return `${translate('factors.name-subcontractors')}`;
    default:
      return `${translate('factors.name-unknown')}`;
  }
};

export const convertUnit = (unit: string) => {
  switch (unit) {
    case 'm³':
      return 'm3';
    case 'M3':
      return 'm3';
    case 'm²':
      return 'm2';
    case 'M2':
      return 'm2';
    case 'KG':
      return 'kg';
    case 'Kg':
      return 'kg';
    default:
      return unit;
  }
};

export const areUnitsEqual = (unit1: string, unit2: string) => {
  const unit = unit1.replace('.', '').trimEnd();
  const priceListUnit = unit2.replace('.', '').trimEnd();

  const synonyms: string[][] = [
    ['m', 'lm'],
    ['esk', 'pak', 'pk'],
    ['rull', 'rul', 'rl'],
  ];

  synonyms.forEach(group => {
    if (group.includes(unit) && group.includes(priceListUnit)) return true;
  });

  return unit.toUpperCase() === priceListUnit.toUpperCase();
};

export const convertFloat = (value: string | undefined): number | undefined => {
  if (value) {
    return parseFloat(value.replace(/,/g, '.'));
  } else {
    return undefined;
  }
};

export const isSuccess = (status: number): boolean => {
  return status >= 200 && status <= 299;
};

export const roundToTwoDecimals = (num: string | number | undefined) => {
  let value = 0;

  if (!num) {
    return 0.0;
  }

  if (typeof num === 'string') {
    try {
      value = parseInt(num);
    } catch (e) {
      console.log(`number ${num} parse failed.`);
    }
  } else {
    value = num;
  }

  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const createAddressString = (company: Company | undefined): string => {
  let text = '';

  if (!company) return text;
  if (company.AddressLine1) text += company.AddressLine1;
  if (company.PostOffice || company.PostNumber) {
    text += ', ';
    if (company.PostOffice && !company.PostNumber) {
      text += company.PostOffice;
    } else if (company.PostNumber && !company.PostOffice) {
      text += company.PostNumber;
    } else if (company.PostNumber && company.PostOffice) {
      text += `${company.PostNumber} -${company.PostOffice}`;
    }
  }

  return text;
};

export const roundToOneDecimal = (num: string | number | undefined): number => {
  let value = 0;

  if (!num) {
    return 0.0;
  }

  if (typeof num === 'string') {
    try {
      value = parseInt(num);
    } catch (e) {
      console.log(`number ${num} parse failed.`);
    }
  } else {
    value = num;
  }

  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const roundTotal = (calculations: Array<ProjectOverview>): number => {
  let val = 0;
  calculations.forEach(calc => {
    val += calc.Total;
  });

  return roundToTwoDecimals(val);
};

export const download = (path: string, filename: string) => {
  // Create a new link
  if ('download' in document.createElement('a')) {
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  }
};

export const getGuidFromPath = (path: string): string => {
  return path.replace('https://cdn.byggtjeneste.no/nobb/', '');
};

export const unitDescriptionConverter = (unit: string): string => {
  let converted = UNITS.get(unit.toLocaleUpperCase());

  if (!converted) {
    converted = unit.toLocaleLowerCase();
  }

  return converted;
};

export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

export const numberToAbsStringOrEmpty = (
  num: number | undefined | null,
  decimal: number
): string => {
  return num != null && num >= 0
    ? num.toLocaleString(defaultLocaleString, {
        maximumFractionDigits: decimal,
      })
    : num != null && num < 0
    ? `-${Math.abs(num).toLocaleString(defaultLocaleString, {
        maximumFractionDigits: decimal,
      })}`
    : '';
};

export const precision = (value: number, precision: number): number => {
  const pow = Math.pow(10, precision);

  return Math.round(value * pow) / pow;
};
