

































import { ref, PropType, defineComponent } from '@vue/composition-api';
import { User } from '@/models/auth/interfaces';
import { useUsers } from '@/modules/users';
import Confirm from '@/components/common/confirm/index.vue';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup({ user }) {
    const { loading, deleteUser } = useUsers();
    const valid = ref(false);
    const dialog = ref(false);

    valid.value = user && user.Id !== null && user.Id !== '';

    const deleteEvent = async () => {
      await deleteUser(user);
    };

    return {
      loading,
      valid,
      dialog,
      deleteEvent,
    };
  },
});
