












import { defineComponent } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { usePriceBook } from '@/modules/pricebook';

export default defineComponent({
  components: {
    DialogTitle,
  },
  setup() {
    const { uploadingPriceList, statusText, priceListName } = usePriceBook();

    return {
      uploadingPriceList,
      statusText,
      priceListName,
    };
  },
});
