export const primaryColor = '#263238';
export const secondaryColor = '#58a333';
export const secondaryVariantDarkColor = '#58a333';
export const secondaryVariantLightColor = '#58a333';
export const errorRed = '#d50000';

export const DEFAULT_TEXT_MINI = 5;
export const DEFAULT_TEXT_XSMALL = 6;
export const DEFAULT_TEXT_SMALL = 7;
export const DEFAULT_TEXT_XMEDIUM = 11;
export const DEFAULT_TEXT_MEDIUM = 10;
export const DEFAULT_TEXT_LARGE = 12;

export const LARGE_TEXT_MINI = 7;
export const LARGE_TEXT_XSMALL = 8;
export const LARGE_TEXT_SMALL = 9;
export const LARGE_TEXT_XMEDIUM = 13;
export const LARGE_TEXT_MEDIUM = 12;
export const LARGE_TEXT_LARGE = 14;
