
















































































































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from '@vue/composition-api';
import { useLibrary } from '@/modules/library';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import {
  BUILDING_ITEM_HEADERS_SIMPLE_ALT1,
  LIBRARY__HEADERS,
} from '@/constants/table-headers';
import {
  cleanSource,
  getIconByProfessionCode,
  getNameByProfessionCode,
} from '@/helpers/common';
import Professions from '@/components/profession/index.vue';
import { useProject } from '@/modules/project';
import { Profession } from '@/models/common/interfaces';
import moment from 'moment';
import ExportTemplate from '@/components/library/export-template/index.vue';
import Loader from '@/components/loader/index.vue';
import DeleteTemplate from '@/components/library/delete-template/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import ImportTemplate from '@/components/library/import-template/index.vue';
import DownloadTemplate from '@/components/library/download-template/index.vue';
import NewTemplate from '@/components/library/new-template/index.vue';

export default defineComponent({
  components: {
    Professions,
    ExportTemplate,
    Loader,
    DeleteTemplate,
    ContentBar,
    ImportTemplate,
    DownloadTemplate,
    NewTemplate,
  },
  setup() {
    const { getAllBuildingTemplates, getBuildingTemplate } = useLibrary();
    const { getProfessions } = useProject();

    const search = ref<string>();
    const templates = ref<Array<BuildingTypeTemplate>>();
    const loading = ref<boolean>(false);
    const professions = ref<Array<Profession>>();
    const professionCode = ref<string>();
    const fetchingFull = ref<boolean>(false);

    const currentProfession = computed(() => {
      if (professions.value) {
        return professions.value.find(
          prof => prof.Code === professionCode.value
        );
      }
    });

    const initTemplates = async () => {
      loading.value = true;
      templates.value = await getAllBuildingTemplates(true);
      professions.value = await getProfessions();
      if (!professionCode.value) {
        professionCode.value = '01';
      }
      loading.value = false;
    };

    onMounted(async () => {
      await initTemplates();
    });

    const getTemplateFull = async (template: BuildingTypeTemplate) => {
      if (template.Id) {
        fetchingFull.value = true;
        const templateFull = await getBuildingTemplate(template.Id);

        if (templateFull && templates.value) {
          const temp = templates.value.find(t => t.Id === templateFull.Id);
          if (temp) {
            temp.BuildingElementTemplates =
              templateFull.BuildingElementTemplates;
          }
        }
        fetchingFull.value = false;
      }
    };

    const filteredTemplates = computed(() => {
      let _templates: Array<BuildingTypeTemplate> = cleanSource(
        templates.value ?? []
      );
      if (templates?.value && professionCode.value) {
        _templates = _templates.filter(
          template => professionCode.value === template.ProfessionCode
        );

        if (search.value) {
          _templates = _templates.filter(
            temp =>
              (temp.Name &&
                search.value &&
                temp.Name.toUpperCase().includes(search.value.toUpperCase())) ||
              temp.BuildingElementTemplates.find(
                bet =>
                  bet.Name &&
                  search.value &&
                  bet.Name.toUpperCase().includes(search.value.toUpperCase())
              )
          );
        }
      }
      return _templates;
    });

    const getLibraryCount = (profCode: string): number | undefined => {
      let count: number | undefined = undefined;
      if (templates.value) {
        const filtered = templates.value.filter(
          temp => temp.ProfessionCode === profCode
        );
        count = filtered.length;
      }

      return count;
    };

    return {
      getIconByProfessionCode,
      getNameByProfessionCode,
      getLibraryCount,
      initTemplates,
      getTemplateFull,
      fetchingFull,
      search,
      LIBRARY__HEADERS,
      BUILDING_ITEM_HEADERS_SIMPLE_ALT1,
      filteredTemplates,
      loading,
      professions,
      moment,
      professionCode,
      currentProfession,
    };
  },
});
