import { translate } from '@/localization';
import { DataTableHeader } from '@/models/common/interfaces';

export const BUILDING_ELEMENT_HEADERS_CALCULATION: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: '',
    value: 'palette',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: 'DeliveryOrder',
    value: 'DeliveryOrder',
    align: 'center',
    class: 'header-cell',
    width: '5%',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'Name',
    value: 'Name',
    class: 'header-cell',
    width: '15%',
    sortable: false,
    cellClass: 'cell-color',
  },
  {
    text: '',
    value: 'Precut',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: '',
    value: 'warnings',
    class: 'header-cell',
    width: '2%',
    sortable: false,
    cellClass: 'cell-color',
  },
  {
    text: '',
    value: 'Status',
    class: 'header-cell',
    width: '5%',
    align: 'end',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'BaseTime',
    value: 'BaseTime',
    class: 'header-cell',
    width: '7%',
    align: 'end',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'SumTime',
    value: 'SumTime',
    class: 'header-cell',
    width: '5%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'Unit',
    value: 'Unit',
    class: 'header-cell',
    width: '5%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'Amount',
    value: 'Amount',
    class: 'header-cell',
    width: '5%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'AmountAdjustment',
    value: 'AmountAdjustment',
    class: 'header-cell',
    width: '5%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'AmountPrUnit',
    value: 'AmountPrUnit',
    class: 'header-cell',
    width: '5%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'UnitForOrder',
    value: 'UnitForOrder',
    class: 'header-cell',
    width: '5%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'Price',
    value: 'Price',
    class: 'header-cell',
    width: '5%',
    align: 'end',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'AmountOfMaterials',
    value: 'AmountOfMaterials',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'UnitPriceWithAdditions',
    value: 'UnitPriceWithAdditions',
    class: 'header-cell',
    width: '5%',
    align: 'end',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'SumTotalWithAdditions',
    value: 'SumTotalWithAdditions',
    class: 'header-cell',
    width: '5%',
    align: 'end',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: '',
    value: 'Comments',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-small',
  },
  {
    text: '',
    value: 'Actions',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-small',
  },
];

export const BUILDING_ELEMENT_HEADERS_HOURLY_CONSUMPTION: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'header-cell',
    cellClass: 'cell-color',
    sortable: false,
  },
  {
    text: '',
    value: 'Precut',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: 'MileStone',
    value: 'MileStone',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-small',
    sortable: false,
  },
  {
    text: 'BaseTime',
    value: 'BaseTime',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },

  {
    text: 'OpeningAddition',
    value: 'OpeningAddition',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SurfaceAddition',
    value: 'SurfaceAddition',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Factor1',
    value: 'Factor1',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'TimePrUnit',
    value: 'TimePrUnit',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumTime',
    value: 'SumTime',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'Unit',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Amount',
    value: 'Amount',
    class: 'header-cell',
    width: '5%',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: '',
    value: 'Actions',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
];

export const BUILDING_ELEMENT_HEADERS_ECONOMY: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'SumTime',
    value: 'SumTime',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumProductionSalary',
    value: 'SumProductionSalary',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumSalaryAndSocialCost',
    value: 'SumSalaryAndSocialCost',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'header-cell',
    cellClass: 'cell-color',
    sortable: false,
  },
  {
    text: '',
    value: 'Precut',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: 'Amount',
    value: 'Amount',
    width: '7%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'UnitForOrder',
    value: 'UnitForOrder',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'SumSelfCostMaterialsSubContractors',
    value: 'SumSelfCostMaterialsSubContractors',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'ProjectCost',
    value: 'ProjectCost',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Factor4',
    value: 'Factor4',
    width: '7%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Factor6',
    value: 'Factor6',
    width: '7%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Factor7',
    value: 'Factor7',
    width: '7%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumMargin',
    value: 'SumMargin',
    width: '7%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumTotalWithAdditions',
    value: 'SumTotalWithAdditions',
    width: '7%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: '',
    value: 'Comments',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: '',
    value: 'Actions',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
];

export const BUILDING_ELEMENT_HEADERS_MATERIALS: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'DeliveryOrder',
    value: 'DeliveryOrder',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'header-cell',
    cellClass: 'cell-color',
    sortable: false,
  },
  {
    text: '',
    value: 'Precut',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'Unit',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Amount',
    value: 'Amount',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'AmountAdjustment',
    value: 'AmountAdjustment',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'AmountPrUnit',
    value: 'AmountPrUnit',
    class: 'header-cell',
    width: '5%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'UnitForOrder',
    value: 'UnitForOrder',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Price',
    value: 'Price',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'AmountOfMaterials',
    value: 'AmountOfMaterials',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'UnitPriceMaterials',
    value: 'UnitPriceMaterials',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Factor7',
    value: 'Factor7',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumMaterialsUE',
    value: 'SumMaterialsUE',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: '',
    value: 'Comments',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: '',
    value: 'Actions',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
];

export const BUILDING_ELEMENT_HEADERS_SUBCONTRACTORS: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'header-cell',
    width: '2%',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'DeliveryOrder',
    value: 'DeliveryOrder',
    width: '3%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'header-cell',
    cellClass: 'cell-color-small',
    sortable: false,
  },
  {
    text: '',
    value: 'Precut',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: 'Unit',
    value: 'Unit',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Amount',
    value: 'Amount',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Price',
    value: 'Price',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Factor7',
    value: 'Factor7',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumMaterialsUE',
    value: 'SumMaterialsUE',
    width: '6%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: '',
    value: 'Comments',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: '',
    value: 'Actions',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
];

export const BUILDING_ELEMENT_HEADERS_DELIVERY: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'header-cell',
    width: '2%',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: 'DeliveryOrder',
    value: 'DeliveryOrder',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'MileStone',
    value: 'MileStone',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'header-cell',
    cellClass: 'cell-color',
    sortable: false,
  },
  {
    text: '',
    value: 'Precut',
    class: 'header-cell',
    width: '1%',
    sortable: false,
    align: 'center',
    cellClass: 'cell-color-medium',
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'PriceListName',
    value: 'PriceListName',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'UnitForOrder',
    value: 'UnitForOrder',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'AmountOfMaterials',
    value: 'AmountOfMaterials',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Price',
    value: 'Price',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumTotalWithAdditions',
    value: 'SumTotalWithAdditions',
    width: '5%',
    class: 'header-cell',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: '',
    value: 'Comments',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
  {
    text: '',
    value: 'Actions',
    class: 'header-cell',
    width: '2%',
    align: 'center',
    sortable: false,
    cellClass: 'cell-color-medium',
  },
];

export const BUILDING_ELEMENT_HEADERS_SIMPLE: Array<DataTableHeader> = [
  {
    text: '',
    value: 'data-table-expand',
    width: '2%',
    class: 'element-header',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'element-header',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'Unit',
    width: '6%',
    class: 'element-header',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'actions',
    width: '6%',
    class: 'element-header',
    sortable: false,
    align: 'end',
    divider: true,
  },
];

export const BUILDING_ELEMENT_HEADERS_SIMPLE_ALT1: Array<DataTableHeader> = [
  {
    text: '',
    value: 'data-table-expand',
    width: '2%',
    class: 'element-header2',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'element-header2',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'Unit',
    width: '6%',
    class: 'element-header2',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'actions',
    width: '6%',
    class: 'element-header2',
    sortable: false,
    align: 'end',
  },
];

export const BUILDING_ELEMENT_HEADERS_SIMPLE_ALT2: Array<DataTableHeader> = [
  {
    text: '',
    value: 'data-table-expand',
    width: '2%',
    class: 'element-header3',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'element-header3',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'Unit',
    width: '6%',
    class: 'element-header3',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'actions',
    width: '6%',
    class: 'element-header3',
    sortable: false,
    align: 'end',
  },
];

export const BUILDING_ITEM_HEADERS_SIMPLE: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'item-header',
    width: '1%',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '35%',
    class: 'item-header',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'Unit',
    class: 'item-header',
    sortable: false,
    align: 'center',
  },
  {
    text: 'BaseTime',
    value: 'BaseTime',
    class: 'item-header',
    sortable: false,
    align: 'center',
  },
  {
    text: 'Price',
    value: 'Price',
    class: 'item-header',
    sortable: false,
    align: 'center',
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    class: 'item-header',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'actions',
    class: 'item-header',
    sortable: false,
    width: '15%',
    align: 'end',
  },
];

export const BUILDING_ITEM_HEADERS_SIMPLE_ALT: Array<DataTableHeader> = [
  {
    text: '',
    value: 'sort-drag',
    class: 'item-header2',
    width: '1%',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '35%',
    class: 'item-header2',
    sortable: false,
    align: 'start',
  },
  {
    text: 'Unit',
    value: 'Unit',
    class: 'item-header2',
    sortable: false,
    align: 'center',
  },
  {
    text: 'BaseTime',
    value: 'BaseTime',
    class: 'item-header2',
    sortable: false,
    align: 'center',
  },
  {
    text: 'Price',
    value: 'Price',
    class: 'item-header2',
    sortable: false,
    align: 'center',
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    class: 'item-header2',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'actions',
    class: 'item-header2',
    sortable: false,
    width: '15%',
    align: 'end',
  },
];

export const BUILDING_ITEM_HEADERS_SIMPLE_ALT1: Array<DataTableHeader> = [
  {
    text: 'Name',
    value: 'Name',
    width: '35%',
    class: 'item-header3',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'Unit',
    class: 'item-header3',
    sortable: false,
    align: 'center',
  },
  {
    text: 'BaseTime',
    value: 'BaseTime',
    class: 'item-header3',
    sortable: false,
    align: 'center',
  },
  {
    text: 'Price',
    value: 'Price',
    class: 'item-header3',
    sortable: false,
    align: 'center',
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    class: 'item-header3',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'actions',
    class: 'item-header3',
    sortable: false,
    width: '15%',
    align: 'end',
  },
];

export const BUILDING_ITEM_HEADERS_SIMPLE_ALT2: Array<DataTableHeader> = [
  {
    text: 'Name',
    value: 'Name',
    width: '45%',
    class: 'item-header4',
    sortable: false,
  },
  {
    text: 'Unit',
    value: 'UnitForOrder',
    class: 'item-header4',
    sortable: false,
    align: 'center',
  },
  {
    text: 'Price',
    value: 'Price',
    class: 'item-header4',
    sortable: false,
    align: 'center',
  },
  {
    text: 'PriceListName',
    value: 'PriceListName',
    class: 'item-header4',
    sortable: false,
    align: 'center',
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    class: 'item-header4',
    sortable: false,
    align: 'center',
  },
];

export const PROJECT_HEADERS: Array<DataTableHeader> = [
  {
    text: 'Profession',
    value: 'calculations',
    width: '6%',
    class: 'project-header',
    align: 'center',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '18%',
    class: 'project-header',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'ProjectNumber',
    value: 'ProjectNumber',
    width: '8%',
    class: 'project-header',
    align: 'center',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'Shared',
    value: 'Shared',
    width: '3%',
    class: 'project-header',
    align: 'center',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'OwnedByName',
    value: 'OwnedByName',
    width: '10%',
    class: 'project-header',
    align: 'center',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'LastModified',
    value: 'LastModified',
    width: '10%',
    class: 'project-header',
    align: 'center',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'LastModifiedByName',
    value: 'LastModifiedByName',
    width: '10%',
    class: 'project-header',
    align: 'center',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'Total',
    value: 'Total',
    width: '6%',
    class: 'project-header',
    align: 'end',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'actions',
    value: 'actions',
    width: '15%',
    class: 'project-header',
    align: 'end',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
];

export const CALCULATION_HEADERS_SMALL: Array<DataTableHeader> = [
  {
    text: 'profession',
    value: 'calculations',
    width: '6%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'ProjectNumber',
    value: 'ProjectNumber',
    width: '4%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '15%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    sortable: false,
  },
  {
    text: 'Shared',
    value: 'Shared',
    width: '4%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
  {
    text: 'SumHours',
    value: 'ProjectTotals.Hours',
    width: '6%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SelfCostMaterialsSubContractors',
    value: 'ProjectTotals.SelfCostMaterialsSubContractors',
    width: '6%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'SumMargin',
    value: 'ProjectTotals.SumMargin',
    width: '6%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Profit',
    value: 'ProjectTotals.Profit',
    width: '6%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Total',
    value: 'ProjectTotals.Total',
    width: '6%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'end',
    sortable: false,
  },
  {
    text: 'actions',
    value: 'actions',
    width: '15%',
    class: 'calculation-header',
    cellClass: 'cell-color-medium',
    align: 'center',
    sortable: false,
  },
];

export const CALCULATION_BY_PROFESSION: Array<DataTableHeader> = [
  {
    text: '',
    value: 'calculations',
    align: 'center',
    sortable: false,
  },
  {
    text: 'ProjectNumber',
    value: 'ProjectNumber',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    sortable: false,
  },
  {
    text: '',
    value: 'actions',
    align: 'end',
    sortable: false,
  },
];

export const ESTIMATE_HEADERS: Array<DataTableHeader> = [
  {
    text: 'NS3420',
    value: 'NS3420',
    class: 'estimate-header',
    cellClass: 'default',
  },
  {
    text: 'Name',
    value: 'Name',
    class: 'estimate-header',
    cellClass: 'default',
  },
  {
    text: 'Unit',
    value: 'Unit',
    class: 'estimate-header',
    cellClass: 'default',
  },
  {
    text: 'BaseTime',
    value: 'BaseTime',
    class: 'estimate-header',
    cellClass: 'default',
  },
  {
    text: 'OpeningAddition',
    value: 'OpeningAddition',
    class: 'estimate-header',
    cellClass: 'default',
  },
  {
    text: 'SurfaceAddition',
    value: 'SurfaceAddition',
    class: 'estimate-header',
    cellClass: 'default',
  },
  {
    text: 'TransportAddition',
    value: 'TransportAddition',
    class: 'estimate-header',
    cellClass: 'default',
  },
  {
    text: '',
    value: 'actions',
    class: 'estimate-header',
    cellClass: 'default',
    align: 'end',
    sortable: false,
  },
];

export const LIBRARY_ELEMENT_HEADERS_SIMPLE: Array<DataTableHeader> = [
  {
    text: '',
    value: 'data-table-select',
    class: 'element-main-header',
    width: '3%',
    cellClass: 'default',
  },
  {
    text: '',
    value: 'profession',
    width: '3%',
    class: 'element-main-header',
    cellClass: 'default',
    align: 'center',
  },
  {
    text: 'Library',
    value: 'library',
    width: '15%',
    class: 'element-main-header',
    cellClass: 'default',
  },
  {
    text: 'Building elements',
    value: 'Name',
    width: '30%',
    class: 'element-main-header',
    cellClass: 'big',
  },
  {
    text: 'Unit (NS 3420)',
    value: 'Unit',
    width: '6%',
    class: 'element-main-header',
    cellClass: 'default',
  },
];

export const LIBRARY__HEADERS: Array<DataTableHeader> = [
  {
    text: '',
    value: 'profession-icon',
    width: '1%',
    class: 'element-main-header',
    cellClass: 'default',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Name',
    value: 'Name',
    width: '30%',
    class: 'element-main-header',
    cellClass: 'big',
    sortable: false,
  },
  {
    text: 'Created',
    value: 'Created',
    width: '6%',
    class: 'element-main-header',
    cellClass: 'default',
    sortable: false,
  },
  {
    text: 'LastModified',
    value: 'LastModified',
    width: '6%',
    class: 'element-main-header',
    cellClass: 'default',
    sortable: false,
  },
  {
    text: 'ImportTime',
    value: 'ImportTime',
    width: '6%',
    class: 'element-main-header',
    cellClass: 'default',
    sortable: false,
  },
  {
    text: '',
    value: 'actions',
    width: '6%',
    class: 'element-main-header',
    cellClass: 'default',
    sortable: false,
  },
];

export const PRODUCT_HEADERS: Array<DataTableHeader> = [
  {
    text: 'EAN13ItemNumber',
    value: 'EAN13ItemNumber',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'ItemNumber',
    value: 'ItemNumber',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'ItemText',
    value: 'ItemText',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'BasePrice',
    value: 'BasePrice',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'RecommendedPrice',
    value: 'RecommendedPrice',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'UnitDesc',
    value: 'UnitDesc',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'ItemText2',
    value: 'ItemText2',
    class: 'product-header',
    cellClass: 'product-value',
  },
  {
    text: 'PackageUnitDesc',
    value: 'PackageUnitDesc',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'ItemsInPackage',
    value: 'ItemsInPackage',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'BuyingPrice',
    value: 'BuyingPrice',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'Discount',
    value: 'Discount',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'ItemNumberNOBB',
    value: 'ItemNumberNOBB',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'ItemOwnerNumber',
    value: 'ItemOwnerNumber',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
  {
    text: 'ModuleNumber',
    value: 'ModuleNumber',
    class: 'product-header',
    cellClass: 'product-value',
    sortable: false,
  },
];

export const PRICELIST_ITEM_HEADERS: Array<DataTableHeader> = [
  {
    text: 'Name',
    value: 'Name',
    class: 'item-header',
    cellClass: 'item-value',
    width: '35%',
    sortable: false,
  },
  {
    text: 'ItemUnit',
    value: 'Unit',
    class: 'item-header',
    cellClass: 'item-value',
    sortable: false,
  },
  {
    text: 'ItemPrice',
    value: 'Price',
    class: 'item-header',
    cellClass: 'item-value',
    sortable: false,
  },
  {
    text: 'DiscountPercentage',
    value: 'DiscountPercentage',
    class: 'item-header',
    cellClass: 'item-value',
    sortable: false,
  },
  {
    text: 'BasePrice',
    value: 'BasePrice',
    class: 'item-header',
    cellClass: 'item-value',
    sortable: false,
  },
  {
    text: 'ItemNumber',
    value: 'ItemNumber',
    class: 'item-header',
    cellClass: 'item-value',
    sortable: false,
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    class: 'item-header',
    cellClass: 'item-value',
    sortable: false,
  },
  {
    text: '',
    value: 'actions',
    width: '4%',
    class: 'item-header',
    cellClass: 'item-value',
    sortable: false,
  },
];

export const PRICELIST_ITEM_HEADERS_ALT: Array<DataTableHeader> = [
  {
    text: 'Name',
    value: 'Name',
    class: 'item-header2',
    width: '35%',
    sortable: false,
  },
  {
    text: 'ItemUnit',
    value: 'Unit',
    class: 'item-header2',
    sortable: false,
  },
  {
    text: 'ItemPrice',
    value: 'Price',
    class: 'item-header2',
    sortable: false,
  },
  {
    text: 'DiscountPercentage',
    value: 'DiscountPercentage',
    class: 'item-header2',
    sortable: false,
  },
  {
    text: 'BasePrice',
    value: 'BasePrice',
    class: 'item-header2',
    sortable: false,
  },
  {
    text: 'NOBBNumber',
    value: 'NOBBNumber',
    class: 'item-header2',
    sortable: false,
  },
  {
    text: '',
    value: 'actions',
    width: '4%',
    class: 'item-header2',
    sortable: false,
  },
];

export const CATEGORY_HEADERS: Array<DataTableHeader> = [
  {
    text: 'CategoryName',
    value: 'CategoryName',
    class: 'category-header',
    cellClass: 'category-value',
    sortable: false,
  },
  {
    text: 'CategoryProductLength',
    value: 'CategoryProductLength',
    class: 'category-header',
    cellClass: 'category-value',
    sortable: false,
  },
];

export const BUILDING_ITEM_PRICE_UPDATE: Array<DataTableHeader> = [
  {
    value: 'data-table-select',
    align: 'center',
    sortable: false,
  },
  {
    text: `${translate('headers.name')}`,
    value: 'Name',
    sortable: false,
    align: 'start',
  },
  {
    text: `${translate('headers.status')}`,
    value: 'Status',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.product')}`,
    value: 'Product',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.existing-price')}`,
    value: 'Price',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.new-price')}`,
    value: 'NewPrice',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.discount')}`,
    value: 'Discount',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.baseprice')}`,
    value: 'BasePrice',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.amountprunit')}`,
    value: 'AmountPrUnit',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.price-diff')}`,
    value: 'Diff',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.existing-unit')}`,
    value: 'Unit',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.new-unit')}`,
    value: 'NewUnit',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.pricelistname')}`,
    value: 'PriceListName',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.nobbnumber')}`,
    value: 'NOBBNumber',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'Actions',
    sortable: false,
    align: 'center',
  },
];

export const BUILDING_ITEM_PRODUCT: Array<DataTableHeader> = [
  {
    value: 'data-table-select',
    align: 'center',
    sortable: false,
  },
  {
    text: `${translate('headers.product-text')}`,
    value: 'Name',
    sortable: false,
    align: 'start',
  },
  {
    text: `${translate('headers.unit')}`,
    value: 'Unit',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.product-price')}`,
    value: 'Price',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.discountpercentage')}`,
    value: 'DiscountPercentage',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.baseprice')}`,
    value: 'BasePrice',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.nobbnumber')}`,
    value: 'NobbNumber',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.nobb-module')}`,
    value: 'NobbModuleNumber',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.itemnumber')}`,
    value: 'ItemNumber',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.categoryname')}`,
    value: 'CategoryName',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.pricelistname')}`,
    value: 'PriceListName',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.nobbtext')}`,
    value: 'NobbText',
    sortable: false,
    align: 'center',
  },
  {
    text: `${translate('headers.nobbkeywords')}`,
    value: 'NobbKeywords',
    sortable: false,
    align: 'center',
  },
  {
    text: '',
    value: 'Actions',
    sortable: false,
    align: 'center',
  },
];
