import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { Company } from '@/models/company/interfaces';
import { useSnackbar } from '@/modules/snackbar';
import { useErrorModal } from './error';
import { CustomError } from '@/models/common/interfaces';
import { isSuccess } from '@/helpers/common';

interface CompanyState {
  company?: Company;
  loading: boolean;
  error?: CustomError;
}

const state = reactive<CompanyState>({
  company: undefined,
  loading: false,
  error: undefined,
});

export const useCompany = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const getCompany = async (force?: boolean): Promise<Company | undefined> => {
    const { get } = useApi('/company');
    if (state.company && !force) {
      return state.company;
    }
    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.company = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return state.company;
  };

  const updateCompany = async (updatedCompany: Company): Promise<void> => {
    const { put } = useApi('/company/save');

    state.loading = true;

    try {
      const response = await put(updatedCompany);
      if (isSuccess(response.status)) {
        state.company = response.data;
        snack('snack.company-updated', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
  };

  const getLogo = async (): Promise<string> => {
    if (!state.company) {
      await getCompany();
    }
    return state.company?.Logo ?? '';
  };

  const setBaseUrl = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        let baseString: string;
        reader.onloadend = evt => {
          if (evt.target && evt.target.readyState == FileReader.DONE) {
            baseString = reader.result as string;
            if (state.company) {
              const base = baseString.substring(
                baseString.indexOf('base64,') + 'base64,'.length,
                baseString.length
              );
              state.company.Logo = base;
              resolve(base);
            }
          }
        };
        reader.readAsDataURL(file);
      } catch (e) {
        reject(e);
      }
    });
  };

  const uploadLogo = async (file: File): Promise<boolean> => {
    const { postMultipart } = useApi('/company/logo');

    const formData = new FormData();
    formData.append('file', file);

    let updated = false;

    try {
      await postMultipart(formData);
      await setBaseUrl(file);
      snack('snack.logo-updated', true);
      updated = true;
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return updated;
  };

  return {
    getCompany,
    updateCompany,
    getLogo,
    uploadLogo,
    ...toRefs(state),
  };
};
