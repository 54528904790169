export const blobToString = async (blob: Blob) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = ev => {
      if (ev?.target?.result) {
        resolve(ev.target.result);
      } else {
        reject;
      }
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
};

export const createCategoryFileName = (
  categoryId: string,
  categoryName: string
) => {
  const asteriskCharThatDoesNotChangeInUrlEncodeButIsIllegalAsFileNameOnDisk =
    '*';
  categoryName = categoryName.replace(
    asteriskCharThatDoesNotChangeInUrlEncodeButIsIllegalAsFileNameOnDisk,
    ' '
  );
  return `category@${categoryId}@${encodeURIComponent(categoryName)}.json.gz`;
};

export const getPriceListBlobNamePrefix = (priceListId: string) => {
  return `pricelist@${priceListId}/`;
};

export const getCategoryNameFromUri = (uri: string) => {
  const indexOf = uri.lastIndexOf('@') + 1;
  let categoryName = uri.substr(
    indexOf,
    uri.length - indexOf - '.json.gz'.length
  );

  categoryName = categoryName.replace(/\+/g, '%20');
  categoryName = decodeURIComponent(categoryName);
  return categoryName;
};

export const getCategoryIdFromBlobUri = (uri: string) => {
  const indexOf = uri.indexOf('category@');
  const categoryIdAsString = uri.substr(indexOf + 'category@'.length, 36);
  return categoryIdAsString;
};
