export enum Locales {
  EN = 'en',
  NO = 'no',
  FO = 'fo',
}

export interface Language {
  value: Locales;
  caption: string;
}

export const LOCALES: Array<Language> = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.NO, caption: 'Norsk' },
  { value: Locales.FO, caption: 'Føroyskt' },
];
