


































































































































































































import { defineComponent, onMounted } from '@vue/composition-api';
import Subscriptions from '@/components/home/subscriptions/index.vue';
import router from '@/router/index';

export default defineComponent({
  components: {
    Subscriptions,
  },
  setup() {
    onMounted(() => {
      const hash = router?.currentRoute?.hash;

      if (hash) {
        const anchor = document.querySelector(hash);
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
    return {
      policyUrl: process.env.VUE_APP_POLICY_URL,
      termsUrl: process.env.VUE_APP_TERMS_URL,
      supportUrl: process.env.VUE_APP_SUPPORT_URL,
      documentationUrl: process.env.VUE_APP_DOCUMENTATION_URL,
    };
  },
});
