



































































































































































































import { ref, defineComponent, onMounted } from '@vue/composition-api';
import { cleanSource } from '@/helpers/common';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { ProjectDetails } from '@/models/projects/interfaces';
import { useProject } from '@/modules/project';
import { translate } from '@/localization';

const valid = ref<boolean>(false);

export default defineComponent({
  components: {
    DialogTitle,
  },
  setup(props, { emit }) {
    const {
      saveDetails,
      activeProjectOverview,
      getActiveProjectDetails,
    } = useProject();
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const payload = ref<ProjectDetails>({ Shared: true });

    const initDetails = async () => {
      if (activeProjectOverview?.value?.Id) {
        const details = await getActiveProjectDetails(
          activeProjectOverview.value.Id
        );
        if (details) payload.value = cleanSource(details);
      }
    };

    onMounted(async () => {
      await initDetails();
    });

    const close = async () => {
      await initDetails();
      dialog.value = false;
    };

    const submit = async () => {
      loading.value = true;
      if (payload.value.Id) {
        await saveDetails(payload.value.Id, payload.value);
        await close();
        emit('updated', true);
      }
      loading.value = false;
    };

    return {
      submit,
      close,
      loading,
      valid,
      payload,
      dialog,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
        min: (v: string) =>
          (v && v.length >= 8) ||
          `${translate('validation.minimum-characters', [8])}`,
      },
    };
  },
});
