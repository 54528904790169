






import LoginForm from '@/components/forms/login-form/index.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Login',
  components: {
    LoginForm,
  },
});
