import { render, staticRenderFns } from "./NobbItemCertificates.vue?vue&type=template&id=23d5bcbc&scoped=true&"
import script from "./NobbItemCertificates.vue?vue&type=script&lang=ts&"
export * from "./NobbItemCertificates.vue?vue&type=script&lang=ts&"
import style0 from "./NobbItemCertificates.vue?vue&type=style&index=0&id=23d5bcbc&lang=scss&scoped=true&"
import style1 from "./NobbItemCertificates.vue?vue&type=style&index=1&id=23d5bcbc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d5bcbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VIcon,VList,VListItem,VMenu,VTooltip})
