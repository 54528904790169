


























































































































































































































import { getIconByProfessionCode } from '@/helpers/common';
import { CreateProject, ProjectOverview } from '@/models/projects/interfaces';
import { useProject } from '@/modules/project';
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  PropType,
  computed,
} from '@vue/composition-api';
import Profession from '@/components/profession/index.vue';
import { translate } from '@/localization';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { useCalculation } from '@/modules/calculation';
import {
  PROJECT_CALCULATION_PATH,
  PROJECT_OVERVIEW_PATH,
} from '@/constants/routes';
import router from '@/router';

interface State {
  occupation: Nullable<string>;
  calculationName: Nullable<string>;
  newCalc: CreateProject | undefined;
  navigateToCalc: boolean;
  dialog: boolean;
  loading: boolean;
}

const state = reactive<State>({
  occupation: null,
  calculationName: null,
  newCalc: undefined,
  dialog: false,
  loading: false,
  navigateToCalc: true,
});

export default defineComponent({
  components: {
    Profession,
    DialogTitle,
  },
  props: {
    parent: {
      type: Object as PropType<ProjectOverview>,
      required: true,
    },
    isMenuitem: {
      type: Boolean,
    },
    first: {
      type: Boolean,
    },
    name: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      getProfessions,
      getNewProjectNumber,
      createProject,
      getProjects,
      refreshActiveProject,
      setActiveProject,
      professions,
      activeProjectOverview,
    } = useProject();

    const { setActiveCalculation } = useCalculation();

    const initProject = async (): Promise<void> => {
      const newProjectNumber = await getNewProjectNumber(props.parent.Id);

      if (!newProjectNumber || !props.parent.Id) return;

      state.newCalc = {
        Name: '',
        Number: newProjectNumber,
        ProfessionCode: '',
        ParentId: props.parent.Id,
        Shared: true,
      };

      state.dialog = props.show;
      state.occupation = null;
      state.calculationName = props.name ?? null;
    };

    onMounted(async () => {
      await getProfessions();
      await initProject();
    });

    const close = async () => {
      state.dialog = false;
      // If a direct link has been used to open dialog, update url when closing dialog.
      if (props.show) {
        await router.push(PROJECT_OVERVIEW_PATH);
      } else {
        initProject();
      }
    };

    const valid = computed(() => {
      return state.calculationName && state.occupation;
    });

    const submit = async () => {
      if (valid && state.occupation && state.newCalc) {
        state.newCalc.ProfessionCode = state.occupation;
        state.newCalc.Name = state.calculationName ?? '';
        state.loading = true;
        const calculation = await createProject(state.newCalc, true);
        if (calculation) {
          const projects = await getProjects(true);

          if (state.navigateToCalc && projects) {
            const newActiveProj = projects.find(proj =>
              proj.Children.some(calc => calc.Id === calculation)
            );

            if (
              newActiveProj &&
              activeProjectOverview.value &&
              newActiveProj.Id === activeProjectOverview.value.Id
            ) {
              close();
              await refreshActiveProject();
            } else if (newActiveProj?.Id) {
              await setActiveProject(newActiveProj.Id, false);
            }

            if (newActiveProj) {
              const calc = newActiveProj.Children.find(
                calc => calc.Id === calculation
              );
              if (calc) {
                await setActiveCalculation(calc);
                await router.push(PROJECT_CALCULATION_PATH);
              }
            }
          } else {
            await refreshActiveProject();
          }
        }
        close();
        state.loading = false;
      }
    };

    return {
      close,
      getIconByProfessionCode,
      submit,
      ...toRefs(state),
      professions,
      valid,
      textFieldRule: [
        (v: string) => !!v || `${translate('validation.required')}`,
      ],
    };
  },
});
