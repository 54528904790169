









































































import { defineComponent } from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import Calculalation from '@/components/calculation/index.vue';
import NewElement from '@/components/calculation/new-building-element/index.vue';
import DeleteProject from '@/components/projects/delete-project/index.vue';
import LockCalculation from '@/components/projects/lock-project/index.vue';
import ExportProject from '@/components/projects/export-project/index.vue';
import Reports from '@/components/reports/index.vue';
import { useCalculation } from '@/modules/calculation';
import ConvertToTemplate from '@/components/calculation/convert-to-template/index.vue';
import {
  PROJECT_OVERVIEW_PATH,
  PROJECT_CALCULATION_PRICE_UPDATE_PATH,
} from '@/constants/routes';

export default defineComponent({
  components: {
    ContentBar,
    Calculalation,
    NewElement,
    DeleteProject,
    LockCalculation,
    ExportProject,
    Reports,
    ConvertToTemplate,
  },
  setup() {
    const {
      activeCalculation,
      projectCalculation,
      activateLibrary,
    } = useCalculation();
    return {
      activeCalculation,
      projectCalculation,
      activateLibrary,
      PROJECT_OVERVIEW_PATH,
      PROJECT_CALCULATION_PRICE_UPDATE_PATH,
    };
  },
});
