



















































































import EditModal from '@/components/factors/factor-edit-modal/index.vue';
import { calculateFactor, calculateValue } from '@/helpers/factor';
import { CompanyFactor } from '@/models/company/interfaces';
import { useFactors } from '@/modules/factors';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  components: {
    EditModal,
  },
  props: {
    factors: {
      type: Array as PropType<Array<CompanyFactor>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getFactorNameFromId } = useFactors();

    const updateFactors = () => {
      emit('updateFactors', true);
    };

    return {
      calculateFactor,
      getFactorNameFromId,
      calculateValue,
      updateFactors,
    };
  },
});
