











































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    customTitle: {
      type: Boolean,
    },
    value: {
      type: Boolean,
      default: false,
    },
    customClose: {
      type: Boolean,
    },
  },
});
