









































import { defineComponent, ref } from '@vue/composition-api';
import ContactCard from '@/components/contacts/contact/index.vue';
import EditCalculationContacts from '@/components/contacts/calculation-contacts/edit-modal/index.vue';
import { useCalculation } from '@/modules/calculation';

export default defineComponent({
  components: {
    ContactCard,
    EditCalculationContacts,
  },
  setup() {
    const { calculationContacts } = useCalculation();

    const dialog = ref<boolean>(false);

    return {
      dialog,
      calculationContacts,
    };
  },
});
