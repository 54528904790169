

























































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    headerText: {
      type: String,
      required: true,
    },
    secondaryText: {
      type: String,
    },
    icon: {
      type: String,
    },
    center: {
      type: Boolean,
    },
    halfAndHalf: {
      type: Boolean,
    },
  },
});
