import { render, staticRenderFns } from "./CalculationEnvironmentalImpact.vue?vue&type=template&id=91ab2064&scoped=true&"
import script from "./CalculationEnvironmentalImpact.vue?vue&type=script&lang=ts&"
export * from "./CalculationEnvironmentalImpact.vue?vue&type=script&lang=ts&"
import style0 from "./CalculationEnvironmentalImpact.vue?vue&type=style&index=0&id=91ab2064&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91ab2064",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardTitle,VIcon,VSimpleTable,VSubheader,VTooltip})
