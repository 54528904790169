



































































































































































































import { defineComponent, PropType } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import ElementPropItem from '@/components/calculation/building-elements/element-prop-item/index.vue';
import { PROJECT_CALCULATION_PATH } from '@/constants/routes';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import { useCalculation } from '@/modules/calculation';
import CalculationStatus from '@/components/project-footer-status/calculation-status/index.vue';
import {
  elementSumHours,
  elementSumMargin,
  elementSumProfit,
  elementSumSelfCostMaterialsSubContractors,
  elementSumTotal,
} from '@/helpers/building-element';
import { useProject } from '@/modules/project';

export default defineComponent({
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
    },
  },
  components: {
    Loader,
    ElementPropItem,
    CalculationStatus,
  },
  setup() {
    const { projectCalculation } = useCalculation();
    const { activeProjectOverview } = useProject();
    return {
      elementSumHours,
      elementSumSelfCostMaterialsSubContractors,
      elementSumProfit,
      elementSumTotal,
      elementSumMargin,
      PROJECT_CALCULATION_PATH,
      projectCalculation,
      activeProjectOverview,
    };
  },
});
