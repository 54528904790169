import { BatchType } from '@/constants/enum';
import {
  createNewDefaultBIC,
  createNewDefaultFromElement,
  instanceOfBuildingItemCalculation,
} from '@/helpers/building-item';
import {
  BuildingElementCalculation,
  BuildingItem,
  BuildingItemCalculation,
} from '@/models/building-element/interfaces';
import { CustomError } from '@/models/common/interfaces';
import { BuildingItemTemplate } from '@/models/library/interfaces';
import { TimeEstimate } from '@/models/time-estimate/interface';
import { reactive, toRefs } from '@vue/composition-api';
import { useBatch } from './batch';
import { useSnackbar } from './snackbar';
import { v4 as uuidv4 } from 'uuid';
import { PriceListItem } from '@/models/pricebook/interfaces';
import { calculatedPrice } from '@/helpers/pricebook';

interface State {
  error?: CustomError;
}

const state = reactive<State>({
  error: undefined,
});

export const useBuildingItem = () => {
  const { snack } = useSnackbar();

  const addItemToElement = async (
    item: BuildingItemCalculation,
    element: BuildingElementCalculation
  ): Promise<boolean> => {
    const { registerBuildingItemBatchOperation } = useBatch();

    if (element.BuildingItems) {
      registerBuildingItemBatchOperation(item, BatchType.Insert);
      element.BuildingItems.push(item);
      item.IsRecentlyAdded = true;
      return true;
    } else {
      return false;
    }
  };

  const deleteItemFromCalculation = (bi: BuildingItemCalculation) => {
    const { registerBuildingItemBatchOperation } = useBatch();
    if (bi.BuildingElement?.BuildingItems) {
      bi.BuildingElement.BuildingItems.forEach(bit => {
        if (bit.Id === bi.Id && bi.BuildingElement?.BuildingItems) {
          const index = bi.BuildingElement.BuildingItems.indexOf(bit);
          registerBuildingItemBatchOperation(bi, BatchType.Delete);
          bi.BuildingElement.BuildingItems.splice(index, 1);
          return;
        }
      });
    }
  };

  const createNewItem = (
    element: BuildingElementCalculation,
    elementId: string
  ) => {
    const newCalcitem = createNewDefaultBIC(element, elementId);
    newCalcitem.IsRecentlyAdded = true;
    addItemToElement(newCalcitem, element);
    element.IsExpanded = true;
  };

  const updateBuildingItemPrices = (
    items: Array<BuildingItemCalculation>,
    priceListName: string
  ) => {
    const { registerBuildingItemsBatchOperation } = useBatch();
    items.forEach(i => {
      if (i.PriceMatch) {
        i.NOBBNumber = i.PriceMatch.NobbNumber;
        i.Price = i.PriceMatch.PriceListPrice;
        i.UnitForOrder = i.PriceMatch.UnitForOrder;
        i.PriceListItemName = i.PriceMatch.PriceListItemName;
        i.PriceListName = priceListName;
      }
    });
    registerBuildingItemsBatchOperation(items, BatchType.Save);
  };

  const upsertProduct = async (
    item: BuildingItem | BuildingItemCalculation,
    pItem: PriceListItem,
    priceList: string
  ) => {
    const { registerItemSaveChangesWithAdditionAndParent } = useBatch();

    item.NOBBNumber = pItem.NOBBNumber;
    item.Price = calculatedPrice(pItem);
    item.UnitForOrder = pItem.Unit;
    item.PriceListItemName = pItem.Name;
    item.PriceListName = priceList;

    if (instanceOfBuildingItemCalculation(item)) {
      item.IsModifiedRecently = true;
      await registerItemSaveChangesWithAdditionAndParent(item);
    }

    snack('snack.product-updated', true);
  };

  const upsertTimeEstimate = async (
    estimate: TimeEstimate,
    item: BuildingItem | BuildingItemCalculation,
    overwriteName: boolean
  ) => {
    const { registerItemSaveChangesWithAdditionAndParent } = useBatch();

    if (overwriteName) {
      item.Name = estimate.Name;
    }
    item.NS3420 = estimate.NS3420;
    item.BaseTime = estimate.BaseTime;
    item.TransportationAddition = estimate.TransportAddition;
    item.Unit = estimate.Unit;
    item.TimeEstimateName = estimate.Name;

    if (item.Unit.toLocaleLowerCase() === 'm2') {
      item.SurfaceAddition = estimate.SurfaceAddition;
      item.OpeningAddition = estimate.OpeningAddition;
    }

    if (instanceOfBuildingItemCalculation(item)) {
      item.IsModifiedRecently = true;
      await registerItemSaveChangesWithAdditionAndParent(item);
    }

    snack('snack.estimate-updated', true);
  };

  const createBICFromTimeBook = async (
    estimate: TimeEstimate,
    element: BuildingElementCalculation,
    elementId: string
  ) => {
    const newCalcitem = createNewDefaultBIC(element, elementId);
    await upsertTimeEstimate(estimate, newCalcitem, true);
    newCalcitem.IsRecentlyAdded = true;
    addItemToElement(newCalcitem, element);
  };

  const createFromTimeBook = (
    estimate: TimeEstimate,
    element: BuildingElementCalculation
  ): BuildingItem => {
    const item: BuildingItem = {
      Id: uuidv4(),
      AmountPrUnit: 1,
      BaseTime: estimate.BaseTime,
      OpeningAddition: 0,
      SurfaceAddition: 0,
      TransportationAddition: 0,
      Precut: false,
      Price: 0,
      Name: estimate.Name,
      NS3420: estimate.NS3420,
      Unit: estimate.Unit,
      TimeEstimateName: estimate.Name,
      Transport: estimate.TransportAddition,
      BuildingElementId: element.Id,
    };
    return item;
  };

  const createFromPriceBook = (
    plItem: PriceListItem,
    element: BuildingElementCalculation,
    priceListName: string
  ): BuildingItem | undefined => {
    if (element.Id) {
      const item: BuildingItem = createNewDefaultFromElement(element.Id);

      item.PriceListName = priceListName;
      item.PriceListItemName = plItem.Name;
      item.NOBBNumber = plItem.NOBBNumber;
      item.Unit = plItem.Unit;
      item.UnitForOrder = plItem.Unit;
      item.Price = calculatedPrice(plItem);
      item.BuildingElementId = element.Id;
      item.Name = plItem.Name;
      item.Comments = plItem.Description;

      return item;
    }
  };

  const createFromBuildingItemTemplate = (
    template: BuildingItemTemplate,
    elementId: string
  ): BuildingItem => {
    const item: BuildingItem = {
      Id: uuidv4(),
      Name: template.Name,
      BuildingElementId: elementId,
      PriceListItemName: template.PriceListItemName,
      PriceListName: template.PriceListName,
      DeliveryOrder: template.DeliveryOrder,
      Amount: template.Amount,
      AmountAdjustment: template.AmountAdjustment,
      AmountPrUnit: template.AmountPrUnit,
      BaseTime: template.BaseTime,
      Comments: template.Comments,
      SurfaceAddition: template.SurfaceAddition,
      OpeningAddition: template.OpeningAddition,
      IsFDVExportSelected: template.IsFDVExportSelected,
      MileStone: template.MileStone,
      NS3420: template.NS3420,
      Unit: template.Unit,
      UnitForOrder: template.UnitForOrder,
      Price: template.Price,
      Transport: template.Transport,
      TransportationAddition: template.TransportationAddition,
      SortOrder: template.SortOrder,
      Precut: template.Precut,
      TimeEstimateName: template.TimeEstimateName,
    };

    if (template.AccountNumber != null) {
      const accountNumber = Number.parseFloat(template.AccountNumber);
      item.AccountNumber = accountNumber;
    }

    if (template.NOBBNumber != null) {
      const nobbNr = Number.parseInt(template.NOBBNumber);
      item.NOBBNumber = Number.isNaN(nobbNr) ? null : nobbNr;
    }

    return item;
  };

  const createBICFromBuildingItemTemplate = (
    template: BuildingItemTemplate,
    element: BuildingElementCalculation,
    elementId: string
  ): BuildingItemCalculation => {
    const newCalcitem = createNewDefaultBIC(element, elementId);

    newCalcitem.IsRecentlyAdded = true;
    newCalcitem.BuildingElement = element;
    newCalcitem.BuildingElementId = element.Id;

    newCalcitem.Name = template.Name;
    newCalcitem.PriceListName = template.PriceListName;
    newCalcitem.PriceListItemName = template.PriceListItemName;
    newCalcitem.TimeEstimateName = template.TimeEstimateName;
    newCalcitem.DeliveryOrder = template.DeliveryOrder;

    if (template.AccountNumber != null) {
      const accountNumber = Number.parseFloat(template.AccountNumber);
      newCalcitem.AccountNumber = accountNumber;
    }

    if (template.NOBBNumber != null) {
      const nobbNr = Number.parseInt(template.NOBBNumber);
      newCalcitem.NOBBNumber = Number.isNaN(nobbNr) ? null : nobbNr;
    }

    newCalcitem.NS3420 = template.NS3420;
    newCalcitem.Unit = template.Unit;
    newCalcitem.UnitForOrder = template.UnitForOrder;
    newCalcitem.Amount = template.Amount;
    newCalcitem.AmountAdjustment = template.AmountAdjustment;
    newCalcitem.AmountPrUnit = template.AmountPrUnit;
    newCalcitem.Price = template.Price;
    newCalcitem.BaseTime = template.BaseTime;
    newCalcitem.OpeningAddition = template.OpeningAddition;
    newCalcitem.SurfaceAddition = template.SurfaceAddition;
    newCalcitem.TransportationAddition = template.TransportationAddition;
    newCalcitem.IsFDVExportSelected = template.IsFDVExportSelected ?? false;
    newCalcitem.MileStone = template.MileStone;
    newCalcitem.Comments = template.Comments;
    newCalcitem.Transport = template.Transport;
    newCalcitem.Precut = template.Precut;
    newCalcitem.SortOrder = template.SortOrder;
    newCalcitem.IsRecentlyAdded = true;

    return newCalcitem;
  };

  return {
    createFromPriceBook,
    createNewItem,
    upsertTimeEstimate,
    createFromTimeBook,
    createBICFromTimeBook,
    deleteItemFromCalculation,
    createBICFromBuildingItemTemplate,
    createFromBuildingItemTemplate,
    upsertProduct,
    updateBuildingItemPrices,
    ...toRefs(state),
  };
};
