






































































































































import { PropType, defineComponent } from '@vue/composition-api';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { NobbItem } from '@/models/nobb/interfaces';
import NobbItemCertificates from '@/components/environment/NobbItemCertificates.vue';
import ValidationDate from '@/components/environment/ValidationDate.vue';
import EnvironmentImpact from '@/components/environment/EnvironmentImpact.vue';
import CalculatedEnvironmentImpact from '@/components/environment/CalculatedEnvironmentImpact.vue';
import PriceUnit from '@/components/environment/PriceUnit.vue';
import NobbInfo from '@/components/nobb-info/index.vue';
import NobbItemEPDDialog from './NobbItemEPDDialog.vue';

export default defineComponent({
  components: {
    NobbItemCertificates,
    ValidationDate,
    EnvironmentImpact,
    PriceUnit,
    CalculatedEnvironmentImpact,
    NobbInfo,
    NobbItemEPDDialog,
  },
  props: {
    buildingItem: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
    nobbItem: {
      type: Object as PropType<NobbItem | undefined>,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
