









































































































































































import { PublicationType } from '@/constants/enum';
import { translate } from '@/localization';
import {
  BuildingElementCalculation,
  BuildingItemCalculation,
  DocumentationStatus,
} from '@/models/building-element/interfaces';
import { useProductInfo } from '@/modules/product-info';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from '@vue/composition-api';
import Loader from '../loader/index.vue';
import NobbInfo from '@/components/nobb-info/index.vue';
import DocumentStatus from '@/components/documentation/document-status/index.vue';

export default defineComponent({
  components: {
    NobbInfo,
    Loader,
    DocumentStatus,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
    primary: {
      type: Boolean,
    },
  },
  setup(props) {
    const dialog = ref<boolean>(false);
    const selected = ref<Array<BuildingItemCalculation>>();
    const failed = ref<Array<string>>([]);
    const succeeded = ref<Array<string>>([]);
    const selectedDocType = ref<PublicationType>(PublicationType.Fdv);
    const loadingItemId = ref<Nullable<string>>('');
    const loading = ref<boolean>(false);
    const ranOnce = ref<boolean>(false);

    const typeChanged = async () => {
      if (!selected.value) return;

      ranOnce.value = false;
      for (const item of selected.value) {
        item.DocumentationDownloaded = DocumentationStatus.Default;
      }
    };

    const toggleSingle = (item: BuildingItemCalculation) => {
      if (!selected.value) return;

      const index = selected.value.findIndex(i => i.Id === item.Id);
      if (index >= 0) {
        selected.value.splice(index, 1);
      } else {
        selected.value.push(item);
      }
    };
    const isSelected = (item: BuildingItemCalculation) => {
      if (!selected.value) return;

      return selected.value.findIndex(i => i.Id === item.Id) >= 0;
    };
    const toggleAll = () => {
      let allSelected = true;
      const available = props.element.BuildingItems.filter(
        i => i.NOBBNumber != null
      );
      available.forEach(i => {
        if (!isSelected(i)) {
          allSelected = false;
          return;
        }
      });
      if (allSelected) {
        selected.value = [];
      } else {
        selected.value = available;
      }
      return allSelected;
    };

    onMounted(() => {
      selected.value = [];
      toggleAll();
      typeChanged();
    });

    const allSelected = computed(() => {
      let allSelected = true;
      const available = props.element.BuildingItems.filter(
        i => i.NOBBNumber != null
      );
      available.forEach(i => {
        if (!isSelected(i)) {
          allSelected = false;
          return;
        }
      });
      return allSelected;
    });
    const valid = computed(() => {
      if (selected.value && selected.value.length === 0) return false;
      if (selectedDocType.value == null) return false;
      if (loading.value) return false;
      return true;
    });

    const downloadAll = async () => {
      const { getDocumentsByItem } = useProductInfo();

      if (!selected.value) return;

      loading.value = true;
      ranOnce.value = true;
      for (const item of selected.value) {
        if (item.NOBBNumber) {
          loadingItemId.value = item.Id;
          const fileName = `${(
            item.PriceListItemName ?? `${translate('common.unknown')}`
          ).replace(/ /g, '_')}_${PublicationType[selectedDocType.value]}.pdf`;
          const downloaded = await getDocumentsByItem(
            item.NOBBNumber,
            PublicationType[selectedDocType.value],
            fileName
          );

          if (downloaded && item.Id) {
            item.DocumentationDownloaded = DocumentationStatus.Succeeded;
          } else if (item.Id) {
            failed.value.push(item.Id);
            item.DocumentationDownloaded = DocumentationStatus.Failed;
          }
        }
      }
      loading.value = false;
      loadingItemId.value = '';
    };
    return {
      toggleSingle,
      toggleAll,
      isSelected,
      downloadAll,
      typeChanged,
      dialog,
      loadingItemId,
      loading,
      selected,
      headers: [
        {
          text: '',
          value: 'data-table-select',
          cellClass: 'default',
          width: '2%',
        },
        {
          text: '',
          value: 'status',
          cellClass: 'default',
          width: '18%',
        },
        {
          text: `${translate('headers.name')}`,
          value: 'PriceListItemName',
          width: '50%',
          class: 'header-cell',
          cellClass: 'cell-color',
          sortable: false,
        },
        {
          text: `${translate('headers.nobbnumber')}`,
          value: 'NOBBNumber',
          width: '15%',
          class: 'header-cell',
          cellClass: 'cell-color-medium',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          width: '15%',
          class: 'header-cell',
          cellClass: 'cell-color-medium',
          sortable: false,
        },
      ],
      allSelected,
      PublicationType,
      selectedDocType,
      valid,
      ranOnce,
      succeeded,
    };
  },
});
