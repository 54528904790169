import {
  DEFAULT_LAYOUT,
  LOADING_LAYOUT,
  PROTECTED_LAYOUT,
} from '@/constants/common';
import {
  HOME_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  PROJECT_ADMIN_PATH,
  PROJECT_CALCULATION_PATH,
  PROJECT_ORGANIZATION_PATH,
  PROJECT_START_PATH,
  PROJECT_PRICEBOOK_PATH,
  PROJECT_OVERVIEW_PATH,
  PROJECT_LIBRARY_PATH,
  PROJECT_CALCULATION_OVERVIEW_PATH,
  PROJECT_CALCULATION_BUILDING_ELEMENT_PATH,
  PUBLIC_PATHS,
  VERSIONS_PATH,
  PROJECT_CALCULATION_ENVIRONMENT,
  PROJECT_CALCULATION_PRICE_UPDATE_PATH,
} from '@/constants/routes';
import { useAuth } from '@/modules/auth';
import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { useProject } from '@/modules/project';
import { useCalculation } from '@/modules/calculation';
import { useGlobal } from '@/modules/global';
import Home from '@/views/Home.vue';
import Versions from '@/views/Versions.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import Start from '@/views/Start.vue';
import Pricebook from '@/views/Pricebook.vue';
import Organization from '@/views/Organization.vue';
import Admin from '@/views/Admin.vue';
import Library from '@/views/Library.vue';
import Calculation from '@/views/Calculation.vue';
import CalculationOverview from '@/views/CalculationOverview.vue';
import ProjectOverview from '@/views/ProjectOverview.vue';
import BuildingElement from '@/views/BuildingElement.vue';
import Environment from '@/views/Environment.vue';
import PriceUpdate from '@/views/PriceUpdate.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: HOME_PATH,
    name: 'Home',
    component: Home,
    meta: {
      layout: DEFAULT_LAYOUT,
    },
  },
  {
    path: VERSIONS_PATH,
    name: 'Versions',
    component: Versions,
    meta: {
      layout: DEFAULT_LAYOUT,
    },
  },
  {
    path: LOGIN_PATH,
    name: 'Login',
    component: Login,
    meta: {
      layout: DEFAULT_LAYOUT,
    },
  },
  {
    path: LOGOUT_PATH,
    name: 'Logout',
    component: Logout,
    meta: {
      requiresAuth: true,
      layout: LOADING_LAYOUT,
    },
  },
  {
    path: PROJECT_START_PATH,
    name: 'Start',
    component: Start,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_PRICEBOOK_PATH,
    name: 'Pricebook',
    component: Pricebook,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_ORGANIZATION_PATH,
    name: 'Organization',
    component: Organization,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresAdmin: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_ADMIN_PATH,
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresAdmin: true,
      requiresFullVersion: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_LIBRARY_PATH,
    name: 'Library',
    component: Library,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_CALCULATION_PATH,
    name: 'Calculation',
    component: Calculation,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresActiveProject: true,
      requiresActiveCalculation: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_CALCULATION_OVERVIEW_PATH,
    name: 'Calculation Overview',
    component: CalculationOverview,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresActiveProject: true,
      requiresActiveCalculation: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_OVERVIEW_PATH + '/:projectNumber/add/:calculationName?',
    component: ProjectOverview,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresActiveProjectOrNumberInProp: true,
      layout: PROTECTED_LAYOUT,
    },
    props: (route: Route) => ({
      createNewCalculation: true,
      calculationName: route.params.calculationName,
      projectNumber: route.params.projectNumber,
    }),
  },
  {
    path: PROJECT_OVERVIEW_PATH + '/:projectNumber?',
    name: 'Project Overview',
    component: ProjectOverview,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresActiveProjectOrNumberInProp: true,
      layout: PROTECTED_LAYOUT,
    },
    props: true,
  },
  {
    path: PROJECT_CALCULATION_BUILDING_ELEMENT_PATH,
    name: 'Building Element',
    component: BuildingElement,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresActiveProject: true,
      requiresActiveCalculation: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_CALCULATION_ENVIRONMENT,
    name: 'Environment',
    component: Environment,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresActiveProject: true,
      requiresActiveCalculation: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: PROJECT_CALCULATION_PRICE_UPDATE_PATH,
    name: 'PriceUpdate',
    component: PriceUpdate,
    meta: {
      requiresAuth: true,
      requireLicense: true,
      requiresActiveProject: true,
      requiresActiveCalculation: true,
      layout: PROTECTED_LAYOUT,
    },
  },
  {
    path: '/*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { checkAuthAndLicense, user, license } = useAuth();
  const { activeProjectOverview } = useProject();
  const { activeCalculation, buildCalculationDeps } = useCalculation();
  const { initLanguage } = useGlobal();

  initLanguage();

  const authAndLicenseOK = await checkAuthAndLicense();

  if (authAndLicenseOK) {
    if (!PUBLIC_PATHS.includes(to.path)) {
      await buildCalculationDeps();
    }
  }

  const authedRequirement = user?.value ? true : false;
  const licenseRequirement = authedRequirement
    ? license?.value
      ? license.value.LicenseOk
        ? true
        : false
      : false
    : false;
  const activeProjectRequirement = activeProjectOverview?.value ? true : false;
  const activeCalculationRequirement = activeCalculation?.value ? true : false;
  const adminRequirement = user?.value?.IsAdmin ? true : false;
  const fullVersionRequirement = license?.value
    ? license.value.Trial
      ? false
      : true
    : false;

  const redirectPath = (): string => {
    if (to.meta?.requiresAuth === true && !authedRequirement) {
      console.log('requires auth, redirect to login');
      return LOGIN_PATH;
    }
    if (to.meta?.requireLicense === true && !licenseRequirement) {
      console.log('requires auth and license, redirect to login');
      return LOGIN_PATH;
    }
    if (to.meta?.requiresAdmin === true && !adminRequirement) {
      console.log('requires admin, redirect to project start');
      return PROJECT_START_PATH;
    }
    if (to.meta?.requiresActiveProject === true && !activeProjectRequirement) {
      console.log('requires active project, redirect to project start');
      return PROJECT_START_PATH;
    }
    if (to.meta?.requiresFullVersion === true && !fullVersionRequirement) {
      console.log('requires full version, redirect to project start');
      return PROJECT_START_PATH;
    }
    if (
      to.meta?.requiresActiveProjectOrNumberInProp === true &&
      !activeProjectRequirement &&
      to.params.projectNumber === undefined
    ) {
      console.log(
        'requires active project or projectNumber in props, redirect to project start'
      );
      return PROJECT_START_PATH;
    }
    if (
      to.meta?.requiresActiveCalculation === true &&
      !activeCalculationRequirement
    ) {
      console.log('requiresActiveCalculation, redirect to project start');
      return PROJECT_START_PATH;
    }
    return '';
  };

  if (authAndLicenseOK) {
    if (redirectPath() !== '') {
      next({ path: redirectPath() });
    } else {
      next();
    }
  } else {
    console.log('Auth and license check negative, route to login');
    next({ path: LOGIN_PATH });
  }
});

export default router;
