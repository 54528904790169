import { AUTH_KEY } from '@/constants/common';
import { AuthState, Login, User } from '@/models/auth/interfaces';
import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { useLicense } from './license';
import { isSuccess } from '@/helpers/common';
import { useLoader } from './loader';
import { translate } from '@/localization';
import { useProject } from './project';
import { usePriceBook } from './pricebook';

Vue.use(VueCookies);

const state = reactive<AuthState>({
  authenticating: false,
  user: undefined,
  error: undefined,
  license: undefined,
  loading: false,
});

export const useAuth = () => {
  const token: string = Vue.$cookies.get(AUTH_KEY);

  const getUserInfo = async (): Promise<User | undefined> => {
    const { get } = useApi('/account/GetUserInfo');

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.user = response.data;
      }
    } catch (e) {
      state.error = e.response;
      Vue.$cookies.remove(AUTH_KEY);
    }

    return state.user;
  };

  const buildAuth = async (): Promise<void> => {
    const { checkUserLicense, error } = useLicense();
    const { startLoader, stopLoader } = useLoader();
    startLoader(`${translate('loader.authenticating')}`);

    const user = await getUserInfo();
    const license = await checkUserLicense();

    if (user && license) {
      state.license = license;
      state.user = user;
    }

    if (error?.value) {
      Vue.$cookies.remove(AUTH_KEY);
    }

    stopLoader();
  };

  const checkAuthAndLicense = async (): Promise<boolean> => {
    if (token && (state.user === undefined || state.license === undefined))
      await buildAuth();
    return true;
  };

  const updateAuthAndLicense = async (): Promise<void> => {
    await buildAuth();
  };

  const setToken = (payload: Login, remember: boolean): void => {
    if (remember) {
      Vue.$cookies.set(AUTH_KEY, payload.access_token, payload['.expires']);
    }
    state.error = undefined;
  };

  const login = async (
    params: URLSearchParams,
    rememberMe: boolean
  ): Promise<void> => {
    const { postUrlencoded } = useApi('/account/login');
    state.loading = true;

    try {
      const response = await postUrlencoded(params);
      if (isSuccess(response.status)) {
        setToken(response.data, rememberMe);
      }
    } catch (e) {
      state.error = e.response;
    }

    state.loading = false;
  };

  const logout = async (): Promise<void> => {
    const { destructActiveProject } = useProject();
    const { destructPriceLists } = usePriceBook();
    Vue.$cookies.remove(AUTH_KEY);
    destructActiveProject();
    destructPriceLists();
    return Promise.resolve((state.user = undefined));
  };

  const resetPassword = async (username: string): Promise<boolean> => {
    const { post } = useApi(`/account/forgotpassword?email=${username}`);
    let success = false;

    try {
      const response = await post();
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
    }

    return success;
  };

  return {
    updateAuthAndLicense,
    checkAuthAndLicense,
    setToken,
    logout,
    login,
    resetPassword,
    ...toRefs(state),
  };
};
