







































































import {
  ref,
  defineComponent,
  PropType,
  onMounted,
  computed,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import {
  BlobPriceListCategoryOverview,
  PriceListItem,
} from '@/models/pricebook/interfaces';
import { usePriceBook } from '@/modules/pricebook';
import { useSnackbar } from '@/modules/snackbar';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    item: {
      type: Object as PropType<PriceListItem>,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const loading = ref(false);
    const selected = ref<BlobPriceListCategoryOverview>();

    const {
      getCompanyPricelistCategories,
      companyPricelistCategories,
      insertPriceListItem,
    } = usePriceBook();
    const { snack } = useSnackbar();

    const valid = computed(() => {
      return selected.value != null;
    });

    onMounted(async () => {
      await getCompanyPricelistCategories();
    });

    const close = () => {
      dialog.value = false;
      selected.value = undefined;
    };

    const submit = async () => {
      loading.value = true;
      if (selected.value && props.item) {
        await insertPriceListItem(selected.value, props.item);
        snack('snack.copied-to-company-pricelist', true);
      }
      loading.value = false;
      dialog.value = false;
    };

    return {
      close,
      submit,
      dialog,
      loading,
      valid,
      selected,
      companyPricelistCategories,
    };
  },
});
