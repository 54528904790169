




















import { defineComponent } from '@vue/composition-api';
import Projects from '@/components/projects/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import ImportProject from '@/components/projects/import-project/index.vue';
import NewProject from '@/components/projects/new-project/index.vue';
import { useProject } from '@/modules/project';

export default defineComponent({
  components: {
    Projects,
    ContentBar,
    ImportProject,
    NewProject,
  },
  name: 'Start',
  setup() {
    const { firstProject } = useProject();
    return { firstProject };
  },
});
