






































































































































import { PriceListItem } from '@/models/pricebook/interfaces';
import { defineComponent, ref, PropType, computed } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';
import { usePriceBook } from '@/modules/pricebook';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    item: {
      type: Object as PropType<PriceListItem>,
      required: true,
    },
  },
  setup(props) {
    const { updatePriceListItem } = usePriceBook();
    const dialog = ref(false);
    const loading = ref(false);

    const close = () => {
      dialog.value = false;
    };

    const valid = computed(() => {
      return true;
    });

    const submit = async () => {
      loading.value = true;
      await updatePriceListItem(props.item);
      loading.value = false;
      close();
    };

    return {
      close,
      submit,
      dialog,
      valid,
      loading,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
    };
  },
});
