

































import { ref, defineComponent, PropType } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { usePriceBook } from '@/modules/pricebook';
import { PriceListItem } from '@/models/pricebook/interfaces';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    item: {
      type: Object as PropType<PriceListItem>,
      required: true,
    },
  },
  setup(props) {
    const { deletePriceListItem } = usePriceBook();
    const valid = ref(false);
    const loading = ref(false);
    const dialog = ref(false);

    const deleteEvent = async () => {
      loading.value = true;
      await deletePriceListItem(props.item);
      loading.value = false;
      dialog.value = false;
    };

    valid.value = true;

    return {
      loading,
      valid,
      dialog,
      deleteEvent,
    };
  },
});
