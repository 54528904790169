





















































































import { translate } from '@/localization';
import { LicensePrice } from '@/models/admin/interfaces';
import { computed, defineComponent, PropType } from '@vue/composition-api';
export default defineComponent({
  props: {
    price: {
      type: Object as PropType<LicensePrice>,
      required: true,
    },
  },
  setup({ price }) {
    const offerTitle = computed(() => {
      if (price.Member) {
        return translate('common.monthly-membership-price');
      } else {
        return translate('common.monthly-price');
      }
    });

    return {
      offerTitle,
    };
  },
});
