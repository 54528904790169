var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":"","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('span',{staticClass:"subheader"},[_vm._v(_vm._s(_vm.$t('titles.add-from-timebook')))]),_c('v-spacer'),_c('v-checkbox',{staticClass:"toggle-exclude",attrs:{"dense":"","hide-details":"","label":_vm.$t('labels.show-all')},model:{value:(_vm.excludeItems),callback:function ($$v) {_vm.excludeItems=$$v},expression:"excludeItems"}}),_c('v-text-field',{staticClass:"estimate-search .d-none .d-sm-flex",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('labels.search-estimates'),"single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('profession',{attrs:{"code":_vm.projectCalculation &&
          _vm.projectCalculation.Profession.Code &&
          _vm.projectCalculation.Profession.Code.toString()}})],1),_c('perfect-scrollbar',{attrs:{"options":{
      suppressScrollX: true,
    }}},[(_vm.filteredCategories)?_c('v-card-text',{staticClass:"edit-content"},[_c('v-expansion-panels',{attrs:{"tile":"","value":_vm.expanded}},_vm._l((_vm.filteredCategories),function(category){return _c('v-expansion-panel',{key:category.Name},[_c('v-expansion-panel-header',{staticClass:"estimate-header"},[_c('v-row',{staticClass:"category-props",attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(((category.NS3420) + " - " + (category.Name) + " (" + (category.Estimates.length) + ")"))+" ")])],1)],1),_c('v-expansion-panel-content',{staticClass:"estimate-content"},[_c('v-data-table',{staticClass:"estimates",attrs:{"headers":_vm.ESTIMATE_HEADERS,"items":category.Estimates,"search":_vm.excludeItems ? '' : _vm.search,"sort-by":"Name","loading-text":_vm.$t('info.loading'),"items-per-page":1000,"no-data-text":_vm.$t('info.no-data'),"hide-default-footer":"","no-results-text":_vm.$t('info.no-result')},scopedSlots:_vm._u([_vm._l((_vm.ESTIMATE_HEADERS),function(h){return {key:("header." + (h.value)),fn:function(ref){return [_c('v-tooltip',{key:h.text,attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(h.text)?_c('span',_vm._g({staticStyle:{"max-width":"90px"}},on),[_vm._v(" "+_vm._s(_vm.$t(("headers." + (h.text.toLowerCase()))))+" ")]):_vm._e()]}}],null,true)},[(h.text)?_c('span',[_vm._v(_vm._s(_vm.$t(("headers-description." + (h.text.toLowerCase())))))]):_vm._e()])]}}}),{key:"item.Name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Name))]),(category.Description)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"icon":"","small":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(("" + (category.Description))))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","color":"secondary","small":""},on:{"click":function($event){return _vm.itemSelected(item)}}},[_vm._v(_vm._s(_vm.$t('buttons.add')))])]}}],null,true)})],1)],1)}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }