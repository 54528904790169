







































































































import { defineComponent } from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import DeleteProject from '@/components/projects/delete-project/index.vue';
import NewCalculation from '@/components/projects/new-calculation/index.vue';
import ExportProject from '@/components/projects/export-project/index.vue';
import { useProject } from '@/modules/project';
import Calculations from '@/components/projects/calculations/index.vue';
import ImportCalculation from '@/components/projects/import-calculation/index.vue';
import Reports from '@/components/reports/index.vue';
import ProjectDetails from '@/components/projects/details/index.vue';
import Contacts from '@/components/contacts/index.vue';
import KalkHelper from '@/components/common/kalk-helper/index.vue';
import { useLoader } from '@/modules/loader';

export default defineComponent({
  components: {
    ContentBar,
    DeleteProject,
    NewCalculation,
    Calculations,
    ExportProject,
    ImportCalculation,
    Reports,
    ProjectDetails,
    Contacts,
    KalkHelper,
  },
  props: {
    projectNumber: {
      type: String,
      required: false,
    },
    createNewCalculation: {
      type: Boolean,
      default: false,
    },
    calculationName: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const { activeProjectOverview, setActiveProjectByNumber } = useProject();
    const { loading } = useLoader();

    if (
      props.projectNumber !== undefined &&
      props.projectNumber !== activeProjectOverview.value?.ProjectNumber
    ) {
      setActiveProjectByNumber(props.projectNumber.toLowerCase());
    }

    return {
      props,
      activeProjectOverview,
      loading,
      documentationUrl: process.env.VUE_APP_DOCUMENTATION_URL,
    };
  },
});
