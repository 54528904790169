





































import { PropType, computed, defineComponent } from '@vue/composition-api';
import { Epd } from '@/models/nobb/interfaces';
import { useEnvironment } from '@/modules/environment';
import { areUnitsEqual, unitDescriptionConverter } from '@/helpers/common';

export default defineComponent({
  props: {
    epd: {
      type: Array as PropType<Array<Epd>>,
      required: true,
    },
    buildingItemAmountCalculated: {
      type: Number,
    },
    buildingItemUnit: {
      type: String,
    },
    priceUnit: {
      type: String,
    },
  },
  setup(props) {
    const {
      tryGetEpdA1A3GWPIOBCUnit,
      tryGetEpdA1A3GWPIOBCNumber,
    } = useEnvironment();

    const numberA1A3IOBC = computed(() =>
      tryGetEpdA1A3GWPIOBCNumber(props.epd)
    );
    const unit = computed(() => tryGetEpdA1A3GWPIOBCUnit(props.epd));

    const calculatedByAmount = computed(() => {
      if (numberA1A3IOBC.value && props.buildingItemAmountCalculated) {
        return numberA1A3IOBC.value * props.buildingItemAmountCalculated;
      }
    });

    const buildingItemUnitIsEqualToPriceUnit = computed(() => {
      if (props.priceUnit && props.buildingItemUnit) {
        return areUnitsEqual(
          unitDescriptionConverter(props.priceUnit),
          props.buildingItemUnit
        );
      }
    });
    return {
      props,
      calculatedByAmount,
      buildingItemUnitIsEqualToPriceUnit,
      unitDescriptionConverter,
      unit,
    };
  },
});
