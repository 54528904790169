
































import Factors from '@/components/factors/index.vue';
import Organization from '@/components/organization/index.vue';
import Contacts from '@/components/contacts/index.vue';
import Logo from '@/components/logo/index.vue';
import { defineComponent } from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
export default defineComponent({
  components: {
    Factors,
    Organization,
    Contacts,
    Logo,
    ContentBar,
  },
});
