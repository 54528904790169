
















































import { AdditionItemCalculation } from '@/models/building-element/interfaces';
import { defineComponent, PropType } from '@vue/composition-api';
import { useBatch } from '@/modules/batch';
import { BatchType } from '@/constants/enum';

export default defineComponent({
  props: {
    additionItem: {
      type: Object as PropType<AdditionItemCalculation>,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    isSurface: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { registerElementAndAdditionsChanges } = useBatch();

    return {
      registerElementAndAdditionsChanges,
      BatchType,
    };
  },
});
