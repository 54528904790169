








































































































































































































































































































import {
  BuildingElementCalculation,
  BuildingItemCalculation,
  ItemStatus,
} from '@/models/building-element/interfaces';
import {
  PriceListTreeNode,
  PriceMatch,
  SearchablePriceListItem,
} from '@/models/pricebook/interfaces';
import { usePriceBook } from '@/modules/pricebook';
import ContentBar from '@/components/common/content-bar/index.vue';
import BuildingItemStatus from '@/components/calculation/building-item/building-item-status/index.vue';
import {
  defineComponent,
  onMounted,
  ref,
  PropType,
  computed,
} from '@vue/composition-api';
import { BUILDING_ITEM_PRICE_UPDATE } from '@/constants/table-headers';
import { areUnitsEqual, unitDescriptionConverter } from '@/helpers/common';
import { translate } from '@/localization';
import { useBuildingItem } from '@/modules/building-item';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import EditTimeEstimate from '@/components/calculation/building-item/edit-time-estimate/index.vue';
import RemoveTimeEstimate from '@/components/calculation/building-item/remove-time-estimate/index.vue';
import GetProduct from '@/components/calculation/get-product/index.vue';
import NobbInfo from '@/components/nobb-info/index.vue';
import { useBatch } from '@/modules/batch';
import { ItemWithProduct } from '@/models/common/interfaces';
import { BatchType } from '@/constants/enum';
import { calculatedPrice } from '@/helpers/pricebook';

export default defineComponent({
  components: {
    ContentBar,
    BuildingItemStatus,
    DialogTitle,
    EditTimeEstimate,
    RemoveTimeEstimate,
    GetProduct,
    NobbInfo,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      getPriceLists,
      updatePricesFromNOBBNumbers,
      pricelists,
    } = usePriceBook();
    const { updateBuildingItemPrices } = useBuildingItem();
    const {
      registerItemSaveChangesWithAdditionAndParent,
      registerBuildingItemBatchOperation,
    } = useBatch();
    const loading = ref(false);
    const fetchingPrices = ref(false);
    const updatingPrices = ref(false);
    const chosenPriceList = ref<PriceListTreeNode>();
    const pricesFetched = ref(false);
    const selected = ref<Array<BuildingItemCalculation>>();
    const searchablePriceListItems = ref<Array<SearchablePriceListItem>>();

    const toggleSingle = (item: BuildingItemCalculation) => {
      if (!selected.value) selected.value = [];

      const index = selected.value.findIndex(i => i.Id === item.Id);
      if (index >= 0) {
        selected.value.splice(index, 1);
      } else {
        selected.value.push(item);
      }
    };
    const isSelected = (item: BuildingItemCalculation) => {
      if (!selected.value) return;

      return selected.value.findIndex(i => i.Id === item.Id) >= 0;
    };

    const isEnabled = (item: BuildingItemCalculation) => {
      let enable = true;

      if (!pricesFetched.value || !item.NOBBNumber) {
        enable = false;
      }

      if (
        (item.Status && item.Status.StatusType === ItemStatus.MatchNoDiff) ||
        (item.Status && item.Status.StatusType === ItemStatus.MissingNobb)
      ) {
        enable = false;
      }

      return enable;
    };

    const toggleAll = () => {
      if (!pricesFetched.value) return;
      let allSelected = true;
      const available = props.element.BuildingItems.filter(i => isEnabled(i));
      available.forEach(i => {
        if (!isSelected(i)) {
          allSelected = false;
          return;
        }
      });
      if (allSelected) {
        selected.value = [];
      } else {
        selected.value = available;
      }
      return allSelected;
    };

    const allSelected = computed(() => {
      let allSelected = true;
      const available = props.element.BuildingItems.filter(
        i => i.NOBBNumber != null
      );
      available.forEach(i => {
        if (!isSelected(i)) {
          allSelected = false;
          return;
        }
      });
      return allSelected;
    });

    const updatePriceMatchStatus = (items: Array<BuildingItemCalculation>) => {
      items.forEach(item => {
        if (item.PriceMatch) {
          if (item.PriceMatch.HasDifferentUnit.value) {
            item.Status = {
              StatusColor: 'error',
              StatusText: `${translate('info.match-unit-diff')}`,
              StatusType: ItemStatus.MatchWithUnitDiff,
            };
          } else if (item.PriceMatch.HasPrice.value) {
            item.Status = {
              StatusColor: 'secondary',
              StatusText: `${translate('info.match-price-diff')}`,
              StatusType: ItemStatus.MatchWithPriceDiff,
            };
          }
        } else if (item.NOBBNumber) {
          item.Status = {
            StatusColor: 'orange',
            StatusText: `${translate('info.match-no-hit')}`,
            StatusType: ItemStatus.MatchNoDiff,
          };
        }
      });
    };

    const setPriceMatches = (
      items: Array<BuildingItemCalculation>,
      priceListItems: Array<SearchablePriceListItem>,
      withoutChecking?: boolean
    ) => {
      items.forEach(item => {
        const priceListItem = priceListItems.find(
          pItem => item.NOBBNumber && item.NOBBNumber === pItem.NobbNumber
        );

        if (priceListItem) {
          const priceMatch: PriceMatch = {
            BuildingElementId: item.BuildingElementId ?? null,
            BuildingElementName: item.BuildingElement?.Name ?? null,
            BuildingItemId: item.Id,
            Name: item.Name,
            NobbNumber: item.NOBBNumber ?? null,
            Price: item.Price ?? null,
            BuildingItemPriceListItemName: item.PriceListItemName,
            BuildingItemPriceListName: item.PriceListName ?? null,
            UnitForOrder: item.UnitForOrder,
            PriceDifference: computed(() => {
              let diff = 0;
              if (priceMatch.Price && priceMatch.PriceListPrice) {
                diff = priceMatch.PriceListPrice - priceMatch.Price;
              }
              return diff;
            }),
            HasDifferentUnit: computed(() => {
              let diffUnit = false;

              if (priceMatch.UnitForOrder && priceMatch.PriceListUnit) {
                diffUnit = !areUnitsEqual(
                  priceMatch.UnitForOrder,
                  unitDescriptionConverter(priceMatch.PriceListUnit)
                );
              }

              return diffUnit;
            }),
            HasPrice: computed(() => {
              let priceFound = false;

              if (
                priceMatch.PriceListPrice != null &&
                priceMatch.PriceListPrice !== 0
              ) {
                priceFound = true;
              }
              return priceFound;
            }),
            ShouldAutoSelect: computed(() => {
              return (
                priceMatch.HasPrice.value && !priceMatch.HasDifferentUnit.value
              );
            }),
            PriceListItemName: priceListItem.Name,
            PriceListUnit: priceListItem.Unit,
            DiscountPercentage: priceListItem.DiscountPercentage ?? null,
            BasePrice: priceListItem.BasePrice ?? null,
            PriceListPrice: 0,
          };

          if (priceListItem.BasePrice) {
            let discountPercentage = 0;

            if (priceListItem.DiscountPercentage) {
              discountPercentage = priceListItem.DiscountPercentage;
            }

            priceMatch.PriceListPrice =
              (priceListItem.BasePrice * (100 - discountPercentage)) / 100;
          }

          item.PriceMatch = priceMatch;
          if (priceMatch.ShouldAutoSelect.value && !withoutChecking) {
            toggleSingle(item);
          }
        }
      });

      updatePriceMatchStatus(items);
    };

    const timeUpdated = (item: BuildingItemCalculation) => {
      if (searchablePriceListItems.value) {
        registerBuildingItemBatchOperation(item, BatchType.Save);
        setPriceMatches(
          props.element.BuildingItems,
          searchablePriceListItems.value,
          true
        );
      }
    };

    const clearPriceMatches = (items: Array<BuildingItemCalculation>) => {
      items.forEach(item => {
        item.PriceMatch = null;
        item.Status = null;
      });
    };

    const updateProduct = (iwp: ItemWithProduct) => {
      if (searchablePriceListItems.value && iwp.Item && iwp.PriceListItem) {
        iwp.Item.NOBBNumber = iwp.PriceListItem.NobbNumber;
        iwp.Item.Price = calculatedPrice(iwp.PriceListItem);
        iwp.Item.UnitForOrder = iwp.PriceListItem.Unit;
        iwp.Item.PriceListItemName = iwp.PriceListItem.Name;
        iwp.Item.PriceListName = iwp.PriceListItem.PriceListName;
        iwp.Item.IsModifiedRecently = true;
        registerBuildingItemBatchOperation(iwp.Item, BatchType.Save);

        setPriceMatches(
          props.element.BuildingItems,
          searchablePriceListItems.value,
          true
        );
      }
    };

    const reset = () => {
      pricesFetched.value = false;
      selected.value = undefined;
      searchablePriceListItems.value = undefined;

      clearPriceMatches(props.element.BuildingItems);
    };

    const amountChange = (item: BuildingItemCalculation) => {
      if (searchablePriceListItems.value) {
        registerItemSaveChangesWithAdditionAndParent(item);
        setPriceMatches(
          props.element.BuildingItems,
          searchablePriceListItems.value,
          true
        );
      }
    };

    const close = () => {
      reset();
      chosenPriceList.value = undefined;
      emit('cancelUpdate', true);
    };

    const fetchPrices = async () => {
      if (!chosenPriceList.value?.Id) return;

      const nobbs: Array<number> = [];

      reset();

      props.element.BuildingItems.forEach(bi => {
        if (bi.NOBBNumber) {
          nobbs.push(bi.NOBBNumber);
        }
      });

      fetchingPrices.value = true;

      try {
        searchablePriceListItems.value = await updatePricesFromNOBBNumbers(
          chosenPriceList.value.Id,
          nobbs
        );
        pricesFetched.value = true;
      } catch (e) {
        console.log(e);
      } finally {
        fetchingPrices.value = false;
      }

      if (searchablePriceListItems.value) {
        setPriceMatches(
          props.element.BuildingItems,
          searchablePriceListItems.value
        );
      }
    };

    const valid = computed(() => {
      let canUpdate = true;

      if (!pricesFetched.value) {
        canUpdate = false;
      }

      if (!selected.value || selected.value.length === 0) {
        canUpdate = false;
      }

      return canUpdate;
    });

    const priceUpdate = async () => {
      if (!valid || !selected.value || !chosenPriceList.value?.Name) return;

      updatingPrices.value = true;
      await updateBuildingItemPrices(
        selected.value,
        chosenPriceList.value.Name
      );
      updatingPrices.value = false;
      close();
    };

    onMounted(async () => {
      loading.value = true;
      await getPriceLists();
      loading.value = false;
      selected.value = [];
    });

    return {
      priceUpdate,
      fetchPrices,
      toggleSingle,
      isSelected,
      toggleAll,
      close,
      isEnabled,
      amountChange,
      updateProduct,
      timeUpdated,
      BUILDING_ITEM_PRICE_UPDATE,
      pricelists,
      allSelected,
      chosenPriceList,
      loading,
      valid,
      fetchingPrices,
      pricesFetched,
      updatingPrices,
    };
  },
});
