
















































import { translate } from '@/localization';
import { PriceListTreeNode, VareKategori } from '@/models/pricebook/interfaces';
import { usePriceBook } from '@/modules/pricebook';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    pricelist: {
      type: Object as PropType<PriceListTreeNode>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { addNewCategoryToPriceList } = usePriceBook();

    const dialog = ref(false);
    const categoryName = ref<string>();

    const close = () => {
      dialog.value = false;
    };

    const valid = ref();

    const submit = async () => {
      if (props.pricelist.Id && valid && categoryName.value) {
        const category: VareKategori = {
          Number: '',
          Text: categoryName.value,
          Varer: [],
        };
        await addNewCategoryToPriceList(props.pricelist.Id, category, true);
        emit('update', true);
      }
    };
    return {
      close,
      submit,
      valid,
      dialog,
      categoryName,
      rules: {
        required: (v: string) => !!v || translate('validation.required'),
        min: (v: string) =>
          (v && v.length >= 3) ||
          translate('validation.minimum-characters', [3]),
      },
    };
  },
});
