import { isSuccess } from '@/helpers/common';
import {
  RegisterUserModel,
  User,
  UserpasswordModel,
  UserpasswordPayload,
} from '@/models/auth/interfaces';
import { CustomError } from '@/models/common/interfaces';
import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { useErrorModal } from './error';
import { useLicense } from './license';
import { useSnackbar } from './snackbar';

interface AdminState {
  users?: Array<User>;
  loading: boolean;
  error?: CustomError;
}

const state = reactive<AdminState>({
  loading: false,
  users: undefined,
  error: undefined,
});

export const useUsers = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const getUsers = async (
    force?: boolean
  ): Promise<Array<User> | undefined> => {
    const { get } = useApi('/accountmanagement/account/allusers');
    if (state.users && !force) {
      return state.users;
    }

    state.loading = true;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.users = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return state.users;
  };

  const createUser = async (
    user: RegisterUserModel
  ): Promise<string | undefined> => {
    const { post } = useApi('/accountmanagement/account');

    let email = undefined;
    if (!user) return undefined;

    try {
      const response = await post(user);
      if (isSuccess(response.status)) {
        email = user.Email;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return email;
  };

  const setUserPassword = async (pw: UserpasswordModel): Promise<boolean> => {
    const { post } = useApi(`/accountmanagement/account/${pw.UserId}/password`);

    let success = false;
    if (!pw) return success;

    state.loading = true;
    const payload: UserpasswordPayload = {
      NewPassword: pw.Password,
    };

    try {
      const response = await post(payload);
      if (isSuccess(response.status)) {
        snack('snack.user-pw-change', true);
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return success;
  };

  const deleteUser = async (user: User): Promise<boolean> => {
    const { deleteReq } = useApi(`/accountmanagement/account/${user.Id}`);

    let success = false;

    if (!user.Id) return success;
    state.loading = true;

    try {
      const response = await deleteReq();
      if (isSuccess(response.status)) {
        state.users?.forEach((u, i) => {
          if (u.Id === user.Id && state.users) {
            state.users.splice(i, 1);
            const { unassignLicense } = useLicense();
            unassignLicense(user);
          }
        });
        snack('snack.user-deleted', true);
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return success;
  };

  const updateUser = async (user: User): Promise<boolean> => {
    const { put } = useApi(`/accountmanagement/account/${user.Id}`);
    let success = false;
    state.loading = true;

    try {
      const response = await put(user);
      if (isSuccess(response.status)) {
        state.users?.forEach((u, i) => {
          if (u.Id === user.Id && state.users) {
            user.FullName = `${user.FirstName} ${user.LastName}`;
            state.users.splice(i, 1);
            state.users.unshift(user);
            snack('snack.user-updated', true);
          }
        });
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    state.loading = false;
    return success;
  };

  return {
    ...toRefs(state),
    getUsers,
    updateUser,
    setUserPassword,
    deleteUser,
    createUser,
  };
};
