var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pricelist-table-card",attrs:{"tile":"","flat":""}},[_c('content-bar',{attrs:{"small":true,"title":_vm.$t('titles.products'),"breadcrumb":_vm.priceListTitle},scopedSlots:_vm._u([{key:"content-right",fn:function(){return [_c('v-text-field',{staticClass:"pricelist-search .d-none .d-sm-flex",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-2 pricelist-items",attrs:{"headers":_vm.PRICELIST_ITEM_HEADERS,"items":_vm.activePriceListItems,"loading":_vm.loading,"search":_vm.search,"loading-text":_vm.$t('info.loading'),"no-data-text":_vm.$t('info.no-data'),"no-results-text":_vm.$t('info.no-result'),"footer-props":{
      itemsPerPageText: _vm.$t('info.item-rows'),
    }},scopedSlots:_vm._u([_vm._l((_vm.PRICELIST_ITEM_HEADERS),function(h){return {key:("header." + (h.value)),fn:function(ref){return [_c('v-row',{key:h.text,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(h.text)?_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t(("headers." + (h.text.toLowerCase()))))+" ")]):_vm._e()]}}],null,true)},[(h.text)?_c('span',[_vm._v(_vm._s(_vm.$t(("headers-description." + (h.text.toLowerCase())))))]):_vm._e()])],1)],1)]}}}),{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","dense":""}},[_c('v-menu',{staticClass:"action-menu",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var menu = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-menu",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.activePricelist && !_vm.activePricelist.IsSubscription)?_c('edit-item',{attrs:{"item":item}}):_vm._e(),(_vm.activePricelist && !_vm.activePricelist.IsSubscription)?_c('delete-item',{attrs:{"item":item}}):_vm._e(),(_vm.activePricelist && !_vm.activePricelist.CompanyPriceList)?_c('copy-item',{attrs:{"item":item}}):_vm._e(),_c('v-divider'),(item.NOBBNumber)?_c('nobb-info',{attrs:{"nobbNr":item.NOBBNumber}}):_vm._e()],1)],1)],1)],1)]}},{key:"item.Price",fn:function(ref){
    var item = ref.item;
return [(_vm.calculatedPrice(item) != null)?_c('div',[_vm._v(" "+_vm._s(_vm.calculatedPrice(item).toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}},{key:"item.BasePrice",fn:function(ref){
    var item = ref.item;
return [(item.BasePrice != null)?_c('div',[_vm._v(" "+_vm._s(item.BasePrice.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }