

















































































import {
  ref,
  defineComponent,
  computed,
  PropType,
  onMounted,
  watch,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { useLibrary } from '@/modules/library';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import { cleanSource } from '@/helpers/common';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    template: {
      type: Object as PropType<BuildingTypeTemplate>,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const payload = ref<BuildingTypeTemplate>();

    watch(
      () => props.template,
      newVal => {
        payload.value = cleanSource(newVal);
      }
    );

    const valid = computed(() => {
      return props.template?.ProfessionCode && payload.value;
    });

    onMounted(() => {
      payload.value = cleanSource(props.template);
    });

    const close = () => {
      payload.value = undefined;
      dialog.value = false;
    };

    const submit = async () => {
      const {
        updateBuildingTypeTemplate,
        initCalculationLibrary,
      } = useLibrary();

      if (props.template?.ProfessionCode && payload.value) {
        loading.value = true;
        const success = await updateBuildingTypeTemplate(payload.value);
        if (success) {
          initCalculationLibrary();
          loading.value = false;
          close();
        }
      }
    };

    return {
      submit,
      close,
      dialog,
      payload,
      valid,
      loading,
    };
  },
});
