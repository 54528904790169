var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700","retain-focus":false},on:{"click:outside":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('buttons.export'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('dialog-title',{attrs:{"headerText":_vm.$t('titles.export-template'),"secondaryText":_vm.template.Name},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}])}),_c('v-card-text',{staticClass:"export-message"},[_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t('labels.select-format')))]),_c('v-item-group',[_c('v-container',[_c('v-row',{staticClass:"options"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"d-flex align-center type-card",attrs:{"height":"150","hover":"","color":_vm.type === _vm.ExportType.XML ? 'primary' : ''},on:{"click":function($event){_vm.type = _vm.ExportType.XML}}},[_c('v-icon',{class:_vm.type === _vm.ExportType.XML ? 'type-icon-active' : 'type-icon'},[_vm._v("mdi-xml")]),_c('span',{class:_vm.type === _vm.ExportType.XML ? 'type-name-active' : 'type-name'},[_vm._v("XML")]),_c('v-scroll-y-transition',[(_vm.type === _vm.ExportType.XML)?_c('div',{staticClass:"display-3 flex-grow-1"}):_vm._e()])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"d-flex align-center type-card",attrs:{"height":"150","hover":"","color":_vm.type === _vm.ExportType.JSON ? 'primary' : ''},on:{"click":function($event){_vm.type = _vm.ExportType.JSON}}},[_c('v-icon',{class:_vm.type === _vm.ExportType.JSON
                      ? 'type-icon-active'
                      : 'type-icon'},[_vm._v("mdi-code-json")]),_c('span',{class:_vm.type === _vm.ExportType.JSON
                      ? 'type-name-active'
                      : 'type-name'},[_vm._v("JSON")]),_c('v-scroll-y-transition',[(_vm.type === _vm.ExportType.JSON)?_c('div',{staticClass:"display-3 flex-grow-1"}):_vm._e()])],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"export-actions"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.export'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-export ")])],1),_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }