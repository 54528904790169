




import { defineComponent } from '@vue/composition-api';
import LibraryManager from '@/components/library/manager/index.vue';

export default defineComponent({
  components: {
    LibraryManager,
  },
});
