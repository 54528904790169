












































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useContacts } from '@/modules/contacts';
import ContactCard from '@/components/contacts/contact/index.vue';
import EditContacts from '@/components/contacts/contacts-edit-modal/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import { Contact } from '@/models/contacts/interfaces';
import Loader from '@/components/loader/index.vue';

export default defineComponent({
  components: {
    ContactCard,
    EditContacts,
    ContentBar,
    Loader,
  },
  setup() {
    const { getContacts } = useContacts();

    const dialog = ref<boolean>(false);
    const contacts = ref<Array<Contact> | undefined>(undefined);
    const loading = ref<boolean>(true);

    onMounted(async () => {
      contacts.value = await getContacts();
      loading.value = false;
    });

    return {
      dialog,
      loading,
      contacts,
    };
  },
});
