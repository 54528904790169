

































import { defineComponent } from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import DeleteProject from '@/components/projects/delete-project/index.vue';
import LockCalculation from '@/components/projects/lock-project/index.vue';
import ExportProject from '@/components/projects/export-project/index.vue';
import { useCalculation } from '@/modules/calculation';
import CalculationFactors from '@/components/factors/calculation-factors/index.vue';
import CalculationContacts from '@/components/contacts/calculation-contacts/index.vue';
import Reports from '@/components/reports/index.vue';

export default defineComponent({
  components: {
    ContentBar,
    DeleteProject,
    LockCalculation,
    CalculationFactors,
    CalculationContacts,
    ExportProject,
    Reports,
  },
  setup() {
    const { projectCalculation, activeCalculation } = useCalculation();
    return {
      projectCalculation,
      activeCalculation,
    };
  },
});
