




















import { CalculationState } from '@/constants/enum';
import { translate } from '@/localization';
import { useCalculation } from '@/modules/calculation';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const { state, updating } = useCalculation();

    const text = computed(() => {
      switch (state.value) {
        case CalculationState.Idle:
          return translate('calculation.calculation-idle');
        case CalculationState.UnsavedChanges:
          return translate('calculation.calculation-unsaved');
        case CalculationState.Updated:
          return translate('calculation.calculation-updated');
        case CalculationState.Problem:
          return translate('calculation.calculation-problem');
        default:
          return '';
      }
    });

    const icon = computed(() => {
      switch (state.value) {
        case CalculationState.Idle:
          return 'mdi-clipboard-text-clock-outline';
        case CalculationState.UnsavedChanges:
          return 'mdi-content-save-alert-outline';
        case CalculationState.Updated:
          return 'mdi-checkbox-marked-outline';
        case CalculationState.Problem:
          return 'mdi-alert-circle';
        default:
          return '';
      }
    });

    const color = computed(() => {
      switch (state.value) {
        case CalculationState.Idle:
          return 'grey--text';
        case CalculationState.UnsavedChanges:
          return 'primary--text darken-5';
        case CalculationState.Updated:
          return 'secondary--text';
        case CalculationState.Problem:
          return 'error--text';
        default:
          return '';
      }
    });

    return {
      updating,
      text,
      color,
      icon,
    };
  },
});
