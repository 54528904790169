











import { PriceList } from '@/models/pricebook/interfaces';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    file: {
      type: Object as PropType<PriceList>,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
});
