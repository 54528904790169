export const parseOptimeraFloat = (sPrice: string): number => {
  let num = 0;
  if (sPrice.length < 0) return num;
  let result = '';

  const validStrings: Array<string> = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
  ];

  for (let counter = 0; counter < sPrice.length; counter++) {
    let sign = sPrice.substr(counter, 1);
    if (!validStrings.includes(sign)) sign = '0';

    result += sign;
  }

  num = Number(result.substr(0, result.length - 2));
  num += Number(result.substr(result.length - 2)) / 100;

  return num;
};

export const parseOptimeraInt = (stringToParse: string): Nullable<number> => {
  const parsed = Number(stringToParse);
  if (Number.isSafeInteger(parsed)) {
    return parsed;
  }
  return null;
};

export const isCSVorTXT = (fileName: string) => {
  return (
    fileName.toLowerCase().includes('.txt') ||
    fileName.toLowerCase().includes('.csv') ||
    fileName.toLowerCase().includes('.scv')
  );
};

export const isExcel = (fileName: string) => {
  return fileName.toLowerCase().includes('.xlsx');
};
