import { defaultLocaleString } from '@/localization';
import {
  BuildingElementCalculation,
  BuildingItemCalculation,
} from '@/models/building-element/interfaces';
import {
  Calculation,
  ProjectCalculation,
} from '@/models/calculation/interfaces';
import { useCalculation } from '@/modules/calculation';
import { computed } from '@vue/composition-api';

export const mapCalculationProperties = (
  calc: Calculation
): ProjectCalculation => {
  const _calc: ProjectCalculation = {
    ConcurrencyToken: calc.ConcurrencyToken,
    Id: calc.Id,
    IsLocked: calc.IsLocked,
    Profession: calc.Profession,
    Hours: calc.Hours,
    Factors: calc.Factors,
    Name: calc.Name,
    MaterialsSubContractors: calc.MaterialsSubContractors,
    MaterialsUE: calc.MaterialsUE,
    SelfCost: calc.SelfCost,
    Total: calc.Total,
    Profit: calc.Profit,
    AmountControl: calc.AmountControl,
    CostSoFar: calc.CostSoFar,
    BuildingElements: [],
    Children: [],
    HasUnsavedChanges: false,
    IsFDVExportSelected: false,
    SalaryCostAdjusted: null,
    SalaryCostAdjustedCalculated: null,
    SelfCostMaterialsSubContractors: null,
  };

  return _calc;
};

export const setComputedProperties = (calc: ProjectCalculation) => {
  const { getCalculationFactorValue } = useCalculation();
  calc.HoursAdjusted = computed(() => {
    let adjusted = 0;

    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        adjusted += ele.HoursAdjusted ?? 0;
      });
    }
    return adjusted;
  });
  calc.HoursTotal = computed(() => {
    let total = 0;

    if (
      calc.Hours != null &&
      calc.HoursAdjusted?.value != null &&
      calc.HoursAdjusted.value > 0
    ) {
      total = calc.Hours + calc.HoursAdjusted.value;
    }

    return total;
  });
  calc.HourCostToCustomer = computed(() => {
    let cost: Nullable<number> = null;

    const f2 = getCalculationFactorValue(2, calc);
    const f3 = getCalculationFactorValue(3, calc);
    const f4 = getCalculationFactorValue(4, calc);
    const f6 = getCalculationFactorValue(6, calc);

    if (f2 && f3 && f4 && f6) {
      cost = f2 * (f3 - f4 - 1) * f6;
    }

    return cost;
  });
  calc.SumAmountControl = computed(() => {
    let amountControl = 0;

    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumAmountControl?.value != null)
          amountControl += ele.SumAmountControl.value;
      });
    }

    return amountControl;
  });
  calc.SumSalaryCostAdjusted = computed(() => {
    let sumAdjusted = 0;

    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SalaryCostAdjustedCalculated?.value != null)
          sumAdjusted += ele.SalaryCostAdjustedCalculated.value;
      });
    }

    return sumAdjusted;
  });
  calc.SumSalaryAndExpensesAdjusted = computed(() => {
    let sum = 0;

    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumSalaryAndExpensesAdjusted?.value != null)
          sum += ele.SumSalaryAndExpensesAdjusted.value;
      });
    }

    return sum;
  });
  calc.SumSalaryAndSocialCost = computed(() => {
    let sum = 0;

    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumSalaryAndSocialCost?.value != null)
          sum += ele.SumSalaryAndSocialCost.value;
      });
    }

    return sum;
  });
  calc.SumMaterialsAndCostAdjusted = computed(() => {
    let adjusted = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumMaterialsAndCostAdjusted?.value != null)
          adjusted += ele.SumMaterialsAndCostAdjusted.value;
      });
    }
    return adjusted;
  });
  calc.MaterialCostAdjusted = computed(() => {
    let materialCost = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        materialCost += ele.MaterialCostAdjusted ?? 0;
      });
    }
    return materialCost;
  });
  calc.SumCorrections = computed(() => {
    let sum = 0;

    if (
      calc.MaterialCostAdjusted?.value != null &&
      calc.MaterialCostAdjusted.value > 0 &&
      calc.SalaryCostAdjusted != null
    ) {
      sum = calc.MaterialCostAdjusted.value + calc.SalaryCostAdjusted;
    }

    return sum;
  });
  calc.SumTotalCorrected = computed(() => {
    let sum = 0;

    if (
      calc.Total != null &&
      calc.SumCorrections?.value != null &&
      calc.SumCorrections.value > 0
    ) {
      sum = calc.Total + calc.SumCorrections.value;
    }

    return sum;
  });
  calc.SumTotal = computed(() => {
    let sum = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumTotal?.value != null) sum += ele.SumTotal.value;
      });
    }
    return sum;
  });
  calc.SumProfitCorrected = computed(() => {
    let sum = 0;

    if (
      calc.Profit != null &&
      calc.SumCorrections?.value != null &&
      calc.SumCorrections.value > 0
    ) {
      sum = calc.Profit - calc.SumCorrections.value;
    }

    return sum;
  });
  calc.SumMarginCorrected = computed(() => {
    let sum = 0;

    if (
      calc.SumFactor4?.value &&
      calc.SumFactor4.value != null &&
      calc.SumFactor4.value > 0 &&
      calc.SumProfitCorrected?.value != null &&
      calc.SumProfitCorrected.value > 0
    ) {
      sum = calc.SumFactor4.value + calc.SumProfitCorrected.value;
    }

    return sum;
  });
  calc.CoverageCorrected = computed(() => {
    let sum = 0;

    if (
      calc.SumTotal?.value != null &&
      calc.SumTotal.value > 0 &&
      calc.SumMarginCorrected != null &&
      calc.SumMarginCorrected.value > 0
    ) {
      sum = (calc.SumTotal.value / calc.SumMarginCorrected.value) * 100;
    }

    return sum;
  });
  calc.SumHours = computed(() => {
    let hours = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumHours?.value != null) hours += ele.SumHours.value;
      });
    }
    return hours;
  });
  calc.SumMaterialsUE = computed(() => {
    let sum = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumMaterialsUE?.value) sum += ele.SumMaterialsUE.value;
      });
    }
    return sum;
  });
  calc.SumSelfCostMaterialsSubContractors = computed(() => {
    let sum = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumSelfCostMaterialsSubContractors?.value)
          sum += ele.SumSelfCostMaterialsSubContractors.value;
      });
    }
    return sum;
  });
  calc.SumSelfCost = computed(() => {
    let sum = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumSelfCost?.value != null) sum += ele.SumSelfCost.value;
      });
    }
    return sum;
  });
  calc.SumProfit = computed(() => {
    let sum = 0;
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(ele => {
        if (ele.SumProfit?.value != null) sum += ele.SumProfit.value;
      });
    }
    return sum;
  });
  calc.SumMargin = computed(() => {
    let margin = 0;

    if (calc.BuildingElements) {
      margin = calc.BuildingElements.reduce((prev, cur) => {
        return prev + (cur.SumMargin.value ?? 0);
      }, 0);
    }
    return margin;
  });
  calc.Coverage = computed(() => {
    let sum = 0;

    if (
      calc.SumTotal?.value != null &&
      calc.SumTotal.value > 0 &&
      calc.SumMargin != null &&
      calc.SumMargin.value > 0
    ) {
      sum = (calc.SumMargin.value / calc.SumTotal.value) * 100;
    }

    return sum;
  });
  calc.SumFactor4 = computed(() => {
    let sum = 0;
    if (calc.BuildingElements) {
      sum = calc.BuildingElements.reduce((prev, cur) => {
        return prev + (cur.Factor4?.value ?? 0);
      }, 0);
    }
    return sum;
  });

  calc.SumTotalEnterprise = computed(() => {
    let sum = 0;
    if (calc.Children) {
      calc.Children.forEach(child => {
        sum += child.Total ?? 0;
      });
    }
    return sum;
  });

  calc.ActiveBuildingElements = computed(() => {
    let activeElements: Array<BuildingElementCalculation> = [];

    activeElements = calc.BuildingElements.filter(
      element => element.IsAmountSet.value
    );

    if (activeElements) {
      activeElements = activeElements.sort((a, b) =>
        (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
          ? 1
          : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
          ? -1
          : 0
      );
    }

    return activeElements;
  });

  calc.ActiveBuildingItems = computed(() => {
    let activeItems: Array<BuildingItemCalculation> = [];
    if (calc.BuildingElements) {
      calc.BuildingElements.forEach(element => {
        let filteredItems: Array<BuildingItemCalculation> = [];

        filteredItems = element.BuildingItems.filter(
          item =>
            item.IsAmountSet.value && item.NOBBNumber && item.PriceListItemName
        );

        if (filteredItems) {
          filteredItems = filteredItems.sort((a, b) =>
            (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
              ? 1
              : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
              ? -1
              : 0
          );

          activeItems = activeItems.concat(filteredItems);
        }
      });
    }
    return activeItems;
  });

  calc.AllBuildingItems = computed(() => {
    let activeItems: Array<BuildingItemCalculation> = [];

    if (calc.ActiveBuildingElements?.value) {
      calc.ActiveBuildingElements.value.forEach(element => {
        let filteredItems: Array<BuildingItemCalculation> = [];

        filteredItems = element.BuildingItems.filter(
          item => item.IsAmountSet.value
        );

        if (filteredItems) {
          filteredItems = filteredItems.sort((a, b) =>
            (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
              ? 1
              : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
              ? -1
              : 0
          );

          activeItems = activeItems.concat(filteredItems);
        }
      });
    }
    return activeItems;
  });
};

export const sumAmountControl = (calc: ProjectCalculation) => {
  let amountControl = 0;

  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumAmountControl?.value != null)
        amountControl += ele.SumAmountControl.value;
    });
  }

  return amountControl;
};

export const sumSalaryCostAdjusted = (calc: ProjectCalculation) => {
  let sumAdjusted = 0;

  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SalaryCostAdjustedCalculated?.value != null)
        sumAdjusted += ele.SalaryCostAdjustedCalculated.value;
    });
  }

  return sumAdjusted;
};

export const sumSalaryAndExpensesAdjusted = (calc: ProjectCalculation) => {
  let sum = 0;

  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumSalaryAndExpensesAdjusted?.value != null)
        sum += ele.SumSalaryAndExpensesAdjusted.value;
    });
  }

  return sum;
};

export const sumSalaryAndSocialCost = (calc: ProjectCalculation) => {
  let sum = 0;

  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumSalaryAndSocialCost?.value)
        sum += ele.SumSalaryAndSocialCost.value;
    });
  }

  return sum;
};

export const sumMaterialsAndCostAdjusted = (calc: ProjectCalculation) => {
  let adjusted = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumMaterialsAndCostAdjusted?.value)
        adjusted += ele.SumMaterialsAndCostAdjusted.value;
    });
  }
  return adjusted;
};

export const materialCostAdjusted = (calc: ProjectCalculation) => {
  let materialCost = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      materialCost += ele.MaterialCostAdjusted ?? 0;
    });
  }
  return materialCost;
};

export const sumCorrections = (calc: ProjectCalculation) => {
  let sum = 0;

  if (
    calc.MaterialCostAdjusted != null &&
    calc.MaterialCostAdjusted.value > 0 &&
    calc.SalaryCostAdjusted != null
  ) {
    sum = calc.MaterialCostAdjusted.value + calc.SalaryCostAdjusted;
  }

  return sum;
};

export const sumTotalCorrected = (calc: ProjectCalculation) => {
  let sum = 0;

  if (
    calc.Total &&
    calc.SumCorrections != null &&
    calc.SumCorrections.value > 0
  ) {
    sum = calc.Total + calc.SumCorrections.value;
  }

  return sum;
};

export const sumTotal = (calc: ProjectCalculation) => {
  let sum = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumTotal?.value != null) sum += ele.SumTotal.value;
    });
  }
  return sum;
};

export const sumProfitCorrected = (calc: ProjectCalculation) => {
  let sum = 0;

  if (
    calc.Profit != null &&
    calc.SumCorrections != null &&
    calc.SumCorrections.value > 0
  ) {
    sum = calc.Profit - calc.SumCorrections.value;
  }

  return sum;
};

export const sumMarginCorrected = (calc: ProjectCalculation) => {
  let sum = 0;

  if (
    calc.SumFactor4 &&
    calc.SumFactor4.value &&
    calc.SumFactor4.value > 0 &&
    calc.SumProfitCorrected &&
    calc.SumProfitCorrected.value > 0
  ) {
    sum = calc.SumFactor4.value + calc.SumProfitCorrected.value;
  }

  return sum;
};

export const coverageCorrected = (calc: ProjectCalculation) => {
  let sum = 0;

  if (
    calc.SumTotal?.value != null &&
    calc.SumTotal.value > 0 &&
    calc.SumMarginCorrected &&
    calc.SumMarginCorrected.value > 0
  ) {
    sum = (calc.SumMarginCorrected.value / calc.SumTotal.value) * 100;
  }

  return sum;
};

export const sumCoverage = (calc: ProjectCalculation) => {
  let sum = 0;

  if (
    calc.SumTotal?.value != null &&
    calc.SumTotal.value > 0 &&
    calc.SumMargin != null &&
    calc.SumMargin.value > 0
  ) {
    sum = (calc.SumMargin.value / calc.SumTotal.value) * 100;
  }

  return sum;
};

export const safePercentage = (fraction1: number, fraction2: number) => {
  let sum = 0;

  if (
    fraction1 != null &&
    fraction1 > 0 &&
    fraction2 != null &&
    fraction2 > 0
  ) {
    sum = (fraction2 / fraction1) * 100;
  }

  return sum;
};

export const sumHours = (calc: ProjectCalculation) => {
  let hours = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumHours.value != null) hours += ele.SumHours.value;
    });
  }
  return hours;
};

export const sumMaterialsUE = (calc: ProjectCalculation) => {
  let sum = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumMaterialsUE?.value) sum += ele.SumMaterialsUE.value;
    });
  }
  return sum;
};

export const sumSelfCostMaterialsSubContractors = (
  calc: ProjectCalculation
) => {
  let sum = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumSelfCostMaterialsSubContractors?.value)
        sum += ele.SumSelfCostMaterialsSubContractors.value;
    });
  }
  return sum;
};

export const sumSelfCost = (calc: ProjectCalculation) => {
  let sum = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumSelfCost?.value != null) sum += ele.SumSelfCost.value;
    });
  }
  return sum;
};

export const sumProfit = (calc: ProjectCalculation) => {
  let sum = 0;
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(ele => {
      if (ele.SumProfit?.value != null) sum += ele.SumProfit.value;
    });
  }
  return sum;
};

export const sumFactor4 = (calc: ProjectCalculation) => {
  let sum = 0;
  if (calc.BuildingElements) {
    sum = calc.BuildingElements.reduce((prev, cur) => {
      return prev + (cur.Factor4.value ?? 0);
    }, 0);
  }
  return sum;
};

export const sumMargin = (calc: ProjectCalculation) => {
  let margin = 0;

  if (calc.BuildingElements) {
    margin = calc.BuildingElements.reduce((prev, cur) => {
      return prev + (cur.SumMargin.value ?? 0);
    }, 0);
  }
  return margin;
};

export const sumTotalEnterprise = (calc: ProjectCalculation) => {
  let sum = 0;
  if (calc.Children) {
    calc.Children.forEach(child => {
      sum += child.Total ?? 0;
    });
  }
  return sum;
};

export const activeBuildingItems = (calc: ProjectCalculation) => {
  let activeItems: Array<BuildingItemCalculation> = [];
  if (calc.BuildingElements) {
    calc.BuildingElements.forEach(element => {
      let filteredItems: Array<BuildingItemCalculation> = [];

      filteredItems = element.BuildingItems.filter(
        item => item.IsAmountSet.value
      );

      if (filteredItems) {
        filteredItems = filteredItems.sort((a, b) =>
          (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
            ? 1
            : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
            ? -1
            : 0
        );

        activeItems = activeItems.concat(filteredItems);
      }
    });
  }
  return activeItems;
};

export const allBuildingItems = (calc: ProjectCalculation) => {
  let activeItems: Array<BuildingItemCalculation> = [];

  if (calc.ActiveBuildingElements?.value) {
    calc.ActiveBuildingElements.value.forEach(element => {
      let filteredItems: Array<BuildingItemCalculation> = [];

      filteredItems = element.BuildingItems.filter(
        item => item.IsAmountSet.value
      );

      if (filteredItems) {
        filteredItems = filteredItems.sort((a, b) =>
          (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
            ? 1
            : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
            ? -1
            : 0
        );

        activeItems = activeItems.concat(filteredItems);
      }
    });
  }
  return activeItems;
};

export const getCalculationTotalByProp = (
  propName: string,
  calc: ProjectCalculation
) => {
  const { getCalculationFactorValue } = useCalculation();
  const calcFactor1 = getCalculationFactorValue(1, calc);
  const calcFactor2 = getCalculationFactorValue(2, calc);
  const calcFactor3 = getCalculationFactorValue(3, calc);
  const calcFactor4 = getCalculationFactorValue(4, calc);
  const calcFactor6 = getCalculationFactorValue(6, calc);
  const calcFactor7 = getCalculationFactorValue(7, calc);
  switch (propName) {
    case 'SumTotal':
      return sumTotal(calc).toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 0,
      });
    case 'SumHours':
      return sumHours(calc).toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 2,
      });
    case 'Factor1':
      return (
        calcFactor1 &&
        calcFactor1.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor2':
      return (
        calcFactor2 &&
        calcFactor2.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor3':
      return (
        calcFactor3 &&
        calcFactor3.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor4':
      return (
        calcFactor4 &&
        calcFactor4.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor6':
      return (
        calcFactor6 &&
        calcFactor6.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'Factor7':
      return (
        calcFactor7 &&
        calcFactor7.toLocaleString(defaultLocaleString, {
          maximumFractionDigits: 2,
        })
      );
    case 'SumMaterialsUE':
      return sumMaterialsUE(calc).toLocaleString(defaultLocaleString, {
        maximumFractionDigits: 0,
      });
    case 'SumSelfCostMaterialsSubContractors':
      return sumSelfCostMaterialsSubContractors(calc).toLocaleString(
        defaultLocaleString,
        {
          maximumFractionDigits: 0,
        }
      );
  }
};

export const getCalculationTotalTypography = (propName: string) => {
  switch (propName) {
    case 'SumTotal':
      return 'font-weight-medium ma-1 font-italic green--text text--lighten-4';
    case 'SumHours':
      return 'font-weight-medium ma-1 font-italic orange--text text--lighten-4';
    case 'Factor1':
      return 'font-weight-medium ma-1 font-italic blue--text text--lighten-4';
    case 'Factor2':
      return 'font-weight-medium ma-1 font-italic blue--text text--lighten-4';
    case 'Factor3':
      return 'font-weight-medium ma-1 font-italic blue--text text--lighten-4';
    case 'Factor4':
      return 'font-weight-medium ma-1 font-italic blue--text text--lighten-4';
    case 'Factor6':
      return 'font-weight-medium ma-1 font-italic blue--text text--lighten-4';
    case 'Factor7':
      return 'font-weight-medium ma-1 font-italic blue--text text--lighten-4';
    case 'SumMaterialsUE':
      return 'font-weight-medium ma-1 font-italic purple--text text--lighten-4';
    case 'SumSelfCostMaterialsSubContractors':
      return 'font-weight-medium ma-1 font-italic purple--text text--lighten-4';
  }
};
