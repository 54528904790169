


































































import { ref, defineComponent, PropType, computed } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';
import { BuildingItemTemplate } from '@/models/library/interfaces';
import { useCalculation } from '@/modules/calculation';
import { useBuildingItem } from '@/modules/building-item';
import { useBatch } from '@/modules/batch';
import { BatchType } from '@/constants/enum';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    template: {
      type: Object as PropType<BuildingItemTemplate>,
      required: true,
    },
    icon: {
      type: Boolean,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const loading = ref(false);
    const selected = ref<string>();

    const { projectCalculation } = useCalculation();
    const { createBICFromBuildingItemTemplate } = useBuildingItem();
    const { registerBuildingItemBatchOperation } = useBatch();

    const valid = computed(() => {
      return selected.value != null;
    });

    const close = () => {
      dialog.value = false;
      selected.value = undefined;
    };

    const submit = () => {
      if (
        valid &&
        selected.value &&
        props.template &&
        projectCalculation.value?.BuildingElements
      ) {
        const element = projectCalculation.value.BuildingElements.find(
          ele => ele.Id === selected.value
        );

        if (element?.Id) {
          const newItemCalc = createBICFromBuildingItemTemplate(
            props.template,
            element,
            element.Id
          );
          element.BuildingItems.push(newItemCalc);
          element.IsExpanded = true;
          registerBuildingItemBatchOperation(newItemCalc, BatchType.Insert);
        }

        close();
      }
    };

    return {
      close,
      submit,
      dialog,
      loading,
      projectCalculation,
      buildingElements: computed(
        () => projectCalculation.value?.BuildingElements
      ),
      valid,
      selected,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
    };
  },
});
