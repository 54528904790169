





































import { defineComponent } from '@vue/composition-api';
import { useCalculation } from '@/modules/calculation';
import ContentBar from '@/components/common/content-bar/index.vue';
import BuildingElementEnvironment from '@/components/environment/BuildingElementEnvironment.vue';
import Reports from '@/components/reports/index.vue';
import CalculationEnvironmentalImpact from '@/components/environment/CalculationEnvironmentalImpact.vue';
import GenerateReport from '@/components/environment/GenerateReport.vue';

export default defineComponent({
  name: 'Environment',
  components: {
    ContentBar,
    BuildingElementEnvironment,
    Reports,
    CalculationEnvironmentalImpact,
    GenerateReport,
  },
  setup() {
    const { projectCalculation, activeCalculation } = useCalculation();

    return {
      projectCalculation,
      activeCalculation,
    };
  },
});
