









import { PropType, defineComponent } from '@vue/composition-api';
import { NobbItem } from '@/models/nobb/interfaces';
import { unitDescriptionConverter } from '@/helpers/common';

export default defineComponent({
  props: {
    nobbItem: {
      type: Object as PropType<NobbItem>,
      required: true,
    },
  },
  setup() {
    return {
      unitDescriptionConverter,
    };
  },
});
