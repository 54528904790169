import { Locales } from './locales';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/localization/locales/en.json';
import no from '@/localization/locales/no.json';
import fo from '@/localization/locales/fo.json';

Vue.use(VueI18n);

export const messages = {
  [Locales.EN]: en,
  [Locales.NO]: no,
  [Locales.FO]: fo,
};

export const defaultLocale = Locales.NO;
export const defaultLocaleString = 'no';

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: Locales.EN,
  messages,
});

export const translate = (key: string, values?: VueI18n.Values) => {
  if (!key) {
    return '';
  }
  return i18n.t(key, values);
};
