var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"building-element-environment-card ma-2 pa-2",attrs:{"tile":"","elevation":"1"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.props.calculation.Name)+" ")]),_c('v-subheader',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-home-outline ")]),_vm._v(_vm._s(_vm.$t('labels.building-elements'))+" ("+_vm._s(_vm.props.calculation.BuildingElements.length)+")")],1),(_vm.props.calculation.BuildingElements)?_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('common.building-element'))+" ")]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('environment.gwp-iobc'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.gwp-iobc')))])])],1)])]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('environment.climate-imprint'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.climate-imprint')))])])],1)])])])]),_c('tbody',[_vm._l((_vm.props.calculation.BuildingElements),function(element,i){return [_c('BuildingElementSumRow',{key:i,attrs:{"building-element":element},on:{"sum":function (sum) { return (_vm.calculationSumTotal = _vm.calculationSumTotal + sum); },"sum-calculated":function (calc) { return (_vm.calculationCalculatedSumTotal =
                  _vm.calculationCalculatedSumTotal + calc); }}})]}),_c('tr',{staticClass:"sum-row"},[_c('td',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('labels.sum')))])]),_c('td',{staticClass:"font-weight-bold"},[(_vm.calculationSumTotal)?_c('div',[_vm._v(" "+_vm._s(((_vm.calculationSumTotal.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 10, })) + " " + (_vm.$t('environment.kg-carbondioxide-equivalent'))))+" ")]):_c('div',[_vm._v(" - ")])]),_c('td',{staticClass:"font-weight-bold"},[(_vm.calculationCalculatedSumTotal)?_c('div',[_vm._v(" "+_vm._s(((_vm.calculationCalculatedSumTotal.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 10, } )) + " " + (_vm.$t('environment.kg-carbondioxide-equivalent'))))+" ")]):_c('div',[_vm._v(" - ")])])])],2)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }