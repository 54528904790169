










import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    version: {
      type: String,
    },
    date: {
      type: String,
    },
    current: {
      type: Boolean,
    },
  },
});
