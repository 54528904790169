























































































import { computed, defineComponent, useSlots } from '@vue/composition-api';
import { VersionNoteType } from '@/constants/enum';

export default defineComponent({
  props: {
    text: {
      type: String,
    },
    type: {
      type: Number,
    },
    thanks: {
      type: String,
    },
    org: {
      type: String,
    },
    site: {
      type: String,
    },
  },
  setup() {
    const slots = useSlots();
    const hasDefault = computed(() => slots.default !== undefined);

    return {
      VersionNoteType,
    };
  },
});
