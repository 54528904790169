var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500","retain-focus":false},on:{"click:outside":_vm.close},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('buttons.download-pricelist'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('dialog-title',{attrs:{"headerText":_vm.$t('buttons.download-pricelist')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}])}),_c('v-card-text',{staticClass:"pa-5"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[(
              !_vm.loadingPriceLists &&
                _vm.downloadableFiles &&
                _vm.downloadableFiles.length > 0
            )?_c('v-col',[_c('v-list',{attrs:{"shaped":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('titles.pricelists')))]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}},_vm._l((_vm.downloadableFiles),function(file){return _c('downloadable-pricelist',{key:file.Id,attrs:{"icon":'mdi-library',"file":file}})}),1)],1)],1):(_vm.loadingPriceLists)?_c('v-col',[_c('loader',{attrs:{"margins":true}})],1):_c('v-col',[_c('v-alert',{attrs:{"dense":"","type":"info","tile":"","color":"orange"}},[_vm._v(" "+_vm._s(_vm.$t('info.no-downloadable'))+" ")])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.download'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download ")])],1),_c('v-btn',{staticClass:"ma-2",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }