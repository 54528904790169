














































































































import { ref, defineComponent } from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import { PRICELIST_ITEM_HEADERS } from '@/constants/table-headers';
export default defineComponent({
  components: {
    ContentBar,
  },
  setup() {
    const dialog = ref(false);
    const loading = ref(false);
    const search = ref<string>('');

    const close = () => {
      dialog.value = false;
    };

    return {
      close,
      dialog,
      loading,
      search,
      PRICELIST_ITEM_HEADERS,
    };
  },
});
