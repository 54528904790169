






















































































































































import { reactive, toRefs, ref, defineComponent } from '@vue/composition-api';
import { LoginPayload } from '@/models/auth/interfaces';
import { useAuth } from '@/modules/auth';
import { GRANT_TYPE_PW } from '@/constants/common';
import { PROJECT_START_PATH } from '@/constants/routes';
import LicenseForm from '@/components/forms/license-form/index.vue';
import router from '@/router/index';
import ResetPassword from '@/components/reset-password/index.vue';

const payload = reactive<LoginPayload>({
  username: '',
  password: '',
  // eslint-disable-next-line prettier/prettier
  // eslint-disable-next-line @typescript-eslint/camelcase
  grant_type: GRANT_TYPE_PW,
  rememberMe: true,
});

export default defineComponent({
  components: {
    LicenseForm,
    ResetPassword,
  },
  setup() {
    const { login, error, license } = useAuth();

    const valid = ref(false);
    const loading = ref(false);
    const licenseAttention = ref<boolean>(
      license ? (license.value ? !license.value.LicenseOk : false) : false
    );

    const submit = async () => {
      const params = new URLSearchParams();
      params.append('username', payload.username);
      params.append('password', payload.password);
      params.append('grant_type', payload.grant_type);

      loading.value = true;
      await login(params, payload.rememberMe);

      if (!error?.value && router.currentRoute.path !== PROJECT_START_PATH) {
        await router.push(PROJECT_START_PATH);
      }
      loading.value = false;
    };

    return {
      ...toRefs(payload),
      error,
      loading,
      submit,
      usernameRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [(v: string) => !!v || 'Password is required'],
      valid,
      licenseAttention,
      registerUrl: process.env.VUE_APP_REGISTER_URL,
    };
  },
});
