import { ComputedRef } from '@vue/composition-api';
import { ProjectCalculation } from '../calculation/interfaces';
import { TimeFactor } from '../common/interfaces';
import { PriceMatch } from '../pricebook/interfaces';

interface Entity {
  Id: Nullable<string>;
  Created?: Nullable<string>;
  LastModified?: Nullable<string>;
}

export interface BaseBuildingElement extends Entity {
  Name: Nullable<string>;
  ProjectId: Nullable<string>;
  ProductionLine: Nullable<string>;
  SortOrder: Nullable<number>;
  NS3450: Nullable<string>;
  NS3451: Nullable<string>;
  Amount: Nullable<number>;
  Unit?: Nullable<string>;
  Factor1?: Nullable<number>;
  Factor5?: Nullable<number>;
  Transport: Nullable<number>;
  Comments: Nullable<string>;
  HoursAdjusted?: Nullable<number>;
  HoursUsedSoFar?: Nullable<number>;
  SalaryCostAdjusted?: Nullable<number>;
  SalaryCostSoFar?: Nullable<number>;
  MaterialCostAdjusted?: Nullable<number>;
  MaterialCostSoFar?: Nullable<number>;
}

export interface AdditionItem extends Entity {
  Name?: Nullable<string>;
  BuildingElementId?: Nullable<string>;
  SortOrder?: Nullable<number>;
  Unit?: Nullable<string>;
  Amount?: Nullable<number>;
  AmountAdjustment?: Nullable<number>;
  AmountPrUnit?: Nullable<number>;
  Price?: Nullable<number>;
  Factor1?: Nullable<number>;
  Comments?: Nullable<string>;
}

export interface BuildingElement extends BaseBuildingElement {
  Items: Array<BuildingItem>;
  AdditionItems: Array<AdditionItem>;
}

export interface BuildingItem extends Entity {
  BuildingElementId?: Nullable<string>;
  Name?: Nullable<string>;
  Unit?: Nullable<string>;
  UnitForOrder?: Nullable<string>;
  SortOrder?: Nullable<number>;
  Amount?: Nullable<number>;
  AmountPrUnit?: Nullable<number>;
  Price?: Nullable<number>;
  Factor1?: Nullable<number>;
  Factor5?: Nullable<number>;
  PriceListName?: Nullable<string>;
  PriceListItemName?: Nullable<string>;
  Comments?: Nullable<string>;
  MileStone?: Nullable<string>;
  AmountAdjustment?: Nullable<number>;
  AccountNumber?: Nullable<number>;
  NOBBNumber?: Nullable<number>;
  NS3420?: Nullable<string>;
  BaseTime?: Nullable<number>;
  Precut?: Nullable<boolean>;
  SurfaceAddition?: Nullable<number>;
  Transport?: Nullable<number>;
  AmountControl?: Nullable<number>;
  OpeningAddition?: Nullable<number>;
  IsFDVExportSelected?: Nullable<boolean>;
  DeliveryOrder?: Nullable<number>;
  TransportationAddition?: Nullable<number>;
  TimeEstimateName?: Nullable<string>;
}

export interface ElementItem extends Entity {
  BuildingElement?: BuildingElementCalculation;
  BuildingElementId?: Nullable<string>;
  Name: Nullable<string>;
  PriceListItemName: Nullable<string>;
  Unit: Nullable<string>;
  UnitForOrder: Nullable<string>;
  SortOrder: Nullable<number>;
  Amount: Nullable<number>;
  AmountPrUnit: Nullable<number>;
  AmountCalculated: ComputedRef<Nullable<number>>;
  Price?: Nullable<number>;
  TimePrUnit: ComputedRef<Nullable<number>>;
  SumHours: ComputedRef<Nullable<number>>;
  Factor2: ComputedRef<Nullable<number>>;
  SumProductionSalary: ComputedRef<Nullable<number>>;
  Factor3: ComputedRef<Nullable<number>>;
  Factor4: ComputedRef<Nullable<number>>;
  ProjectCost: ComputedRef<Nullable<number>>;
  SumSelfCostSalaryAndExpenses: ComputedRef<Nullable<number>>;
  SumSelfCostMaterialsSubContractors: ComputedRef<Nullable<number>>;
  Factor6: ComputedRef<Nullable<number>>;
  SumSalaryAndSocialCost: ComputedRef<Nullable<number>>;
  SumSalaryAndExpenses: ComputedRef<Nullable<number>>;
  SumSelfCost: ComputedRef<Nullable<number>>;
  SumProfit: ComputedRef<Nullable<number>>;
  UnitPrice: ComputedRef<Nullable<number>>;
  UnitPriceWithAdditions: ComputedRef<Nullable<number>>;
  UnitPriceMaterials: ComputedRef<Nullable<number>>;
  SumTotal: ComputedRef<Nullable<number>>;
  SumTotalWithAdditions: ComputedRef<Nullable<number>>;
  Comments?: Nullable<string>;
  SumMaterialsUE: ComputedRef<Nullable<number>>;
  MileStone?: Nullable<string>;
  NS3420?: Nullable<string>;
  SumTime: ComputedRef<Nullable<number>>;
}

export interface WarningStatus {
  StatusType: ItemStatus;
  StatusColor: string;
  StatusText: string;
}

export interface BuildingItemCalculation extends ElementItem {
  Items: Array<ElementItem>;
  TimeFactors: Array<TimeFactor>;
  ReportPriceListName: ComputedRef<Nullable<string>>;
  IsHighLighted?: boolean;
  IsRecentlyAdded: boolean;
  IsSelected: boolean;
  IsFDVExportSelected: boolean;
  AccountNumber?: Nullable<number>;
  AmountAdjustment?: Nullable<number>;
  AmountControl?: Nullable<number>;
  BaseTime?: Nullable<number>;
  DisplayName?: Nullable<string>;
  DeliveryOrder?: Nullable<number>;
  Factor1?: Nullable<number>;
  Factor5?: Nullable<number>;
  NOBBNumber?: Nullable<number>;
  Factor7: ComputedRef<number>;
  OpeningAddition?: Nullable<number>;
  Precut?: Nullable<boolean>;
  PriceListName?: Nullable<string>;
  SurfaceAddition?: Nullable<number>;
  TimeEstimateName?: Nullable<string>;
  Transport?: Nullable<number>;
  TransportationAddition?: Nullable<number>;
  IsTimeFactorAdded: ComputedRef<boolean>;
  IsAmountSet: ComputedRef<boolean>;
  IsTimeEstimateAdded: ComputedRef<boolean>;
  PreCutText: ComputedRef<Nullable<string>>;
  Factor1Calculated: ComputedRef<Nullable<number>>;
  Factor5Calculated: ComputedRef<Nullable<number>>;
  AmountOfMaterials: ComputedRef<Nullable<number>>;
  DeviationAmount: ComputedRef<number>;
  DeviationPrice: ComputedRef<number>;
  OpeningAdditionAmount: ComputedRef<Nullable<number>>;
  SurfaceAdditionAmount: ComputedRef<Nullable<number>>;
  SumAdditions: ComputedRef<Nullable<number>>;
  SumMargin: ComputedRef<number>;
  IsModifiedRecently: boolean;
  TransportCalculated?: ComputedRef<Nullable<number>>;
  DocumentationDownloaded: DocumentationStatus;
  ItemWarnings: Array<string>;
  Status: Nullable<WarningStatus>;
  PriceMatch: Nullable<PriceMatch>;
}

export enum AdditionType {
  Åpninger,
  Flatetillegg,
}

export enum DocumentationStatus {
  Default,
  Succeeded,
  Failed,
}

export enum ItemStatus {
  MissingNobb,
  MatchWithPriceDiff,
  MatchWithUnitDiff,
  MatchNoDiff,
}

export interface AdditionItemCalculation extends ElementItem {
  Items: Array<ElementItem>;
  Factor1?: Nullable<number>;
  BaseTime: ComputedRef<Nullable<number>>;
  AmountAdjusted?: Nullable<number>;
  AmountAdjustment?: Nullable<number>;
  Factor1Calculated: ComputedRef<Nullable<number>>;
}

export interface BuildingElementCalculation extends Entity {
  Items: Array<ElementItem>;
  BuildingItems: Array<BuildingItemCalculation>;
  AdditionItems: Array<AdditionItemCalculation>;
  ProjectId?: Nullable<string>;
  Project?: ProjectCalculation;
  Name: Nullable<string>;
  Unit?: ComputedRef<string>;
  Amount: Nullable<number>;
  AmountCalculated: ComputedRef<number>;
  AmountOfMaterials: ComputedRef<number>;
  SumTime: ComputedRef<Nullable<number>>;
  DeliveryOrder?: ComputedRef<Nullable<number>>;
  Comments: Nullable<string>;
  NS3450: Nullable<string>;
  NS3451: Nullable<string>;
  SortOrder: Nullable<number>;
  ProductionLine: Nullable<string>;
  Transport: Nullable<number>;
  TransportAddition: Nullable<number>;
  OpeningAddition: ComputedRef<Nullable<number>>;
  SurfaceAddition: ComputedRef<Nullable<number>>;
  TransportationAddition?: Nullable<number>;
  IsAmountSet: ComputedRef<boolean>;
  HasBuildingItems: ComputedRef<boolean>;
  IsHighLighted: ComputedRef<boolean>;
  IsRecentlyAdded: boolean;
  IsSelected: boolean;
  IsExpanded: boolean;
  HoursAdjusted?: Nullable<number>;
  MaterialCostAdjusted?: Nullable<number>;
  SalaryCostAdjusted?: Nullable<number>;
  HoursAdjustedVM: ComputedRef<number>;
  SalaryCostAdjustedCalculated: ComputedRef<number>;
  TimePrUnit: ComputedRef<number>;
  SumHoursAdjusted: ComputedRef<number>;
  SumMaterialsAndCostAdjusted: ComputedRef<Nullable<number>>;
  SumSalaryAndExpensesAdjusted: ComputedRef<number>;
  SumHours: ComputedRef<number>;
  Factor2: ComputedRef<Nullable<number>>;
  SumProductionSalary: ComputedRef<number>;
  ProjectCost: ComputedRef<Nullable<number>>;
  Factor3: ComputedRef<number>;
  SumSalaryAndSocialCost: ComputedRef<number>;
  Factor4: ComputedRef<number>;
  SumSelfCostSalaryAndExpenses: ComputedRef<number>;
  Factor6: ComputedRef<Nullable<number>>;
  SumSalaryAndExpenses: ComputedRef<Nullable<number>>;
  UnitPriceMaterials: ComputedRef<Nullable<number>>;
  SumSelfCostMaterialsSubContractors: ComputedRef<number>;
  Factor7: ComputedRef<Nullable<number>>;
  SumMaterialsUE: ComputedRef<number>;
  SumSelfCost: ComputedRef<number>;
  UnitPrice?: ComputedRef<Nullable<number>>;
  UnitPriceWithAdditions?: ComputedRef<Nullable<number>>;
  SumProfit: ComputedRef<number>;
  SumTotal: ComputedRef<number>;
  SumAmountControl: ComputedRef<number>;
  Signed?: Nullable<string>;
  FollowupDate?: Nullable<string>;
  NS3451TopLevel: ComputedRef<number>;
  NS3451SecondLevel: ComputedRef<number>;
  NS3451ThirdLevel: ComputedRef<number>;
  Price: ComputedRef<number>;
  Factor1?: Nullable<number>;
  Factor1Calculated: ComputedRef<Nullable<number>>;
  Factor5?: Nullable<number>;
  Factor5Calculated: ComputedRef<Nullable<number>>;
  IsFDVExportSelected: ComputedRef<boolean>;
  ItemCount: ComputedRef<number>;
  SumMargin: ComputedRef<number>;
  ElementWarnings: Array<string>;
}

export interface PrecutItem {
  Amount: Nullable<number>;
  AmountPrUnit: Nullable<number>;
  AmountAdjustment: Nullable<number>;
}
