


























































































































import { usePriceBook } from '@/modules/pricebook';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import { PriceListTreeNode } from '@/models/pricebook/interfaces';
import AddItem from '@/components/pricebook/add-item/index.vue';
import AddCategory from '@/components/pricebook/add-category/index.vue';
import DeletePricelist from '@/components/pricebook/delete-pricelist/index.vue';
import DeleteCategory from '@/components/pricebook/delete-category/index.vue';

export default defineComponent({
  components: {
    Loader,
    ContentBar,
    AddItem,
    AddCategory,
    DeleteCategory,
    DeletePricelist,
  },
  setup() {
    const {
      getPriceLists,
      getPriceListCategoriesOverview,
      destroyDownloaded,
      downloadCategory,
      setActivePricelist,
      setActivePricelistCategory,
      pricelists,
      loadingPricelists,
      activePricelist,
    } = usePriceBook();

    const search = ref<string>('');

    const active = ref<Array<string>>([]);
    const open = ref<Array<string>>([]);

    const getCategories = async (item: PriceListTreeNode) => {
      if (item.Id) {
        item.categories = await getPriceListCategoriesOverview(item);
      }
    };

    const onActiveChangeEvent = async () => {
      const getListByCategory = (categoryId: string) => {
        return pricelists.value?.find(pl =>
          pl.categories?.some(c => c.Id === categoryId)
        );
      };
      destroyDownloaded();

      if (!pricelists.value) return;

      const list = getListByCategory(active.value[0]);
      if (list && list.categories) {
        const category = list.categories.find(c => c.Id === active.value[0]);
        if (category) {
          await downloadCategory(category);
          setActivePricelistCategory(category);
        }
      }
    };

    const onOpenEvent = (openNodes: Array<string>) => {
      if (openNodes.length > 1) {
        open.value.splice(0, 1);
      }

      if (pricelists.value) {
        const newOpened = pricelists.value?.find(pl => pl.Id === open.value[0]);
        if (newOpened) {
          destroyDownloaded(true);
          setActivePricelist(newOpened);
        }
      }
    };

    const updatePriceLists = async (reload: boolean) => {
      await getPriceLists(reload);
    };

    onMounted(async () => {
      await getPriceLists();
    });

    return {
      getCategories,
      onActiveChangeEvent,
      updatePriceLists,
      onOpenEvent,
      selectedItem: 1,
      loadingPricelists,
      pricelists,
      activePricelist,
      active,
      open,
      search,
    };
  },
});
