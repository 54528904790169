











































































































import {
  ref,
  defineComponent,
  PropType,
  onMounted,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import {
  BuildingItemCalculation,
  PrecutItem,
} from '@/models/building-element/interfaces';
import { useBatch } from '@/modules/batch';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import RemoveTimeEstimate from '@/components/calculation/building-item/remove-time-estimate/index.vue';
import { removeTimeEstimate } from '@/helpers/building-item';

export default defineComponent({
  components: {
    DialogTitle,
    InfoTooltip,
    RemoveTimeEstimate,
  },
  props: {
    item: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
    isMenuitem: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const dialog = ref(false);

    const { registerItemSaveChangesWithAdditionAndParent } = useBatch();

    const precutItem = ref<PrecutItem>();

    onMounted(() => {
      precutItem.value = {
        Amount: props.item.Amount,
        AmountAdjustment: props.item.AmountAdjustment ?? null,
        AmountPrUnit: props.item.AmountPrUnit,
      };
    });

    const close = () => {
      props.item.Amount = precutItem.value?.Amount ?? null;
      props.item.AmountAdjustment = precutItem.value?.AmountAdjustment ?? null;
      props.item.AmountPrUnit = precutItem.value?.AmountPrUnit ?? null;
      dialog.value = false;
      emit('close', true);
    };

    const submit = async () => {
      props.item.Precut = true;
      props.item.Unit = 'stk';
      if (props.item.IsTimeEstimateAdded.value) {
        removeTimeEstimate(props.item);
      }

      registerItemSaveChangesWithAdditionAndParent(props.item);

      dialog.value = false;
    };

    return {
      close,
      submit,
      dialog,
    };
  },
});
