import { reactive, toRefs } from '@vue/composition-api';

interface State {
  show: boolean;
  statusText?: string;
  loading: boolean;
}

const state = reactive<State>({
  show: false,
  statusText: undefined,
  loading: false,
});

export const useLoader = () => {
  const updateStatusText = (value: string | undefined) => {
    state.statusText = value;
  };

  const startLoader = (text: string) => {
    state.show = true;
    state.loading = true;
    state.statusText = text;
  };

  const stopLoader = () => {
    state.show = false;
    state.loading = false;
    state.statusText = undefined;
  };

  return {
    updateStatusText,
    startLoader,
    stopLoader,
    ...toRefs(state),
  };
};
