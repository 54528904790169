var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',[_c('v-card',{staticClass:"ma-2"},[_c('loader',{attrs:{"margins":"","text":_vm.$t('environment.info.loading-environment-data', {
          name: _vm.props.buildingElement.Name,
        })}})],1)],1):_c('div',[_c('v-card',{staticClass:"building-element-environment-card ma-2",attrs:{"tile":"","elevation":"1"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.props.buildingElement.Name)+" ")]),_c('v-card-text',[_c('div',{staticClass:"header-with-alerts"},[_c('v-subheader',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-toolbox ")]),_vm._v(" "+_vm._s(_vm.$t('titles.building-items'))+" ("+_vm._s(_vm.buildingElement.BuildingItems.length)+")")],1)],1),(_vm.buildingElement.BuildingItems)?_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('common.part'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('environment.certificates')))]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('environment.validation-date'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.validation-date')))])])],1)])]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('environment.gwp-iobc'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.gwp-iobc')))])])],1)])]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('environment.reference-unit'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.reference-unit')))])])],1)])]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('labels.amount'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.amount')))])])],1)])]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('headers.amountofmaterials'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.amountofmaterials')))])])],1)])]),_c('th',[_c('div',{staticClass:"header-grid"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('environment.climate-imprint'))+" ")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,false,2300533796)},[_c('span',[_vm._v(_vm._s(_vm.$t('environment.info.climate-imprint')))])])],1)])]),_c('th')])]),_c('tbody',[_vm._l((_vm.buildingElement.BuildingItems),function(buildingItem,i){return [_c('BuildingItemEnvironmentRow',{key:i,attrs:{"building-item":buildingItem,"nobb-item":_vm.getNobbItemFromNobbNumber(buildingItem.NOBBNumber)}})]}),_c('tr',{staticClass:"sum-row"},[_c('td',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('labels.sum')))])]),_c('td'),_c('td'),_c('td',[(_vm.computedSumGWP)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(((_vm.computedSumGWP.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 10, })) + " " + (_vm.$t('environment.kg-carbondioxide-equivalent'))))+" ")]):_c('div',[_vm._v("-")])]),_c('td'),_c('td'),_c('td'),_c('td',[(_vm.computedCalculatedSumGWP)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(((_vm.computedCalculatedSumGWP.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 10, })) + " " + (_vm.$t('environment.kg-carbondioxide-equivalent'))))+" ")]):_c('div',[_vm._v("-")])]),_c('td')])],2)]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }