import { reactive, toRefs } from '@vue/composition-api';
import { CustomError } from '@/models/common/interfaces';
import { ReportType } from '@/models/report/enums';
import { generateMaterialOrderReport } from '@/models/report/report-material-order';
import { generateMilestoneReport } from '@/models/report/report-milestone';
import { generateMaterialListPrecutReport } from '@/models/report/report-material-list-precut';
import { generateAttachmentPriceOfferReport } from '@/models/report/report-attachment-price-offer';
import { ReportOptions } from '@/models/report/interfaces';
import { generateBuildingElementReport } from '@/models/report/report-building-elements';
import { generateMainSumsReport } from '@/models/report/report-main-sums';
import { generatePriceRequestReport } from '@/models/report/report-price-request';
import { generateCalculationViewReport } from '@/models/report/view-report-calculation';
import { generateDeliveryViewReport } from '@/models/report/view-report-delivery';
import { generateTimeViewReport } from '@/models/report/view-report-time';
import { generateMaterialViewReport } from '@/models/report/view-report-materials';
import { generateEconomyViewReport } from '@/models/report/view-report-economy';
import { generateProjectMilestoneReport } from '@/models/report/project-report-milestone';
import { generateProjectAttachmentPriceReport } from '@/models/report/project-report-attachment-price';
import { generateProjectBuildingElementsReport } from '@/models/report/project-report-building-elements';
import { generateProjectMainSumsReport } from '@/models/report/project-report-main-sums';
import { generateEnvironmentReport } from '@/models/report/report-environment';

interface State {
  error?: CustomError;
}

const state = reactive<State>({
  error: undefined,
});

export const useReport = () => {
  const checkAndbuildSheetName = (name: string, names: string[]): string => {
    let uniqueName = name.replace(/[&#,+/()$~%.'":*?<>{}]/g, '').slice(0, 31);
    let count = 1;

    while (names.includes(uniqueName)) {
      uniqueName = `${name.slice(0, 27)} (${count++})`; // Truncate to 27 characters and append count
    }

    return uniqueName;
  };

  const generateReport = async (
    type: ReportType,
    options: ReportOptions,
    isProject?: boolean
  ) => {
    if (isProject && options.Calculations) {
      options.Calculations = options.Calculations.sort((a, b) => {
        return parseInt(a.ProfessionCode) - parseInt(b.ProfessionCode);
      });
    }

    try {
      switch (type) {
        case ReportType.MaterialOrder:
          await generateMaterialOrderReport(type, options);
          break;
        case ReportType.MileStone:
          await generateMilestoneReport(type, options);
          break;
        case ReportType.MaterialListPrecut:
          await generateMaterialListPrecutReport(type, options);
          break;
        case ReportType.AttachmentPriceOffer:
          await generateAttachmentPriceOfferReport(type, options);
          break;
        case ReportType.BuildingElements:
          await generateBuildingElementReport(type, options);
          break;
        case ReportType.MainSums:
          await generateMainSumsReport(type, options);
          break;
        case ReportType.PriceRequest:
          await generatePriceRequestReport(type, options);
          break;
        case ReportType.CalculationView:
          await generateCalculationViewReport(type, options);
          break;
        case ReportType.DeliveryView:
          await generateDeliveryViewReport(type, options);
          break;
        case ReportType.TimeView:
          await generateTimeViewReport(type, options);
          break;
        case ReportType.MaterialsView:
          await generateMaterialViewReport(type, options);
          break;
        case ReportType.EconomyView:
          await generateEconomyViewReport(type, options);
          break;
        case ReportType.ProjectMileStone:
          await generateProjectMilestoneReport(type, options);
          break;
        case ReportType.ProjectAttachmentPriceOffer:
          await generateProjectAttachmentPriceReport(type, options);
          break;
        case ReportType.ProjectBuildingElements:
          await generateProjectBuildingElementsReport(type, options);
          break;
        case ReportType.ProjectMainSums:
          await generateProjectMainSumsReport(type, options);
          break;
        case ReportType.Environment:
          await generateEnvironmentReport(type, options);
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    generateReport,
    checkAndbuildSheetName,
    ...toRefs(state),
  };
};
