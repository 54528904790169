var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.elevated ? 'item-table elevation-2' : 'item-table',attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","loading-text":_vm.$t('info.loading'),"no-data-text":_vm.$t('info.no-data'),"no-results-text":_vm.$t('info.no-result'),"item-class":_vm.itemRowBackground,"items-per-page":30,"item-key":"Id"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('draggable',{attrs:{"list":_vm.items,"tag":"tbody","disabled":!_vm.allowDrag,"handle":".item-draggable"},on:{"end":_vm.onDropCallback}},_vm._l((_vm.items),function(item,index){return _c('data-table-row-handler',{key:index,attrs:{"item":item,"headers":_vm.headers,"value":item.Id,"itemClass":_vm.getRowColor(item)},scopedSlots:_vm._u([{key:"item.sort-drag",fn:function(ref){return [_c('div',{staticClass:"item-draggable"},[_c('v-icon',[_vm._v(" mdi-drag-variant ")])],1)]}},{key:"item.palette",fn:function(ref){return [_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-palette ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.changeColor(_vm.RowColors.Default, item)}}},[_c('v-list-item-title',[_c('div',{staticClass:"white-option"})])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeColor(_vm.RowColors.Red, item)}}},[_c('v-list-item-title',[_c('div',{staticClass:"red-option"})])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeColor(_vm.RowColors.Green, item)}}},[_c('v-list-item-title',[_c('div',{staticClass:"green-option"})])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeColor(_vm.RowColors.Orange, item)}}},[_c('v-list-item-title',[_c('div',{staticClass:"orange-option"})])],1)],1)],1)],1)]}},{key:"item.Price",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.registerItemSaveChangesWithAdditionAndParent(item)},"focus":function($event){return $event.target.select()}},model:{value:(item.Price),callback:function ($$v) {_vm.$set(item, "Price", _vm._n($$v))},expression:"item.Price"}})]}},{key:"item.MileStone",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)},"focus":function($event){return $event.target.select()}},model:{value:(item.MileStone),callback:function ($$v) {_vm.$set(item, "MileStone", $$v)},expression:"item.MileStone"}})]}},{key:"item.SurfaceAddition",fn:function(ref){
var item = ref.item;
return [(item.Unit && item.Unit === 'm2')?_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.registerItemSaveChangesWithAdditionAndParent(item)},"focus":function($event){return $event.target.select()}},model:{value:(item.SurfaceAddition),callback:function ($$v) {_vm.$set(item, "SurfaceAddition", _vm._n($$v))},expression:"item.SurfaceAddition"}}):_vm._e()]}},{key:"item.OpeningAddition",fn:function(ref){
var item = ref.item;
return [(item.Unit && item.Unit === 'm2')?_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.registerItemSaveChangesWithAdditionAndParent(item)},"focus":function($event){return $event.target.select()}},model:{value:(item.OpeningAddition),callback:function ($$v) {_vm.$set(item, "OpeningAddition", _vm._n($$v))},expression:"item.OpeningAddition"}}):_vm._e()]}},(!_vm.simple)?{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{staticClass:"pa-2",attrs:{"bottom":"","max-width":"750","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-edit-dialog',{attrs:{"return-value":item.Name,"large":"","save-text":_vm.$t('buttons.save'),"cancel-text":_vm.$t('buttons.cancel')},on:{"update:returnValue":function($event){return _vm.$set(item, "Name", $event)},"update:return-value":function($event){return _vm.$set(item, "Name", $event)},"save":function () { return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-card',{attrs:{"min-width":"450","flat":""}},[_c('v-text-field',{staticClass:"mt-5",attrs:{"value":item.Name,"label":_vm.$t('labels.name'),"outlined":""},on:{"input":function($event){item.Name = $event !== '' ? $event : null},"focus":function($event){return $event.target.select()}}})],1)]},proxy:true}],null,true)},[_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{class:item.IsRecentlyAdded && item.IsRecentlyAdded
                        ? 'recently-added'
                        : 'emphasized'},[_vm._v(" "+_vm._s(item.Name)+" ")]),_c('div',[_vm._v(_vm._s(item.PriceListItemName))])])])]}}],null,true)},[_c('div',{staticClass:"info-tooltip"},[_c('div',{staticClass:"title white--text font-weight-bold"},[_vm._v(" "+_vm._s(item.Name)+" ")]),_c('v-divider',{staticClass:"custom-divider",attrs:{"dark":""}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"white--text",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t('calculation.name-timebook')))]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"1"}},[_vm._v(":")]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"7"}},[_vm._v(_vm._s(item.TimeEstimateName ? item.TimeEstimateName : _vm.$t('common.unset')))]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t('calculation.name-pricelist')))]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"1"}},[_vm._v(":")]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"7"}},[_vm._v(_vm._s(item.PriceListItemName ? item.PriceListItemName : _vm.$t('common.unset')))]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t('calculation.marked-precut')))]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"1"}},[_vm._v(":")]),_c('v-col',{staticClass:"white--text",attrs:{"cols":"7"}},[_vm._v(_vm._s(item.Precut ? _vm.$t('common.yes') : _vm.$t('common.no')))])],1)],1)])]}}:null,(!_vm.simple)?{key:"item.Amount",fn:function(ref){
                        var item = ref.item;
return [_c('v-slide-y-transition',[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.amountTransition),expression:"amountTransition"}],attrs:{"value":item.AmountCalculated.value,"hide-details":"","outlined":"","dense":"","type":"number"},on:{"input":function($event){item.AmountCalculated.value =
                  $event !== '' ? Number($event) : null},"change":function($event){return _vm.registerItemSaveChangesWithAdditionAndParent(item)},"focus":function($event){return $event.target.select()}}})],1)]}}:null,{key:"item.DeliveryOrder",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.DeliveryOrder,"hide-details":"","outlined":"","dense":"","type":"number"},on:{"input":function($event){item.DeliveryOrder = $event !== '' ? Number($event) : null},"change":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)},"focus":function($event){return $event.target.select()}}})]}},{key:"item.Status",fn:function(ref){
                  var item = ref.item;
return [_c('building-item-status',{attrs:{"buildingItemCalc":item}})]}},{key:"item.Precut",fn:function(ref){
                  var item = ref.item;
return [(item.Precut)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alpha-p")]):_c('div')]}},{key:"item.AmountOfMaterials",fn:function(ref){
                  var item = ref.item;
return [(item.AmountOfMaterials.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.AmountOfMaterials.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}},(!_vm.simple)?{key:"item.BaseTime",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","type":"number","width":"50px"},on:{"change":function($event){return _vm.registerItemSaveChangesWithAdditionAndParent(item)},"focus":function($event){return $event.target.select()}},scopedSlots:_vm._u([(!_vm.smallVariant)?{key:"append",fn:function(){return [(item.IsTimeEstimateAdded.value === true)?_c('v-tooltip',{key:item.Name,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#424143"}},'v-icon',attrs,false),on),[_vm._v("mdi-timer-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('info.timebook')))])]):_vm._e()]},proxy:true}:null],null,true),model:{value:(item.BaseTime),callback:function ($$v) {_vm.$set(item, "BaseTime", _vm._n($$v))},expression:"item.BaseTime"}})]}}:null,{key:"item.AmountAdjustment",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('v-text-field',{staticClass:"emphasized-orange",attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.onBuildingItemAdjustmentChange(item)},"focus":function($event){return $event.target.select()}},model:{value:(item.AmountAdjustment),callback:function ($$v) {_vm.$set(item, "AmountAdjustment", _vm._n($$v))},expression:"item.AmountAdjustment"}})],1)]}},{key:"item.UnitForOrder",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)},"focus":function($event){return $event.target.select()}},model:{value:(item.UnitForOrder),callback:function ($$v) {_vm.$set(item, "UnitForOrder", $$v)},expression:"item.UnitForOrder"}})],1)]}},(!_vm.simple)?{key:"item.Unit",fn:function(ref){
                  var item = ref.item;
return [_c('v-select',{attrs:{"hide-details":"","dense":"","outlined":"","items":_vm.NS3420Units},on:{"change":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)}},model:{value:(item.Unit),callback:function ($$v) {_vm.$set(item, "Unit", $$v)},expression:"item.Unit"}})]}}:null,{key:"item.SumSelfCostMaterialsSubContractors",fn:function(ref){
                  var item = ref.item;
return [(item.SumSelfCostMaterialsSubContractors.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumSelfCostMaterialsSubContractors.value.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 0, } ))+" ")]):_vm._e()]}},{key:"item.Factor7",fn:function(ref){
                  var item = ref.item;
return [(item.Factor7.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.Factor7.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.ProjectCost",fn:function(ref){
                  var item = ref.item;
return [(item.ProjectCost.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.ProjectCost.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.UnitPriceMaterials",fn:function(ref){
                  var item = ref.item;
return [(item.UnitPriceMaterials.value !== null)?_c('div',[_vm._v(" "+_vm._s(typeof item.UnitPriceMaterials.value === 'number' ? item.UnitPriceMaterials.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 1, }) : 0)+" ")]):_vm._e()]}},{key:"item.SumMaterialsUE",fn:function(ref){
                  var item = ref.item;
return [(item.SumMaterialsUE.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumMaterialsUE.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},(!_vm.simple)?{key:"item.SumHours",fn:function(ref){
                  var item = ref.item;
return [(item.SumHours.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumHours.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}}:null,(!_vm.simple)?{key:"item.SumTime",fn:function(ref){
                  var item = ref.item;
return [(item.SumTime.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumTime.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}}:null,{key:"item.SumTotal",fn:function(ref){
                  var item = ref.item;
return [(item.SumTotal.value !== null)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.SumTotal.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.SumTotalWithAdditions",fn:function(ref){
                  var item = ref.item;
return [(item.SumTotalWithAdditions.value !== null)?_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.SumTotalWithAdditions.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.UnitPrice",fn:function(ref){
                  var item = ref.item;
return [(item.UnitPrice.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.UnitPrice.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}},{key:"item.UnitPriceWithAdditions",fn:function(ref){
                  var item = ref.item;
return [(item.UnitPriceWithAdditions.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.UnitPriceWithAdditions.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}},{key:"item.Factor1",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{staticClass:"emphasized-primary",attrs:{"value":item.Factor1Calculated.value,"hide-details":"","outlined":"","dense":"","type":"number"},on:{"input":function($event){item.Factor1Calculated.value =
                $event !== '' ? Number($event) : null},"change":function($event){return _vm.registerItemSaveChangesWithAdditionAndParent(item)},"focus":function($event){return $event.target.select()}}})]}},{key:"item.Factor2",fn:function(ref){
                var item = ref.item;
return [(item.Factor2.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.Factor2.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.Factor3",fn:function(ref){
                var item = ref.item;
return [(item.Factor3.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.Factor3.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.Factor4",fn:function(ref){
                var item = ref.item;
return [(item.Factor4.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.Factor4.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.Factor6",fn:function(ref){
                var item = ref.item;
return [(item.Factor6.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.Factor6.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.SumProductionSalary",fn:function(ref){
                var item = ref.item;
return [(item.SumProductionSalary.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumProductionSalary.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.SumSelfCostSalaryAndExpenses",fn:function(ref){
                var item = ref.item;
return [(item.SumSelfCostSalaryAndExpenses.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumSelfCostSalaryAndExpenses.value.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 0, } ))+" ")]):_vm._e()]}},{key:"item.SumSalaryAndSocialCost",fn:function(ref){
                var item = ref.item;
return [(item.SumSalaryAndSocialCost.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumSalaryAndSocialCost.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.SumSalaryAndExpenses",fn:function(ref){
                var item = ref.item;
return [(item.SumSalaryAndExpenses.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumSalaryAndExpenses.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.TimePrUnit",fn:function(ref){
                var item = ref.item;
return [(item.TimePrUnit.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.TimePrUnit.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}},{key:"item.AmountPrUnit",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.registerItemSaveChangesWithAdditionAndParent(item)},"focus":function($event){return $event.target.select()}},model:{value:(item.AmountPrUnit),callback:function ($$v) {_vm.$set(item, "AmountPrUnit", _vm._n($$v))},expression:"item.AmountPrUnit"}})]}},{key:"item.NOBBNumber",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)},"focus":function($event){return $event.target.select()}},model:{value:(item.NOBBNumber),callback:function ($$v) {_vm.$set(item, "NOBBNumber", _vm._n($$v))},expression:"item.NOBBNumber"}})],1)]}},{key:"item.IsFDVExportSelected",fn:function(ref){
                var item = ref.item;
return [_c('v-row',[_c('v-spacer'),_c('v-checkbox',{attrs:{"dense":"","hide-details":""},on:{"change":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)}},model:{value:(item.IsFDVExportSelected),callback:function ($$v) {_vm.$set(item, "IsFDVExportSelected", $$v)},expression:"item.IsFDVExportSelected"}}),_c('v-spacer')],1)]}},{key:"item.SumMargin",fn:function(ref){
                var item = ref.item;
return [(item.SumMargin.value !== null)?_c('div',[_vm._v(" "+_vm._s(item.SumMargin.value.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 0, }))+" ")]):_vm._e()]}},{key:"item.warnings",fn:function(ref){
                var item = ref.item;
return [(item.ItemWarnings && item.ItemWarnings.length > 0)?_c('item-warnings',{attrs:{"warnings":item.ItemWarnings,"calm":true}}):_vm._e()]}},{key:"item.Comments",fn:function(ref){
                var item = ref.item;
return [_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-edit-dialog',{attrs:{"return-value":item.Comments,"large":"","save-text":_vm.$t('buttons.save'),"cancel-text":_vm.$t('buttons.cancel')},on:{"update:returnValue":function($event){return _vm.$set(item, "Comments", $event)},"update:return-value":function($event){return _vm.$set(item, "Comments", $event)},"save":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-card',{attrs:{"min-width":"400","flat":""}},[_c('v-textarea',{staticClass:"mt-5",attrs:{"label":_vm.$t('labels.comment'),"dense":"","outlined":"","auto-grow":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.Comments),callback:function ($$v) {_vm.$set(item, "Comments", $$v)},expression:"item.Comments"}})],1)]},proxy:true}],null,true)},[_c('v-tooltip',{key:item.Name,attrs:{"bottom":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.Comments ? 'secondary' : ''}},[_vm._v(_vm._s(item.Comments ? 'mdi-text-box' : 'mdi-text-box-plus'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Comments ? item.Comments : _vm.$t('info.add-comment')))])])],1)],1)]}},{key:"item.Actions",fn:function(ref){
                var item = ref.item;
return [_c('v-row',{staticClass:"item-id-container",attrs:{"justify":"center","dense":""}},[_c('input',{attrs:{"type":"hidden"},domProps:{"value":item.Id}}),_c('v-menu',{ref:"itemMenu",refInFor:true,attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var menu = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('get-product',{attrs:{"isMenuOption":true,"itemCalc":item},on:{"product":_vm.findItems,"close":_vm.closeMenu}}),(!item.Precut)?_c('edit-time-estimate',{attrs:{"item":item,"element":_vm.calcElement},on:{"close":_vm.closeMenu}}):_vm._e(),(
                      item.IsTimeEstimateAdded.value === true && !item.Precut
                    )?_c('remove-time-estimate',{attrs:{"item":item},on:{"updated":function($event){return _vm.registerBuildingItemBatchOperation(item, _vm.BatchType.Save)},"close":_vm.closeMenu}}):_vm._e(),(!item.Precut)?_c('set-precut',{attrs:{"item":item,"isMenuitem":true},on:{"close":_vm.closeMenu}}):_c('remove-precut',{attrs:{"item":item,"isMenuitem":true},on:{"close":_vm.closeMenu}}),_c('add-item-to-library',{attrs:{"item":item},on:{"close":_vm.closeMenu}}),_c('delete-item',{attrs:{"item":item,"isMenuitem":true},on:{"close":_vm.closeMenu}}),_c('v-divider'),(item.NOBBNumber)?_c('nobb-info',{attrs:{"nobbNr":item.NOBBNumber},on:{"close":_vm.closeMenu}}):_vm._e(),_c('replace-products',{attrs:{"itemCalcs":_vm.sameProduct},on:{"closeEvent":_vm.closeEvent,"replaceAll":_vm.replaceAll}})],1)],1)],1)],1)]}}],null,true)})}),1)]},proxy:true},_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){return [_c('v-row',{key:h.text,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{key:h.value,attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [(h.text)?_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t(("headers." + (h.text.toLowerCase()))))+" ")]):_vm._e()]}}],null,true)},[(h.text)?_c('span',[_vm._v(_vm._s(_vm.$t(("headers-description." + (h.text.toLowerCase())))))]):_vm._e()])],1),_c('v-row',{attrs:{"align-content":"center","dense":""}},[_c('v-spacer'),_c('div',{class:_vm.getElementTotalTypography(h.text)},[_vm._v(" "+_vm._s(_vm.getElementTotalByProp(h.text, _vm.calcElement))+" ")]),_c('v-spacer')],1)],1)]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }