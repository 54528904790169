
















































































import { ref, PropType, defineComponent, computed } from '@vue/composition-api';
import { User, UserpasswordModel } from '@/models/auth/interfaces';
import { useUsers } from '@/modules/users';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  components: {
    DialogTitle,
  },
  setup({ user }) {
    const { loading, setUserPassword } = useUsers();
    const payload = ref<UserpasswordModel>();

    if (user.Id) {
      payload.value = {
        UserId: user.Id,
      };
    }

    const valid = ref(false);
    const dialog = ref(false);

    const submit = async () => {
      if (payload.value)
        if (await setUserPassword(payload.value)) dialog.value = false;
    };

    const passwordValidator = computed(() => {
      let valid = false;
      if (payload.value) {
        if (payload.value.Password && payload.value.ConfirmPassword) {
          if (payload.value.Password.length > 7) {
            if (payload.value.Password === payload.value.ConfirmPassword) {
              if (/[a-z]/.test(payload.value.Password)) {
                if (/[A-Z]/.test(payload.value.Password)) {
                  valid = true;
                }
              }
            }
          }
        }
      }

      return valid;
    });

    return {
      dialog,
      payload,
      submit,
      valid,
      loading,
      passwordValidator,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
        min: (v: string) =>
          (v && v.length >= 8) ||
          `${translate('validation.minimum-characters', [8])}`,
        passwordMatch: () =>
          (payload.value &&
            payload.value.Password === payload.value.ConfirmPassword) ||
          `${translate('validation.pw-missmatch')}`,
        containsLower: (v: string) =>
          /[a-z]/.test(v && v) || `${translate('validation.uppercase')}`,
        containsUpper: (v: string) =>
          /[A-Z]/.test(v && v) || `${translate('validation.uppercase')}`,
      },
    };
  },
});
