























































































































































































import { ref, defineComponent, onMounted } from '@vue/composition-api';
import { useLicense } from '@/modules/license';
import moment from 'moment';
import { useAuth } from '@/modules/auth';
import { PROJECT_START_PATH } from '@/constants/routes';
import { UsernameModel } from '@/models/common/interfaces';
import { LicensePrice } from '@/models/admin/interfaces';
import router from '@/router/index';
import Loader from '@/components/loader/index.vue';
import { translate } from '@/localization';

export default defineComponent({
  components: {
    Loader,
  },
  setup() {
    const { license, user } = useAuth();
    const {
      upgradeLicense,
      purchaseLicense,
      getLicensePrice,
      error,
    } = useLicense();

    const licensePrice = ref<LicensePrice>();
    const loading = ref(true);
    const upgrading = ref(false);
    const readAndUnderstood = ref(false);

    onMounted(async () => {
      licensePrice.value = await getLicensePrice();
      loading.value = false;
    });

    const licenseInfoHeaderText = license?.value?.ExpiryDate
      ? `${translate('license.expired')} ${moment(
          license?.value?.ExpiryDate
        ).format('Do MMMM YYYY')}.`
      : `${translate('license.did-expire')}`;

    const title = license?.value?.Trial
      ? `${translate('license.upgrade')}`
      : `${translate('license.attention')}`;

    const isValid = !moment(license?.value?.ExpiryDate).isBefore();

    const secondaryTitle = license?.value?.Trial
      ? isValid
        ? `${translate('license.valid-until')} ${moment(
            license?.value?.ExpiryDate
          ).format('Do MMMM YYYY')}.`
        : `${translate('license.expired')} ${moment(
            license?.value?.ExpiryDate
          ).format('Do MMMM YYYY')}.`
      : license?.value?.ExpiryDate
      ? `${translate('license.expired')} ${moment(
          license?.value?.ExpiryDate
        ).format('Do MMMM YYYY')}.`
      : `${translate('license.did-expire')}`;

    const upgradeLicenseEvent = async () => {
      if (!user?.value || !license?.value) return;

      upgrading.value = true;
      const userName: UsernameModel = {
        UserName: user.value.Email,
      };

      if (license.value.Trial) {
        await upgradeLicense(userName);
      } else {
        await purchaseLicense(userName);
      }
      upgrading.value = false;
      if (!error?.value && router.currentRoute.path !== PROJECT_START_PATH) {
        await router.push(PROJECT_START_PATH);
      }
    };

    return {
      licensePrice,
      isAdmin: user?.value?.IsAdmin,
      readAndUnderstood,
      licenseInfoHeaderText,
      upgradeLicenseEvent,
      loading,
      error,
      upgrading,
      license,
      title,
      secondaryTitle,
      isValid,
      termsUrl: process.env.VUE_APP_TERMS_URL,
    };
  },
});
