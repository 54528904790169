
























































































import {
  ref,
  PropType,
  defineComponent,
  onMounted,
} from '@vue/composition-api';
import { cleanSource } from '@/helpers/common';
import { User } from '@/models/auth/interfaces';
import { useUsers } from '@/modules/users';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { translate } from '@/localization';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  components: {
    DialogTitle,
  },
  setup({ user }) {
    const { updateUser, loading } = useUsers();
    const updatedUser = ref<User>();
    const valid = ref(false);
    const dialog = ref(false);

    onMounted(() => {
      updatedUser.value = cleanSource(user);
    });

    const close = () => {
      dialog.value = false;
      updatedUser.value = cleanSource(user);
    };

    const submit = async () => {
      if (!updatedUser.value) return;

      await updateUser(updatedUser.value);
      close();
    };

    return {
      dialog,
      close,
      updatedUser,
      submit,
      valid,
      loading,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
        min: (v: string) =>
          (v && v.length >= 3) ||
          `${translate('validation.minimum-characters', [3])}`,
      },
    };
  },
});
