









































































import {
  reactive,
  PropType,
  defineComponent,
  computed,
  ref,
} from '@vue/composition-api';
import { CompanyFactorItem } from '@/models/company/interfaces';
import { cleanSource } from '@/helpers/common';
import Confirm from '@/components/common/confirm/index.vue';
import DialogTitle from '@/components/common/dialog-title/index.vue';

const payload = reactive<CompanyFactorItem>({
  Name: '',
  Value: 0,
});

const valid = computed(() => {
  return payload.Name !== '' && payload.Value > 0;
});

export default defineComponent({
  components: {
    Confirm,
    DialogTitle,
  },
  props: {
    unit: {
      type: String,
    },
    items: {
      type: Array as PropType<Array<CompanyFactorItem>>,
      required: true,
    },
    name: {
      type: String,
    },
  },
  setup({ items }, { emit }) {
    const dialog = ref<boolean>(false);
    const close = () => {
      dialog.value = false;
    };
    const add = (item: CompanyFactorItem) => {
      const newItem: CompanyFactorItem = cleanSource(item);
      items.push(newItem);
      payload.Name = '';
      payload.Value = 0;
      emit('added', true);
    };

    const submit = () => {
      add(payload);
      close();
    };

    return {
      payload,
      valid,
      dialog,
      submit,
    };
  },
});
