







































import { useCalculation } from '@/modules/calculation';
import { defineComponent } from '@vue/composition-api';
import Templates from '@/components/library/templates/index.vue';

export default defineComponent({
  components: {
    Templates,
  },
  props: {
    elementId: {
      type: String,
    },
  },
  setup() {
    const { activateLibrary } = useCalculation();
    return {
      activateLibrary,
    };
  },
});
