/* eslint-disable  @typescript-eslint/no-explicit-any */
import { AUTH_KEY } from '@/constants/common';
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import Vue from 'vue';

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE || 'https://localhost:44301/',
});

export const useApi = (endpoint: string) => {
  const token: string = Vue.$cookies.get(AUTH_KEY);

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const get = async (
    query?: Record<string, any>,
    accept?: string,
    type?: string,
    responseType?: ResponseType
  ): Promise<AxiosResponse<any>> => {
    let queryString = '';

    if (query) {
      queryString =
        '?' +
        Object.entries(query)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&');
    }

    if (accept && type) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: accept,
        'Content-Type': type,
      };
    } else if (accept && !type) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: accept,
      };
    } else if (!accept && type) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': type,
      };
    }

    if (responseType) {
      config.responseType = responseType;
    }

    return await api.get(endpoint + queryString, config);
  };

  const postUrlencoded = async (
    params?: URLSearchParams,
    payload?: Record<string, any>
  ): Promise<AxiosResponse<any>> => {
    const urlencodedConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return await api.post(endpoint, payload ?? params, urlencodedConfig);
  };

  const postMultipart = async (
    formData: FormData
  ): Promise<AxiosResponse<any>> => {
    const multipartConfig = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };

    return await api.post(endpoint, formData, multipartConfig);
  };

  const post = async (
    payload?: Record<string, any>
  ): Promise<AxiosResponse<any>> => {
    return await api.post(endpoint, payload, config);
  };

  const put = async (
    payload?: Record<string, any>
  ): Promise<AxiosResponse<any>> => {
    return await api.put(endpoint, payload, config);
  };

  const deleteReq = async (
    payload?: Record<string, any>
  ): Promise<AxiosResponse<any>> => {
    return await api.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        source: payload,
      },
    });
  };

  return {
    deleteReq,
    put,
    get,
    post,
    postUrlencoded,
    postMultipart,
  };
};
