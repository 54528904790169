




























import {
  PropType,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import { useEnvironment } from '@/modules/environment';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import { NobbItem } from '@/models/nobb/interfaces';
import { useProductInfo } from '@/modules/product-info';
import Loader from '@/components/loader/index.vue';

export default defineComponent({
  components: {
    Loader,
  },
  props: {
    buildingElement: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const nobbItems = ref<Array<NobbItem>>([]);
    const loading = ref(false);
    const calculatedSumGWP = ref(0);
    const sumGWP = ref(0);

    const { getNobbItems } = useProductInfo();
    const {
      getBuildingElementSumGWP,
      getBuildingElementCalculatedSumGWP,
      extractNobbNosFromBuildingElement,
    } = useEnvironment();

    onMounted(async () => {
      try {
        loading.value = true;
        nobbItems.value = await getNobbItems(
          extractNobbNosFromBuildingElement(props.buildingElement)
        );
      } finally {
        loading.value = false;
        calculatedSumGWP.value = getBuildingElementCalculatedSumGWP(
          props.buildingElement,
          nobbItems.value
        );
        sumGWP.value = getBuildingElementSumGWP(
          props.buildingElement,
          nobbItems.value
        );

        emit('sum-calculated', calculatedSumGWP.value);
        emit('sum', sumGWP.value);
      }
    });
    return {
      props,
      loading,
      calculatedSumGWP,
      sumGWP,
    };
  },
});
