



























































































































































































































































































































































































































































import {
  BatchType,
  CalculationColumnView,
  RowColorsElement,
} from '@/constants/enum';
import {
  BuildingElementCalculation,
  ItemStatus,
} from '@/models/building-element/interfaces';
import { DataTableHeader } from '@/models/common/interfaces';
import { useBatch } from '@/modules/batch';
import { useCalculation } from '@/modules/calculation';
import { useLibrary } from '@/modules/library';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import ElementPropItem from '@/components/calculation/building-elements/element-prop-item/index.vue';
import AdditionItem from '@/components/calculation/addition-item/index.vue';
import BuildingItem from '@/components/calculation/building-item/index.vue';
import NewItem from '@/components/calculation/new-building-item/index.vue';
import EditElement from '@/components/calculation/edit-element/index.vue';
import DeleteElement from '@/components/calculation/delete-element/index.vue';
import AddToLibrary from '@/components/calculation/building-elements/add-to-library/index.vue';
import Documentation from '@/components/documentation/index.vue';
import ElementWarnings from '@/components/calculation/building-elements/calc-element/element-warnings/index.vue';
import { PROJECT_CALCULATION_BUILDING_ELEMENT_PATH } from '@/constants/routes';
import { useNudge } from '@/modules/nudge';
import PriceUpdate from '@/components/calculation/building-elements/calc-element/price-update/index.vue';
import { translate } from '@/localization';

export default defineComponent({
  components: {
    ElementPropItem,
    AdditionItem,
    BuildingItem,
    NewItem,
    EditElement,
    DeleteElement,
    Documentation,
    AddToLibrary,
    ElementWarnings,
    PriceUpdate,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
    smallVariant: {
      type: Boolean,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    columns: {
      type: Array as PropType<Array<DataTableHeader>>,
      required: true,
    },
    columnView: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      registerElementSaveChangesWithChildren,
      registerElementBatchOperation,
    } = useBatch();
    const { activeTemplate } = useLibrary();
    const { NS3420Units } = useCalculation();

    const changeColor = (
      color: RowColorsElement,
      element: BuildingElementCalculation
    ) => {
      element.Transport = color;
      registerElementBatchOperation(element, BatchType.Save);
    };

    const getRowColor = (element: BuildingElementCalculation) => {
      let color = '';
      if (element.Transport != null && RowColorsElement[element.Transport]) {
        color = RowColorsElement[element.Transport].toLocaleLowerCase();
      }
      return `${color} lighten-5 element-header`;
    };

    const updateProducts = ref(false);

    const cancelPriceUpdate = () => {
      updateProducts.value = false;
    };

    const initPriceUpdate = async (element: BuildingElementCalculation) => {
      if (element.BuildingItems) {
        element.BuildingItems.forEach(item => {
          if (!item.NOBBNumber) {
            item.Status = {
              StatusColor: 'primary',
              StatusText: `${translate('info.no-nobbno')}`,
              StatusType: ItemStatus.MissingNobb,
            };
          }
        });
      }

      updateProducts.value = true;
    };

    const amountEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Calculation,
        CalculationColumnView.HourlyConsumption,
        CalculationColumnView.Materials,
        CalculationColumnView.Economy,
      ];
      return enabledWhen.includes(props.columnView);
    });

    const milestoneEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(props.columnView);
    });

    const factor1Enabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.HourlyConsumption,
      ];
      return enabledWhen.includes(props.columnView);
    });

    const productionLineEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(props.columnView);
    });

    const FDVEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Materials,
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(props.columnView);
    });

    const deliveryOrderEnabled = computed(() => {
      const enabledWhen: Array<CalculationColumnView> = [
        CalculationColumnView.Calculation,
        CalculationColumnView.Materials,
        CalculationColumnView.Delivery,
      ];
      return enabledWhen.includes(props.columnView);
    });

    const registerAndNudge = (element: BuildingElementCalculation) => {
      const { nudgeAmount } = useNudge();

      registerElementSaveChangesWithChildren(element);
      nudgeAmount();
    };

    const changeEvent = (element: BuildingElementCalculation) => {
      element.IsExpanded = !element.IsExpanded;
    };

    return {
      registerElementBatchOperation,
      registerElementSaveChangesWithChildren,
      changeEvent,
      changeColor,
      registerAndNudge,
      initPriceUpdate,
      cancelPriceUpdate,
      getRowColor,
      RowColorsElement,
      BatchType,
      activeTemplate,
      NS3420Units,
      amountEnabled,
      milestoneEnabled,
      factor1Enabled,
      productionLineEnabled,
      FDVEnabled,
      deliveryOrderEnabled,
      PROJECT_CALCULATION_BUILDING_ELEMENT_PATH,
      updateProducts,
    };
  },
});
