

















































































































































































































































































































import { calculatedPrice } from '@/helpers/pricebook';
import {
  PriceListSearchResult,
  SearchablePriceListItem,
  SearchFilter,
} from '@/models/pricebook/interfaces';
import { usePriceBook } from '@/modules/pricebook';
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  computed,
} from '@vue/composition-api';
import Loader from '@/components/loader/index.vue';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import NobbInfo from '@/components/nobb-info/index.vue';
import Confirm from '@/components/common/confirm/index.vue';
import ReplaceProducts from '@/components/calculation/building-item/replace-products/index.vue';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { BUILDING_ITEM_PRODUCT } from '@/constants/table-headers';
import { areUnitsEqual } from '@/helpers/common';
import { ItemWithProduct } from '@/models/common/interfaces';

export default defineComponent({
  components: {
    NobbInfo,
    Loader,
    Confirm,
    DialogTitle,
    ContentBar,
    ReplaceProducts,
  },
  props: {
    isMenuOption: {
      type: Boolean,
    },
    itemCalc: {
      type: Object as PropType<BuildingItemCalculation>,
    },
    iconButton: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const {
      getPriceLists,
      searchProductsInPricelists,
      getSubscribedPricelists,
      searchProductsInPricelistsWithFilter,
      getCategoryFacets,
      getPriceListFacets,
      pricelists,
    } = usePriceBook();

    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const differentUnit = ref<boolean>(false);
    const differentUnitDialog = ref<boolean>(false);
    const differentUnitDecided = ref<boolean>(false);

    const search = ref<string>('');
    const resultSearch = ref<string>('');
    const searchFilter = ref<SearchFilter>();
    const fetchingProducts = ref(false);
    const chosenItem = ref<SearchablePriceListItem>();
    const azureResult = ref<PriceListSearchResult>();
    const subscribedPriceListIds = ref<Array<string>>([]);

    const toggleItem = (toggleObject: {
      item: SearchablePriceListItem;
      value: boolean;
    }) => {
      if (toggleObject.value) {
        chosenItem.value = toggleObject.item;
      } else {
        chosenItem.value = undefined;
      }
    };

    const close = async () => {
      azureResult.value = undefined;
      chosenItem.value = undefined;
      fetchingProducts.value = false;
      searchFilter.value = {
        CategoryNames: [],
        PriceListNames: [],
        Search: '',
        SubscribedPriceListIds: subscribedPriceListIds.value,
      };

      differentUnit.value = false;
      differentUnitDialog.value = false;
      differentUnitDecided.value = false;
      dialog.value = false;
      emit('close', true);
    };

    const selectedItemHasDifferentUnit = (
      item: SearchablePriceListItem
    ): boolean => {
      const existingUnit = props.itemCalc?.UnitForOrder;

      if (existingUnit) {
        if (item.Unit) {
          const diffUnit = !areUnitsEqual(existingUnit, item.Unit);
          if (diffUnit) {
            return true;
          }
        }
      }
      return false;
    };

    const addFromPricelist = async () => {
      if (chosenItem.value) {
        differentUnit.value = selectedItemHasDifferentUnit(chosenItem.value);

        if (differentUnit.value && !differentUnitDecided.value) {
          differentUnitDialog.value = true;
          return;
        }

        if (props.itemCalc) {
          const iwp: ItemWithProduct = {
            Item: props.itemCalc,
            PriceListItem: chosenItem.value,
          };

          emit('product', iwp);
          close();
        } else {
          const iwp: ItemWithProduct = {
            PriceListItem: chosenItem.value,
          };

          emit('product', iwp);
          close();
        }
      }
    };

    const differentUnitAccept = () => {
      differentUnitDialog.value = false;
      differentUnitDecided.value = true;
      addFromPricelist();
    };

    const differentUnitDeclined = () => {
      differentUnitDecided.value = false;
      differentUnitDialog.value = false;
    };

    const azureSearch = async () => {
      if (searchFilter.value) {
        searchFilter.value.CategoryNames = [];
        searchFilter.value.PriceListNames = [];
      }
      if (search.value) {
        searchFilter.value = {
          Search: search.value,
          SubscribedPriceListIds: subscribedPriceListIds.value,
          CategoryNames: [],
          PriceListNames: [],
        };
        fetchingProducts.value = true;
        azureResult.value = await searchProductsInPricelists(
          searchFilter.value
        );
        fetchingProducts.value = false;
      }
    };

    const searchWithFilters = async () => {
      if (searchFilter.value && azureResult.value) {
        fetchingProducts.value = true;
        const filteredSearch = await searchProductsInPricelistsWithFilter(
          searchFilter.value
        );
        const pricelistFacets = await getPriceListFacets(searchFilter.value);
        const categoryFacets = await getCategoryFacets(searchFilter.value);

        azureResult.value.FoundPriceLists = filteredSearch.FoundPriceLists;
        azureResult.value.TotalResultCount = filteredSearch.TotalResultCount;
        azureResult.value.CategoryFacets = categoryFacets;
        azureResult.value.PriceListFacets = pricelistFacets;
        fetchingProducts.value = false;
      }
    };

    const valid = computed(() => {
      return chosenItem.value != null;
    });

    onMounted(async () => {
      loading.value = true;
      searchFilter.value = undefined;
      subscribedPriceListIds.value = [];
      if (props.itemCalc) {
        if (props.itemCalc.PriceListItemName) {
          search.value = props.itemCalc.PriceListItemName;
        }
      }
      await getPriceLists();
      const spl = await getSubscribedPricelists();

      if (spl) {
        spl.forEach(pl => {
          if (pl.Id) {
            subscribedPriceListIds.value.push(pl.Id);
          }
        });
      }

      loading.value = false;
    });

    return {
      close,
      addFromPricelist,
      calculatedPrice,
      azureSearch,
      searchWithFilters,
      toggleItem,
      confirm,
      differentUnitAccept,
      differentUnitDeclined,
      emit,
      differentUnitDecided,
      differentUnit,
      differentUnitDialog,
      searchFilter,
      pricelists,
      fetchingProducts,
      chosenItem,
      search,
      loading,
      dialog,
      valid,
      azureResult,
      BUILDING_ITEM_PRODUCT,
      resultSearch,
    };
  },
});
