











































































import { defineComponent } from '@vue/composition-api';
import {
  getIconByProfessionCode,
  getNameByProfessionCode,
} from '@/helpers/common';

export default defineComponent({
  props: {
    code: {
      type: String,
    },
    iconLeft: {
      type: Boolean,
    },
    header: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
    color: {
      type: String,
    },
  },
  setup() {
    return {
      getIconByProfessionCode,
      getNameByProfessionCode,
    };
  },
});
