







































































































































































































import { ExportType } from '@/constants/enum';
import { NstNS3459 } from '@/models/standards/ns3459/interfaces';
import { ProjectOverview } from '@/models/projects/interfaces';
import { useExport } from '@/modules/export';
import { ref, defineComponent, PropType } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { useCalculation } from '@/modules/calculation';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    project: {
      type: Object as PropType<ProjectOverview>,
      required: true,
    },
    isMenuitem: {
      type: Boolean,
    },
    isCalculation: {
      type: Boolean,
    },
  },
  setup({ project }) {
    const { exportProject } = useExport();
    const { updating } = useCalculation();
    const dialog = ref(false);
    const loading = ref(false);
    const type = ref<ExportType>(ExportType.JSON);
    const includeCalculations = ref<boolean>(
      project.ParentId === null && project.Children.length > 0
    );

    let response: NstNS3459 | undefined = undefined;

    const exportEvent = async () => {
      loading.value = true;
      if (project.Id) {
        response = await exportProject(
          project.Id,
          includeCalculations.value,
          type.value
        );
        if (response) {
          try {
            const blob = new Blob(
              [
                type.value === ExportType.XML
                  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (response as any)
                  : JSON.stringify(response),
              ],
              {
                type: `application/${
                  type.value === ExportType.XML ? 'xml' : 'json'
                }`,
              }
            );
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${project.Name}.${
              type.value === ExportType.XML ? 'xml' : 'json'
            }`;
            link.click();
            link.remove();
          } catch (e) {
            console.log(e);
          }
        }
      }
      loading.value = false;
      dialog.value = false;
    };

    return {
      exportEvent,
      dialog,
      loading,
      type,
      includeCalculations,
      ExportType,
      updating,
    };
  },
});
