export enum ReportType {
  MaterialOrder,
  MileStone,
  MaterialListPrecut,
  AttachmentPriceOffer,
  BuildingElements,
  PriceRequest,
  MainSums,
  CalculationView,
  DeliveryView,
  EconomyView,
  MaterialsView,
  TimeView,
  ProjectMileStone,
  ProjectAttachmentPriceOffer,
  ProjectBuildingElements,
  ProjectMainSums,
  Environment,
}

export enum ReportFileType {
  PDF,
  XLSX,
  CSV,
}

export const NO_MILESTONE = 'empty';
