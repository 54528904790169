






























































































































































































import { ref, defineComponent, computed } from '@vue/composition-api';
import Report from '@/components/reports/report/index.vue';
import { useReport } from '@/modules/report';
import { useCalculation } from '@/modules/calculation';
import { ReportFileType, ReportType } from '@/models/report/enums';
import { ReportOptions } from '@/models/report/interfaces';
import { useProject } from '@/modules/project';
import { translate } from '@/localization';

export default defineComponent({
  components: {
    Report,
  },
  setup() {
    const { generateReport } = useReport();
    const { updating } = useCalculation();
    const { activeProjectOverview } = useProject();
    const dialog = ref<boolean>(false);
    const selectedItem = ref<ReportType>(ReportType.Environment);
    const loading = ref<boolean>();
    const options = ref<ReportOptions>({
      DeliveryOrders: [],
      Calculations: [],
      FileType: ReportFileType.PDF,
      IncludeComments: true,
      IncludeAmounts: true,
      IncludeItemSums: false,
      IncludeElementSums: false,
      IncludePrecut: true,
      IncludeWithoutDeliveryOrder: true,
      IncludeBuildingItems: true,
      IncludeBuildingElements: true,
      IncludeBuildingItemsWithMissingEPD: true,
      IncludeBuildingItemsWithMissingNOBB: true,
      ShowProductName: true,
      FontSize: 'default',
    });

    const includeBuildingElementsEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [ReportType.Environment];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingItemsWithMissingNOBBEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [ReportType.Environment];
      return enabledWhen.includes(selectedItem.value);
    });

    const includeBuildingItemsWithMissingEPDEnabled = computed(() => {
      if (selectedItem.value == null) return false;
      const enabledWhen: Array<ReportType> = [ReportType.Environment];
      return enabledWhen.includes(selectedItem.value);
    });

    const valid = computed(() => {
      return selectedItem.value !== undefined;
    });

    const submit = async () => {
      loading.value = true;
      if (selectedItem.value !== undefined) {
        await generateReport(selectedItem.value, options.value, false);
      }
      loading.value = false;
    };

    return {
      submit,
      dialog,
      selectedItem,
      options,
      ReportFileType,
      loading,
      updating,
      ReportType,
      valid,
      includeBuildingElementsEnabled,
      includeBuildingItemsWithMissingNOBBEnabled,
      includeBuildingItemsWithMissingEPDEnabled,
      activeProjectOverview,
      items: [
        { name: translate('common.default'), value: 'default' },
        { name: translate('common.large'), value: 'large' },
      ],
    };
  },
});
