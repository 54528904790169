var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},on:{"click:outside":_vm.close},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"small":"","color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('buttons.edit-contact-list'))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-account-edit")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":"","tile":"","disabled":_vm.loading,"loading":_vm.loading}},[_c('dialog-title',{attrs:{"headerText":_vm.$t('buttons.edit-contact-list'),"secondaryText":_vm.$t('titles.contacts')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}])}),_c('v-card-text',[_c('perfect-scrollbar',{attrs:{"options":{
          suppressScrollX: true,
        }}},[_c('v-container',{staticClass:"list-container"},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.contacts),function(contact){return _c('v-col',{key:contact.id,attrs:{"cols":"12"}},[_c('contact-card',{attrs:{"contact":contact}},[_c('template',{slot:"actions"},[(
                      !_vm.existsAsPrimary(contact, _vm.calculationContacts) &&
                        _vm.existsAsCalculationContact(
                          contact,
                          _vm.calculationContacts
                        )
                    )?_c('v-btn',{staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleSetAsPrimary(contact)}}},[_vm._v(_vm._s(_vm.$t('buttons.set-as-primary-contact')))]):_vm._e(),(
                      !_vm.existsAsCalculationContact(
                        contact,
                        _vm.calculationContacts
                      )
                    )?_c('v-btn',{staticClass:"ma-2",attrs:{"min-width":"100","small":"","color":"secondary"},on:{"click":function($event){return _vm.handleAddContact(contact)}}},[_vm._v(_vm._s(_vm.$t('buttons.add')))]):_c('v-btn',{staticClass:"ma-2",attrs:{"min-width":"100","small":"","color":"error"},on:{"click":function($event){return _vm.handleRemoveContact(contact)}}},[_vm._v(_vm._s(_vm.$t('buttons.remove')))])],1)],2)],1)}),1)],1)],1)],1),_c('v-card-actions',{staticClass:"actions"},[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }