



































































































import { translate } from '@/localization';
import { useCompany } from '@/modules/company';
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import ContentBar from '@/components/common/content-bar/index.vue';
import Loader from '@/components/loader/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';

interface State {
  logo: string;
  upload?: File;
  loading: boolean;
  uploading: boolean;
  isUploading: boolean;
  valid: boolean;
}

const initialState: State = {
  logo: '',
  upload: undefined,
  loading: true,
  uploading: false,
  isUploading: false,
  valid: false,
};

const state = reactive<State>(initialState);

export default defineComponent({
  components: {
    CoolLightBox,
    ContentBar,
    Loader,
    InfoTooltip,
  },
  setup() {
    const { getLogo, uploadLogo } = useCompany();

    onMounted(async () => {
      state.logo = await getLogo();
      state.loading = false;
    });

    const submit = async () => {
      if (!state.upload) return;

      state.uploading = true;
      await uploadLogo(state.upload);
      state.logo = await getLogo();
      state.loading = false;
      state.uploading = false;
      state.isUploading = false;
      state.upload = undefined;
    };

    return {
      ...toRefs(state),
      rules: {
        required: (v: File) => !!v || translate('common.required'),
        maxSize: (value: { size: number }) =>
          (value && value.size < 2000000) ||
          !value ||
          translate('common.logo-size'),
      },
      file: state.logo,
      submit,
      index: null,
    };
  },
});
