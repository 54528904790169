


















































































import { ref, PropType, defineComponent } from '@vue/composition-api';
import { ProjectOverview } from '@/models/projects/interfaces';
import { useProject } from '@/modules/project';
import Confirm from '@/components/common/confirm/index.vue';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    project: {
      type: Object as PropType<ProjectOverview>,
      required: true,
    },
    icon: {
      type: Boolean,
    },
    isCalculation: {
      type: Boolean,
    },
    isMenuitem: {
      type: Boolean,
    },
    routeTo: {
      type: String,
    },
  },
  setup(props) {
    const { deleteProject, getProjects, refreshActiveProject } = useProject();

    const valid = ref(false);
    const loading = ref(false);
    const dialog = ref(false);

    const deleteEvent = async () => {
      loading.value = true;
      if (props.project.Id) {
        await deleteProject(
          props.project.Id,
          props.isCalculation,
          props.routeTo
        );
      }

      await getProjects(true);
      await refreshActiveProject();

      loading.value = false;
      dialog.value = false;
    };

    valid.value =
      props.project && props.project.Id !== null && props.project.Id !== '';

    return {
      loading,
      valid,
      dialog,
      deleteEvent,
    };
  },
});
