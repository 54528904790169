








































































































































































import { calculateFactor, calculateValue } from '@/helpers/factor';
import AddModal from '@/components/factors/factor-add-modal/index.vue';
import { ref, PropType, defineComponent } from '@vue/composition-api';
import { CompanyFactor } from '@/models/company/interfaces';
import { cleanSource } from '@/helpers/common';
import { useFactors } from '@/modules/factors';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import { translate } from '@/localization';

export default defineComponent({
  components: {
    AddModal,
    DialogTitle,
    InfoTooltip,
  },
  props: {
    items: {
      type: Array as PropType<Array<CompanyFactor>>,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup({ items, id }, { emit }): Record<string, unknown> {
    const { loading, updateFactors } = useFactors();

    const _factorItems = ref(cleanSource(items));
    const dialog = ref(false);
    const add = ref(false);
    const del = ref(false);
    const change = ref(false);

    const submit = async () => {
      const success = await updateFactors(id, _factorItems.value);
      emit('updateFactors', true);
      if (success) {
        add.value = false;
        del.value = false;
        change.value = false;
      }
    };

    const deleteEvent = (index: number) => {
      _factorItems.value.splice(index, 1);
      del.value = true;
    };

    const close = () => {
      dialog.value = false;
      add.value = false;
      del.value = false;
      change.value = false;
      _factorItems.value = cleanSource(items);
    };

    const added = () => {
      add.value = true;
    };

    return {
      close,
      calculateFactor,
      calculateValue,
      deleteEvent,
      added,
      submit,
      loading,
      _factorItems,
      headers: [
        {
          text: `${translate('labels.description')}`,
          align: 'start',
          sortable: false,
          value: 'Name',
          width: '50%',
        },
        { text: `${translate('labels.value')}`, value: 'Value', width: '15%' },
        { text: `${translate('headers.amount')}`, value: 'unit' },
        { text: '', value: 'actions', sortable: false },
      ],
      dialog,
      add,
      del,
      change,
    };
  },
});
