






























































































import { defineComponent } from '@vue/composition-api';
import { AddItemMethod } from '@/constants/enum';
export default defineComponent({
  props: {
    method: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    nobb: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const methodSelected = (m: AddItemMethod) => {
      emit('selectMethod', m);
    };
    return { AddItemMethod, methodSelected };
  },
});
