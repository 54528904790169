export interface NobbItem {
  bundleItems: BundleItem[];
  customsEuCode: string;
  customsNoCode: string;
  dangerousGoods: DangerousGoods;
  description: string;
  digitalChannelText: string;
  environmentLabels: string[];
  finfoNumber: string;
  firstTimeApproved: string;
  hasDurabilityDate: boolean;
  hazardLabels: string[];
  launchDate: string;
  manufacturerItemNumber: string;
  modelName: string;
  moduleNumber: number;
  nobbNumber: number;
  nrfInfo: NrfInfo;
  priceUnit: string;
  hasPCU: boolean;
  primaryText: string;
  replacedByNobbNumber: number;
  secondaryText: string;
  seriesName: string;
  stocked: boolean;
  tax: Tax;
  toleratesFrost: boolean;
  tunNumber: string;
  productGroupNumber: string;
  productGroupName: string;
  type: string;
  suppliers: Supplier[];
  properties: Properties;
  status: Status;
}

export interface BundleItem {
  nobbNumber: number;
  consistsOfCount: number;
  gtin: string;
}

export interface DangerousGoods {
  number: number;
  adrName: string;
  packagingGroup: number;
  classes: Class[];
}

export interface Class {
  class: string;
  className: string;
}

export interface NrfInfo {
  additionalText: string;
  dimension: string;
  name: string;
  number: number;
  productGroupNumber: string;
  supplierNumber: number;
}

export interface Properties {
  etim: Etim[];
  environment: Environment[];
  marketing: Environment[];
  epd: Epd[];
}

export interface Epd {
  propertyGuid: string;
  propertyName: string;
  propertyDescription: string;
  value: string;
  unit: string;
}

export interface Environment {
  propertyGuid: string;
  propertyName: string;
  propertyDescription: string;
  unit?: string;
  value: string;
}

export interface Etim {
  propertyName: string;
  unit: string;
  value: string;
  valueUpper: string;
  etimFeatureCode: string;
  etimValueCode: string;
  etimUnitCode: string;
}

export interface Status {
  createdDate: Date;
  lastModifiedDate: Date;
}

export interface Supplier {
  participantNumber: string;
  name: string;
  isMainSupplier: boolean;
  supplierItemNumber: string;
  expiryDate: string;
  packages: Package[];
  media: Media[];
}

export interface Media {
  mediaType: string;
  url: string;
}

export interface Package {
  availableFrom: string;
  availableTo: string;
  calculatedCount: number;
  class: string;
  consistsOfCount: number;
  consistsOfUnit: string;
  deliverable: boolean;
  dPakLayerCount: number;
  gtin: string;
  gtinType: string;
  height: number;
  length: number;
  maxStackingWeight: number;
  minOrderQuantity: number;
  packageNumber: number;
  stocked: boolean;
  unit: string;
  volume: number;
  weight: number;
  isPCU: boolean;
  width: number;
  status: Status;
}

export interface Tax {
  code: string;
  description: string;
}

export interface NobbModule {
  brand: string;
  corporateBrand: string;
  description: string;
  internalid: string;
  manufacturerNumber: number;
  manufacturerName: string;
  ownerParticipantNumber: number;
  productGroupNumber: string;
  productGroupName: string;
  text: string;
  status: Status;
  number: number;
}

export interface SubGroup {
  status: Status;
  name: string;
  number: string;
  subGroups?: SubGroup[];
  unspscnr?: string;
}

export enum EPDPropertyGUIDS {
  A1A3_GWP = 'B99EA4C9-A2ED-407C-B274-88FB6E21E37E',
  A1A3_GWP_IOBC = '1D5FB051-5F5B-4364-8A01-8E3B6E6A3A5E',
  A4_GWP_IOBC = '7E665C59-CF5A-4B6E-96CD-196D6C0364A6',
  A5_GWP = '570C4D52-3300-4E46-8E81-6EC920C6F47C',
  A5_GWP_IOBC = '125F05F4-14FA-45E3-B71D-E21DA0BCC26B',
  C1_GWP = '93D984F3-CFD3-4FE6-A05A-FA79235B1A46',
  C2_GWP = '2BEAEB5E-E733-44EC-8FBD-A06B526F025D',
  C3_GWP = '9037C9EF-1DD5-4F61-9013-77AE127AC5B6',
  D_GWP = 'B8D6EAF2-354F-41B6-A1E8-FC1CEA5A7AEA',
  ValidUntil = '33948955-9D6A-4370-BFC4-B1FFE66F9D92',
  ReferenceUnit = 'C5C3A9B6-74E7-411C-909B-029DBCEA021D',
}
