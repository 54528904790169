



































import { ref, defineComponent, PropType } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { BlobPriceListCategoryOverview } from '@/models/pricebook/interfaces';
import { usePriceBook } from '@/modules/pricebook';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    category: {
      type: Object as PropType<BlobPriceListCategoryOverview>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { deleteCategoryFromPriceList } = usePriceBook();
    const valid = ref(false);
    const loading = ref(false);
    const dialog = ref(false);

    const deleteEvent = async () => {
      loading.value = true;
      await deleteCategoryFromPriceList(props.category);
      loading.value = false;
      emit('update', true);
    };

    valid.value = true;

    return {
      loading,
      valid,
      dialog,
      deleteEvent,
    };
  },
});
