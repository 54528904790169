










































































































































































































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useStandards } from '@/modules/standards';
import { Ns3451 } from '@/models/standards/ns3451/interfaces';
import { useCalculation } from '@/modules/calculation';
import BuildingElements from '@/components/calculation/building-elements/index.vue';
import {
  BuildingElement,
  BuildingItem,
} from '@/models/building-element/interfaces';
import Profession from '@/components/profession/index.vue';
import KalkHelper from '@/components/common/kalk-helper/index.vue';
import draggable from 'vuedraggable';
import FromLibrary from '@/components/calculation/from-library/index.vue';
import FromCalculations from '@/components/calculation/from-calculations/index.vue';
import { useBuildingElement } from '@/modules/building-element';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import {
  BUILDING_ELEMENT_HEADERS_SIMPLE,
  BUILDING_ITEM_HEADERS_SIMPLE,
} from '@/constants/table-headers';
import { BuildingElementTemplate } from '@/models/library/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { useBuildingItem } from '@/modules/building-item';
import { useBatch } from '@/modules/batch';
import { cleanSource, scrollToBottom } from '@/helpers/common';
import { createAdditionElements } from '@/helpers/building-element';

enum AddElementMethod {
  CreateNew = 1,
  FromLibraries = 2,
  FromCalculations = 3,
}

export default defineComponent({
  components: {
    BuildingElements,
    Profession,
    FromLibrary,
    FromCalculations,
    DialogTitle,
    KalkHelper,
    draggable,
  },
  setup() {
    const { getStandards } = useStandards();
    const { activeCalculation, projectCalculation } = useCalculation();
    const {
      createElementFromNS3451,
      addFirstelement,
      addBuildingElementToCalculation,
    } = useBuildingElement();
    const { registerNewElementsWithChildren } = useBatch();

    const loading = ref<boolean>(false);
    const dialog = ref<boolean>(false);
    const method = ref<AddElementMethod>();
    const items = ref<Array<Ns3451> | undefined>(undefined);
    const search = ref<string>('');

    const elements = ref();

    const buildingElements = ref<Array<BuildingElement>>([]);

    const onDropCallback = (evt: { oldIndex: number; newIndex: number }) => {
      const temp = buildingElements.value[evt.newIndex];
      buildingElements.value[evt.newIndex] =
        buildingElements.value[evt.oldIndex];
      buildingElements.value[evt.oldIndex] = temp;
    };

    onMounted(async () => {
      loading.value = true;
      items.value = await getStandards();
      loading.value = false;
    });

    const close = () => {
      buildingElements.value = [];
      method.value = AddElementMethod.CreateNew;
      dialog.value = false;
    };

    const addElementFromCalculation = (element: BuildingElement) => {
      if (!projectCalculation.value?.Id) return;

      const newElement: BuildingElement = cleanSource(element);
      newElement.Id = uuidv4();
      newElement.ProjectId = projectCalculation.value.Id;
      newElement.Amount = null;

      if (newElement.Unit === 'm2') {
        const additions = createAdditionElements(newElement);
        newElement.AdditionItems = additions;
      }

      newElement.Items.forEach(bi => {
        bi.Id = uuidv4();
        bi.BuildingElementId = newElement.Id;
      });

      buildingElements.value.push(newElement);
    };

    const addElementFromNS3451 = async (ns3451: Ns3451) => {
      if (!projectCalculation.value?.Id) return;
      const element = await createElementFromNS3451(ns3451);
      if (element) {
        if (element.Unit === 'm2') {
          const additions = createAdditionElements(element);
          element.AdditionItems = additions;
        }
        element.ProjectId = projectCalculation.value.Id;
        buildingElements.value.push(element);
      }
    };

    const addElementFromLibrary = (template: BuildingElementTemplate) => {
      const { createFromBuildingItemTemplate } = useBuildingItem();

      if (!projectCalculation.value?.Id) return;

      const element: BuildingElement = {
        Name: template.Name,
        NS3451: template.NS3451,
        Unit: template.Unit,
        Comments: template.Comments,
        Transport: template.Transport,
        AdditionItems: [],
        Items: [],
        ProjectId: projectCalculation.value.Id,
        SortOrder: template.SortOrder,
        ProductionLine: template.ProductionLine,
        NS3450: template.NS3450,
        Id: uuidv4(),
        Amount: template.Amount,
      };

      template.BuildingItemTemplates.forEach(t => {
        if (element.Id) {
          const item = createFromBuildingItemTemplate(t, element.Id);
          element.Items.push(item);
        }
      });
      // Overflater skal overføres når det importeres fra bibliotek.
      if (element.Unit === 'm2') {
        const additions = createAdditionElements(element);
        element.AdditionItems = additions;
      }

      buildingElements.value.push(element);
    };

    const removeElement = (element: BuildingElement) => {
      const index = buildingElements.value.findIndex(e => e.Id === element.Id);
      if (index >= 0) {
        buildingElements.value.splice(index, 1);
      }
    };

    const removeItem = (item: BuildingItem) => {
      const element = buildingElements.value.find(
        ele => ele.Id === item.BuildingElementId
      );
      if (element) {
        const index = element.Items.findIndex(i => i.Id === item.Id);
        if (index >= 0) {
          element.Items.splice(index, 1);
        }
      }
    };

    const submit = async () => {
      const { projectCalculation } = useCalculation();
      loading.value = true;

      let index = 1;
      for (const e of buildingElements.value) {
        if (
          projectCalculation.value &&
          projectCalculation.value.BuildingElements.length >= 0
        ) {
          e.SortOrder =
            projectCalculation.value.BuildingElements.length + index;
        }
        addBuildingElementToCalculation(e);
        index++;
      }

      loading.value = false;
      registerNewElementsWithChildren(buildingElements.value);
      scrollToBottom();
      close();
    };

    return {
      addElementFromCalculation,
      addElementFromLibrary,
      addElementFromNS3451,
      removeElement,
      removeItem,
      close,
      submit,
      onDropCallback,
      dialog,
      loading,
      items,
      method,
      AddElementMethod,
      addFirstelement,
      code: activeCalculation?.value?.ProfessionCode
        ? activeCalculation?.value?.ProfessionCode
        : '',
      elements,
      BUILDING_ELEMENT_HEADERS_SIMPLE,
      BUILDING_ITEM_HEADERS_SIMPLE,
      buildingElements,
      search,
    };
  },
});
