import { getNameByProfessionCode } from '@/helpers/common';
import { translate } from '@/localization';
import { useCalculation } from '@/modules/calculation';
import { useProject } from '@/modules/project';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ProjectContact } from '@/models/contacts/interfaces';
import { ReportOptions } from './interfaces';
import { DEFAULT_TEXT_SMALL, LARGE_TEXT_SMALL } from '@/constants/styles';

export const createProjectInfo = async (
  doc: jsPDF,
  orderInfo: boolean,
  options: ReportOptions,
  isProject?: boolean
) => {
  const {
    projectCalculation,
    activeCalculation,
    getPrimaryContact,
  } = useCalculation();

  const { activeProjectDetails, activeProjectOverview } = useProject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;

  let contact: ProjectContact | undefined = undefined;

  if (projectCalculation.value?.Id) {
    contact = await getPrimaryContact(projectCalculation.value);
  }

  const data = [
    ['', '', '', '', '', '', '', ''],
    [
      ' ',
      translate('labels.projectnumber'),
      activeCalculation?.value?.ProjectNumber ?? '-',
      translate('labels.contact'),
      !isProject
        ? `${
            contact?.FirstName != null && contact?.LastName != null
              ? `${contact.FirstName} ${contact.LastName}`
              : '-'
          }`
        : '',
      translate('labels.street-number'),
      activeProjectDetails?.value?.StreetNumber ?? '-',
      ' ',
    ],
    [
      ' ',
      translate('labels.main-project'),
      activeProjectOverview.value?.Name ?? '-',
      translate('labels.phone'),
      !isProject ? contact?.Phone ?? '-' : '',
      translate('labels.usage-number'),
      activeProjectDetails?.value?.UsageNumber ?? '-',
      ' ',
    ],
    [
      ' ',
      translate('common.calculation'),
      !isProject ? activeCalculation?.value?.Name ?? '-' : '',
      translate('labels.email'),
      !isProject ? contact?.Email ?? '-' : '',
      translate('labels.housing-number'),
      activeProjectDetails?.value?.HousingNumber ?? '-',
      ' ',
    ],
    [
      ' ',
      translate('labels.occupation-calculation'),
      !isProject
        ? getNameByProfessionCode(
            projectCalculation.value?.Profession.Code ?? ''
          ) ?? '-'
        : '',

      '',
      '',
      translate('labels.section-number'),
      activeProjectDetails?.value?.SectionNumber ?? '-',
      ' ',
    ],
    [
      ' ',
      translate('labels.address-workplace'),
      activeProjectDetails?.value?.AddressLine1 ?? '-',
      '',
      '',
      '',
      '',
      ' ',
    ],
    ['', '', '', '', '', '', '', ''],
  ];

  autoTable(doc, {
    startY: first.finalY + 6,
    body: data,
    theme: 'plain',
    tableLineColor: '#b7b7b7',
    tableLineWidth: 0.1,
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_SMALL : LARGE_TEXT_SMALL,
      cellPadding: 0.5,
    },
    columnStyles: {
      1: {
        fontStyle: 'bold',
      },
      3: {
        fontStyle: 'bold',
      },
      5: {
        fontStyle: 'bold',
      },
      6: {
        halign: 'right',
      },
    },
  });

  if (activeProjectDetails?.value?.AdditionalInformation && orderInfo) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const second = (doc as any).autoTable.previous;

    const orderData = [
      [
        translate('labels.order-information'),
        activeProjectDetails.value.AdditionalInformation,
      ],
    ];
    autoTable(doc, {
      startY: second.finalY + 6,
      body: orderData,
      theme: 'plain',
      styles: {
        fontSize:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_SMALL
            : LARGE_TEXT_SMALL,
      },
      columnStyles: {
        0: {
          fontStyle: 'bold',
        },
        1: {
          fontStyle: 'italic',
        },
      },
    });
  }
};

export const createProjectInfoXlsx = async (
  xlData: (string | number)[][],
  isProject?: boolean
) => {
  const {
    projectCalculation,
    activeCalculation,
    getPrimaryContact,
  } = useCalculation();

  const { activeProjectDetails, activeProjectOverview } = useProject();
  let contact: ProjectContact | undefined = undefined;

  if (projectCalculation.value?.Id) {
    contact = await getPrimaryContact(projectCalculation.value);
  }

  xlData.push([
    `${translate('labels.projectnumber')}`,
    !isProject ? activeCalculation?.value?.ProjectNumber ?? '-' : '-',
    `${translate('labels.contact')}`,
    `${
      !isProject
        ? contact?.FirstName != null && contact?.LastName != null
          ? `${contact.FirstName} ${contact.LastName}`
          : '-'
        : '-'
    }`,
    `${translate('labels.street-number')}`,
    activeProjectDetails?.value?.StreetNumber ?? '-',
  ]);
  xlData.push([
    `${translate('labels.main-project')}`,
    activeProjectOverview.value?.Name ?? '-',
    `${translate('labels.phone')}`,
    !isProject ? contact?.Phone ?? '-' : '-',
    `${translate('labels.usage-number')}`,
    activeProjectDetails?.value?.UsageNumber ?? '-',
  ]);
  xlData.push([
    `${translate('common.calculation')}`,
    !isProject ? activeCalculation?.value?.Name ?? '-' : '-',
    `${translate('labels.email')}`,
    !isProject ? contact?.Email ?? '-' : '-',
    `${translate('labels.housing-number')}`,
    activeProjectDetails?.value?.HousingNumber ?? '-',
  ]);
  xlData.push([
    `${translate('labels.occupation-calculation')}`,
    !isProject
      ? getNameByProfessionCode(
          projectCalculation.value?.Profession.Code ?? ''
        ) ?? '-'
      : '-',

    '',
    '',
    `${translate('labels.section-number')}`,
    activeProjectDetails?.value?.SectionNumber ?? '-',
  ]);
  xlData.push([
    `${translate('labels.address-workplace')}`,
    activeProjectDetails?.value?.AddressLine1 ?? '-',
    '',
    '',
    '',
    '',
  ]);
  xlData.push(['', '', '', '', '', '', '', '']);
};
