




























































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api';
import GetProduct from '@/components/calculation/get-product/index.vue';
import GetTime from '@/components/calculation/get-time/index.vue';
import Confirm from '@/components/common/confirm/index.vue';
import { BuildingItem } from '@/models/building-element/interfaces';
import { createNewDefaultFromElement } from '@/helpers/building-item';
import { useCalculation } from '@/modules/calculation';
import { useBuildingItem } from '@/modules/building-item';
import { TimeEstimate } from '@/models/time-estimate/interface';
import NobbInfo from '@/components/nobb-info/index.vue';
import { ItemWithProduct } from '@/models/common/interfaces';
import { calculatedPrice } from '@/helpers/pricebook';

export default defineComponent({
  components: {
    GetProduct,
    GetTime,
    Confirm,
    NobbInfo,
  },
  props: {
    elementId: {
      type: String,
    },
    editItem: {
      type: Object as PropType<BuildingItem>,
    },
  },
  setup(props, { emit }) {
    const { NS3420Units } = useCalculation();
    const { upsertTimeEstimate } = useBuildingItem();

    const buildingItem = ref<BuildingItem>();
    const timeEstimate = ref<TimeEstimate>();
    const confirmDialog = ref<boolean>(false);

    const initItem = () => {
      if (!props.editItem && props.elementId) {
        buildingItem.value = createNewDefaultFromElement(props.elementId);
      } else {
        buildingItem.value = props.editItem;
      }
      timeEstimate.value = undefined;
    };

    const addFromPricelist = (iwp: ItemWithProduct) => {
      if (buildingItem.value) {
        buildingItem.value.NOBBNumber = iwp.PriceListItem.NobbNumber;
        buildingItem.value.Price = calculatedPrice(iwp.PriceListItem);
        buildingItem.value.UnitForOrder = iwp.PriceListItem.Unit;
        buildingItem.value.PriceListItemName = iwp.PriceListItem.Name;
        buildingItem.value.PriceListName = iwp.PriceListItem.PriceListName;
      }
    };

    const replaceName = (estimate: TimeEstimate) => {
      timeEstimate.value = estimate;
      confirmDialog.value = true;
    };

    const confirm = async () => {
      if (timeEstimate.value && buildingItem.value != null) {
        await upsertTimeEstimate(timeEstimate.value, buildingItem.value, true);
        confirmDialog.value = false;
      }
    };

    const deny = async () => {
      if (timeEstimate.value && buildingItem.value != null) {
        await upsertTimeEstimate(timeEstimate.value, buildingItem.value, false);
        confirmDialog.value = false;
      }
    };

    const addFromEstimate = async (estimate: TimeEstimate) => {
      if (buildingItem.value != null) {
        if (buildingItem.value.Name) {
          replaceName(estimate);
        } else {
          await upsertTimeEstimate(estimate, buildingItem.value, true);
        }
      }
    };

    const addFromDefault = () => {
      emit('addFromDefault', buildingItem.value);
      if (!props.editItem) {
        initItem();
      }
    };

    const hasProduct = computed(() => {
      return (
        buildingItem?.value?.PriceListName != null &&
        buildingItem?.value?.PriceListItemName != null
      );
    });

    const hasTime = computed(() => {
      return (
        buildingItem?.value?.TimeEstimateName != null &&
        buildingItem?.value?.NS3420 != null
      );
    });

    const hasOpeningAndSurface = computed(() => {
      return buildingItem.value?.Unit?.toLowerCase() === 'm2';
    });

    const removeSurfaceAndAddition = () => {
      if (
        buildingItem.value != null &&
        buildingItem.value?.Unit?.toLowerCase() !== 'm2'
      ) {
        buildingItem.value.SurfaceAddition = 0;
        buildingItem.value.OpeningAddition = 0;
      }
    };

    onMounted(() => {
      initItem();
    });

    onUnmounted(() => {
      initItem();
    });

    return {
      initItem,
      addFromDefault,
      addFromPricelist,
      confirm,
      deny,
      addFromEstimate,
      removeSurfaceAndAddition,
      buildingItem,
      NS3420Units,
      hasProduct,
      hasTime,
      hasOpeningAndSurface,
      timeEstimate,
      confirmDialog,
    };
  },
});
