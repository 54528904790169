import { CustomError, CustomErrorMessage } from '@/models/common/interfaces';
import { ProjectOverview, ProjectTotals } from '@/models/projects/interfaces';
import {
  computed,
  ComputedRef,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { useApi } from './api';
import { useErrorModal } from './error';
import { useSnackbar } from './snackbar';
import { useProject } from './project';
import { ProjectContact } from '@/models/contacts/interfaces';
import {
  Calculation,
  CalculationBatch,
  ProjectCalculation,
  ProjectConcurrencyToken,
  ProjectFactorItem,
} from '@/models/calculation/interfaces';
import { isStorageSupported, isSuccess } from '@/helpers/common';
import { useLibrary } from './library';
import { useGlobal } from './global';
import { LS_ACTIVE_CALC_KEY, LS_ACTIVE_PROJECT_KEY } from '@/constants/common';
import { useLoader } from './loader';
import {
  mapCalculationProperties,
  setComputedProperties,
  sumAmountControl,
  sumHours,
  sumMaterialsUE,
  sumProfit,
  sumSalaryCostAdjusted,
  sumSelfCost,
  sumSelfCostMaterialsSubContractors,
  sumTotal,
} from '@/helpers/calculation';
import { useBuildingElement } from './building-element';
import {
  calculateWarnings,
  sortBuildingElementsCalculation,
} from '@/helpers/building-element';
import { translate } from '@/localization';
import moment from 'moment';
import { BatchType, CalculationState } from '@/constants/enum';
import { useBatch } from './batch';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import { v4 as uuidv4 } from 'uuid';
import router from '@/router/index';
import {
  PROJECT_CALCULATION_OVERVIEW_PATH,
  PROJECT_CALCULATION_PATH,
  PROJECT_OVERVIEW_PATH,
} from '@/constants/routes';
import { PriceList, PriceListTreeNode } from '@/models/pricebook/interfaces';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';

interface State {
  building: boolean;
  activeCalculation?: ProjectOverview;
  calculation?: Calculation;
  calculationContacts?: Array<ProjectContact>;
  loading: boolean;
  updating: boolean;
  activateLibrary: Nullable<boolean>;
  error?: CustomError;
  currentState: CalculationState;
  state: ComputedRef<CalculationState>;
  activePriceList: PriceList | undefined;
  computedPriceList: ComputedRef<PriceList | undefined>;
  saveQueue: Array<CalculationBatch>;
}

const projectCalculation = ref<ProjectCalculation>();

const state = reactive<State>({
  building: false,
  loading: false,
  updating: false,
  error: undefined,
  activateLibrary: null,
  activeCalculation: undefined,
  calculationContacts: undefined,
  calculation: undefined,
  currentState: CalculationState.Idle,
  state: computed({
    get(): CalculationState {
      return state.currentState;
    },
    set(val): void {
      state.currentState = val;
    },
  }),
  computedPriceList: computed({
    get(): PriceList | undefined {
      return state.activePriceList;
    },
    set(val): void {
      state.activePriceList = val;
    },
  }),
  activePriceList: undefined,
  saveQueue: [],
});

export const useCalculation = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const NS3420Units = ['m', 'm2', 'm3', 'stk', 'kg', 'tonn', 'RS', 'm2*', ''];

  const getCalculationFactorValue = (
    factorNr: number,
    projectCalculation: ProjectCalculation
  ): Nullable<number> => {
    let value: Nullable<number> = null;
    if (projectCalculation.Factors) {
      const factor = projectCalculation.Factors.find(
        f => f.FactorNumber === factorNr
      );
      if (factor?.Unit && factor.Unit === '%' && factor.Value != null) {
        value = 1 + factor.Value / 100;
      } else if (factor?.Unit && factor.Unit === 'kr' && factor?.Value) {
        value = factor.Value;
      }
    }
    return value;
  };

  const getCalculationFactorSum = (factorNr: number): Nullable<number> => {
    let sum: Nullable<number> = null;

    if (projectCalculation?.value?.Factors) {
      const factor = projectCalculation.value.Factors.find(
        f => f.FactorNumber === factorNr
      );

      if (factor?.Value) {
        sum = factor.Value;
      }
    }

    return sum;
  };

  const buildProjectCalculation = async (
    calc: Calculation
  ): Promise<ProjectCalculation | undefined> => {
    const { initElements } = useBuildingElement();
    const projCalc = mapCalculationProperties(calc);

    projCalc.BuildingElements = await initElements(
      calc.BuildingElements,
      projCalc
    );

    setComputedProperties(projCalc);

    sortBuildingElementsCalculation(projCalc.BuildingElements);

    return projCalc;
  };

  const getCalculation = async (
    projectId: string
  ): Promise<Calculation | undefined> => {
    const { get } = useApi(`/project/${projectId}/calculation`);
    let calculation: Calculation | undefined = undefined;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        calculation = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    return calculation;
  };

  const getCalculationContacts = async (
    calculationId: string,
    force?: boolean
  ): Promise<Array<ProjectContact> | undefined> => {
    if (state.calculationContacts && !force) return state.calculationContacts;

    const { get } = useApi(`/project/${calculationId}/contacts`);

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.calculationContacts = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    return state.calculationContacts;
  };

  const initActiveCalculation = async (projectId: string): Promise<void> => {
    const { initCalculationLibrary } = useLibrary();
    const { getSettings } = useGlobal();

    if (!state.activeCalculation) return;

    if (isStorageSupported()) {
      localStorage.setItem(LS_ACTIVE_CALC_KEY, projectId);
    }

    //build usersettings
    await getSettings();

    // build calculation data
    state.calculation = await getCalculation(projectId);
    await getCalculationContacts(projectId, true);

    if (state.calculation) {
      projectCalculation.value = await buildProjectCalculation(
        state.calculation
      );
    }

    // build library data
    await initCalculationLibrary();

    // Set warnings
    if (projectCalculation.value) calculateWarnings(projectCalculation.value);
  };

  const destructActiveCalculation = async (routeToOverview?: boolean) => {
    const { destructCurrentLibrary } = useLibrary();

    state.activeCalculation = undefined;
    state.calculation = undefined;
    state.calculationContacts = undefined;
    state.error = undefined;
    state.currentState = CalculationState.Idle;
    state.activePriceList = undefined;
    state.saveQueue = [];
    projectCalculation.value = undefined;

    destructCurrentLibrary();

    if (isStorageSupported()) {
      localStorage.removeItem(LS_ACTIVE_CALC_KEY);
    }

    if (routeToOverview) {
      await router.push(PROJECT_OVERVIEW_PATH);
    }
  };

  const setActiveCalculation = async (
    project: ProjectOverview
  ): Promise<void> => {
    if (project && project.Id) {
      state.activeCalculation = project;
      await initActiveCalculation(project.Id);
    }
  };

  const recalculateActiveCalculationTotals = () => {
    if (projectCalculation.value) {
      projectCalculation.value.Hours = sumHours(projectCalculation.value);
      projectCalculation.value.Total = sumTotal(projectCalculation.value);
      projectCalculation.value.SelfCost = sumSelfCost(projectCalculation.value);
      projectCalculation.value.Profit = sumProfit(projectCalculation.value);
      projectCalculation.value.MaterialsUE = sumMaterialsUE(
        projectCalculation.value
      );
      projectCalculation.value.SelfCostMaterialsSubContractors = sumSelfCostMaterialsSubContractors(
        projectCalculation.value
      );
      projectCalculation.value.AmountControl = sumAmountControl(
        projectCalculation.value
      );
      projectCalculation.value.SalaryCostAdjusted = sumSalaryCostAdjusted(
        projectCalculation.value
      );
    }
  };

  const recalculateTotals = (
    projectCalc: ProjectCalculation
  ): ProjectTotals => {
    projectCalc.Hours = projectCalc.SumHours?.value ?? 0;
    projectCalc.Total = projectCalc.SumTotal?.value ?? 0;
    projectCalc.SelfCost = projectCalc.SumSelfCost?.value ?? 0;
    projectCalc.Profit = projectCalc.SumProfit?.value ?? 0;
    projectCalc.MaterialsUE = projectCalc.SumMaterialsUE?.value ?? 0;
    projectCalc.SelfCostMaterialsSubContractors =
      projectCalc.SumSelfCostMaterialsSubContractors?.value ?? 0;
    projectCalc.AmountControl = projectCalc.SumAmountControl?.value ?? 0;
    projectCalc.SalaryCostAdjusted =
      projectCalc.SumSalaryCostAdjusted?.value ?? 0;

    return {
      AmountControl: projectCalc.AmountControl ?? undefined,
      Hours: projectCalc.Hours ?? undefined,
      MaterialsSubContractors: projectCalc.MaterialsSubContractors ?? undefined,
      MaterialsUE: projectCalc.MaterialsUE ?? undefined,
      Profit: projectCalc.Profit ?? undefined,
      SelfCost: projectCalc.SelfCost ?? undefined,
      SelfCostMaterialsSubContractors:
        projectCalc.SelfCostMaterialsSubContractors ?? undefined,
      Total: projectCalc.Total ?? undefined,
    };
  };

  const getTotals = (): ProjectTotals | undefined => {
    if (!projectCalculation.value) return;

    return {
      AmountControl: projectCalculation.value.AmountControl ?? undefined,
      Hours: projectCalculation.value.Hours ?? undefined,
      MaterialsSubContractors:
        projectCalculation.value.MaterialsSubContractors ?? undefined,
      MaterialsUE: projectCalculation.value.MaterialsUE ?? undefined,
      Profit: projectCalculation.value.Profit ?? undefined,
      SelfCost: projectCalculation.value.SelfCost ?? undefined,
      SelfCostMaterialsSubContractors:
        projectCalculation.value.SelfCostMaterialsSubContractors ?? undefined,
      Total: projectCalculation.value.Total ?? undefined,
    };
  };

  const updateActiveCalculationWithBatch = async (
    batch: CalculationBatch
  ): Promise<boolean> => {
    let success = false;
    if (
      !projectCalculation.value ||
      !projectCalculation.value.Id ||
      !projectCalculation.value.ConcurrencyToken?.Token
    )
      return success;

    const { post } = useApi(
      `/project/${
        projectCalculation.value.Id
      }/calculation?concurrencyToken=${encodeURIComponent(
        projectCalculation.value.ConcurrencyToken.Token
      )}`
    );
    state.updating = true;
    recalculateActiveCalculationTotals();

    const totals = getTotals();

    if (totals) {
      batch.ProjectTotals = totals;
    } else return success;

    const { updateActiveProjectTotal } = useProject();
    updateActiveProjectTotal(totals);

    try {
      const response = await post(batch);
      if (isSuccess(response.status)) {
        const concurrencyToken: ProjectConcurrencyToken = response.data;
        projectCalculation.value.ConcurrencyToken = concurrencyToken;
        success = true;
        state.state = CalculationState.Updated;
      }
    } catch (e) {
      state.error = e.response;
      state.saveQueue = [];
      if (
        state.error &&
        state.error.data.LastModified &&
        state.error.data.LastModifiedBy
      ) {
        const customError: CustomErrorMessage = {
          message: `${translate('errors.message-409', [
            moment(state.error.data.LastModified).format('Do MMMM YYYY'),
            state.error.data.LastModifiedBy,
          ])}`,
          title: `${translate('errors.title-409')}`,
        };
        errorModal(state.error, customError, true);
        state.state = CalculationState.Problem;
      } else {
        errorModal(state.error);
        state.state = CalculationState.Problem;
      }
    }

    state.updating = false;
    return success;
  };

  const saveCalculation = async (batch: CalculationBatch) => {
    if (
      projectCalculation.value?.Id &&
      projectCalculation.value?.ConcurrencyToken?.Token
    ) {
      state.saveQueue.push(batch);
      if (!state.updating) {
        const currentBatch = state.saveQueue.shift();
        if (currentBatch) {
          const success = await updateActiveCalculationWithBatch(currentBatch);
          calculateWarnings(projectCalculation.value);
          if (success && state.saveQueue.length > 0) {
            const nextBatch = state.saveQueue.shift();
            if (nextBatch) {
              saveCalculation(nextBatch);
            }
          }
        }
      }
    }
  };

  const getPrimaryContact = async (
    calc: ProjectCalculation
  ): Promise<ProjectContact | undefined> => {
    if (calc.Id) {
      const contacts = await getCalculationContacts(calc.Id);

      if (contacts) {
        const primaryContact = contacts.find(
          contact => contact.IsPrimaryContact
        );
        return primaryContact;
      }
    }
    return undefined;
  };

  const getCalculationByProfession = async (
    professionCode: string
  ): Promise<Array<ProjectOverview>> => {
    const { projects, getProjects } = useProject();
    let calculationsByProf: Array<ProjectOverview> = [];
    await getProjects();

    if (projects?.value) {
      projects.value.forEach(proj => {
        calculationsByProf = calculationsByProf.concat(
          proj.Children.filter(calc => calc.ProfessionCode === professionCode)
        );
      });
    }

    calculationsByProf = calculationsByProf.sort((a, b) =>
      ('' + a.ProjectNumber ?? '').localeCompare(b.ProjectNumber ?? '' + '')
    );

    return calculationsByProf;
  };

  const lockCalculation = async (projectId: string): Promise<boolean> => {
    const { put } = useApi(`/project/${projectId}/calculation/lock`);
    const {
      getProjects,
      setActiveProjectFromProjectList,
      activeProjectOverview,
    } = useProject();
    const { isOnRoute } = useGlobal();
    state.loading = true;
    let success = false;

    try {
      const response = await put();
      if (isSuccess(response.status) && activeProjectOverview.value?.Id) {
        success = true;
        const projects = await getProjects(true);
        setActiveProjectFromProjectList(activeProjectOverview.value.Id);
        if (
          state.activeCalculation &&
          state.activeCalculation.Id === projectId
        ) {
          const parent = projects?.find(proj =>
            proj.Children.some(calc => calc.Id === projectId)
          );
          const calculation = parent?.Children.find(
            calc => calc.Id === projectId
          );

          if (calculation) {
            setActiveCalculation(calculation);
          }
        }
        snack('snack.calc-locked', true);

        if (isOnRoute(PROJECT_CALCULATION_PATH)) {
          await router.push(PROJECT_CALCULATION_OVERVIEW_PATH);
        }
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return success;
  };

  const unlockCalculation = async (projectId: string): Promise<boolean> => {
    const { deleteReq } = useApi(`/project/${projectId}/calculation/lock`);
    const {
      getProjects,
      setActiveProjectFromProjectList,
      activeProjectOverview,
    } = useProject();
    state.loading = true;
    let success = false;

    try {
      const response = await deleteReq();
      if (isSuccess(response.status) && activeProjectOverview.value?.Id) {
        success = true;
        const projects = await getProjects(true);
        setActiveProjectFromProjectList(activeProjectOverview.value.Id);
        if (
          state.activeCalculation &&
          state.activeCalculation.Id === projectId
        ) {
          const parent = projects?.find(proj =>
            proj.Children.some(calc => calc.Id === projectId)
          );
          const calc = parent?.Children.find(calc => calc.Id === projectId);
          if (calc) {
            setActiveCalculation(calc);
          }
        }
        snack('snack.calc-unlocked', true);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return success;
  };

  const buildCalculationDeps = async (): Promise<void> => {
    const {
      setActiveProject,
      getProjectOverviewById,
      activeProjectOverview,
      getActiveProjectDetails,
      getProjects,
    } = useProject();
    const { startLoader, stopLoader } = useLoader();

    let _activeCalculation: ProjectOverview | undefined = undefined;

    if (isStorageSupported()) {
      const projectId: string | null = localStorage.getItem(
        LS_ACTIVE_PROJECT_KEY
      );
      const calcId: string | null = localStorage.getItem(LS_ACTIVE_CALC_KEY);

      /* if there are no values, continue as usual. Also if either active calculation or active project exist, it is not a hard refresh, continue as usual. */
      if (!projectId && !calcId) return;
      if (state.activeCalculation || activeProjectOverview?.value) return;

      startLoader(`${translate('loader.loading-project')}`);
      // init previously set active project
      if (projectId) {
        await getProjects();
        await setActiveProject(projectId, false);
        await getActiveProjectDetails(projectId);
      }

      // init previously set active calculation
      if (calcId) {
        _activeCalculation = await getProjectOverviewById(calcId);
        if (_activeCalculation) {
          await setActiveCalculation(_activeCalculation);
        }
      }
      stopLoader();
    }
  };

  const convertCalculationToTemplate = async (
    templateName: string
  ): Promise<void> => {
    if (!projectCalculation.value?.Profession?.Id) return;

    const {
      createBuildingTypeTemplate,
      createBuildingElementTemplate,
      createBuildingElementTemplateFromBEC,
      initCalculationLibrary,
      setActiveTemplate,
      buildingTemplatesByProfession,
    } = useLibrary();
    const { startLoader, stopLoader, updateStatusText } = useLoader();

    const newTemplate: BuildingTypeTemplate = {
      Id: uuidv4(),
      GlobalKey: uuidv4(),
      Name: templateName,
      Created: new Date().toLocaleString(),
      LastModified: new Date().toLocaleString(),
      Profession: projectCalculation.value.Profession,
      ProfessionCode: projectCalculation.value.Profession.Code,
      ProfessionId: projectCalculation.value.Profession.Id,
      BuildingElementTemplates: [],
      Description: '',
      ImportTime: null,
      OrganizationId: null,
    };

    startLoader(`${translate('loader.creating-template', [templateName])}`);
    const success = await createBuildingTypeTemplate(newTemplate);

    if (
      success &&
      projectCalculation?.value?.BuildingElements &&
      newTemplate.Id
    ) {
      for (const element of projectCalculation.value.BuildingElements) {
        if (element.BuildingItems.length > 0) {
          updateStatusText(
            `${translate('loader.create-element-template', [
              element.Name ?? '',
            ])}`
          );
          const template = createBuildingElementTemplateFromBEC(
            element,
            newTemplate.Id
          );
          const success = await createBuildingElementTemplate(
            template,
            newTemplate.Id
          );
          if (!success) return;
        }
      }
    }

    stopLoader();
    initCalculationLibrary();

    const recentlyAddedTemplate = buildingTemplatesByProfession.value?.find(
      template => template.Id === newTemplate.Id
    );

    if (recentlyAddedTemplate) {
      setActiveTemplate(recentlyAddedTemplate);
      state.activateLibrary = true;
    }
  };

  const setPriceListForActiveCalculation = async (
    list: PriceListTreeNode
  ): Promise<void> => {
    const { registerBuildingItemBatchOperation } = useBatch();
    if (projectCalculation.value?.BuildingElements) {
      projectCalculation.value.BuildingElements.forEach(ele => {
        ele.BuildingItems.forEach(item => {
          if (item.NOBBNumber) {
            item.PriceListName = list.Name;
            registerBuildingItemBatchOperation(item, BatchType.Save);
          }
        });
      });
    }
  };

  const getItemsWithSimilarProduct = (
    nobbNo: number,
    id: string
  ): Array<BuildingItemCalculation> => {
    let itemsContainingProduct: Array<BuildingItemCalculation> = [];

    if (projectCalculation.value?.BuildingElements) {
      projectCalculation.value.BuildingElements.forEach(ele => {
        const sameProduct = ele.BuildingItems.filter(
          i => i.NOBBNumber != null && i.NOBBNumber === nobbNo && i.Id !== id
        );

        itemsContainingProduct = itemsContainingProduct.concat(sameProduct);
      });
    }

    return itemsContainingProduct;
  };

  const getCalculationPriceListFromElements = async () => {
    let activeList: Nullable<string> | undefined = '';
    if (projectCalculation.value?.BuildingElements) {
      const elementsWithPriceList = projectCalculation.value.BuildingElements.filter(
        ele => ele.BuildingItems.some(item => item.PriceListName)
      );

      if (
        elementsWithPriceList.length !==
        projectCalculation.value.BuildingElements.length
      )
        return activeList;

      const elementWithPriceList = elementsWithPriceList.find(ele =>
        ele.BuildingItems.some(item => item.PriceListName)
      );

      if (elementWithPriceList) {
        activeList = elementWithPriceList?.BuildingItems.find(
          bi => bi.PriceListName
        )?.PriceListName;

        if (activeList) {
          elementsWithPriceList.forEach(ele => {
            if (
              ele.BuildingItems.some(item => item.PriceListName !== activeList)
            )
              return '';
          });
        }
      }

      return activeList;
    }
  };

  const reloadCalculation = async () => {
    if (state.calculation) {
      projectCalculation.value = await buildProjectCalculation(
        state.calculation
      );
    }
  };

  const updateProjectCalculationFactors = async (
    id: string,
    newFactors: Array<ProjectFactorItem>
  ) => {
    if (id && projectCalculation.value && projectCalculation.value.Factors) {
      const projectFactor = projectCalculation.value.Factors.find(
        pf => pf.Id === id
      );
      if (
        projectFactor &&
        projectFactor.ProjectId === projectCalculation.value.Id
      ) {
        projectFactor.FactorItems = newFactors;
        await reloadCalculation();
      }
    }
  };

  return {
    ...toRefs(state),
    setActiveCalculation,
    lockCalculation,
    unlockCalculation,
    getCalculationContacts,
    getCalculationByProfession,
    buildCalculationDeps,
    getCalculationFactorValue,
    getCalculationFactorSum,
    recalculateActiveCalculationTotals,
    saveCalculation,
    initActiveCalculation,
    convertCalculationToTemplate,
    setPriceListForActiveCalculation,
    getCalculationPriceListFromElements,
    destructActiveCalculation,
    getCalculation,
    buildProjectCalculation,
    reloadCalculation,
    getItemsWithSimilarProduct,
    recalculateTotals,
    getPrimaryContact,
    updateProjectCalculationFactors,
    updateActiveCalculationWithBatch,
    NS3420Units,
    projectCalculation,
  };
};
