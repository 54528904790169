



























































































































import { ref, defineComponent, computed, PropType } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import ProfessionView from '@/components/profession/index.vue';
import { useLibrary } from '@/modules/library';
import { createDefaultBuildingTypeTemplate } from '@/helpers/library';
import { Profession } from '@/models/common/interfaces';
import {
  getIconByProfessionCode,
  getNameByProfessionCode,
} from '@/helpers/common';

interface NewDefaultBuildingTypeTemplatePayload {
  name: Nullable<string>;
  description: Nullable<string>;
}

export default defineComponent({
  components: {
    DialogTitle,
    ProfessionView,
  },
  props: {
    profession: {
      type: Object as PropType<Profession>,
    },
    first: {
      type: Boolean,
    },
    button: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const payload = ref<NewDefaultBuildingTypeTemplatePayload>({
      name: null,
      description: null,
    });

    const valid = computed(() => {
      return payload.value.name && props.profession;
    });

    const submit = async () => {
      const {
        createBuildingTypeTemplate,
        initCalculationLibrary,
      } = useLibrary();

      if (!props.profession) return;

      const newTemplate = createDefaultBuildingTypeTemplate(props.profession);

      if (newTemplate && props.profession) {
        newTemplate.Name = payload.value.name;
        newTemplate.Description = payload.value.description;

        loading.value = true;
        const success = await createBuildingTypeTemplate(newTemplate);

        if (success) {
          initCalculationLibrary();
          emit('update', props.profession.Code.toString());
          payload.value.name = null;
          payload.value.description = null;
          loading.value = false;
          dialog.value = false;
        }
      }
    };

    return {
      submit,
      getIconByProfessionCode,
      getNameByProfessionCode,
      dialog,
      payload,
      valid,
      loading,
    };
  },
});
