import { createReportName, downloadExcel } from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportFileType, ReportType } from '@/models/report//enums';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportOptions } from '@/models/report/interfaces';
import XLSX from 'xlsx';
import {
  getNameByProfessionCode,
  numberToAbsStringOrEmpty,
} from '@/helpers/common';
import { useProject } from '@/modules/project';
import {
  DEFAULT_TEXT_LARGE,
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_MINI,
  LARGE_TEXT_LARGE,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_MINI,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

export const generateProjectMainSumsReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();
  const { activeProjectOverview } = useProject();

  if (options.Calculations.length === 0) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);
  const pdfName = createReportName(type, ReportFileType.PDF, true);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, true);

  const reportName = `${translate('reports.project-main-sums-report')}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;
  doc.text(reportName, 14, first.finalY + 9);

  const projectSummaryData: (string | number)[][] = [];
  let projectSumTime = 0;
  let projectSumSelfCostSalaryAndExpenses = 0;
  let projectSumSelfCostMaterialsSubContractors = 0;
  let projectFactor4 = 0;
  let projectSumSelfCost = 0;
  let projectSumProfit = 0;
  let projectSumTotal = 0;

  let calculationCount = 0;
  for (const calc of options.Calculations) {
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc || projectCalc.BuildingElements.length === 0) continue;
        calculationCount++;

        const head = [
          [
            translate('labels.description'),
            translate('labels.amount'),
            translate('labels.unit'),
            translate('labels.time-per-unit'),
            translate('labels.hours'),
            translate('labels.sum-selfcost-salary-and-expenses'),
            translate('labels.sum-selfcost-mat-and-sub'),
            translate('labels.factor4'),
            translate('labels.selfcost'),
            translate('labels.profit'),
            translate('labels.total'),
          ],
        ];

        const calcName = `${projectCalc.Name} - ${getNameByProfessionCode(
          projectCalc.Profession.Code
        )}`;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const first = (doc as any).autoTable.previous;

        autoTable(doc, {
          startY: calculationCount === 1 ? first.finalY + 14 : first.finalY + 6,
          head: [[calcName]],
          theme: 'plain',
          styles: {
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_LARGE
                : LARGE_TEXT_LARGE,
            halign: 'left',
          },
        });

        const bEdata: (string | number)[][] = [];
        const buildingElementBreakPoints: Array<number> = [];

        const elements = projectCalc.BuildingElements;

        if (!elements) return;

        elements.forEach(ele => {
          buildingElementBreakPoints.push(bEdata.length);
          const row = [
            ele.Name ?? '',
            numberToAbsStringOrEmpty(ele.Amount, 2),
            ele.Unit?.value ?? '',
            numberToAbsStringOrEmpty(ele.TimePrUnit.value, 2),
            numberToAbsStringOrEmpty(ele.SumTime.value, 2),
            numberToAbsStringOrEmpty(ele.SumSelfCostSalaryAndExpenses.value, 2),
            numberToAbsStringOrEmpty(
              ele.SumSelfCostMaterialsSubContractors.value,
              2
            ),
            numberToAbsStringOrEmpty(ele.Factor4.value, 2),
            numberToAbsStringOrEmpty(ele.SumSelfCost.value, 2),
            numberToAbsStringOrEmpty(ele.SumProfit.value, 2),
            numberToAbsStringOrEmpty(ele.SumTotal.value, 2),
          ];
          bEdata.push(row);

          if (options.IncludeBuildingItems) {
            ele.BuildingItems.forEach(item => {
              const row = [
                item.Name ?? '',
                numberToAbsStringOrEmpty(item.Amount, 2),
                item.Unit ?? '',
                numberToAbsStringOrEmpty(item.TimePrUnit.value, 2),
                numberToAbsStringOrEmpty(item.SumTime.value, 2),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostSalaryAndExpenses.value,
                  2
                ),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostMaterialsSubContractors.value,
                  2
                ),
                numberToAbsStringOrEmpty(item.Factor4.value, 2),
                numberToAbsStringOrEmpty(item.SumSelfCost.value, 2),
                numberToAbsStringOrEmpty(item.SumProfit.value, 2),
                numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 2),
              ];
              bEdata.push(row);
            });
          }
        });

        projectSumTime += elements.reduce((prev, cur) => {
          return prev + (cur.SumTime.value ?? 0);
        }, 0);
        projectSumSelfCostSalaryAndExpenses += elements.reduce((prev, cur) => {
          return prev + (cur.SumSelfCostSalaryAndExpenses.value ?? 0);
        }, 0);
        projectSumSelfCostMaterialsSubContractors += elements.reduce(
          (prev, cur) => {
            return prev + (cur.SumSelfCostMaterialsSubContractors.value ?? 0);
          },
          0
        );
        projectFactor4 += elements.reduce((prev, cur) => {
          return prev + (cur.Factor4.value ?? 0);
        }, 0);
        projectSumSelfCost += elements.reduce((prev, cur) => {
          return prev + (cur.SumSelfCost.value ?? 0);
        }, 0);
        projectSumProfit += elements.reduce((prev, cur) => {
          return prev + (cur.SumProfit.value ?? 0);
        }, 0);
        projectSumTotal += elements.reduce((prev, cur) => {
          return prev + (cur.SumTotal.value ?? 0);
        }, 0);

        const lastRow = [
          projectCalc.Name,
          '',
          '',
          '',
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumTime.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostSalaryAndExpenses.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostMaterialsSubContractors.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.Factor4.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumSelfCost.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumProfit.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumTotal.value ?? 0);
            }, 0),
            2
          ),
        ];

        bEdata.push(lastRow);
        projectSummaryData.push(lastRow);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const second = (doc as any).autoTable.previous;

        autoTable(doc, {
          startY: second.finalY + 3,
          head: head,
          body: bEdata,
          showHead: 'firstPage',
          headStyles: {
            fillColor: '#263238',
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_MINI
                : LARGE_TEXT_MINI,
            valign: 'middle',
            overflow: 'linebreak',
          },
          styles: {
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_MINI
                : LARGE_TEXT_MINI,
          },
          didParseCell(data) {
            if (
              buildingElementBreakPoints.includes(data.row.index) &&
              data.section === 'body' &&
              options.IncludeBuildingItems
            ) {
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.fillColor = '#d6d8da';
            } else if (
              data.section === 'body' &&
              data.row.index === bEdata.length - 1
            ) {
              data.cell.styles.fillColor = 'white';
              data.cell.styles.fontStyle = 'bold';
            } else if (
              data.section === 'body' &&
              options.IncludeBuildingItems
            ) {
              data.cell.styles.fillColor = 'white';
            }
          },
          columnStyles: {
            0: {
              valign: 'middle',
              cellWidth: 30,
            },
            1: {
              valign: 'middle',
            },
            2: {
              valign: 'middle',
            },
            3: {
              valign: 'middle',
              halign: 'right',
            },
            4: {
              valign: 'middle',
              halign: 'right',
            },
            5: {
              valign: 'middle',
              halign: 'right',
              cellWidth: 15,
            },
            6: {
              valign: 'middle',
              halign: 'right',
              cellWidth: 15,
            },
            7: {
              valign: 'middle',
              halign: 'right',
              cellWidth: 15,
            },
            8: {
              valign: 'middle',
              halign: 'right',
            },
            9: {
              valign: 'middle',
              halign: 'right',
            },
            10: {
              valign: 'middle',
              halign: 'right',
            },
          },
        });
      }
    }
  }

  if (activeProjectOverview.value) {
    const head = [
      [
        translate('labels.description'),
        translate('labels.amount'),
        translate('labels.unit'),
        translate('labels.time-per-unit'),
        translate('labels.hours'),
        translate('labels.sum-selfcost-salary-and-expenses'),
        translate('labels.sum-selfcost-mat-and-sub'),
        translate('labels.factor4'),
        translate('labels.selfcost'),
        translate('labels.profit'),
        translate('labels.total'),
      ],
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const first = (doc as any).autoTable.previous;

    autoTable(doc, {
      startY: first.finalY + 6,
      head: [[`Total (${activeProjectOverview.value.Name})`]],
      theme: 'plain',
      styles: {
        fontSize:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_LARGE
            : LARGE_TEXT_LARGE,
        halign: 'left',
      },
    });

    const lastRow = [
      '',
      '',
      '',
      '',
      numberToAbsStringOrEmpty(projectSumTime, 2),
      numberToAbsStringOrEmpty(projectSumSelfCostSalaryAndExpenses, 2),
      numberToAbsStringOrEmpty(projectSumSelfCostMaterialsSubContractors, 2),
      numberToAbsStringOrEmpty(projectFactor4, 2),
      numberToAbsStringOrEmpty(projectSumSelfCost, 2),
      numberToAbsStringOrEmpty(projectSumProfit, 2),
      numberToAbsStringOrEmpty(projectSumTotal, 2),
    ];

    projectSummaryData.push(lastRow);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const second = (doc as any).autoTable.previous;

    autoTable(doc, {
      startY: second.finalY + 3,
      head: head,
      body: projectSummaryData,
      showHead: 'firstPage',
      headStyles: {
        fillColor: '#263238',
        fontSize:
          options.FontSize === 'default' ? DEFAULT_TEXT_MINI : LARGE_TEXT_MINI,
        valign: 'middle',
        overflow: 'linebreak',
      },
      didParseCell(data) {
        if (
          data.section === 'body' &&
          data.row.index === projectSummaryData.length - 1
        ) {
          data.cell.styles.fillColor = 'white';
          data.cell.styles.fontStyle = 'bold';
        }
      },
      styles: {
        fontSize:
          options.FontSize === 'default' ? DEFAULT_TEXT_MINI : LARGE_TEXT_MINI,
      },
      columnStyles: {
        0: {
          valign: 'middle',
          cellWidth: 30,
        },
        1: {
          valign: 'middle',
        },
        2: {
          valign: 'middle',
        },
        3: {
          valign: 'middle',
          halign: 'right',
        },
        4: {
          valign: 'middle',
          halign: 'right',
        },
        5: {
          valign: 'middle',
          halign: 'right',
          cellWidth: 15,
        },
        6: {
          valign: 'middle',
          halign: 'right',
          cellWidth: 15,
        },
        7: {
          valign: 'middle',
          halign: 'right',
          cellWidth: 15,
        },
        8: {
          valign: 'middle',
          halign: 'right',
        },
        9: {
          valign: 'middle',
          halign: 'right',
        },
        10: {
          valign: 'middle',
          halign: 'right',
        },
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

export const generateProjectMainSumsReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();
  const { activeProjectOverview } = useProject();
  const { checkAndbuildSheetName } = useReport();

  if (options.Calculations.length === 0) return;

  const wb = XLSX.utils.book_new();
  const xlsxName = createReportName(type, ReportFileType.XLSX, true);

  const projectSummaryData: (string | number)[][] = [];
  let projectSumTime = 0;
  let projectSumSelfCostSalaryAndExpenses = 0;
  let projectSumSelfCostMaterialsSubContractors = 0;
  let projectFactor4 = 0;
  let projectSumSelfCost = 0;
  let projectSumProfit = 0;
  let projectSumTotal = 0;

  projectSummaryData.push([activeProjectOverview.value?.Name ?? '']);
  projectSummaryData.push(['']);

  await createHeaderXlsx(projectSummaryData);
  await createProjectInfoXlsx(projectSummaryData);

  projectSummaryData.push([
    `${translate('labels.description')}`,
    `${translate('labels.amount')}`,
    `${translate('labels.unit')}`,
    `${translate('labels.time-per-unit')}`,
    `${translate('labels.hours')}`,
    `${translate('labels.sum-selfcost-salary-and-expenses')}`,
    `${translate('labels.sum-selfcost-mat-and-sub')}`,
    `${translate('labels.factor4')}`,
    `${translate('labels.selfcost')}`,
    `${translate('labels.profit')}`,
    `${translate('labels.total')}`,
  ]);

  for (const calc of options.Calculations) {
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc) continue;

        const sheetName = checkAndbuildSheetName(
          projectCalc.Name,
          wb.SheetNames
        );

        wb.Props = {
          Title: `${translate('reports.reports.project-main-sums-report')}`,
          CreatedDate: new Date(),
        };

        wb.SheetNames.push(sheetName);

        const bEdata: (string | number)[][] = [];

        bEdata.push([sheetName]);
        bEdata.push(['']);

        await createHeaderXlsx(bEdata);
        await createProjectInfoXlsx(bEdata);

        const elements = projectCalc.BuildingElements;

        if (!elements) return;

        bEdata.push([
          `${translate('labels.description')}`,
          `${translate('labels.amount')}`,
          `${translate('labels.unit')}`,
          `${translate('labels.time-per-unit')}`,
          `${translate('labels.hours')}`,
          `${translate('labels.sum-selfcost-salary-and-expenses')}`,
          `${translate('labels.sum-selfcost-mat-and-sub')}`,
          `${translate('labels.factor4')}`,
          `${translate('labels.selfcost')}`,
          `${translate('labels.profit')}`,
          `${translate('labels.total')}`,
        ]);

        elements.forEach(ele => {
          const row = [
            ele.Name ?? '',
            numberToAbsStringOrEmpty(ele.Amount, 2),
            ele.Unit?.value ?? '',
            numberToAbsStringOrEmpty(ele.TimePrUnit.value, 2),
            numberToAbsStringOrEmpty(ele.SumTime.value, 2),
            numberToAbsStringOrEmpty(ele.SumSelfCostSalaryAndExpenses.value, 2),
            numberToAbsStringOrEmpty(
              ele.SumSelfCostMaterialsSubContractors.value,
              2
            ),
            numberToAbsStringOrEmpty(ele.Factor4.value, 2),
            numberToAbsStringOrEmpty(ele.SumSelfCost.value, 2),
            numberToAbsStringOrEmpty(ele.SumProfit.value, 2),
            numberToAbsStringOrEmpty(ele.SumTotal.value, 2),
          ];
          bEdata.push(row);

          if (options.IncludeBuildingItems) {
            ele.BuildingItems.forEach(item => {
              const row = [
                item.Name ?? '',
                numberToAbsStringOrEmpty(item.Amount, 2),
                item.Unit ?? '',
                numberToAbsStringOrEmpty(item.TimePrUnit.value, 2),
                numberToAbsStringOrEmpty(item.SumTime.value, 2),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostSalaryAndExpenses.value,
                  2
                ),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostMaterialsSubContractors.value,
                  2
                ),
                numberToAbsStringOrEmpty(item.Factor4.value, 2),
                numberToAbsStringOrEmpty(item.SumSelfCost.value, 2),
                numberToAbsStringOrEmpty(item.SumProfit.value, 2),
                numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 2),
              ];
              bEdata.push(row);
            });
          }
        });

        projectSumTime += elements.reduce((prev, cur) => {
          return prev + (cur.SumTime.value ?? 0);
        }, 0);
        projectSumSelfCostSalaryAndExpenses += elements.reduce((prev, cur) => {
          return prev + (cur.SumSelfCostSalaryAndExpenses.value ?? 0);
        }, 0);
        projectSumSelfCostMaterialsSubContractors += elements.reduce(
          (prev, cur) => {
            return prev + (cur.SumSelfCostMaterialsSubContractors.value ?? 0);
          },
          0
        );
        projectFactor4 += elements.reduce((prev, cur) => {
          return prev + (cur.Factor4.value ?? 0);
        }, 0);
        projectSumSelfCost += elements.reduce((prev, cur) => {
          return prev + (cur.SumSelfCost.value ?? 0);
        }, 0);
        projectSumProfit += elements.reduce((prev, cur) => {
          return prev + (cur.SumProfit.value ?? 0);
        }, 0);
        projectSumTotal += elements.reduce((prev, cur) => {
          return prev + (cur.SumTotal.value ?? 0);
        }, 0);

        const lastRow = [
          projectCalc.Name,
          '',
          '',
          '',
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumTime.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostSalaryAndExpenses.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostMaterialsSubContractors.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.Factor4.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumSelfCost.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumProfit.value ?? 0);
            }, 0),
            2
          ),
          numberToAbsStringOrEmpty(
            elements.reduce((prev, cur) => {
              return prev + (cur.SumTotal.value ?? 0);
            }, 0),
            2
          ),
        ];

        bEdata.push(lastRow);
        projectSummaryData.push(lastRow);

        const ws = XLSX.utils.aoa_to_sheet(bEdata);
        wb.Sheets[sheetName] = ws;
      }
    }
  }

  if (activeProjectOverview.value) {
    const lastRow = [
      '',
      '',
      '',
      '',
      numberToAbsStringOrEmpty(projectSumTime, 2),
      numberToAbsStringOrEmpty(projectSumSelfCostSalaryAndExpenses, 2),
      numberToAbsStringOrEmpty(projectSumSelfCostMaterialsSubContractors, 2),
      numberToAbsStringOrEmpty(projectFactor4, 2),
      numberToAbsStringOrEmpty(projectSumSelfCost, 2),
      numberToAbsStringOrEmpty(projectSumProfit, 2),
      numberToAbsStringOrEmpty(projectSumTotal, 2),
    ];

    projectSummaryData.push(lastRow);

    const projectName = checkAndbuildSheetName(
      activeProjectOverview.value.Name,
      wb.SheetNames
    );
    wb.SheetNames.push(projectName);

    const ws = XLSX.utils.aoa_to_sheet(projectSummaryData);
    wb.Sheets[projectName] = ws;
  }
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  downloadExcel(xlsxName, wbout);
};

export const generateProjectMainSumsReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateProjectMainSumsReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateProjectMainSumsReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
