



















































































import {
  ref,
  defineComponent,
  PropType,
  onMounted,
  computed,
} from '@vue/composition-api';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { useCalculation } from '@/modules/calculation';
import { translate } from '@/localization';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    element: {
      type: Object as PropType<BuildingElementCalculation>,
    },
    isMenuitem: {
      type: Boolean,
    },
  },
  setup() {
    const {
      projectCalculation,
      convertCalculationToTemplate,
    } = useCalculation();
    const dialog = ref<boolean>(false);
    const templateName = ref<string>();

    const close = () => {
      dialog.value = false;
      if (projectCalculation.value) {
        templateName.value = projectCalculation.value.Name;
      }
    };

    const submit = async () => {
      if (templateName.value) {
        await convertCalculationToTemplate(templateName.value);
        close();
      }
    };

    const valid = computed(() => {
      return (
        templateName.value !== undefined &&
        templateName.value !== null &&
        templateName.value !== ''
      );
    });

    onMounted(() => {
      if (projectCalculation.value) {
        templateName.value = projectCalculation.value.Name;
      }
    });

    return {
      close,
      submit,
      dialog,
      projectCalculation,
      templateName,
      valid,
      nameRules: [(v: string) => !!v || `${translate('validation.required')}`],
    };
  },
});
