

































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    small: {
      type: Boolean,
    },
    margins: {
      type: Boolean,
    },
    text: {
      type: String,
    },
  },
});
