import { isSuccess } from '@/helpers/common';
import { CustomError } from '@/models/common/interfaces';
import {
  TimeEstimate,
  TimeEstimateCategory,
} from '@/models/time-estimate/interface';
import { reactive, toRefs } from '@vue/composition-api';
import { useApi } from './api';
import { useErrorModal } from './error';
import { useSnackbar } from './snackbar';
interface State {
  error?: CustomError;
}

const state = reactive<State>({
  error: undefined,
});

export const useTimeEstimate = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const getTimeEstimateCategories = async (
    professionCode: string
  ): Promise<Array<TimeEstimateCategory> | undefined> => {
    if (!professionCode) return;

    const { get } = useApi(`/timeestimates/profession/${professionCode}`);
    let categories: Array<TimeEstimateCategory> | undefined = undefined;

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        categories = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return categories;
  };

  const findAllEstimatesInCategory = (
    category: TimeEstimateCategory
  ): Array<TimeEstimate> => {
    let categoryEstimates: Array<TimeEstimate> = category.Estimates;

    if (category.Categories && category.Categories.length > 0) {
      category.Categories.forEach(cat => {
        categoryEstimates = categoryEstimates.concat(cat.Estimates);
      });
    }

    return categoryEstimates;
  };

  const buildEstimates = async (categories: Array<TimeEstimateCategory>) => {
    categories.forEach(category => {
      category.Estimates = findAllEstimatesInCategory(category);
    });
  };

  return {
    getTimeEstimateCategories,
    buildEstimates,
    ...toRefs(state),
  };
};
