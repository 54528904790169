












import { PropType, computed, defineComponent } from '@vue/composition-api';
import { Epd } from '@/models/nobb/interfaces';
import moment from 'moment';
import { useEnvironment } from '@/modules/environment';

export default defineComponent({
  props: {
    epd: {
      type: Array as PropType<Array<Epd>>,
      required: true,
    },
  },
  setup(props) {
    const { tryGetUnformatedEpdValidationDate } = useEnvironment();
    const validationDate = computed(() =>
      tryGetUnformatedEpdValidationDate(props.epd)
    );

    const isValid = !moment(validationDate.value).isBefore();
    return {
      props,
      validationDate,
      isValid,
      moment,
    };
  },
});
