





















































































































































































































































































































































































import { translate } from '@/localization';
import { useImport } from '@/modules/import';
import { useSnackbar } from '@/modules/snackbar';
import { ref, defineComponent } from '@vue/composition-api';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import Loader from '@/components/loader/index.vue';
import { PriceListVM, VareKategori } from '@/models/pricebook/interfaces';
import { PRODUCT_HEADERS } from '@/constants/table-headers';
import { cleanSource } from '@/helpers/common';
import { isCSVorTXT, isExcel } from '@/helpers/parse';
import { usePriceBook } from '@/modules/pricebook';

interface Payload {
  categories: Array<VareKategori>;
  name: string;
}

export default defineComponent({
  components: {
    vueDropzone: vue2Dropzone,
    DialogTitle,
    InfoTooltip,
    Loader,
  },
  setup() {
    const dialog = ref(false);
    const loading = ref(false);
    const parsing = ref(false);
    const pricelistFile = ref<File>();
    const importZone = ref();
    const validFile = ref(false);
    const validForm = ref(false);
    const allCategories = ref(true);
    const validating = ref(false);

    const categories = ref<PriceListVM>();

    const payload = ref<Payload>({ categories: [], name: '' });

    const { parsePriceListCSV, parsePriceListExcel } = useImport();

    const { uploadPriceList } = usePriceBook();

    const handleError = (file: File, message: string) => {
      const { snack } = useSnackbar();
      importZone.value.removeFile(file);
      if (!message.includes('Upload')) {
        snack(message, false);
      }
    };

    const cancel = () => {
      pricelistFile.value = undefined;
      validFile.value = false;
      categories.value = undefined;
      allCategories.value = true;
      payload.value.name = '';
      payload.value.categories = [];
      if (importZone.value) importZone.value.enable();
    };

    const close = () => {
      pricelistFile.value = undefined;
      dialog.value = false;
      validFile.value = false;
      categories.value = undefined;
      allCategories.value = true;
      payload.value.name = '';
      payload.value.categories = [];
    };

    const submit = () => {
      if (validForm && categories.value) {
        categories.value.Name = payload.value.name;
        categories.value.Categories = payload.value.categories;
        uploadPriceList(categories.value);
        close();
      }
    };

    const validateImport = async (file: File) => {
      validating.value = true;
      pricelistFile.value = file;
      const { validatePriceListFile } = useImport();
      try {
        validFile.value = await validatePriceListFile(file);
      } catch (e) {
        console.log(e);
      }

      if (validFile.value) {
        importZone.value.disable();
        if (pricelistFile.value) {
          parsing.value = true;

          if (isCSVorTXT(pricelistFile.value.name)) {
            categories.value = await parsePriceListCSV(pricelistFile.value);
          } else if (isExcel(pricelistFile.value.name)) {
            categories.value = await parsePriceListExcel(pricelistFile.value);
          }

          if (categories.value) {
            payload.value.categories = cleanSource(categories.value.Categories);
            payload.value.name = categories.value.Name;
          } else {
            cancel();
          }

          parsing.value = false;
        }
      } else {
        cancel();
      }

      validating.value = false;
    };

    return {
      validateImport,
      handleError,
      cancel,
      close,
      submit,
      importZone,
      dialog,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'http://localhost',
        thumbnailWidth: 150,
        maxFilesize: 100,
        maxFiles: 1,
        acceptedFiles: '.txt,.csv,.xlsx',
        dictDefaultMessage: `${translate(
          'project.import-project-default-message'
        )}`,
      },
      loading,
      parsing,
      validFile,
      validForm,
      pricelistFile,
      categories,
      PRODUCT_HEADERS,
      payload,
      fieldRules: [(v: string) => !!v || `${translate('validation.required')}`],
      allCategories,
    };
  },
});
