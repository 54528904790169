







































import { ref, PropType, defineComponent } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { useBatch } from '@/modules/batch';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    item: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
    isMenuitem: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);

    const { registerItemSaveChangesWithAdditionAndParent } = useBatch();

    const removeEvent = async () => {
      props.item.Precut = false;
      registerItemSaveChangesWithAdditionAndParent(props.item);
      emit('close', true);
      dialog.value = false;
    };

    return {
      removeEvent,
      dialog,
    };
  },
});
