var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700"},on:{"click:outside":_vm.close},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isMenuitem)?_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('buttons.new-calculation'))+" ")]),_c('v-list-item-icon',[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1):_c('v-btn',_vm._g(_vm._b({class:_vm.parent.Children.length == 0
          ? 'start new-calculation ma-2'
          : 'new-calculation ma-2',attrs:{"color":"secondary","small":"","fab":!_vm.$vuetify.breakpoint.xlOnly}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(!_vm.$vuetify.breakpoint.xlOnly ? '' : _vm.$t('buttons.new-calculation'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":_vm.$vuetify.breakpoint.xlOnly}},on),[_vm._v(" mdi-plus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.new-calculation')))])])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[(_vm.newCalc)?_c('v-form',{ref:"calculation",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('dialog-title',{attrs:{"headerText":_vm.$t('titles.add-calculation', { name: _vm.parent.Name })},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}],null,false,3663722865)}),_c('v-card-text',{staticClass:"edit-content pa-4"},[_c('v-text-field',{attrs:{"autofocus":"","label":_vm.$t('labels.calculation-name'),"rules":_vm.textFieldRule,"required":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.calculationName),callback:function ($$v) {_vm.calculationName=$$v},expression:"calculationName"}}),_c('v-subheader',{staticClass:"field-name"},[_vm._v(" "+_vm._s(_vm.$t('titles.select-occupation'))+" ")]),_c('v-row',{staticClass:"options",attrs:{"dense":""}},_vm._l((_vm.professions),function(profession){return _c('v-col',{key:profession.Id,attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"d-flex align-center profession-card",attrs:{"hover":"","tile":"","color":_vm.occupation === profession.Code ? 'secondary' : ''},on:{"click":function($event){_vm.occupation =
                  _vm.occupation === profession.Code ? undefined : profession.Code}}},[_c('v-icon',{class:_vm.occupation === profession.Code
                    ? 'profession-icon-active'
                    : 'profession-icon'},[_vm._v(_vm._s(_vm.getIconByProfessionCode(profession.Code)))]),_c('span',{staticClass:"profession-name",class:_vm.occupation === profession.Code
                    ? 'profession-name-active'
                    : 'profession-name'},[_vm._v(_vm._s(profession.Name))]),_c('v-scroll-y-transition',[(_vm.occupation === profession.Code)?_c('div',{staticClass:"display-3 flex-grow-1"}):_vm._e()])],1)],1)}),1)],1),_c('v-card-actions',[_c('v-checkbox',{attrs:{"color":"primary","dense":"","label":_vm.$t('labels.navigate-to-calc'),"hide-details":""},model:{value:(_vm.navigateToCalc),callback:function ($$v) {_vm.navigateToCalc=$$v},expression:"navigateToCalc"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","type":"submit","loading":_vm.loading,"disabled":!_vm.valid}},[_vm._v(" "+_vm._s(_vm.$t('buttons.create'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('v-btn',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }