var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.active},on:{"click:outside":_vm.close}},[_c('v-card',{attrs:{"flat":"","tile":"","loading":_vm.loading}},[_c('dialog-title',{attrs:{"headerText":_vm.$t('titles.price-update'),"halfAndHalf":true},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-row',{attrs:{"dense":"","align-content":"center"}},[_c('v-spacer'),_c('v-select',{staticClass:"pricelists",attrs:{"dense":"","outlined":"","label":_vm.$t('labels.choose-pricelist'),"item-text":"Name","items":_vm.pricelists,"return-object":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.chosenPriceList),callback:function ($$v) {_vm.chosenPriceList=$$v},expression:"chosenPriceList"}}),_c('v-btn',{staticClass:"fetch-button",attrs:{"color":"primary","disabled":!_vm.chosenPriceList,"loading":_vm.fetchingPrices,"small":""},on:{"click":_vm.fetchPrices}},[_vm._v(" "+_vm._s(_vm.$t('buttons.get-prices'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-bullseye-arrow")])],1),_c('v-btn',{staticClass:"close-icon",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]},proxy:true}])}),_c('v-card-text',{staticClass:"ma-0 pa-1"},[(_vm.element.BuildingItems && _vm.element.BuildingItems.length > 0)?_c('v-subheader',{staticClass:"field-name items-subheader"},[_vm._v(" "+_vm._s(((_vm.$t('titles.building-items')) + " (" + (_vm.element.BuildingItems.length) + ")"))+" ")]):_vm._e(),_c('perfect-scrollbar',{attrs:{"options":{
          suppressScrollX: true,
        }}},[_c('v-data-table',{staticClass:"building-items",attrs:{"headers":_vm.BUILDING_ITEM_PRICE_UPDATE,"items":_vm.element.BuildingItems,"item-key":"Id","loading-text":_vm.$t('info.loading'),"no-data-text":_vm.$t('info.no-data'),"no-results-text":_vm.$t('info.no-result'),"show-select":"","items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return [_c('v-simple-checkbox',{attrs:{"value":_vm.allSelected,"disabled":!_vm.pricesFetched,"ripple":false},on:{"click":_vm.toggleAll}})]}},{key:"item.data-table-select",fn:function(ref){
        var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":_vm.isSelected(item),"ripple":false,"disabled":!_vm.isEnabled(item)},on:{"click":function($event){_vm.isEnabled(item) && _vm.toggleSingle(item)}}})]}},{key:"item.Name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"emphasized"},[_vm._v(" "+_vm._s(item.Name)+" ")]),_c('div',[_vm._v(_vm._s(item.PriceListItemName))])]}},{key:"item.Status",fn:function(ref){
        var item = ref.item;
return [_c('building-item-status',{attrs:{"buildingItemCalc":item}})]}},{key:"item.PriceListName",fn:function(ref){
        var item = ref.item;
return [(item.PriceMatch)?_c('div',[_vm._v(" "+_vm._s(item.PriceMatch.BuildingItemPriceListName)+" ")]):_c('div')]}},{key:"item.Price",fn:function(ref){
        var item = ref.item;
return [(item.Price !== null)?_c('div',[_vm._v(" "+_vm._s(item.Price.toLocaleString(_vm.$i18n.locale, { maximumFractionDigits: 2, }))+" ")]):_vm._e()]}},{key:"item.Unit",fn:function(ref){
        var item = ref.item;
return [(item.UnitForOrder !== null)?_c('div',[_vm._v(" "+_vm._s(item.UnitForOrder)+" ")]):_vm._e()]}},{key:"item.NewUnit",fn:function(ref){
        var item = ref.item;
return [(item.PriceMatch && item.PriceMatch.PriceListUnit)?_c('div',{class:item.PriceMatch.HasDifferentUnit.value
                  ? 'emphasize-red'
                  : 'emphasize'},[_vm._v(" "+_vm._s(item.PriceMatch.PriceListUnit)+" ")]):_vm._e()]}},{key:"item.NewPrice",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"emphasize"},[_vm._v(" "+_vm._s(item.PriceMatch && item.PriceMatch.PriceListPrice ? item.PriceMatch.PriceListPrice.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 2, } ) : '')+" ")])]}},{key:"item.Diff",fn:function(ref){
                  var item = ref.item;
return [(item.PriceMatch && item.PriceMatch.PriceDifference)?_c('div',{staticClass:"emphasize"},[_vm._v(" "+_vm._s(item.PriceMatch.PriceDifference.value.toLocaleString( _vm.$i18n.locale, { maximumFractionDigits: 2, } ))+" ")]):_vm._e()]}},{key:"item.Product",fn:function(ref){
                  var item = ref.item;
return [(
                item.PriceMatch &&
                  item.PriceMatch.BuildingItemPriceListItemName
              )?_c('div',{staticClass:"emphasize"},[_vm._v(" "+_vm._s(item.PriceMatch.BuildingItemPriceListItemName)+" ")]):_vm._e()]}},{key:"item.AmountPrUnit",fn:function(ref){
              var item = ref.item;
return [(_vm.pricesFetched)?_c('v-text-field',{staticClass:"amountprunit",attrs:{"hide-details":"","outlined":"","dense":"","type":"number"},on:{"change":function($event){return _vm.amountChange(item)},"focus":function($event){return $event.target.select()}},model:{value:(item.AmountPrUnit),callback:function ($$v) {_vm.$set(item, "AmountPrUnit", _vm._n($$v))},expression:"item.AmountPrUnit"}}):_c('div',[_vm._v(_vm._s(item.AmountPrUnit))])]}},{key:"item.Discount",fn:function(ref){
              var item = ref.item;
return [(item.PriceMatch && item.PriceMatch.DiscountPercentage)?_c('div',[_vm._v(" "+_vm._s(item.PriceMatch.DiscountPercentage)+" ")]):_vm._e()]}},{key:"item.BasePrice",fn:function(ref){
              var item = ref.item;
return [(item.PriceMatch && item.PriceMatch.BasePrice)?_c('div',[_vm._v(" "+_vm._s(item.PriceMatch.BasePrice)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){
              var item = ref.item;
return [_c('v-row',{staticClass:"action-row",attrs:{"dense":""}},[(_vm.pricesFetched)?_c('get-product',{attrs:{"itemCalc":item,"iconButton":true},on:{"product":_vm.updateProduct}}):_vm._e(),(!item.Precut && _vm.pricesFetched)?_c('edit-time-estimate',{attrs:{"item":item,"element":item.BuildingElement,"iconButton":true},on:{"updated":function($event){return _vm.timeUpdated(item)}}}):_vm._e(),(
                  item.IsTimeEstimateAdded.value === true &&
                    !item.Precut &&
                    _vm.pricesFetched
                )?_c('remove-time-estimate',{attrs:{"item":item,"iconButton":true},on:{"updated":function($event){return _vm.timeUpdated(item)}}}):_vm._e(),(item.NOBBNumber)?_c('nobb-info',{attrs:{"nobbNr":item.NOBBNumber,"iconButton":true}}):_vm._e()],1)]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary","disabled":!_vm.valid,"loading":_vm.updatingPrices},on:{"click":_vm.priceUpdate}},[_vm._v(" "+_vm._s(_vm.$t('buttons.update'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-update")])],1),_c('v-btn',{staticClass:"ma-2",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }