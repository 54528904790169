





































import { useLoader } from '@/modules/loader';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const { statusText } = useLoader();
    return {
      statusText,
    };
  },
});
