






















import { defineComponent } from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import Pricelists from '@/components/pricebook/pricelists/index.vue';
import Itemlist from '@/components/pricebook/itemlist/index.vue';
import ImportPricelist from '@/components/pricebook/import-pricelist/index.vue';
import UploadPricelist from '@/components/pricebook/uploadPriceList/index.vue';
import DownloadPricelist from '@/components/pricebook/download-pricelist/index.vue';

export default defineComponent({
  components: {
    ContentBar,
    Pricelists,
    Itemlist,
    ImportPricelist,
    UploadPricelist,
    DownloadPricelist,
  },
});
