
















import { PriceListTreeNode } from '@/models/pricebook/interfaces';
import { useCalculation } from '@/modules/calculation';
import { usePriceBook } from '@/modules/pricebook';
import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const { getPriceLists } = usePriceBook();
    const {
      getCalculationPriceListFromElements,
      setPriceListForActiveCalculation,
    } = useCalculation();

    const priceLists = ref<Array<PriceListTreeNode>>([]);
    const selected = ref<PriceListTreeNode>();

    const onChangeEvent = async (pricelistId: string) => {
      selected.value = priceLists.value.find(pl => pl.Id === pricelistId);
      if (selected.value) {
        await setPriceListForActiveCalculation(selected.value);
      }
    };

    const loadingPricelists = ref<boolean>(false);
    onMounted(async () => {
      loadingPricelists.value = true;
      priceLists.value = await getPriceLists();
      loadingPricelists.value = false;
      const plId = await getCalculationPriceListFromElements();
      selected.value = priceLists.value.find(pl => pl.Id === plId);
    });
    return {
      onChangeEvent,
      getCalculationPriceListFromElements,
      loadingPricelists,
      priceLists,
      selected,
    };
  },
});
