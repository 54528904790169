


















































import { ref, PropType, defineComponent, computed } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { removeTimeEstimate } from '@/helpers/building-item';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    item: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
    iconButton: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const valid = computed(() => {
      return props.item !== undefined;
    });

    const close = () => {
      dialog.value = false;
      emit('close', true);
    };

    const deleteEvent = () => {
      loading.value = true;
      removeTimeEstimate(props.item);
      emit('updated', true);
      loading.value = false;
      close();
    };

    return {
      valid,
      dialog,
      loading,
      deleteEvent,
    };
  },
});
