












import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DataTableRowHandler',
  props: {
    itemClass: {
      type: String,
    },
    item: {
      type: Object,
    },
    headers: {
      type: Array,
    },
    value: {
      type: String,
    },
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnName = (header: { value: any }) => {
      return `item.${header.value}`;
    };

    const getAlignment = (header: { align: string }) => {
      const align = header.align ? header.align : 'right';
      return `text-align: ${align} !important`;
    };

    const getNonSlotValue = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item: { [x: string]: any },
      header: { value: string | number }
    ) => {
      const val = item[header.value];

      if (val != null) {
        return val;
      }

      return '';
    };

    return {
      getNonSlotValue,
      getAlignment,
      columnName,
    };
  },
});
