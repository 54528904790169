import { createReportName, downloadExcel } from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportFileType, ReportType } from '@/models/report//enums';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportOptions } from '@/models/report/interfaces';
import XLSX from 'xlsx';
import {
  getNameByProfessionCode,
  numberToAbsStringOrEmpty,
} from '@/helpers/common';
import {
  DEFAULT_TEXT_LARGE,
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_XSMALL,
  LARGE_TEXT_LARGE,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_XSMALL,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

export const generateProjectAttachmentPriceReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();

  if (options.Calculations.length === 0) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);
  const pdfName = createReportName(type, ReportFileType.PDF, true);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, true);

  const reportName = `${translate('reports.project-attachment-price-report')}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;
  doc.text(reportName, 14, first.finalY + 9);

  const totalData: (string | number)[][] = [];

  const MVA = 0.25;
  let projectSumExclMva = 0;
  let projectSumInclMva = 0;
  let projectMvaOnly = 0;

  let calculationCount = 0;
  for (const calc of options.Calculations) {
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc || projectCalc.BuildingElements.length === 0) continue;
        calculationCount++;
        const buildingElementBreakPoints: Array<number> = [];

        const head = [
          [
            translate('labels.zip'),
            translate('labels.description'),
            translate('labels.comments'),
            translate('labels.unit'),
            translate('labels.amount'),
            translate('labels.sum'),
          ],
        ];

        const calcName = `${projectCalc.Name} - ${getNameByProfessionCode(
          projectCalc.Profession.Code
        )}`;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const first = (doc as any).autoTable.previous;

        autoTable(doc, {
          startY: calculationCount === 1 ? first.finalY + 14 : first.finalY + 6,
          head: [[calcName]],
          theme: 'plain',
          styles: {
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_LARGE
                : LARGE_TEXT_LARGE,
            halign: 'left',
          },
        });

        const offerData: (string | number)[][] = [];

        let elements = projectCalc.BuildingElements.filter(
          ele => ele.IsAmountSet
        );

        if (elements) {
          let postnr = 1;
          elements = elements.sort((a, b) =>
            (a.SortOrder ?? -1) > (b.SortOrder ?? -1)
              ? 1
              : (b.SortOrder ?? -1) > (a.SortOrder ?? -1)
              ? -1
              : 0
          );

          elements.forEach(ele => {
            const row = [
              '',
              ele.Name ?? '',
              options.IncludeComments ? ele.Comments ?? '' : '',
              ele.Unit?.value ?? '',
              options.IncludeAmounts
                ? ele.Amount?.toLocaleString(defaultLocaleString, {
                    maximumFractionDigits: 2,
                  }) ?? ''
                : '',
              options.IncludeElementSums
                ? numberToAbsStringOrEmpty(ele.SumTotal.value, 2)
                : '',
            ];

            buildingElementBreakPoints.push(offerData.length);
            offerData.push(row);

            let filteredItems = ele.BuildingItems.filter(
              i => i.UnitPrice.value && i.UnitPrice.value > 0
            );

            filteredItems = filteredItems.sort((a, b) =>
              (a.BuildingElement?.SortOrder ?? -1) >
              (b.BuildingElement?.SortOrder ?? -1)
                ? 1
                : (b.BuildingElement?.SortOrder ?? -1) >
                  (a.BuildingElement?.SortOrder ?? -1)
                ? -1
                : 0
            );

            if (options.IncludeBuildingItems) {
              filteredItems.forEach(item => {
                const row = [
                  postnr++,
                  `${item.Name ?? ''} ${
                    item.PriceListItemName && options.ShowProductName
                      ? `(${item.PriceListItemName})`
                      : ''
                  }`,
                  options.IncludeComments ? item.Comments ?? '' : '',
                  item.Unit ?? '',
                  options.IncludeAmounts
                    ? item.AmountCalculated.value?.toLocaleString(
                        defaultLocaleString,
                        {
                          maximumFractionDigits: 2,
                        }
                      ) ?? ''
                    : '',
                  options.IncludeItemSums
                    ? numberToAbsStringOrEmpty(
                        item.SumTotalWithAdditions.value,
                        2
                      )
                    : '',
                ];

                offerData.push(row);
              });
            }
          });

          const MVA = 0.25;
          let sumExclMva = 0;
          let sumInclMva = 0;
          let MvaOnly = 0;

          elements.forEach(ele => {
            sumExclMva += ele.SumTotal.value ?? 0;
          });

          MvaOnly = sumExclMva * MVA;
          sumInclMva = sumExclMva + MvaOnly;

          projectSumExclMva = projectSumExclMva + sumExclMva;

          const fillRow = ['', '', '', '', ''];
          const sumExclMvaRow = [
            '',
            '',
            '',
            '',
            `${translate('labels.sum-exclude-mva')}`,
            numberToAbsStringOrEmpty(sumExclMva, 2),
          ];
          const mvaRow = [
            '',
            '',
            '',
            '',
            `${translate('labels.sum-mva-only')}`,
            numberToAbsStringOrEmpty(MvaOnly, 2),
          ];
          const sumInclMvaRow = [
            '',
            '',
            '',
            '',
            `${translate('labels.sum-include-mva')}`,
            numberToAbsStringOrEmpty(sumInclMva, 2),
          ];

          offerData.push(fillRow);
          offerData.push(sumExclMvaRow);
          offerData.push(mvaRow);
          offerData.push(sumInclMvaRow);

          const calcTotalRow = [
            calcName,
            '',
            '',
            '',
            '',
            numberToAbsStringOrEmpty(sumExclMva, 2),
          ];

          totalData.push(calcTotalRow);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const elePos = (doc as any).autoTable.previous;

          autoTable(doc, {
            startY: elePos.finalY + 2,
            head: head,
            body: offerData,
            showHead: 'firstPage',
            headStyles: { fillColor: '#263238' },
            styles: {
              fontSize:
                options.FontSize === 'default'
                  ? DEFAULT_TEXT_XSMALL
                  : LARGE_TEXT_XSMALL,
            },
            didParseCell(data) {
              if (
                buildingElementBreakPoints.includes(data.row.index) &&
                data.section === 'body'
              ) {
                data.cell.styles.fontStyle = 'bold';
                data.cell.styles.fillColor = options.IncludeBuildingItems
                  ? '#d6d8da'
                  : 'white';
              } else if (
                (data.section === 'body' &&
                  data.row.index === offerData.length - 1) ||
                data.row.index === offerData.length - 2 ||
                data.row.index === offerData.length - 3
              ) {
                data.row.cells[0].styles.fillColor = 'white';
                data.row.cells[1].styles.fillColor = 'white';
                data.row.cells[2].styles.fillColor = 'white';
                data.row.cells[3].styles.fillColor = 'white';

                data.row.cells[4].styles.fontStyle = 'bold';
                data.row.cells[4].styles.cellPadding = 0.7;
                data.row.cells[4].styles.fillColor = '#f7f7f7';

                data.row.cells[5].styles.fontStyle = 'bold';
                data.row.cells[5].styles.cellPadding = 0.7;
                data.row.cells[5].styles.fillColor = '#f7f7f7';
              } else if (data.section === 'body') {
                data.cell.styles.fillColor = 'white';
              }
            },
            columnStyles: {
              0: {
                valign: 'middle',
              },
              1: {
                valign: 'middle',
              },
              2: {
                valign: 'middle',
              },
              3: {
                valign: 'middle',
              },
              4: {
                valign: 'middle',
                halign: 'right',
                cellWidth: 20,
              },
              5: {
                valign: 'middle',
                halign: 'right',
                cellWidth: 20,
              },
            },
          });
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const totalPos = (doc as any).autoTable.previous;

  autoTable(doc, {
    startY: calculationCount === 1 ? totalPos.finalY + 14 : totalPos.finalY + 6,
    head: [['Total']],
    theme: 'plain',
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_LARGE : LARGE_TEXT_LARGE,
      halign: 'left',
    },
  });

  projectMvaOnly = projectSumExclMva * MVA;
  projectSumInclMva = projectSumExclMva + projectMvaOnly;

  const fillRow = ['', '', '', '', ''];
  const sumExclMvaRow = [
    '',
    '',
    '',
    '',
    `${translate('labels.sum-exclude-mva')}`,
    numberToAbsStringOrEmpty(projectSumExclMva, 2),
  ];
  const mvaRow = [
    '',
    '',
    '',
    '',
    `${translate('labels.sum-mva-only')}`,
    numberToAbsStringOrEmpty(projectMvaOnly, 2),
  ];
  const sumInclMvaRow = [
    '',
    '',
    '',
    '',
    `${translate('labels.sum-include-mva')}`,
    numberToAbsStringOrEmpty(projectSumInclMva, 2),
  ];

  totalData.push(fillRow);
  totalData.push(sumExclMvaRow);
  totalData.push(mvaRow);
  totalData.push(sumInclMvaRow);

  const totalHead = [
    [translate('labels.projects'), '', '', '', '', translate('labels.sum')],
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const totalTablePos = (doc as any).autoTable.previous;

  autoTable(doc, {
    startY: totalTablePos.finalY + 2,
    head: totalHead,
    body: totalData,
    showHead: 'firstPage',
    headStyles: { fillColor: '#263238' },
    styles: {
      fontSize:
        options.FontSize === 'default'
          ? DEFAULT_TEXT_XSMALL
          : LARGE_TEXT_XSMALL,
    },
    didParseCell(data) {
      if (
        (data.section === 'body' && data.row.index === totalData.length - 1) ||
        data.row.index === totalData.length - 2 ||
        data.row.index === totalData.length - 3
      ) {
        data.row.cells[0].styles.fillColor = 'white';
        data.row.cells[1].styles.fillColor = 'white';
        data.row.cells[2].styles.fillColor = 'white';
        data.row.cells[3].styles.fillColor = 'white';

        data.row.cells[4].styles.fontStyle = 'bold';
        data.row.cells[4].styles.cellPadding = 0.7;
        data.row.cells[4].styles.fillColor = '#f7f7f7';

        data.row.cells[5].styles.fontStyle = 'bold';
        data.row.cells[5].styles.cellPadding = 0.7;
        data.row.cells[5].styles.fillColor = '#f7f7f7';
      } else if (data.section === 'body') {
        data.cell.styles.fillColor = 'white';
      }
    },
    columnStyles: {
      0: {
        valign: 'middle',
      },
      1: {
        valign: 'middle',
      },
      2: {
        valign: 'middle',
      },
      3: {
        valign: 'middle',
      },
      4: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 20,
      },
      5: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 20,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

export const generateProjectAttachmentPriceReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();

  if (options.Calculations.length === 0) return;

  const wb = XLSX.utils.book_new();
  const xlsxName = createReportName(type, ReportFileType.XLSX, true);

  const totalData: (string | number)[][] = [];

  const MVA = 0.25;
  let projectSumExclMva = 0;
  let projectSumInclMva = 0;
  let projectMvaOnly = 0;

  const sheetName = 'Total';

  wb.Props = {
    Title: `${translate('reports.project-attachment-price-report')}`,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  totalData.push([sheetName]);
  totalData.push(['']);

  await createHeaderXlsx(totalData);
  await createProjectInfoXlsx(totalData, true);

  totalData.push([
    `${translate('labels.projects')}`,
    '',
    '',
    '',
    '',
    `${translate('labels.sum')}`,
  ]);

  for (const calc of options.Calculations) {
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc) continue;

        const sheetName = checkAndbuildSheetName(
          projectCalc.Name,
          wb.SheetNames
        );

        wb.Props = {
          Title: `${translate('reports.project-attachment-price-report')}`,
          CreatedDate: new Date(),
        };

        wb.SheetNames.push(sheetName);

        const offerData: (string | number)[][] = [];

        offerData.push([sheetName]);
        offerData.push(['']);

        await createHeaderXlsx(offerData);
        await createProjectInfoXlsx(offerData, true);

        let elements = projectCalc.BuildingElements.filter(
          ele => ele.IsAmountSet
        );

        if (elements) {
          offerData.push([
            `${translate('labels.zip')}`,
            `${translate('labels.description')}`,
            `${translate('labels.comments')}`,
            `${translate('labels.unit')}`,
            `${translate('labels.amount')}`,
            `${translate('labels.sum')}`,
          ]);
          let postnr = 1;
          elements = elements.sort((a, b) =>
            (a.SortOrder ?? -1) > (b.SortOrder ?? -1)
              ? 1
              : (b.SortOrder ?? -1) > (a.SortOrder ?? -1)
              ? -1
              : 0
          );

          elements.forEach(ele => {
            const row = [
              '',
              ele.Name ?? '',
              options.IncludeComments ? ele.Comments ?? '' : '',
              ele.Unit?.value ?? '',
              options.IncludeAmounts
                ? ele.Amount?.toLocaleString(defaultLocaleString, {
                    maximumFractionDigits: 2,
                  }) ?? ''
                : '',
              options.IncludeElementSums
                ? numberToAbsStringOrEmpty(ele.SumTotal.value, 2)
                : '',
            ];

            offerData.push(row);

            let filteredItems = ele.BuildingItems.filter(
              i => i.UnitPrice.value && i.UnitPrice.value > 0
            );

            filteredItems = filteredItems.sort((a, b) =>
              (a.BuildingElement?.SortOrder ?? -1) >
              (b.BuildingElement?.SortOrder ?? -1)
                ? 1
                : (b.BuildingElement?.SortOrder ?? -1) >
                  (a.BuildingElement?.SortOrder ?? -1)
                ? -1
                : 0
            );

            if (options.IncludeBuildingItems) {
              filteredItems.forEach(item => {
                const row = [
                  postnr++,
                  `${item.Name ?? ''} ${
                    item.PriceListItemName && options.ShowProductName
                      ? `(${item.PriceListItemName})`
                      : ''
                  }`,
                  options.IncludeComments ? item.Comments ?? '' : '',
                  item.Unit ?? '',
                  options.IncludeAmounts
                    ? item.AmountCalculated.value?.toLocaleString(
                        defaultLocaleString,
                        {
                          maximumFractionDigits: 2,
                        }
                      ) ?? ''
                    : '',
                  options.IncludeItemSums
                    ? numberToAbsStringOrEmpty(
                        item.SumTotalWithAdditions.value,
                        2
                      )
                    : '',
                ];

                offerData.push(row);
              });
            }
          });

          const MVA = 0.25;
          let sumExclMva = 0;
          let sumInclMva = 0;
          let MvaOnly = 0;

          elements.forEach(ele => {
            sumExclMva += ele.SumTotal.value ?? 0;
          });

          MvaOnly = sumExclMva * MVA;
          sumInclMva = sumExclMva + MvaOnly;

          totalData.push([
            projectCalc.Name,
            '',
            '',
            '',
            '',
            numberToAbsStringOrEmpty(sumExclMva, 2),
          ]);

          projectSumExclMva = projectSumExclMva + sumExclMva;

          const fillRow = ['', '', '', '', ''];
          const sumExclMvaRow = [
            '',
            '',
            '',
            '',
            `${translate('labels.sum-exclude-mva')}`,
            numberToAbsStringOrEmpty(sumExclMva, 2),
          ];
          const mvaRow = [
            '',
            '',
            '',
            '',
            `${translate('labels.sum-mva-only')}`,
            numberToAbsStringOrEmpty(MvaOnly, 2),
          ];
          const sumInclMvaRow = [
            '',
            '',
            '',
            '',
            `${translate('labels.sum-include-mva')}`,
            numberToAbsStringOrEmpty(sumInclMva, 2),
          ];

          offerData.push(fillRow);
          offerData.push(sumExclMvaRow);
          offerData.push(mvaRow);
          offerData.push(sumInclMvaRow);

          const ws = XLSX.utils.aoa_to_sheet(offerData);
          wb.Sheets[sheetName] = ws;
        }
      }
    }
  }

  projectMvaOnly = projectSumExclMva * MVA;
  projectSumInclMva = projectSumExclMva + projectMvaOnly;

  const fillRow = ['', '', '', '', ''];
  const sumExclMvaRow = [
    '',
    '',
    '',
    '',
    `${translate('labels.sum-exclude-mva')}`,
    numberToAbsStringOrEmpty(projectSumExclMva, 2),
  ];
  const mvaRow = [
    '',
    '',
    '',
    '',
    `${translate('labels.sum-mva-only')}`,
    numberToAbsStringOrEmpty(projectMvaOnly, 2),
  ];
  const sumInclMvaRow = [
    '',
    '',
    '',
    '',
    `${translate('labels.sum-include-mva')}`,
    numberToAbsStringOrEmpty(projectSumInclMva, 2),
  ];

  totalData.push(fillRow);
  totalData.push(sumExclMvaRow);
  totalData.push(mvaRow);
  totalData.push(sumInclMvaRow);

  const ws = XLSX.utils.aoa_to_sheet(totalData);
  wb.Sheets[sheetName] = ws;

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  downloadExcel(xlsxName, wbout);
};

export const generateProjectAttachmentPriceReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateProjectAttachmentPriceReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateProjectAttachmentPriceReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
