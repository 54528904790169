































































































































































































































































































































import { NobbItem, NobbModule, Supplier } from '@/models/nobb/interfaces';
import { useProductInfo } from '@/modules/product-info';
import { ref, defineComponent, computed } from '@vue/composition-api';
import Media from '@/components/nobb-info/media/index.vue';
import moment from 'moment';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { boolToYesNoLocaleKey } from '@/helpers/common';
import Loader from '../loader/index.vue';
import ImagePlaceholder from '@/assets/images/image-placeholder.png';

export default defineComponent({
  components: {
    Media,
    CoolLightBox,
    Loader,
  },
  props: {
    nobbNr: {
      type: Number,
      required: true,
    },
    button: {
      type: Boolean,
    },
    iconButton: {
      type: Boolean,
    },
  },
  setup({ nobbNr }, { emit }) {
    const nobbItem = ref<NobbItem>();
    const nobbModule = ref<NobbModule>();
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const supplier = ref<Supplier>();
    const { getNobbItem, getNobbModule } = useProductInfo();

    const imageUrl = computed(() => {
      let url = '';
      if (supplier.value && supplier.value.media) {
        const media = supplier.value.media.find(med => med.mediaType === 'PB');
        if (media) {
          url = media.url;
        }
      }
      return url;
    });

    const close = () => {
      dialog.value = false;
      emit('close', true);
    };

    const initDialog = async (event: { stopPropagation: () => void }) => {
      if (event) {
        event.stopPropagation();
      }
      dialog.value = true;
      loading.value = true;
      nobbItem.value = await getNobbItem(nobbNr);

      if (nobbItem.value && nobbItem.value.moduleNumber) {
        nobbModule.value = await getNobbModule(nobbItem.value.moduleNumber);
      }

      if (
        nobbItem.value &&
        nobbItem.value.suppliers &&
        nobbItem.value.suppliers.length > 0
      ) {
        supplier.value = nobbItem.value.suppliers[0];
      }
      loading.value = false;
    };

    return {
      close,
      boolToYesNoLocaleKey,
      initDialog,
      moment,
      dialog,
      nobbItem,
      nobbModule,
      supplier,
      imageUrl,
      index: null,
      loading,
      ImagePlaceholder,
    };
  },
});
