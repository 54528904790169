











































import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    warnings: {
      type: Array as PropType<Array<string>>,
    },
    calm: {
      type: Boolean,
    },
  },
});
