



































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import { BUILDING_ITEM_HEADERS_SIMPLE_ALT2 } from '@/constants/table-headers';

export default defineComponent({
  components: {
    DialogTitle,
  },
  props: {
    isMenuOption: {
      type: Boolean,
    },
    itemCalcs: {
      type: Array as PropType<Array<BuildingItemCalculation>>,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const close = () => {
      emit('closeEvent', true);
    };

    const submit = async () => {
      if (props.itemCalcs) {
        emit('replaceAll', true);
        close();
      }
    };

    return {
      close,
      submit,
      loading,
      BUILDING_ITEM_HEADERS_SIMPLE_ALT2,
    };
  },
});
