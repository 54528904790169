













































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useGlobal } from './modules/global';

export default defineComponent({
  setup() {
    const { setUserReadPatchNotes } = useGlobal();
    const registration = ref();
    const updateExists = ref<boolean>(false);
    const refreshing = ref<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateAvailable = (e: any) => {
      if (e.detail) {
        registration.value = e.detail;
        updateExists.value = true;
      } else {
        console.warn('No worker data found!');
      }
    };

    const refreshApp = () => {
      updateExists.value = false;

      if (!registration.value || !registration.value.waiting) return;
      setUserReadPatchNotes(false);
      registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
    };

    onMounted(() => {
      document.addEventListener('swupdatefound', updateAvailable, {
        once: true,
      });
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing.value) return;
        refreshing.value = true;
        window.location.reload();
      });
    });

    return {
      refreshApp,
      updateExists,
      registration,
      refreshing,
    };
  },
});
