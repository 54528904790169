











































































































































































































import { ProffesionType } from '@/constants/enum';
import { CreateProject, ProjectDetails } from '@/models/projects/interfaces';
import { useProject } from '@/modules/project';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import { translate } from '@/localization';

export default defineComponent({
  components: {
    DialogTitle,
    InfoTooltip,
  },
  props: {
    first: {
      type: Boolean,
    },
  },
  setup() {
    const {
      getNewProjectNumber,
      createProject,
      getProjects,
      setActiveProject,
      saveDetails,
      getDetails,
    } = useProject();

    const newProject = ref<CreateProject | undefined>(undefined);
    const projectDetails = ref<ProjectDetails>({ Shared: true });
    const valid = ref<boolean>(false);
    const dialog = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const initDetails = async () => {
      projectDetails.value.ProjectNumber = await getNewProjectNumber();
      projectDetails.value.Shared = true;
    };

    onMounted(async () => {
      await initDetails();
    });

    const close = async () => {
      dialog.value = false;
      await initDetails();
    };

    const submit = async () => {
      loading.value = true;

      if (projectDetails.value.ProjectNumber && projectDetails.value.Name) {
        newProject.value = {
          Name: projectDetails.value.Name,
          Number: projectDetails.value.ProjectNumber,
          ProfessionCode: ProffesionType.Carpentry,
          ParentId: '',
          Shared: projectDetails.value.Shared,
        };
        const projectId = await createProject(newProject.value, false);
        if (projectId) {
          const projects = await getProjects(true);
          if (projects) {
            const newActive = projects.find(proj => (proj.Id = projectId));
            if (newActive?.Id) {
              const newDetails = await getDetails(newActive.Id);
              if (newDetails) {
                projectDetails.value.Id = newDetails.Id;
                projectDetails.value.Name = newDetails.Name;
                projectDetails.value.Shared = newDetails.Shared;
                projectDetails.value.Owner = newDetails.Owner;
                await saveDetails(projectId, projectDetails.value);
                await setActiveProject(newActive.Id, true);
              }
            }
          }
        }
        loading.value = true;
      }
    };

    return {
      submit,
      close,
      loading,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
        min: (v: string) =>
          (v && v.length >= 8) ||
          `${translate('validation.minimum-characters', [8])}`,
      },
      projectDetails,
      dialog,
      valid,
    };
  },
});
