











































































































































import { defineComponent, onUnmounted, ref } from '@vue/composition-api';
import { PRICELIST_ITEM_HEADERS } from '@/constants/table-headers';
import ContentBar from '@/components/common/content-bar/index.vue';
import { usePriceBook } from '@/modules/pricebook';
import NobbInfo from '@/components/nobb-info/index.vue';
import EditItem from '@/components/pricebook/edit-item/index.vue';
import DeleteItem from '@/components/pricebook/delete-item/index.vue';
import CopyItem from '@/components/pricebook/copy-item/index.vue';
import { calculatedPrice } from '@/helpers/pricebook';

export default defineComponent({
  components: {
    ContentBar,
    NobbInfo,
    EditItem,
    DeleteItem,
    CopyItem,
  },
  setup() {
    const {
      activePriceListItems,
      loading,
      priceListTitle,
      activePricelist,
    } = usePriceBook();
    const search = ref<string>('');

    onUnmounted(() => {
      if (activePriceListItems?.value) activePriceListItems.value = undefined;
      if (priceListTitle?.value) priceListTitle.value = undefined;
    });

    return {
      calculatedPrice,
      PRICELIST_ITEM_HEADERS,
      activePriceListItems,
      loading,
      search,
      priceListTitle,
      activePricelist,
    };
  },
});
