import { createReportName, downloadExcel } from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportOptions } from '@/models/report/interfaces';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportFileType, ReportType } from '@/models/report/enums';
import XLSX from 'xlsx';
import {
  areUnitsEqual,
  precision,
  unitDescriptionConverter,
} from '@/helpers/common';
import {
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_MINI,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_MINI,
} from '@/constants/styles';
import { useReport } from '@/modules/report';
import { useProductInfo } from '@/modules/product-info';
import { useEnvironment } from '@/modules/environment';
import { NobbItem } from '../nobb/interfaces';
import { BuildingElementCalculation } from '../building-element/interfaces';

export const generateEnvironmentReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  const { getNobbItems } = useProductInfo();
  const {
    getBuildingElementSumGWP,
    getBuildingElementCalculatedSumGWP,
    extractNobbNosFromBuildingElement,
    tryGetUnformatedEpdValidationDate,
    tryGetEpdA1A3GWPIOBCUnit,
    tryGetEpdA1A3GWPIOBCNumber,
  } = useEnvironment();

  if (!projectCalculation.value) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);

  const pdfName = createReportName(type, ReportFileType.PDF);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, false);

  const calculationTotalHeader = [
    [translate('environment.sum-from-building-elements')],
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;

  const reportName = `${translate('reports.environment-report')}`;
  doc.text(reportName, 14, first.finalY + 9);

  autoTable(doc, {
    startY: first.finalY + 14,
    head: calculationTotalHeader,
    theme: 'plain',
    styles: {
      fontStyle: 'italic',
    },
  });

  const head = [
    [
      translate('common.building-element'),
      translate('environment.gwp-iobc'),
      translate('environment.climate-imprint'),
    ],
  ];

  const elementEnvironmentSummedData: (string | number)[][] = [];

  let totalSumGWP = 0;
  let totalCalculatedSumGWP = 0;

  let nobbItems: Array<NobbItem> = [];

  interface ElementWithNobbItems {
    element: BuildingElementCalculation;
    nobbItems: Array<NobbItem>;
  }

  const buildingElementsWithNobbItems: Array<ElementWithNobbItems> = [];

  const getNobbItemFromNobbNumber = (
    nobbItems: Array<NobbItem>,
    nobbNumber?: Nullable<number>
  ) => {
    if (nobbNumber && nobbItems) {
      return nobbItems.find(item => item.nobbNumber === nobbNumber);
    }
  };

  for (const element of projectCalculation.value.BuildingElements) {
    let calculatedSumGWP = 0;
    let sumGWP = 0;
    try {
      nobbItems = await getNobbItems(
        extractNobbNosFromBuildingElement(element)
      );
    } finally {
      calculatedSumGWP = getBuildingElementCalculatedSumGWP(element, nobbItems);
      sumGWP = getBuildingElementSumGWP(element, nobbItems);
    }

    elementEnvironmentSummedData.push([
      element.Name ?? '',
      sumGWP
        ? `${sumGWP} ${translate('environment.kg-carbondioxide-equivalent')}`
        : '-',
      calculatedSumGWP
        ? `${calculatedSumGWP} ${translate(
            'environment.kg-carbondioxide-equivalent'
          )}`
        : '-',
    ]);

    totalSumGWP += sumGWP;
    totalCalculatedSumGWP += calculatedSumGWP;

    buildingElementsWithNobbItems.push({ element, nobbItems });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const second = (doc as any).autoTable.previous;

  elementEnvironmentSummedData.push([
    `${translate('labels.sum')}`,
    totalSumGWP
      ? `${totalSumGWP} ${translate('environment.kg-carbondioxide-equivalent')}`
      : '-',
    totalCalculatedSumGWP
      ? `${totalCalculatedSumGWP} ${translate(
          'environment.kg-carbondioxide-equivalent'
        )}`
      : '-',
  ]);

  autoTable(doc, {
    startY: second.finalY + 2,
    head: head,
    body: elementEnvironmentSummedData,
    showHead: 'firstPage',
    headStyles: {
      fillColor: '#263238',
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_MINI : LARGE_TEXT_MINI,
      valign: 'middle',
      overflow: 'linebreak',
    },
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_MINI : LARGE_TEXT_MINI,
    },
    didParseCell(data) {
      if (
        data.section === 'body' &&
        data.row.index === elementEnvironmentSummedData.length - 1
      ) {
        data.cell.styles.fillColor = 'white';
        data.cell.styles.fontStyle = 'bold';
      }
    },
  });

  if (options.IncludeBuildingElements) {
    for (const elementWithNobbItems of buildingElementsWithNobbItems) {
      let calculatedSumGWP = 0;
      let sumGWP = 0;
      const buildingItemEnvironmentData: (string | number)[][] = [];
      const buildingElementHead = [
        [
          translate('common.part'),
          translate('environment.validation-date'),
          translate('environment.gwp-iobc'),
          translate('environment.reference-unit'),
          translate('labels.amount'),
          translate('headers.amountofmaterials'),
          translate('environment.climate-imprint'),
        ],
      ];

      for (const item of elementWithNobbItems.element.BuildingItems) {
        const nobbItem = getNobbItemFromNobbNumber(
          elementWithNobbItems.nobbItems,
          item.NOBBNumber
        );

        if (!options.IncludeBuildingItemsWithMissingNOBB && !item.NOBBNumber) {
          continue;
        }

        if (
          !options.IncludeBuildingItemsWithMissingEPD &&
          nobbItem?.properties.epd.length === 0
        ) {
          continue;
        }

        const name = item.Name ?? '';
        let validationDate: string | undefined = '-';
        let gwp: number | undefined = 0;
        let gwpUnit: string | undefined = '-';
        let priceUnit: string | undefined = '-';
        let gwpCalculated: number | undefined = 0;
        let priceUnitEqualsToBuildingItemUnit = false;

        if (nobbItem) {
          validationDate = tryGetUnformatedEpdValidationDate(
            nobbItem.properties.epd
          );
          gwp = tryGetEpdA1A3GWPIOBCNumber(nobbItem.properties.epd);
          gwpUnit = tryGetEpdA1A3GWPIOBCUnit(nobbItem.properties.epd);
          priceUnit = nobbItem.priceUnit;
          if (gwp && item.AmountOfMaterials.value) {
            gwpCalculated = gwp * item.AmountOfMaterials.value;
          }
        }

        if (priceUnit && item.UnitForOrder) {
          priceUnitEqualsToBuildingItemUnit = areUnitsEqual(
            unitDescriptionConverter(priceUnit),
            item.UnitForOrder
          );
        }

        buildingItemEnvironmentData.push([
          name,
          validationDate ? validationDate : '',
          gwp ? `${gwp} ${gwpUnit ?? ''}` : '-',
          priceUnit ? unitDescriptionConverter(priceUnit) : '-',
          item.AmountCalculated.value
            ? `${precision(item.AmountCalculated.value, 2)} ${item.Unit}`
            : '-',
          item.AmountOfMaterials.value
            ? `${precision(item.AmountOfMaterials.value, 2)} ${
                item.UnitForOrder
              }`
            : '-',
          priceUnitEqualsToBuildingItemUnit && gwpCalculated
            ? `${gwpCalculated} ${gwpUnit}`
            : '-',
        ]);
      }

      calculatedSumGWP = getBuildingElementCalculatedSumGWP(
        elementWithNobbItems.element,
        elementWithNobbItems.nobbItems
      );
      sumGWP = getBuildingElementSumGWP(
        elementWithNobbItems.element,
        elementWithNobbItems.nobbItems
      );
      const lastRow = [
        `${translate('labels.sum')}`,
        '',
        sumGWP
          ? `${sumGWP} ${translate('environment.kg-carbondioxide-equivalent')}`
          : '-',
        '',
        '',
        '',
        calculatedSumGWP
          ? `${calculatedSumGWP} ${translate(
              'environment.kg-carbondioxide-equivalent'
            )}`
          : '-',
      ];

      buildingItemEnvironmentData.push(lastRow);

      const buildingElementHeader = [[elementWithNobbItems.element.Name]];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const first = (doc as any).autoTable.previous;

      autoTable(doc, {
        startY: first.finalY + 10,
        head: buildingElementHeader,
        theme: 'plain',
        styles: {
          fontStyle: 'italic',
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const second = (doc as any).autoTable.previous;

      autoTable(doc, {
        startY: second.finalY + 2,
        head: buildingElementHead,
        body: buildingItemEnvironmentData,
        showHead: 'firstPage',
        headStyles: {
          fillColor: '#656d72',
          fontSize:
            options.FontSize === 'default'
              ? DEFAULT_TEXT_MINI
              : LARGE_TEXT_MINI,
          valign: 'middle',
          overflow: 'linebreak',
        },
        styles: {
          fontSize:
            options.FontSize === 'default'
              ? DEFAULT_TEXT_MINI
              : LARGE_TEXT_MINI,
        },
        didParseCell(data) {
          if (
            data.section === 'body' &&
            data.row.index === buildingItemEnvironmentData.length - 1
          ) {
            data.cell.styles.fillColor = 'white';
            data.cell.styles.fontStyle = 'bold';
          }
        },
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

export const generateEnvironmentReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();
  const { getNobbItems } = useProductInfo();
  const {
    getBuildingElementSumGWP,
    getBuildingElementCalculatedSumGWP,
    extractNobbNosFromBuildingElement,
    tryGetEpdA1A3GWPIOBCNumber,
    tryGetUnformatedEpdValidationDate,
  } = useEnvironment();

  if (!projectCalculation.value) return;

  const wb = XLSX.utils.book_new();

  const xlsxName = createReportName(type, ReportFileType.XLSX);

  const sheetName = checkAndbuildSheetName(
    projectCalculation.value.Name,
    wb.SheetNames
  );

  wb.Props = {
    Title: `${translate('reports.environment-report')}`,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  const elementEnvironmentSummedData: (string | number)[][] = [];

  await createHeaderXlsx(elementEnvironmentSummedData);
  await createProjectInfoXlsx(elementEnvironmentSummedData);

  elementEnvironmentSummedData.push([sheetName]);
  elementEnvironmentSummedData.push(['']);

  const head = [
    `${translate('common.building-element')}`,
    `${translate('environment.gwp-iobc')}`,
    `${translate('environment.climate-imprint')}`,
    `${translate('environment.gwp-iobc-unit')}`,
  ];

  elementEnvironmentSummedData.push(head);

  let totalSumGWP = 0;
  let totalCalculatedSumGWP = 0;

  let nobbItems: Array<NobbItem> = [];

  interface ElementWithNobbItems {
    element: BuildingElementCalculation;
    nobbItems: Array<NobbItem>;
  }

  const buildingElementsWithNobbItems: Array<ElementWithNobbItems> = [];

  const getNobbItemFromNobbNumber = (
    nobbItems: Array<NobbItem>,
    nobbNumber?: Nullable<number>
  ) => {
    if (nobbNumber && nobbItems) {
      return nobbItems.find(item => item.nobbNumber === nobbNumber);
    }
  };

  for (const element of projectCalculation.value.BuildingElements) {
    let calculatedSumGWP = 0;
    let sumGWP = 0;
    try {
      nobbItems = await getNobbItems(
        extractNobbNosFromBuildingElement(element)
      );
    } finally {
      calculatedSumGWP = getBuildingElementCalculatedSumGWP(element, nobbItems);
      sumGWP = getBuildingElementSumGWP(element, nobbItems);
    }

    elementEnvironmentSummedData.push([
      element.Name ?? '',
      sumGWP ? precision(sumGWP, 10) : '-',
      calculatedSumGWP ? precision(calculatedSumGWP, 10) : '-',
      `${translate('environment.kg-carbondioxide-equivalent')}`,
    ]);

    totalSumGWP += sumGWP;
    totalCalculatedSumGWP += calculatedSumGWP;

    buildingElementsWithNobbItems.push({ element, nobbItems });
  }

  elementEnvironmentSummedData.push([
    `${translate('labels.sum')}`,
    totalSumGWP ? precision(totalSumGWP, 10) : '-',
    totalCalculatedSumGWP ? precision(totalCalculatedSumGWP, 10) : '-',
    `${translate('environment.kg-carbondioxide-equivalent')}`,
  ]);

  if (options.IncludeBuildingElements) {
    for (const elementWithNobbItems of buildingElementsWithNobbItems) {
      let calculatedSumGWP = 0;
      let sumGWP = 0;

      elementEnvironmentSummedData.push(['']);
      elementEnvironmentSummedData.push([
        elementWithNobbItems.element.Name ?? '',
      ]);
      elementEnvironmentSummedData.push(['']);

      const buildingElementHead = [
        `${translate('common.part')}`,
        `${translate('environment.validation-date')}`,
        `${translate('environment.gwp-iobc')}`,
        `${translate('environment.reference-unit')}`,
        `${translate('labels.amount')}`,
        `${translate('labels.unit')}`,
        `${translate('headers.amountofmaterials')}`,
        `${translate('labels.unit-for-order')}`,
        `${translate('environment.climate-imprint')}`,
        `${translate('environment.gwp-iobc-unit')}`,
      ];

      elementEnvironmentSummedData.push(buildingElementHead);

      for (const item of elementWithNobbItems.element.BuildingItems) {
        const nobbItem = getNobbItemFromNobbNumber(
          elementWithNobbItems.nobbItems,
          item.NOBBNumber
        );

        if (!options.IncludeBuildingItemsWithMissingNOBB && !item.NOBBNumber) {
          continue;
        }

        if (
          !options.IncludeBuildingItemsWithMissingEPD &&
          nobbItem?.properties.epd.length === 0
        ) {
          continue;
        }

        const name = item.Name ?? '';
        let validationDate: string | undefined = '-';
        let gwp: number | undefined = 0;
        let priceUnit: string | undefined = '-';
        let gwpCalculated: number | undefined = 0;
        let priceUnitEqualsToBuildingItemUnit = false;

        if (nobbItem) {
          validationDate = tryGetUnformatedEpdValidationDate(
            nobbItem.properties.epd
          );
          gwp = tryGetEpdA1A3GWPIOBCNumber(nobbItem.properties.epd);
          priceUnit = nobbItem.priceUnit;
          if (gwp && item.AmountOfMaterials.value) {
            gwpCalculated = gwp * item.AmountOfMaterials.value;
          }
        }

        if (priceUnit && item.UnitForOrder) {
          priceUnitEqualsToBuildingItemUnit = areUnitsEqual(
            unitDescriptionConverter(priceUnit),
            item.UnitForOrder
          );
        }

        elementEnvironmentSummedData.push([
          name,
          validationDate ? validationDate : '',
          gwp ? precision(gwp, 10) : '-',
          priceUnit ? unitDescriptionConverter(priceUnit) : '-',
          item.AmountCalculated.value
            ? precision(item.AmountCalculated.value, 2)
            : '-',
          item.Unit ?? '-',
          item.AmountOfMaterials.value
            ? precision(item.AmountOfMaterials.value, 2)
            : '-',
          item.UnitForOrder ?? '-',
          priceUnitEqualsToBuildingItemUnit && gwpCalculated
            ? precision(gwpCalculated, 10)
            : '-',
          `${translate('environment.kg-carbondioxide-equivalent')}`,
        ]);
      }

      calculatedSumGWP = getBuildingElementCalculatedSumGWP(
        elementWithNobbItems.element,
        elementWithNobbItems.nobbItems
      );
      sumGWP = getBuildingElementSumGWP(
        elementWithNobbItems.element,
        elementWithNobbItems.nobbItems
      );
      const lastRow = [
        `${translate('labels.sum')}`,
        '',
        sumGWP ? precision(sumGWP, 10) : '-',
        '',
        '',
        '',
        '',
        '',
        calculatedSumGWP ? precision(calculatedSumGWP, 10) : '-',
        `${translate('environment.kg-carbondioxide-equivalent')}`,
      ];

      elementEnvironmentSummedData.push(lastRow);
    }
  }

  const ws = XLSX.utils.aoa_to_sheet(elementEnvironmentSummedData);
  wb.Sheets[sheetName] = ws;

  // set column width
  ws['!cols'] = [
    { wch: 44 }, // "Bygningsdel" takes the most space
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 20 },
    { wch: 30 },
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
  ];

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  downloadExcel(xlsxName, wbout);
};

export const generateEnvironmentReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateEnvironmentReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateEnvironmentReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
