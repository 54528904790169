



































































import { getIconByProfessionCode } from '@/helpers/common';
import { translate } from '@/localization';
import { ProjectOverview } from '@/models/projects/interfaces';
import { useCalculation } from '@/modules/calculation';
import { useLoader } from '@/modules/loader';
import { useProject } from '@/modules/project';
import { useSnackbar } from '@/modules/snackbar';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const { activeProjectOverview } = useProject();
    const { activeCalculation, setActiveCalculation } = useCalculation();
    const { startLoader, stopLoader } = useLoader();
    const { snack } = useSnackbar();

    const calculations = computed(() => {
      if (
        activeProjectOverview?.value?.Children &&
        activeCalculation?.value?.Id != null
      ) {
        const calcId = activeCalculation.value.Id;
        const calcs: Array<ProjectOverview> = activeProjectOverview.value.Children.filter(
          calc => calc.Id !== calcId
        );
        return calcs ?? [];
      }
    });

    const handleChange = async (item: ProjectOverview) => {
      if (item.Id !== activeCalculation?.value?.Id) {
        await startLoader(`${translate('loader.calculation-changing')}`);
        await setActiveCalculation(item);
        await stopLoader();

        snack(`${translate('calculation.calculation-changed')}`, true);
      }
    };
    return {
      calculations,
      activeCalculation,
      getIconByProfessionCode,
      handleChange,
    };
  },
});
