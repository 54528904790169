





























































import FactorSummary from '@/components/factors/factor-summary/index.vue';
import CalculationFactorItems from '@/components/factors/calculation-factors/calculation-factor/index.vue';
import { defineComponent, onMounted } from '@vue/composition-api';
import { useProject } from '@/modules/project';
import { useCalculation } from '@/modules/calculation';
import Profession from '@/components/profession/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import Loader from '@/components/loader/index.vue';

export default defineComponent({
  components: {
    CalculationFactorItems,
    FactorSummary,
    Profession,
    InfoTooltip,
    ContentBar,
    Loader,
  },
  setup() {
    const { getProfessions } = useProject();
    const {
      projectCalculation,
      loading,
      activeCalculation,
      updating,
    } = useCalculation();

    onMounted(async () => {
      await getProfessions();
    });

    return {
      activeCalculation,
      projectCalculation,
      loading,
      updating,
    };
  },
});
