




































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useLicense } from '@/modules/license';
import moment from 'moment';
import LicenseModal from '@/components/license-adm/license-modal/index.vue';
import DeleteLicenseModal from '@/components/license-adm/license-delete-modal/index.vue';
import AssignLicenseModal from '@/components/license-adm/license-assign-modal/index.vue';
import Status from '@/components/license-adm/status/index.vue';
import Loader from '@/components/loader/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';

export default defineComponent({
  components: {
    LicenseModal,
    DeleteLicenseModal,
    AssignLicenseModal,
    Status,
    Loader,
    ContentBar,
  },
  setup() {
    const {
      licenses,
      canAssignLicense,
      canDeleteLicense,
      showLicenseActions,
      getLicenses,
    } = useLicense();

    const loading = ref<boolean>(true);

    onMounted(async () => {
      await getLicenses();
      loading.value = false;
    });

    return {
      licenses,
      loading,
      moment,
      canAssignLicense,
      canDeleteLicense,
      showLicenseActions,
    };
  },
});
