































































































import { ref, defineComponent, PropType, computed } from '@vue/composition-api';
import { BuildingItemCalculation } from '@/models/building-element/interfaces';
import TimeEstimates from '@/components/time-estimate/index.vue';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import { TimeEstimate } from '@/models/time-estimate/interface';
import { useBuildingItem } from '@/modules/building-item';
import Confirm from '@/components/common/confirm/index.vue';

export default defineComponent({
  components: {
    TimeEstimates,
    DialogTitle,
    Confirm,
  },
  props: {
    item: {
      type: Object as PropType<BuildingItemCalculation>,
      required: true,
    },
    iconButton: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);
    const confirmDialog = ref<boolean>(false);
    const estimate = ref<TimeEstimate>();
    const overwriteName = ref<boolean>(true);
    const userConfirm = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const { upsertTimeEstimate } = useBuildingItem();

    const valid = computed(() => {
      let ok = false;
      if (estimate.value) {
        ok = true;
      }
      return ok;
    });

    const upsertTime = async () => {
      if (estimate.value) {
        loading.value = true;
        await upsertTimeEstimate(
          estimate.value,
          props.item,
          overwriteName.value
        );
        emit('updated', true);
        loading.value = false;
      }
    };

    const close = () => {
      userConfirm.value = false;
      overwriteName.value = true;
      confirmDialog.value = false;
      dialog.value = false;
      estimate.value = undefined;
      emit('close', true);
    };

    const confirm = async () => {
      userConfirm.value = true;
      overwriteName.value = true;
      confirmDialog.value = false;
      await upsertTime();
      close();
    };

    const deny = async () => {
      userConfirm.value = true;
      overwriteName.value = false;
      confirmDialog.value = false;
      await upsertTime();
      close();
    };

    const selectedEvent = async (est: TimeEstimate | undefined) => {
      userConfirm.value = false;
      estimate.value = est;
      if (valid.value && estimate.value) {
        if (props.item.Name != null) {
          confirmDialog.value = true;
        } else {
          overwriteName.value = true;
          await upsertTime();
          close();
        }
      }
    };

    return {
      close,
      selectedEvent,
      confirm,
      deny,
      loading,
      dialog,
      confirmDialog,
      valid,
      estimate,
    };
  },
});
