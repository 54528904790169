













































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    propValue: {
      type: [String, Number],
      required: true,
    },
    propValueClass: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    textClass: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
    },
    margin: {
      type: Boolean,
    },
  },
});
