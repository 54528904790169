


























































import { ref, defineComponent, PropType } from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import FromDefault from '@/components/calculation/new-building-item/from-default/index.vue';
import { BuildingItem } from '@/models/building-element/interfaces';
import { useCalculation } from '@/modules/calculation';

const valid = ref<boolean>(false);

export default defineComponent({
  components: {
    DialogTitle,
    FromDefault,
  },
  props: {
    buildingItem: {
      type: Object as PropType<BuildingItem>,
      required: true,
    },
  },
  setup() {
    const { NS3420Units } = useCalculation();
    const dialog = ref<boolean>(false);

    const close = async () => {
      dialog.value = false;
    };

    const submit = async () => {
      close();
    };

    return {
      submit,
      close,
      valid,
      dialog,
      NS3420Units,
    };
  },
});
