var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.projectCalculation &&
      _vm.projectCalculation.BuildingElements &&
      _vm.projectCalculation.BuildingElements.length > 0) ||
      (_vm.customElements && _vm.customElements.length > 0)
  )?_c('v-card',{staticClass:"table-card",attrs:{"tile":"","min-height":"550","flat":""}},[_c('v-card-title',{staticClass:"calc-card-title"},[_c('v-row',{attrs:{"dense":""}},[_c('v-subheader',{staticClass:"field-name"},[_vm._v(" "+_vm._s(_vm.$t('titles.views'))+" ")]),_c('v-btn-toggle',{attrs:{"mandatory":"","tile":"","color":"secondary","group":"","dense":""}},[_c('v-btn',{on:{"click":function($event){_vm.columnView = _vm.CalculationColumnView.Calculation}}},[_vm._v(" "+_vm._s(_vm.$t('calculation.category1'))+" ")]),_c('v-btn',{on:{"click":function($event){_vm.columnView = _vm.CalculationColumnView.HourlyConsumption}}},[_vm._v(" "+_vm._s(_vm.$t('calculation.category2'))+" ")]),_c('v-btn',{on:{"click":function($event){_vm.columnView = _vm.CalculationColumnView.Materials}}},[_vm._v(" "+_vm._s(_vm.$t('calculation.category4'))+" ")]),_c('v-btn',{on:{"click":function($event){_vm.columnView = _vm.CalculationColumnView.Delivery}}},[_vm._v(" "+_vm._s(_vm.$t('calculation.category6'))+" ")]),_c('v-btn',{on:{"click":function($event){_vm.columnView = _vm.CalculationColumnView.Economy}}},[_vm._v(" "+_vm._s(_vm.$t('calculation.category3'))+" ")])],1)],1),_c('v-spacer'),_c('calculation-switcher'),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"exp-col pa-5",attrs:{"icon":"","small":""},on:{"click":_vm.collapseAll}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-collapse-vertical")])],1)]}}],null,false,2761609898)},[_c('span',[_vm._v(_vm._s(_vm.$t('calculation.collapse-all')))])])],1),_c('v-subheader',{staticClass:"field-name"},[_vm._v(" "+_vm._s(_vm.$t('titles.building-elements-length', { length: _vm.customElements ? _vm.customElements.length : _vm.projectCalculation.BuildingElements != null && _vm.projectCalculation.BuildingElements.length, }))+" ")]),(!_vm.loading)?_c('v-expansion-panels',{staticClass:"building-elements",attrs:{"multiple":"","tile":"","focusable":"","value":_vm.expendCalc,"id":"element-container"}},[_c('draggable',{staticClass:"draggable-wrapper",attrs:{"v-model":_vm.elements,"disabled":!_vm.allowDrag,"options":{ handle: '.element-draggable' }},on:{"end":_vm.onDropCallback}},_vm._l((_vm.elements),function(element){return _c('building-calc-element',{key:("" + (element.Id) + _vm.key),attrs:{"element":element,"smallVariant":_vm.smallVariant,"columns":_vm.columns,"search":_vm.search,"columnView":_vm.columnView}})}),1)],1):_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1):_c('kalk-helper',{attrs:{"title":_vm.$t('titles.add-first-element'),"message":_vm.$t('info.add-first-element1')}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"ma-2",attrs:{"to":_vm.PROJECT_CALCULATION_OVERVIEW_PATH,"small":""}},[_vm._v(_vm._s(_vm.$t('buttons.go-to-factors')))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }