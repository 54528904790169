




































































































































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { useLicense } from '@/modules/license';
import Offer from '@/components/license-adm/offer/index.vue';
import { UserRegistration, RegisterUserModel } from '@/models/auth/interfaces';
import { useUsers } from '@/modules/users';
import { UsernameModel } from '@/models/common/interfaces';
import { translate } from '@/localization';

interface State {
  dialog: boolean;
  user: UserRegistration;
  selectedUser?: string;
  method?: number;
  step: number;
  readAndUnderstood: boolean;
}

const state = reactive<State>({
  dialog: false,
  user: {
    Administrator: false,
    ReadAndUnderstood: false,
  },
  selectedUser: undefined,
  step: 1,
  method: undefined,
  readAndUnderstood: false,
});

export default defineComponent({
  components: {
    Offer,
  },
  setup(): Record<string, unknown> {
    const {
      loading,
      error,
      getLicensePrice,
      purchaseLicense,
      getLicenses,
      canAssignToExistingUser,
      availableUsers,
      licensePrice,
      calculatingChoices,
      canChoose,
    } = useLicense();

    const { createUser, getUsers } = useUsers();

    const createLoad = ref<boolean>(false);
    const form = ref();

    onMounted(async () => {
      await getLicensePrice();
      await canAssignToExistingUser();
    });

    const close = () => {
      state.selectedUser = undefined;
      state.user = {
        Administrator: false,
        ReadAndUnderstood: false,
      } as UserRegistration;
      state.dialog = false;
      state.step = 1;
      state.method = undefined;
      state.readAndUnderstood = false;
      form.value.resetValidation();
    };

    const goBack = () => {
      state.selectedUser = undefined;
      state.user = {
        Administrator: false,
        ReadAndUnderstood: false,
      } as UserRegistration;
      state.step = 1;
      state.method = undefined;
      state.readAndUnderstood = false;
    };

    const submit = async () => {
      createLoad.value = true;

      const reg: RegisterUserModel = {
        Email: state.user.Email ? state.user.Email : '',
        FirstName: state.user.FirstName ? state.user.FirstName : '',
        LastName: state.user.LastName ? state.user.LastName : '',
        Password: state.user.Password ? state.user.Password : '',
        IsAdmin: state.user.Administrator,
      };

      let createdUser: string | undefined = '';

      if (!state.selectedUser) {
        createdUser = await createUser(reg);
      } else {
        createdUser = state.selectedUser;
      }

      if (createdUser) {
        const user: UsernameModel = {
          UserName: createdUser,
        };
        await purchaseLicense(user);
      }

      await getUsers(true);
      await getLicenses(true);
      await canAssignToExistingUser();
      close();
      createLoad.value = false;
    };

    const passwordValidator = () => {
      let valid = false;
      if (state.user.Password && state.user.ConfirmPassword) {
        if (state.user.Password.length > 7) {
          if (state.user.Password === state.user.ConfirmPassword) {
            if (/[a-z]/.test(state.user.Password)) {
              if (/[A-Z]/.test(state.user.Password)) {
                if (/[0-9]+/.test(state.user.Password)) {
                  valid = true;
                }
              }
            }
          }
        }
      }
      return valid;
    };

    const formIsValid = computed(() => {
      const valid = false;
      if (
        state.user.FirstName &&
        state.user.LastName &&
        passwordValidator() &&
        state.user.ReadAndUnderstood
      ) {
        return state.user.Password === state.user.ConfirmPassword;
      }
      return valid;
    });

    const altFormIsValid = computed(() => {
      return (
        state.selectedUser !== undefined && state.readAndUnderstood === true
      );
    });

    return {
      ...toRefs(state),
      goBack,
      submit,
      close,
      loading,
      error,
      emailRules: [
        (v: string) => !!v || `${translate('validation.required')}`,
        (v: string) =>
          /.+@.+\..+/.test(v) || `${translate('validation.valid-email')}`,
      ],
      formIsValid,
      createLoad,
      form,
      availableUsers,
      altFormIsValid,
      licensePrice,
      calculatingChoices,
      canChoose,
      termsUrl: process.env.VUE_APP_TERMS_URL,
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
        min: (v: string) =>
          (v && v.length >= 8) ||
          `${translate('validation.minimum-characters', { amount: 8 })}`,
        passwordMatch: () =>
          state.user.Password === state.user.ConfirmPassword ||
          `${translate('validation.pw-missmatch')}`,
        containsLower: (v: string) =>
          /[a-z]/.test(v && v) || `${translate('validation.lowercase')}`,
        containsUpper: (v: string) =>
          /[A-Z]/.test(v && v) || `${translate('validation.uppercase')}`,
        containsNumber: (v: string) =>
          /[0-9]+/.test(v && v) || `${translate('validation.number')}`,
      },
    };
  },
});
