import { areUnitsEqual, unitDescriptionConverter } from '@/helpers/common';
import { BuildingElementCalculation } from '@/models/building-element/interfaces';
import {
  EPDPropertyGUIDS,
  Environment,
  Epd,
  NobbItem,
} from '@/models/nobb/interfaces';

export const useEnvironment = () => {
  const tryGetEpdReferenceUnit = (epd: Array<Epd>): string | undefined => {
    return epd.find(epd => epd.propertyGuid === EPDPropertyGUIDS.ReferenceUnit)
      ?.value;
  };

  const tryGetEpdA1A3GWPIOBCUnit = (epd: Array<Epd>): string | undefined => {
    return epd.find(epd => epd.propertyGuid === EPDPropertyGUIDS.A1A3_GWP_IOBC)
      ?.unit;
  };

  const tryGetEpdA1A3GWPIOBCNumber = (epd: Array<Epd>): number | undefined => {
    const a1A3 = epd.find(
      epd => epd.propertyGuid === EPDPropertyGUIDS.A1A3_GWP_IOBC
    )?.value;
    if (a1A3) {
      return Number(a1A3);
    }
  };

  const tryGetEpdA1A3Number = (epd: Array<Epd>): number | undefined => {
    const a1A3 = epd.find(epd => epd.propertyGuid === EPDPropertyGUIDS.A1A3_GWP)
      ?.value;
    if (a1A3) {
      return Number(a1A3);
    }
  };

  const tryGetEpdA1A3Unit = (epd: Array<Epd>): string | undefined => {
    return epd.find(epd => epd.propertyGuid === EPDPropertyGUIDS.A1A3_GWP)
      ?.unit;
  };

  const tryGetUnformatedEpdValidationDate = (epd: Array<Epd>) => {
    return epd.find(epd => epd.propertyGuid === EPDPropertyGUIDS.ValidUntil)
      ?.value;
  };

  const getNobbItemFromNobbNumber = (
    nobbItems: Array<NobbItem>,
    nobbNumber?: Nullable<number>
  ) => {
    if (nobbNumber && nobbItems) {
      return nobbItems.find(item => item.nobbNumber === nobbNumber);
    }
  };

  const getBuildingElementSumGWP = (
    element: BuildingElementCalculation,
    nobbItems: Array<NobbItem>
  ): number => {
    let sumGWP = 0;
    element.BuildingItems.forEach(buildingItem => {
      const item = getNobbItemFromNobbNumber(
        nobbItems,
        buildingItem.NOBBNumber
      );
      if (item && item.properties.epd.length > 0) {
        const a1A3 = tryGetEpdA1A3GWPIOBCNumber(item.properties.epd);
        if (a1A3) {
          sumGWP += Number(a1A3);
        }
      }
    });
    return sumGWP;
  };

  const getBuildingElementCalculatedSumGWP = (
    element: BuildingElementCalculation,
    nobbItems: Array<NobbItem>
  ): number => {
    let sumCalculatedGWP = 0;
    element.BuildingItems.forEach(buildingItem => {
      const item = getNobbItemFromNobbNumber(
        nobbItems,
        buildingItem.NOBBNumber
      );
      if (item && item.properties.epd.length > 0 && buildingItem.UnitForOrder) {
        const priceUnit = unitDescriptionConverter(item.priceUnit);

        const buildingItemUnitIsEqualToPriceUnit = areUnitsEqual(
          priceUnit,
          buildingItem.UnitForOrder
        );

        const a1A3 = tryGetEpdA1A3GWPIOBCNumber(item.properties.epd);
        if (a1A3 && buildingItemUnitIsEqualToPriceUnit) {
          if (buildingItem.AmountOfMaterials.value) {
            const gwpTimesAmount = a1A3 * buildingItem.AmountOfMaterials.value;
            sumCalculatedGWP += gwpTimesAmount;
          }
        }
      }
    });
    return sumCalculatedGWP;
  };

  const tryGetProductCertificates = (certificates: Array<Environment>) => {
    return certificates.filter(cert => cert.value === 'True');
  };

  const extractNobbNosFromBuildingElement = (
    element: BuildingElementCalculation
  ): number[] => {
    const nos: Array<number> = [];

    element.BuildingItems.forEach(item => {
      if (item.NOBBNumber) {
        nos.push(item.NOBBNumber);
      }
    });

    return nos;
  };

  return {
    tryGetEpdReferenceUnit,
    tryGetEpdA1A3Unit,
    tryGetEpdA1A3Number,
    tryGetEpdA1A3GWPIOBCUnit,
    tryGetEpdA1A3GWPIOBCNumber,
    tryGetUnformatedEpdValidationDate,
    getBuildingElementSumGWP,
    getBuildingElementCalculatedSumGWP,
    tryGetProductCertificates,
    extractNobbNosFromBuildingElement,
  };
};
