











































































import {
  ref,
  defineComponent,
  onMounted,
  computed,
} from '@vue/composition-api';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import DownloadablePricelist from '@/components/pricebook/download-pricelist/downloadable-pricelist/index.vue';
import Loader from '@/components/loader/index.vue';
import { PriceList } from '@/models/pricebook/interfaces';
import { usePriceBook } from '@/modules/pricebook';

export default defineComponent({
  components: {
    DialogTitle,
    DownloadablePricelist,
    Loader,
  },
  setup() {
    const { getDownloadableFiles, subscribeToPriceList } = usePriceBook();
    const dialog = ref(false);
    const loadingPriceLists = ref(false);
    const loading = ref(false);
    const selectedFile = ref<PriceList>();
    const downloadableFiles = ref<Array<PriceList>>([]);

    const valid = computed(() => {
      return selectedFile.value != null;
    });

    const initDownloadable = async () => {
      loadingPriceLists.value = true;
      downloadableFiles.value = await getDownloadableFiles();
      loadingPriceLists.value = false;
    };

    onMounted(async () => {
      await initDownloadable();
    });

    const close = () => {
      selectedFile.value = undefined;
      dialog.value = false;
    };

    const submit = async () => {
      if (selectedFile.value && valid.value) {
        loading.value = true;
        const success = await subscribeToPriceList(selectedFile.value);
        if (success) {
          selectedFile.value = undefined;
          await initDownloadable();
        } else {
          close();
        }
      }
      loading.value = false;
    };

    return {
      close,
      submit,
      dialog,
      loading,
      valid,
      selectedFile,
      downloadableFiles,
      loadingPriceLists,
    };
  },
});
