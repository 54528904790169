































































































































































































































































































































































































































































































import {
  ref,
  defineComponent,
  PropType,
  computed,
  onMounted,
} from '@vue/composition-api';
import { useImport } from '@/modules/import';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { useProject } from '@/modules/project';
import { useSnackbar } from '@/modules/snackbar';
import { ImportedProject, ProjectOverview } from '@/models/projects/interfaces';
import { translate } from '@/localization/index';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import InfoTooltip from '@/components/common/tooltip-helper/index.vue';
import ImportInfo from '@/components/projects/import-project/import-info/index.vue';
import Loader from '@/components/loader/index.vue';
import Confirm from '@/components/common/confirm/index.vue';
import { NstGenerelt } from '@/models/standards/ns3459/interfaces';
import Calculations from '@/components/projects/calculations/index.vue';
import { CALCULATION_BY_PROFESSION } from '@/constants/table-headers';
import { useExport } from '@/modules/export';
import { ExportType } from '@/constants/enum';
import { PROJECT_CALCULATION_PATH } from '@/constants/routes';
import { useCalculation } from '@/modules/calculation';
import router from '@/router';

enum ImportMethod {
  FromFile,
  FromCalculations,
}

interface ProjectFilter {
  search: string;
}

export default defineComponent({
  props: {
    isMenuitem: {
      type: Boolean,
    },
    parent: {
      type: Object as PropType<ProjectOverview>,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    DialogTitle,
    InfoTooltip,
    Calculations,
    ImportInfo,
    Loader,
    Confirm,
  },
  setup({ parent }) {
    const {
      parseProjectFile,
      importProject,
      createImportProject,
    } = useImport();
    const {
      getProjects,
      refreshActiveProject,
      setActiveProject,
      projects,
      activeProjectOverview,
    } = useProject();
    const { setActiveCalculation } = useCalculation();

    const dialog = ref(false);
    const loading = ref(false);
    const importingCalculation = ref<ProjectOverview>();
    const includeAmount = ref(true);
    const includeDialog = ref(false);
    const projectFile = ref<File>();
    const importing = ref(false);
    const navigateToCalc = ref(true);
    const importZone = ref();
    const validating = ref(false);
    const newProject = ref<ImportedProject>();
    const generalInfo = ref<NstGenerelt>();
    const method = ref<ImportMethod>(ImportMethod.FromCalculations);
    const filter = ref<ProjectFilter>({
      search: '',
    });
    const newName = ref<string>();

    const valid = computed(() => {
      return (
        newProject.value &&
        generalInfo.value &&
        projectFile.value &&
        newProject.value.Project.Name &&
        newProject.value.Project.ProjectNumber
      );
    });

    const filteredProjects = computed(() => {
      if (projects?.value && projects.value.length > 0) {
        let filtered: Array<ProjectOverview> = projects.value;
        if (filter.value.search) {
          filtered = filtered.filter(p => {
            return (
              p.Name.toUpperCase().includes(
                filter.value.search.toUpperCase()
              ) ||
              p.Children.find(c =>
                c.Name.toUpperCase().includes(filter.value.search.toUpperCase())
              )
            );
          });
        }
        return filtered;
      }
    });

    const fileOk = computed(() => {
      return newProject.value && generalInfo.value && projectFile.value;
    });

    onMounted(async () => {
      await getProjects();
    });

    const validateImport = async (file: File) => {
      validating.value = true;
      projectFile.value = file;
      importZone.value && importZone.value.removeAllFiles();
      if (file.type === 'application/json' || file.type === 'text/xml') {
        try {
          const ns = await parseProjectFile(file);
          if (ns) {
            generalInfo.value = ns.genereltField;
            newProject.value = await createImportProject(ns, parent);
          }
        } catch (e) {
          console.log(e);
        }

        if (valid.value) {
          importZone.value.disable();
        } else {
          projectFile.value = undefined;
        }
        importZone.value && importZone.value.removeAllFiles();
      } else {
        projectFile.value = undefined;
        importZone.value && importZone.value.removeAllFiles();
      }
      validating.value = false;
    };

    const removeCalc = (calcId: string) => {
      if (
        newProject.value &&
        newProject.value.Children &&
        newProject.value.Children.length > 0
      ) {
        const calcIndex = newProject.value.Children.findIndex(
          calc => calc.Project && calc.Project.Id === calcId
        );

        if (calcIndex >= 0) {
          newProject.value.Children.splice(calcIndex, 1);
        }
      }
    };

    const cancel = () => {
      projectFile.value = undefined;
      newProject.value = undefined;
      generalInfo.value = undefined;
      importingCalculation.value = undefined;
      importing.value = false;
      includeDialog.value = false;
      includeAmount.value = true;

      filter.value = {
        search: '',
      };
      if (importZone.value) importZone.value.enable();
    };

    const switchMethod = (newMethod: ImportMethod) => {
      cancel();
      method.value = newMethod;
    };

    const close = () => {
      projectFile.value = undefined;
      newProject.value = undefined;
      generalInfo.value = undefined;
      filter.value = {
        search: '',
      };
      method.value = ImportMethod.FromCalculations;
      importingCalculation.value = undefined;
      importing.value = false;
      includeDialog.value = false;
      newName.value = undefined;
      includeAmount.value = true;
      navigateToCalc.value = true;
      dialog.value = false;
    };

    const handleError = (file: File, message: string) => {
      const { snack } = useSnackbar();
      importZone.value.removeFile(file);
      if (!message.includes('Upload')) {
        snack(message, false);
      }
    };

    const importCalculation = async (calculation: ProjectOverview) => {
      importingCalculation.value = calculation;
      newName.value = calculation.Name;
      includeDialog.value = true;
    };

    const importCalc = async () => {
      const { exportProject } = useExport();
      const { createImportProject, importProject } = useImport();

      if (
        !importingCalculation.value ||
        !projects?.value ||
        !importingCalculation.value.Id
      )
        return;

      importing.value = true;

      const nsProject = await exportProject(
        importingCalculation.value.Id,
        false,
        ExportType.JSON
      );

      if (parent && nsProject) {
        const newImport = await createImportProject(
          nsProject,
          parent,
          newName.value,
          includeAmount.value
        );

        if (newImport) {
          const success = await importProject(newImport);
          if (success) {
            await getProjects(true);
            if (navigateToCalc.value && projects.value) {
              const newActiveProj = projects.value.find(proj =>
                proj.Children.some(calc => calc.Id === newImport.Project.Id)
              );

              if (
                newActiveProj &&
                activeProjectOverview.value &&
                newActiveProj.Id === activeProjectOverview.value.Id
              ) {
                close();
                await refreshActiveProject();
              } else if (newActiveProj?.Id) {
                await setActiveProject(newActiveProj.Id, false);
              }

              if (newActiveProj) {
                const calc = newActiveProj.Children.find(
                  calc => calc.Id === newImport.Project.Id
                );
                if (calc) {
                  await setActiveCalculation(calc);
                  await router.push(PROJECT_CALCULATION_PATH);
                }
              }
            } else {
              await refreshActiveProject();
            }
          }
        }
      }

      importing.value = false;
      close();
    };

    const abort = () => {
      importingCalculation.value = undefined;
      includeDialog.value = false;
      newName.value = undefined;
    };

    const submit = async () => {
      loading.value = true;
      if (valid.value && newProject.value) {
        let imported = false;
        imported = await importProject(newProject.value);
        if (imported) {
          await getProjects(true);
          await refreshActiveProject();
          close();
        }
      }

      loading.value = false;
    };

    return {
      cancel,
      close,
      validateImport,
      handleError,
      submit,
      removeCalc,
      switchMethod,
      importCalculation,
      abort,
      importCalc,
      loading,
      importZone,
      projectFile,
      dialog,
      valid,
      fileOk,
      validating,
      newProject,
      generalInfo,
      method,
      ImportMethod,
      filter,
      filteredProjects,
      importing,
      includeDialog,
      includeAmount,
      navigateToCalc,
      newName,
      importingCalculation,
      CALCULATION_BY_PROFESSION,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'http://localhost',
        thumbnailWidth: 150,
        maxFilesize: 3,
        maxFiles: 1,
        acceptedFiles: '.xml,.json',
        dictDefaultMessage: `${translate(
          'project.import-project-default-message'
        )}`,
      },
      rules: {
        required: (v: string) => !!v || `${translate('validation.required')}`,
      },
    };
  },
});
