import { reactive, toRefs } from '@vue/composition-api';
import { CustomError } from '@/models/common/interfaces';
import { useApi } from './api';
import { isSuccess } from '@/helpers/common';
import { useSnackbar } from './snackbar';
import { useErrorModal } from './error';
import { Ns3451 } from '@/models/standards/ns3451/interfaces';

interface State {
  Ns3451?: Array<Ns3451>;
  error?: CustomError;
  Ns3451Children?: Array<Ns3451>;
}

const state = reactive<State>({
  Ns3451: undefined,
  error: undefined,
  Ns3451Children: undefined,
});

export const useStandards = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const getStandards = async (
    force?: boolean
  ): Promise<Array<Ns3451> | undefined> => {
    if (state.Ns3451 && !force) {
      return state.Ns3451;
    }

    const { get } = useApi('/standard');

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.Ns3451 = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.Ns3451;
  };

  const getStandardChildren = async (
    force?: boolean
  ): Promise<Array<Ns3451> | undefined> => {
    if (state.Ns3451Children && !force) {
      return state.Ns3451Children;
    }

    const { get } = useApi('/standard/children');

    try {
      const response = await get();
      if (isSuccess(response.status)) {
        state.Ns3451Children = response.data;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return state.Ns3451Children;
  };

  return {
    ...toRefs(state),
    getStandards,
    getStandardChildren,
  };
};
