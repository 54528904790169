






















































































































































































































































































































































































































































































































































































































































































































































































import {
  BuildingElementCalculation,
  BuildingItemCalculation,
} from '@/models/building-element/interfaces';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useCalculation } from '@/modules/calculation';
import NobbInfo from '@/components/nobb-info/index.vue';
import { DataTableHeader, ItemWithProduct } from '@/models/common/interfaces';
import EditTimeEstimate from '@/components/calculation/building-item/edit-time-estimate/index.vue';
import RemoveTimeEstimate from '@/components/calculation/building-item/remove-time-estimate/index.vue';
import DeleteItem from '@/components/calculation/building-item/delete-item/index.vue';
import GetProduct from '@/components/calculation/get-product/index.vue';
import ReplaceProducts from '@/components/calculation/building-item/replace-products/index.vue';
import BuildingItemStatus from '@/components/calculation/building-item/building-item-status/index.vue';
import AddItemToLibrary from '@/components/calculation/building-item/add-item-to-library/index.vue';
import { useBatch } from '@/modules/batch';
import { useNudge } from '@/modules/nudge';
import { BatchType, RowColors } from '@/constants/enum';
import {
  getElementTotalByProp,
  getElementTotalTypography,
} from '@/helpers/building-element';
import { onBuildingItemAdjustmentChange } from '@/helpers/building-item';
import ProductSearch from '@/components/pricebook/product-search/index.vue';
import DataTableRowHandler from '@/components/common/table-row-handler/index.vue';
import draggable from 'vuedraggable';
import ItemWarnings from '@/components/calculation/building-elements/calc-element/element-warnings/index.vue';
import SetPrecut from '@/components/calculation/building-item/set-precut/index.vue';
import RemovePrecut from '@/components/calculation/building-item/remove-precut/index.vue';
import { calculatedPrice } from '@/helpers/pricebook';
import { SearchablePriceListItem } from '@/models/pricebook/interfaces';

export default defineComponent({
  components: {
    NobbInfo,
    EditTimeEstimate,
    RemoveTimeEstimate,
    DeleteItem,
    ProductSearch,
    GetProduct,
    ReplaceProducts,
    BuildingItemStatus,
    AddItemToLibrary,
    DataTableRowHandler,
    draggable,
    ItemWarnings,
    SetPrecut,
    RemovePrecut,
  },
  props: {
    items: {
      type: Array as PropType<Array<BuildingItemCalculation>>,
      required: true,
    },
    headers: {
      type: Array as PropType<Array<DataTableHeader>>,
      required: true,
    },
    simple: {
      type: Boolean,
    },
    calcElement: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
    smallVariant: {
      type: Boolean,
      required: true,
    },
    elevated: {
      type: Boolean,
    },
  },
  setup(props) {
    const {
      NS3420Units,
      projectCalculation,
      getItemsWithSimilarProduct,
    } = useCalculation();
    const {
      registerBuildingItemBatchOperation,
      registerItemSaveChangesWithAdditionAndParent,
      registerBuildingItemsBatchOperation,
    } = useBatch();
    const { amountTransition } = useNudge();

    const sameProduct = ref<Array<BuildingItemCalculation>>();
    const product = ref<SearchablePriceListItem>();
    const itemMenu = ref();

    const closeMenu = () => {
      if (itemMenu.value && itemMenu.value.length > 0) {
        itemMenu.value.forEach(
          (menu: { isActive: boolean }) => (menu.isActive = false)
        );
      }
    };

    const findItems = async (iwp: ItemWithProduct) => {
      if (!iwp.Item?.Id) return;

      if (iwp.Item.NOBBNumber) {
        sameProduct.value = getItemsWithSimilarProduct(
          iwp.Item.NOBBNumber,
          iwp.Item.Id
        );
      }

      product.value = iwp.PriceListItem;
      iwp.Item.NOBBNumber = product.value.NobbNumber;
      iwp.Item.Price = calculatedPrice(product.value);
      iwp.Item.UnitForOrder = product.value.Unit;
      iwp.Item.PriceListItemName = product.value.Name;
      iwp.Item.PriceListName = product.value.PriceListName;
      iwp.Item.IsModifiedRecently = true;

      registerBuildingItemBatchOperation(iwp.Item, BatchType.Save);
    };

    const closeEvent = () => {
      sameProduct.value = [];
      product.value = undefined;
      closeMenu();
    };

    const replaceAll = async () => {
      if (sameProduct.value && product.value) {
        for (const item of sameProduct.value) {
          item.NOBBNumber = product.value.NobbNumber;
          item.Price = calculatedPrice(product.value);
          item.UnitForOrder = product.value.Unit;
          item.PriceListItemName = product.value.Name;
          item.PriceListName = product.value.PriceListName;
          item.IsModifiedRecently = true;
        }
        registerBuildingItemsBatchOperation(sameProduct.value, BatchType.Save);
        closeEvent();
      }
    };

    const itemRowBackground = (item: BuildingItemCalculation) => {
      return item.IsModifiedRecently ? 'modified' : '';
    };

    const onDropCallback = (evt: { from: HTMLElement }) => {
      const parent = evt.from as HTMLElement;

      if (parent.children) {
        for (let i = 0; i < parent.children.length; i++) {
          const id = parent.children[i].getAttribute('data-id');

          if (props.calcElement && id) {
            const item = props.calcElement.BuildingItems.find(
              item => item.Id === id
            );
            if (item) {
              item.SortOrder = i;
            }
          }
        }
        registerBuildingItemsBatchOperation(
          props.calcElement.BuildingItems,
          BatchType.Save
        );
      }
    };

    const getRowColor = (item: BuildingItemCalculation) => {
      let color = '';
      if (item.Transport != null && RowColors[item.Transport]) {
        color = RowColors[item.Transport].toLocaleLowerCase();
      }
      return color;
    };

    const changeColor = (color: RowColors, item: BuildingItemCalculation) => {
      item.Transport = color;
      registerBuildingItemBatchOperation(item, BatchType.Save);
    };

    return {
      registerItemSaveChangesWithAdditionAndParent,
      registerBuildingItemBatchOperation,
      itemRowBackground,
      getElementTotalByProp,
      getElementTotalTypography,
      onBuildingItemAdjustmentChange,
      findItems,
      closeEvent,
      replaceAll,
      onDropCallback,
      changeColor,
      getRowColor,
      projectCalculation,
      BatchType,
      RowColors,
      NS3420Units,
      amountTransition,
      sameProduct,
      allowDrag: true,
      itemMenu,
      closeMenu,
    };
  },
});
