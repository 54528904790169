









































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import ContentBar from '@/components/common/content-bar/index.vue';
import { useCalculation } from '@/modules/calculation';
import { areUnitsEqual, unitDescriptionConverter } from '@/helpers/common';
import BuildingItemRow from '@/components/price-update/BuildingItemRow.vue';
import { usePriceBook } from '@/modules/pricebook';
import { useBuildingItem } from '@/modules/building-item';
import { useBatch } from '@/modules/batch';
import {
  PriceListTreeNode,
  PriceMatch,
  SearchablePriceListItem,
} from '@/models/pricebook/interfaces';
import {
  BuildingElementCalculation,
  BuildingItemCalculation,
  ItemStatus,
} from '@/models/building-element/interfaces';
import { BatchType } from '@/constants/enum';
import { ItemWithProduct } from '@/models/common/interfaces';
import { calculatedPrice } from '@/helpers/pricebook';
import EditTimeEstimate from '@/components/calculation/building-item/edit-time-estimate/index.vue';
import RemoveTimeEstimate from '@/components/calculation/building-item/remove-time-estimate/index.vue';
import GetProduct from '@/components/calculation/get-product/index.vue';
import NobbInfo from '@/components/nobb-info/index.vue';
import { translate } from '@/localization';
import CalculationStatus from '@/components/project-footer-status/calculation-status/index.vue';
import ReplaceProducts from '@/components/calculation/building-item/replace-products/index.vue';

export default defineComponent({
  components: {
    ContentBar,
    BuildingItemRow,
    EditTimeEstimate,
    RemoveTimeEstimate,
    GetProduct,
    NobbInfo,
    CalculationStatus,
    ReplaceProducts,
  },
  setup() {
    const {
      projectCalculation,
      updating,
      getItemsWithSimilarProduct,
    } = useCalculation();
    const {
      getPriceLists,
      updatePricesFromNOBBNumbers,
      pricelists,
    } = usePriceBook();
    const { updateBuildingItemPrices } = useBuildingItem();
    const {
      registerItemSaveChangesWithAdditionAndParent,
      registerBuildingItemBatchOperation,
      registerBuildingItemsBatchOperation,
    } = useBatch();

    const loading = ref(false);
    const fetchingPrices = ref(false);
    const updatingPrices = ref(false);
    const chosenPriceList = ref<PriceListTreeNode>();
    const pricesFetched = ref(false);
    const selected = ref<Array<BuildingItemCalculation>>();
    const searchablePriceListItems = ref<Array<SearchablePriceListItem>>();
    const sameProduct = ref<Array<BuildingItemCalculation>>();
    const product = ref<SearchablePriceListItem>();

    onMounted(async () => {
      loading.value = true;
      await getPriceLists();
      loading.value = false;
      selected.value = [];

      if (
        projectCalculation === undefined ||
        projectCalculation.value === undefined
      ) {
        throw Error('Unexpected undefined calculation');
      }
      projectCalculation.value.BuildingElements.forEach(element => {
        element.BuildingItems.forEach(item => {
          if (!item.NOBBNumber) {
            item.Status = {
              StatusColor: 'dark-grey',
              StatusText: `${translate('info.no-nobbno')}`,
              StatusType: ItemStatus.MissingNobb,
            };
          }
        });
      });
    });

    const closeEvent = () => {
      sameProduct.value = [];
      product.value = undefined;
    };

    const findItems = async (iwp: ItemWithProduct) => {
      if (!iwp.Item?.Id) return;

      if (iwp.Item.NOBBNumber) {
        sameProduct.value = getItemsWithSimilarProduct(
          iwp.Item.NOBBNumber,
          iwp.Item.Id
        );
      }

      product.value = iwp.PriceListItem;
      iwp.Item.NOBBNumber = product.value.NobbNumber;
      iwp.Item.Price = calculatedPrice(product.value);
      iwp.Item.UnitForOrder = product.value.Unit;
      iwp.Item.PriceListItemName = product.value.Name;
      iwp.Item.PriceListName = product.value.PriceListName;
      iwp.Item.IsModifiedRecently = true;

      registerBuildingItemBatchOperation(iwp.Item, BatchType.Save);
    };

    const replaceAll = async () => {
      if (sameProduct.value && product.value) {
        for (const item of sameProduct.value) {
          item.NOBBNumber = product.value.NobbNumber;
          item.Price = calculatedPrice(product.value);
          item.UnitForOrder = product.value.Unit;
          item.PriceListItemName = product.value.Name;
          item.PriceListName = product.value.PriceListName;
          item.IsModifiedRecently = true;
        }
        registerBuildingItemsBatchOperation(sameProduct.value, BatchType.Save);
        closeEvent();
      }
    };

    const computedValidPricesMatchesCount = computed(() => {
      let count = 0;
      if (projectCalculation.value?.BuildingElements) {
        projectCalculation.value.BuildingElements.forEach(element => {
          count += element.BuildingItems.filter(
            item => item.Status?.StatusType === ItemStatus.MatchWithPriceDiff
          ).length;
        });
      }
      return count;
    });

    const computedTotalItemsCount = computed(() => {
      let count = 0;
      if (projectCalculation.value?.BuildingElements) {
        projectCalculation.value.BuildingElements.forEach(element => {
          count += element.BuildingItems.length;
        });
      }
      return count;
    });

    const computedTotalNoNobbOnBuildingItem = computed(() => {
      let count = 0;
      if (projectCalculation.value?.BuildingElements) {
        projectCalculation.value.BuildingElements.forEach(element => {
          count += element.BuildingItems.filter(
            item => item.NOBBNumber === null || item.NOBBNumber === undefined
          ).length;
        });
      }
      return count;
    });

    const computedTotalHitWithUnitDiffBuildingItem = computed(() => {
      let count = 0;
      if (projectCalculation.value?.BuildingElements) {
        projectCalculation.value.BuildingElements.forEach(element => {
          count += element.BuildingItems.filter(
            item => item.Status?.StatusType === ItemStatus.MatchWithUnitDiff
          ).length;
        });
      }
      return count;
    });

    const computedTotalNoHitBuildingItem = computed(() => {
      let count = 0;
      if (projectCalculation.value?.BuildingElements) {
        projectCalculation.value.BuildingElements.forEach(element => {
          count += element.BuildingItems.filter(
            item =>
              item.Status?.StatusType === ItemStatus.MatchNoDiff &&
              item.NOBBNumber
          ).length;
        });
      }
      return count;
    });

    const clearPriceMatches = () => {
      if (projectCalculation.value?.BuildingElements) {
        projectCalculation.value.BuildingElements.forEach(element => {
          element.BuildingItems.forEach(item => {
            item.PriceMatch = null;
            item.Status = null;
          });
        });

        projectCalculation.value.BuildingElements.forEach(element => {
          element.BuildingItems.forEach(item => {
            if (!item.NOBBNumber) {
              item.Status = {
                StatusColor: 'dark-grey',
                StatusText: `${translate('info.no-nobbno')}`,
                StatusType: ItemStatus.MissingNobb,
              };
            }
          });
        });
      }
    };

    const reset = () => {
      pricesFetched.value = false;
      selected.value = [];
      searchablePriceListItems.value = undefined;

      clearPriceMatches();
    };

    const resetPriceMatches = () => {
      reset();
      chosenPriceList.value = undefined;
    };

    const toggleSingle = (item: BuildingItemCalculation) => {
      if (!selected.value) selected.value = [];

      const index = selected.value.findIndex(i => i.Id === item.Id);
      if (index >= 0) {
        selected.value.splice(index, 1);
      } else {
        selected.value.push(item);
      }
    };
    const isSelected = (item: BuildingItemCalculation) => {
      if (!selected.value) return;

      return selected.value.findIndex(i => i.Id === item.Id) >= 0;
    };

    const updatePriceMatchStatus = (
      elements: Array<BuildingElementCalculation>
    ) => {
      elements.forEach(element => {
        element.BuildingItems.forEach(item => {
          if (item.PriceMatch) {
            if (item.PriceMatch.HasDifferentUnit.value) {
              item.Status = {
                StatusColor: 'info',
                StatusText: `${translate('info.match-unit-diff')}`,
                StatusType: ItemStatus.MatchWithUnitDiff,
              };
            } else if (item.PriceMatch.HasPrice.value) {
              item.Status = {
                StatusColor: 'secondary',
                StatusText: `${translate('info.match-price-diff')}`,
                StatusType: ItemStatus.MatchWithPriceDiff,
              };
            }
          } else if (item.NOBBNumber) {
            item.Status = {
              StatusColor: 'primary',
              StatusText: `${translate('info.match-no-hit')}`,
              StatusType: ItemStatus.MatchNoDiff,
            };
          }
        });
      });
    };

    const setPriceMatches = (
      elements: Array<BuildingElementCalculation>,
      priceListItems: Array<SearchablePriceListItem>,
      withoutChecking?: boolean
    ) => {
      elements.forEach(element => {
        element.BuildingItems.forEach(item => {
          const priceListItem = priceListItems.find(
            pItem => item.NOBBNumber && item.NOBBNumber === pItem.NobbNumber
          );

          if (priceListItem) {
            const priceMatch: PriceMatch = {
              BuildingElementId: item.BuildingElementId ?? null,
              BuildingElementName: item.BuildingElement?.Name ?? null,
              BuildingItemId: item.Id,
              Name: item.Name,
              NobbNumber: item.NOBBNumber ?? null,
              Price: item.Price ?? null,
              BuildingItemPriceListItemName: item.PriceListItemName,
              BuildingItemPriceListName: item.PriceListName ?? null,
              UnitForOrder: item.UnitForOrder,
              PriceDifference: computed(() => {
                let diff = 0;
                if (priceMatch.Price && priceMatch.PriceListPrice) {
                  diff = priceMatch.PriceListPrice - priceMatch.Price;
                }
                return diff;
              }),
              HasDifferentUnit: computed(() => {
                let diffUnit = false;

                if (priceMatch.UnitForOrder && priceMatch.PriceListUnit) {
                  diffUnit = !areUnitsEqual(
                    priceMatch.UnitForOrder,
                    unitDescriptionConverter(priceMatch.PriceListUnit)
                  );
                }
                return diffUnit;
              }),
              HasPrice: computed(() => {
                let priceFound = false;

                if (
                  priceMatch.PriceListPrice != null &&
                  priceMatch.PriceListPrice !== 0
                ) {
                  priceFound = true;
                }
                return priceFound;
              }),
              ShouldAutoSelect: computed(() => {
                return (
                  priceMatch.HasPrice.value &&
                  !priceMatch.HasDifferentUnit.value
                );
              }),
              PriceListItemName: priceListItem.Name,
              PriceListUnit: priceListItem.Unit,
              DiscountPercentage: priceListItem.DiscountPercentage ?? null,
              BasePrice: priceListItem.BasePrice ?? null,
              PriceListPrice: 0,
            };

            if (priceListItem.BasePrice) {
              let discountPercentage = 0;

              if (priceListItem.DiscountPercentage) {
                discountPercentage = priceListItem.DiscountPercentage;
              }

              priceMatch.PriceListPrice =
                (priceListItem.BasePrice * (100 - discountPercentage)) / 100;
            }

            item.PriceMatch = priceMatch;
            if (priceMatch.ShouldAutoSelect.value && !withoutChecking) {
              toggleSingle(item);
            }
          }
        });
      });

      updatePriceMatchStatus(elements);
    };

    const fetchPrices = async () => {
      if (!chosenPriceList.value?.Id) return;

      const nobbs: Array<number> = [];

      reset();

      projectCalculation.value?.BuildingElements.forEach(element => {
        element.BuildingItems.forEach(bi => {
          if (bi.NOBBNumber) {
            nobbs.push(bi.NOBBNumber);
          }
        });
      });

      fetchingPrices.value = true;

      try {
        searchablePriceListItems.value = await updatePricesFromNOBBNumbers(
          chosenPriceList.value.Id,
          nobbs
        );
        pricesFetched.value = true;
      } catch (e) {
        console.log(e);
      } finally {
        fetchingPrices.value = false;
      }

      if (
        searchablePriceListItems.value &&
        projectCalculation.value !== undefined
      ) {
        setPriceMatches(
          projectCalculation.value.BuildingElements,
          searchablePriceListItems.value
        );
      }
    };

    const amountChange = (item: BuildingItemCalculation) => {
      if (
        searchablePriceListItems.value &&
        projectCalculation.value?.BuildingElements
      ) {
        registerItemSaveChangesWithAdditionAndParent(item);
        setPriceMatches(
          projectCalculation.value.BuildingElements,
          searchablePriceListItems.value,
          true
        );
      }
    };

    const valid = computed(() => {
      let canUpdate = true;

      if (!pricesFetched.value) {
        canUpdate = false;
      }

      if (!selected.value || selected.value.length === 0) {
        canUpdate = false;
      }

      return canUpdate;
    });

    const priceUpdate = async () => {
      if (!valid || !selected.value || !chosenPriceList.value?.Name) return;

      updatingPrices.value = true;
      await updateBuildingItemPrices(
        selected.value,
        chosenPriceList.value.Name
      );
      updatingPrices.value = false;
      resetPriceMatches();
    };

    const isEnabled = (item: BuildingItemCalculation) => {
      let enable = true;

      if (!pricesFetched.value || !item.NOBBNumber) {
        enable = false;
      }

      if (
        (item.Status && item.Status.StatusType === ItemStatus.MatchNoDiff) ||
        (item.Status && item.Status.StatusType === ItemStatus.MissingNobb)
      ) {
        enable = false;
      }

      return enable;
    };

    const updateProduct = (iwp: ItemWithProduct) => {
      if (
        searchablePriceListItems.value &&
        iwp.Item &&
        iwp.PriceListItem &&
        projectCalculation.value
      ) {
        iwp.Item.NOBBNumber = iwp.PriceListItem.NobbNumber;
        iwp.Item.Price = calculatedPrice(iwp.PriceListItem);
        iwp.Item.UnitForOrder = iwp.PriceListItem.Unit;
        iwp.Item.PriceListItemName = iwp.PriceListItem.Name;
        iwp.Item.PriceListName = iwp.PriceListItem.PriceListName;
        iwp.Item.IsModifiedRecently = true;
        registerBuildingItemBatchOperation(iwp.Item, BatchType.Save);

        setPriceMatches(
          projectCalculation.value.BuildingElements,
          searchablePriceListItems.value,
          true
        );
      }
    };

    const timeUpdated = (item: BuildingItemCalculation) => {
      if (searchablePriceListItems.value && projectCalculation.value) {
        registerBuildingItemBatchOperation(item, BatchType.Save);
        setPriceMatches(
          projectCalculation.value.BuildingElements,
          searchablePriceListItems.value,
          true
        );
      }
    };

    onUnmounted(() => {
      reset();
    });

    return {
      priceUpdate,
      fetchPrices,
      toggleSingle,
      isSelected,
      close,
      isEnabled,
      amountChange,
      updateProduct,
      timeUpdated,
      reset,
      resetPriceMatches,
      findItems,
      replaceAll,
      closeEvent,
      sameProduct,
      projectCalculation,
      pricelists,
      chosenPriceList,
      loading,
      valid,
      updating,
      fetchingPrices,
      pricesFetched,
      updatingPrices,
      selected,
      computedValidPricesMatchesCount,
      computedTotalItemsCount,
      computedTotalNoNobbOnBuildingItem,
      computedTotalHitWithUnitDiffBuildingItem,
      computedTotalNoHitBuildingItem,
    };
  },
});
