
















































































































































































































































































































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useProject } from '@/modules/project';
import NewProject from '@/components/projects/new-project/index.vue';
import NewCalculation from '@/components/projects/new-calculation/index.vue';
import moment from 'moment';
import {
  getIconByProfessionCode,
  getNameByProfessionCode,
} from '@/helpers/common';
import Calculations from '@/components/projects/calculations/index.vue';
import DeleteProject from '@/components/projects/delete-project/index.vue';
import { ProjectStartFilter } from '@/constants/enum';
import ExportProject from '@/components/projects/export-project/index.vue';
import ImportProject from '@/components/projects/import-project/index.vue';
import ImportCalculation from '@/components/projects/import-calculation/index.vue';
import { PROJECT_HEADERS } from '@/constants/table-headers';
import KalkHelper from '@/components/common/kalk-helper/index.vue';
import ContentBar from '@/components/common/content-bar/index.vue';
import { projectSumTotal } from '@/helpers/project';
import { ProjectOverview } from '@/models/projects/interfaces';

interface RowEvent {
  isExpanded: boolean;
}

export default defineComponent({
  components: {
    NewProject,
    NewCalculation,
    ImportCalculation,
    Calculations,
    DeleteProject,
    ExportProject,
    ImportProject,
    KalkHelper,
    ContentBar,
  },
  setup() {
    const {
      getProjects,
      setActiveProject,
      deleteProject,
      firstProject,
      filteredProjects,
      projectFilter,
    } = useProject();

    const searchValue = ref<string>('');
    const expanded = ref<Array<number>>([]);
    const loadingId = ref<Nullable<string>>();
    const loading = ref(false);

    const updateFilterWithProfessionCode = async (code: string) => {
      if (projectFilter.value && projectFilter.value.professionCode === code) {
        projectFilter.value.professionCode = undefined;
        return;
      }
      projectFilter.value.professionCode = code;
    };

    onMounted(async () => {
      loading.value = true;
      await getProjects();
      loading.value = false;
    });

    const handleClick = async (item: ProjectOverview) => {
      if (item.Id) {
        loadingId.value = item.Id;
        await setActiveProject(item.Id, true);
        loadingId.value = null;
      }
    };

    const clickRow = (item: number, event: RowEvent) => {
      if (event.isExpanded) {
        const index = expanded.value.findIndex(i => i === item);
        expanded.value.splice(index, 1);
      } else {
        expanded.value.push(item);
      }
    };

    return {
      getIconByProfessionCode,
      getNameByProfessionCode,
      handleClick,
      deleteProject,
      clickRow,
      projectSumTotal,
      updateFilterWithProfessionCode,
      moment,
      expanded,
      projectFilter,
      filteredProjects,
      searchValue,
      firstProject,
      PROJECT_HEADERS,
      ProjectStartFilter,
      loadingId,
      loading,
      documentationUrl: process.env.VUE_APP_DOCUMENTATION_URL,
    };
  },
});
