import { translate } from '@/localization/index';
import { CustomError, CustomErrorMessage } from '@/models/common/interfaces';
import { computed, reactive, toRefs } from '@vue/composition-api';
import { useCalculation } from './calculation';
import { useLoader } from './loader';

interface State {
  active: boolean;
  error?: CustomError;
  message?: CustomErrorMessage;
  reloadOption?: boolean;
}

const state = reactive<State>({
  active: false,
  error: undefined,
  message: undefined,
  reloadOption: undefined,
});

export const useErrorModal = () => {
  const errorModal = (
    error?: CustomError,
    message?: CustomErrorMessage,
    reloadOption?: boolean
  ) => {
    state.error = error;
    state.active = true;
    state.message = message;
    state.reloadOption = reloadOption;
  };

  const close = () => {
    state.active = false;
    state.error = undefined;
    state.message = undefined;
    state.reloadOption = undefined;
  };

  const reload = async (): Promise<void> => {
    const { initActiveCalculation, activeCalculation } = useCalculation();
    const { startLoader, stopLoader } = useLoader();

    if (activeCalculation?.value?.Id) {
      startLoader(`${translate('loader.loading-project')}`);
      await initActiveCalculation(activeCalculation.value.Id);
      close();
      stopLoader();
    }
  };

  const getMessageFromErrorCode = computed(() => {
    if (!state.error?.status) return translate('errors.message-no-status');
    switch (state.error.status) {
      case 405:
        return translate('errors.message-405');
      case 409:
        return translate('errors.message-409');
      case 500:
        return translate('errors.message-500');
      default:
        return translate('errors.message-default');
    }
  });

  const getTitleFromErrorCode = computed(() => {
    if (!state.error?.status) return 'Unknown issue!';
    switch (state.error.status) {
      case 405:
        return translate('errors.title-405');
      case 409:
        return translate('errors.title-409');
      case 500:
        return translate('errors.title-500');
      default:
        return translate('errors.title-default');
    }
  });

  return {
    errorModal,
    close,
    reload,
    ...toRefs(state),
    getMessageFromErrorCode,
    getTitleFromErrorCode,
  };
};
