
































import { License } from '@/models/admin/interfaces';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    license: {
      type: Object as PropType<License>,
      required: true,
    },
  },
});
