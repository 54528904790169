



























































































































































































































































































































































































import { ProjectOverview } from '@/models/projects/interfaces';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import { getIconByProfessionCode } from '@/helpers/common';
import DeleteProject from '@/components/projects/delete-project/index.vue';
import LockCalculation from '@/components/projects/lock-project/index.vue';
import ExportProject from '@/components/projects/export-project/index.vue';
import EditProject from '@/components/projects/edit-project/index.vue';
import Loader from '@/components/loader/index.vue';
import moment from 'moment';
import router from '@/router/index';
import {
  PROJECT_CALCULATION_PATH,
  PROJECT_CALCULATION_OVERVIEW_PATH,
} from '@/constants/routes';
import { useCalculation } from '@/modules/calculation';
import { useProject } from '@/modules/project';
import CopyToAnotherProject from '@/components/projects/copy-project/index.vue';
import {
  PROJECT_HEADERS,
  CALCULATION_HEADERS_SMALL,
} from '@/constants/table-headers';
import ContentBar from '@/components/common/content-bar/index.vue';
import { safePercentage } from '@/helpers/calculation';

interface RowEvent {
  isExpanded: boolean;
  item: ProjectOverview;
}

export default defineComponent({
  components: {
    DeleteProject,
    LockCalculation,
    ExportProject,
    CopyToAnotherProject,
    ContentBar,
    EditProject,
    Loader,
  },
  props: {
    calculations: {
      type: Array as PropType<Array<ProjectOverview>>,
      required: true,
    },
    parent: {
      type: Object as PropType<ProjectOverview>,
      required: true,
    },
    showHeaders: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    hideTitle: {
      type: Boolean,
    },
    importCalculation: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const {
      setActiveProject,
      projects,
      buildingTotals,
      activeProjectOverview,
    } = useProject();
    const { setActiveCalculation, loading } = useCalculation();
    const loadingId = ref<Nullable<string>>();

    const traverse = async (path: string, calculation: ProjectOverview) => {
      loadingId.value = calculation.Id;
      try {
        if (props.parent.Id) {
          if (
            !activeProjectOverview.value ||
            activeProjectOverview.value.Id !== props.parent.Id
          ) {
            await setActiveProject(props.parent.Id, false);
          }
          await setActiveCalculation(calculation);
          await router.push(path);
        }
      } catch (e) {
        console.log(e);
      }
      loadingId.value = null;
    };

    const emitImport = (calculation: ProjectOverview) => {
      emit('importCalculation', calculation);
    };

    const dblClickRow = (mouseEvent: MouseEvent, dblClickEvent: RowEvent) => {
      traverse(PROJECT_CALCULATION_PATH, dblClickEvent.item);
    };

    return {
      traverse,
      getIconByProfessionCode,
      dblClickRow,
      emitImport,
      safePercentage,
      moment,
      PROJECT_CALCULATION_PATH,
      PROJECT_CALCULATION_OVERVIEW_PATH,
      loading,
      projects,
      PROJECT_HEADERS,
      CALCULATION_HEADERS_SMALL,
      loadingId,
      buildingTotals,
    };
  },
});
