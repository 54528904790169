
















































import { ref, defineComponent, PropType, computed } from '@vue/composition-api';
import Confirm from '@/components/common/confirm/index.vue';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import { useLibrary } from '@/modules/library';

export default defineComponent({
  components: {
    Confirm,
  },
  props: {
    isMenuitem: {
      type: Boolean,
    },
    text: {
      type: Boolean,
    },
    template: {
      type: Object as PropType<BuildingTypeTemplate>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const loading = ref(false);

    const valid = computed(() => {
      return props.template && props.template.Id;
    });

    const deleteEvent = async () => {
      const {
        deleteBuildingTypeTemplate,
        initCalculationLibrary,
      } = useLibrary();
      if (props.template.Id) {
        loading.value = true;
        const success = await deleteBuildingTypeTemplate(props.template.Id);

        if (success) {
          initCalculationLibrary();
          emit('update', props.template.ProfessionCode);
          loading.value = false;
          dialog.value = false;
        }
      }
    };

    return {
      deleteEvent,
      dialog,
      loading,
      valid,
    };
  },
});
